import React, { useEffect, useState } from "react";
import { Outlet, Link, useLocation } from "react-router-dom";
import Breadcrums from "../../../common/breadcrumbs";
import { Button, Modal } from "rsuite";
import { useFormik } from "formik";
import * as languageFilesService from "../../../services/languagefiles.services";
import Swal from "sweetalert2";
import { SWAL_SETTINGS } from "../../../../../utils/Constants";
import { globalLoader, handleServerValidations } from "../../../../../utils/commonfunction";

const LanguageFiles = () => {
  const loc = useLocation();
  const [secondModal, setSecondModal] = useState(false);
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Language Files", url: "" },
  ];
  const formik = useFormik({
    initialValues: {
      backend: [],
      frontend: [],
    },
    onSubmit: (values) => {
      let formData = new FormData();
      formData.append("backend", JSON.stringify(values?.backend));
      formData.append("frontend", JSON.stringify(values?.frontend));
      languageFilesService
      .UpdateLanguageKeys(formData)
      .then((response) => {
        if (response.success) {
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
          formik.resetForm();
        } else {
          Swal.fire({
            icon: "error",
            text: handleServerValidations(response),
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((error) => {
        console.log("error ====> ", error);
      });
    },
  });

  const handleClick = () => {
    globalLoader(true);
    languageFilesService
      .LanguageKeys()
      .then((response) => {
        globalLoader(false);
        if (response.success) {
          if(response.data.new_keys) {
            formik.setFieldValue("backend", response?.data?.new_keys?.backend);
            formik.setFieldValue("frontend", response?.data?.new_keys?.frontend);
            Swal.fire({
              icon: "warning",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          }else{
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          }
        }
      })
      .catch((error) => {
        console.log("error ====> ", error);
      });
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h6 className="main-content-label">Language Files</h6>
                <div>
                  <button
                    className="btn btn-success me-2"
                    type="button"
                    onClick={() => {
                      setSecondModal(true);
                      handleClick();
                    }}
                  >
                    Sync Translation
                  </button>
                </div>
              </div>
              <div className="tabs-wrap">
                <div className="profile-tab tab-menu-heading mb-4">
                  <nav className="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
                    <Link
                      className={
                        "nav-link linkactive " +
                        (loc.pathname === "/admin/cms/language-files/frontend"
                          ? " active"
                          : "")
                      }
                      aria-current="page"
                      to={"/admin/cms/language-files/frontend"}
                    >
                      Frontend Files
                    </Link>
                    <Link
                      className={
                        "nav-link linkactive " +
                        (loc.pathname === "/admin/cms/language-files/backend"
                          ? " active"
                          : "")
                      }
                      to={"/admin/cms/language-files/backend"}
                    >
                      Backend Files
                    </Link>
                  </nav>
                </div>
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size={"lg"}
        className="invitation-modal modal-height-auto"
        open={Boolean(formik.values?.backend?.length || formik.values?.frontend?.length)}
        onClose={() => formik.resetForm()}
        backdrop={"static"}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>New Translation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <form onSubmit={formik.handleSubmit}>
              <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <div className="row">
                  {formik?.values?.backend?.length ? (
                    <>
                      <h5>Backend Keys</h5>
                      <div className="table-responsive">
                        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Backend Key</th>
                              <th>EN Value</th>
                              <th>DE Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formik?.values?.backend.map((item, index) => (
                              <tr key={index}>
                                <td>{index+1}</td>
                                <td>{item.key}</td>
                                <td>
                                  <input
                                    name={`backend[${index}].en_value`}
                                    id={"backend-en" + index}
                                    type="text"
                                    value={item.en_value}
                                    onChange={formik.handleChange}
                                    className="form-control"
                                  />
                                </td>
                                <td>
                                  <input
                                    name={`backend[${index}].de_value`}
                                    id={"backend-de" + index}
                                    type="text"
                                    value={item.de_value}
                                    onChange={formik.handleChange}
                                    className="form-control"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : null}
                  {formik?.values?.frontend?.length ? (
                    <>
                      <h5>Frontend Keys</h5>
                      <div className="table-responsive">
                        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100 mt-4">
                          <thead>
                            <tr>
                              <th>#</th>
                              <th>Frontend Key</th>
                              <th>EN Value</th>
                              <th>DE Value</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formik?.values?.frontend.map((item, index) => (
                              <tr key={index}>
                                <td>{index+1}</td>
                                <td>{item.key}</td>
                                <td>
                                  <input
                                    name={`frontend[${index}].en_value`}
                                    id={"frontend-en" + index}
                                    type="text"
                                    value={item.en_value}
                                    onChange={formik.handleChange}
                                    className="form-control"
                                  />
                                </td>
                                <td>
                                  <input
                                    name={`frontend[${index}].de_value`}
                                    id={"frontend-de" + index}
                                    type="text"
                                    value={item.de_value}
                                    onChange={formik.handleChange}
                                    className="form-control"
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : null}
                </div>
                <Modal.Footer className="mt-4">
                  <div className="d-flex justify-content-between align-items-center">
                    <p style={{ marginBottom: 0 }}>
                      Note: Please make changes according you.
                    </p>
                  </div>
                  <Button
                    onClick={() => {
                      formik.resetForm();
                    }}
                    appearance="subtle"
                  >
                    Close
                  </Button>
                  <Button appearance="primary" type="submit">
                    Submit
                  </Button>
                  <div></div>
                </Modal.Footer>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LanguageFiles;
