import axiosInstance from "../../../utils/axios";

const path = "admin/global-settings"

export const Add = async (values) => {
    return await axiosInstance().post(`${path}/add`, values);
}

export const Details = async () => {
    return await axiosInstance().get(`${path}/details`);
}