import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import * as eventsService from "../../services/events.services";
import Loader from "../../common/loader";
import {
  formateDate,
  getLocalizedDate,
  addSpecialCharacter,
  removeCommas,
  globalLoader,
  writeMyExcel,
} from "../../../../utils/commonfunction";
import Sorting from "../../common/sorting";
import CustomPagination from "../../common/custompagination";
import {
  Modal
} from "rsuite";
const ViewAgenda = (props) => {
  const params = useParams();
  const [sorting, setSorting] = useState({});
  const [loader, setLoader] = useState(false);
  const [agendaList, setAgendaList] = useState([]);
  const [itemperpage] = useState(10);
  const [open, setOpen] = useState([]);
  const [page, setPage] = useState(1);
  const [statsupdate] = useState(false);
  const [csvHeaders, setCsvHeaders] = useState(["Creation Date", "Change Date", "Agenda","Element Name","Guest Type", "Salutation", "Title", "First Name", "Last Name", "Email"]);
  const [allUsersExportFlag] = useState(false);
  const [csvHeadersNew] = useState(["Element Name", "Total Quantity", "Sold Quantity", "Available Quantity"]);
  const [datalength, setDataLength] = useState("");


  useEffect(() => {
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", itemperpage);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("event_id", params.id);
    eventsService
      .ListAgenda(formData)
      .then((response) => {
        setLoader(false);
        if (response?.success) {
          setAgendaList(response?.data?.list);
          setDataLength(response?.data?.total_records);
          setPage(response && response?.data && response?.data?.page ? response.data.page : 1);
        }
      })
      .catch((err) => console.log("err", err));
  }, [sorting, page, statsupdate, itemperpage, params.pgno, params.id]);

  
  const exportAllUsers = (type="all", agendaId) => {
    globalLoader(true);
    const formData = new FormData();
    formData.append("page", 1);
    formData.append("per_page", datalength);
    formData.append("sort", JSON.stringify(sorting));
    if(type === "single"){
      formData.append("agenda_id", agendaId);
      formData.append("event_id", params?.id);
      formData.append("type", type);
    }else{
      formData.append("event_id", params?.id);
    }
    eventsService
      .ReportAgenda(formData)
      .then((response) => {
        let data = response &&  response?.data ? response.data : [];
        if(data?.length > 0){
          let row = data.map((item => {
            const tempRow = [
            removeCommas(formateDate(item?.createdAt)),
            removeCommas(formateDate(item?.updatedAt)),
            removeCommas(item?.agenda_name) ? removeCommas(item.agenda_name) : "N/A",
            removeCommas(item?.element_data?.name) ? removeCommas(item.element_data?.name) : "N/A",
            removeCommas(item?.user_type) ? removeCommas(item.user_type) : "N/A",
            removeCommas(item?.user_data?.title) ? removeCommas(item.user_data.title) : "N/A",
            removeCommas(item?.user_data?.gender) ? removeCommas(item.user_data.gender) : "N/A",
            removeCommas(item?.user_data?.first_name) ? removeCommas(item.user_data.first_name) : "N/A",
            removeCommas(item?.user_data?.last_name) ? removeCommas(item.user_data.last_name) : "N/A",
            removeCommas(item?.user_data?.email) ? removeCommas(item.user_data.email) : "N/A",
            ];
            const tempData = [...csvHeaders];
            setCsvHeaders([...tempData]);
            return tempRow;
          }));;
          writeMyExcel([csvHeaders, ...row], `Agenda-${addSpecialCharacter(agendaList[0]?.event_data?.title)}.xlsx`).then(() => {
            setTimeout(() => {
              globalLoader(false);
            }, 1000);
            }).catch((err)=>{
              console.log(err);
            });
        }
      }).catch((error) => {
        console.log("error ====> ", error);
        globalLoader(false);
      });
  };

  const handleSort = (e, column) => {
    ;
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };

  /** This function is used to export elements data start */
  const exportUser = (row) => {
    let data = [
      csvHeadersNew,
      [
        removeCommas(row?.name) ? removeCommas(row.name) : "N/A",
        removeCommas(row?.total_quantity) ? removeCommas(row?.total_quantity) : "0",
        removeCommas(row?.sold_quantity) ? removeCommas(row?.sold_quantity) : "0",
        removeCommas(row?.available_quantity) ? removeCommas(row?.available_quantity) : "0",
      ],
    ];
    writeMyExcel(data, `${row?.name}.xlsx`).then(() => {
      globalLoader(false);
    }).catch((err) => {
      console.log(err);
    });
    // return data;
  };
  /** This function is used to export elements data end */


  return (
    <div className="row">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="main-content-label"><span></span></h6>
        <div className="d-flex align-items-center">
          {
            allUsersExportFlag ?
              <>
                <button className="btn ripple btn-main-primary signbtn mr-2" disabled = {datalength === 0} onClick={exportAllUsers}>Export Report</button>
                {/* <CSVLink
                  ref={exportAllButton}
                  style={{ display: 'none' }}
                  data={allUsersToExport}
                  asyncOnClick={true}
                  headers={csvHeaders}
                  // filename={"Agenda-.xlsx"}
                  filename={`Agenda-${addSpecialCharacter(agendaList[0]?.event_data?.title)}.${EXPORT_EXTENSION_TYPE}`}

                >
                  Export Report
                </CSVLink> */}
              </>
              : <button className="btn ripple btn-main-primary signbtn mr-2" disabled={datalength === 0} onClick={exportAllUsers}>Export Report</button>
          }
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
          <thead>
            <tr>
              {/* <th className="sr_head">S.No</th> */}
              <th>
                <div className="sorting_column">
                  <span>Name</span>
                  <Sorting
                    sort={sorting}
                    handleSort={handleSort}
                    column="name"
                  />
                </div>
              </th>
              <th>Minimum bookable element</th>
              <th>Maximum bookable element</th>
              <th>Elements</th>
              <th>Start Date</th>
              <th>Start Time</th>
              <th>End Time</th>
              <th className="action_head">Action</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={9}>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {agendaList.length ? (
                  agendaList.map((row, i) => {
                    return (
                      <tr key={i}>
                        {/* <td>{serialno + (i + 1)}</td> */}
                        <td>{row.name ? row.name : "N/A"}</td>
                        <td>
                          {row.minimum_bookable_element
                            ? row.minimum_bookable_element
                            : "0"}
                        </td>
                        <td>
                          {row.maximum_bookable_element
                            ? row.maximum_bookable_element
                            : "0"}
                        </td>
                        <td>
                          {row.elements.length ? row.elements.length : "0"}
                        </td>
                        <td>
                          {row.start_date
                            ? row.start_date.split("T")[0]
                            : "N/A"}
                        </td>
                        <td>
                          {row.start_time
                            ? getLocalizedDate(row.start_time)
                            : "N/A"}
                        </td>
                        <td>
                          {row.end_time
                            ? getLocalizedDate(row.end_time)
                            : "N/A"}
                        </td>
                        <td>
                          <div className="d-flex">
                            <button
                              className="btn ripple btn-main-primary signbtn"
                              onClick={() => {
                                setOpen(row.elements);
                              }}
                            >
                              View Elements
                            </button>
                            <button className="btn ripple btn-main-primary signbtn ms-2" onClick={() => { exportAllUsers('single', row._id) }}>Export</button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={9} className="text-center">
                      No records
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="" id="example1_info" role="status" aria-live="polite">
        <b>Total Records : {datalength ? datalength : "0"}</b>
      </div>
      {datalength && datalength > 0 ? (
        <CustomPagination
          datalength={datalength}
          itemperpage={itemperpage}
          currentPage={page}
          setPage={setPage}
          pageRoute={[
            {
              name: "Events",
              path: `/admin/event-management/events/view/agenda/${params.id}`,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Modal
        size={"lg"}
        open={open.length !== 0}
        backdrop={'static'}
        onClose={() => {
          setOpen([]);
        }}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Elements</Modal.Title>
        </Modal.Header>
        <div className="table-responsive">
          <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
            <thead>
              <tr>
                <th>Name</th>
                <th>Total Quantity</th>
                <th>Sold Quantity</th>
                <th>Available Quantity</th>
                <th className="action_head">Actions</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <td colSpan={5}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {open.length ? (
                    open.map((row, i) => {
                      return (
                        <tr key={i}>
                          <td>{row?.name ? row.name : "N/A"}</td>
                          <td>{row?.total_quantity ? row.total_quantity : 0}</td>
                          <td>{row?.sold_quantity ? row.sold_quantity : 0}</td>
                          <td>
                            {row?.available_quantity
                              ? row?.available_quantity
                              : 0}
                          </td>
                          <td>
                            <div className="d-flex">
                              {/* <CSVLink
                                  className="btn ripple btn-main-primary signbtn mt-2 ms-2 mb-2"
                                  filename={`Agenda-element-${addSpecialCharacter(agendaList[0]?.event_data?.title)}.${EXPORT_EXTENSION_TYPE}`}
                                  data={exportUser(row)}
                                >
                                  Export
                                </CSVLink> */}
                              <a
                                href="/"
                                className="btn ripple btn-main-primary signbtn mt-2 ms-2 mb-2"
                                onClick={(e) => {
                                  e.preventDefault();
                                  exportUser(row)
                                }}
                              >
                                Export
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={5} className="text-center">
                        No records
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default ViewAgenda;
