import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrums from "../../common/breadcrumbs";
import { SWAL_SETTINGS } from "../../../../utils/Constants";
import { handleServerValidations, minutesInterval } from "../../../../utils/commonfunction";
import * as Yup from "yup";
import CustomCkeditor from "../../common/customeditor";
import { RoomsDetails, RoomsEdit } from "../../services/roommanagement.services";
import * as questionSetServices from "../../services/questionset";
import CustomDatetimepicker from "../../common/customDatetimepicker";
import * as eventServices from "../../services/events.services";
import { NameBadgeEdit } from "../../services/namebadge.services";

const RoomManagementEdit = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});
  const [dataLoaded, setDataLoaded] = useState(false);
  const [saveType, setSaveType] = useState("");
  const [roomBookingQueSetList, setRoomBookingQueSetList] = useState([]);
  const [id, setId] = useState("");
  const [eventOptions, setEventOptions] = useState([]);
  const breadcrumbs = [
    { title: "Dashboard", url: "/dashboard" },
    {
      title: "Room Management ",
      url: "/admin/room-management/list/1",
    },
    { title: "Edit", url: "" },
  ];
  const formikRef = useRef();


  const hrminutesInterval = minutesInterval()

  useEffect(() => {
    RoomsDetails(params.id)
      .then((response) => {
        setShowDefault(response && response.data ? response.data : []);
        setTimeout(() => {
          setDataLoaded(true);
        }, 100);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, []);

  useEffect(() => {
    questionSetServices
      .Options({ category: "room_booking_registration" })
      .then((response) => {
        if (response.success) {
          setRoomBookingQueSetList(
            response && response.data ? response.data : []
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  useEffect(() => {
    eventServices
      .Options()
      .then((response) => {
        if (response.success) {
          setEventOptions(response?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Room name is required")
      .test("no-spaces", "Room name is required", (value) => value.trim()),
    question_set: Yup.string()
      .required("Question set is required")
      .test("no-spaces", "Question set is required", (value) => value.trim()),
    description: Yup.string()
      .required("Description is required")
      .test("no-spaces", "Description  is required", (value) => value.trim()),
    capacity: Yup.string()
      .required("Capacity is required")
      .test("no-spaces", "Capacity is required", (value) => value.trim()),
    bookable_interval: Yup.string()
      .required("This is required")
      .test("no-spaces", "This is required", (value) => value.trim()),
    start_date: Yup.string()
      .required("Start date is required")
      .test("no-spaces", "Start date is required", (value) => value.trim()),
    end_date: Yup.string()
      .required("End date is required")
      .test("no-spaces", "End date is required", (value) => value.trim()),
    event_id: Yup.string()
    .required("This field is required"),
  });

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <Formik
        enableReinitialize
        initialValues={{
          name: showdefault && showdefault.name ? showdefault.name : "",
          description:
            showdefault && showdefault.description
              ? showdefault.description
              : "",
          question_set:
            showdefault && showdefault.question_set
              ? showdefault.question_set
              : "",
          capacity:
            showdefault && showdefault.capacity ? showdefault.capacity : "",
          start_date:
            showdefault && showdefault.start_date
              ? new Date(showdefault?.start_date)
              : "",
          end_date:
            showdefault && showdefault.end_date
              ? new Date(showdefault?.end_date)
              : "",
          bookable_interval:
            showdefault && showdefault.bookable_interval
              ? showdefault.bookable_interval
              : "",
          event_id:
            showdefault && showdefault.event_id ? showdefault.event_id : "",
        }}
        innerRef={formikRef}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          let formData = new FormData();
          formData.append("name", values.name);
          formData.append("question_set", values.question_set);
          formData.append("description", values.description);
          formData.append("capacity", values.capacity);
          formData.append("bookable_interval", values.bookable_interval);
          formData.append("start_date", values.start_date);
          formData.append("end_date", values.end_date);
          formData.append("event_id", values.event_id);
          formData.append('o_id', params.id);
          RoomsEdit(formData)
            .then((response) => {
              setSubmitting(false);
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
                if (saveType !== 'Save') {
                    setTimeout(() => {
                        resetForm({ values: "" });
                        navigate(`/admin/room-management/list/1`);
                    }, 1000);
                }
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
              }
            })
            .catch((error) => {
              console.log("error ====> ", error);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            {console.log("*****",errors)}
            <div className="row row-sm">
              <div className="col-lg-12 col-md-12 animation_fade">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">
                        Room Management{" "}
                      </h6>
                    </div>
                    <div className="row row-sm">
                      <div className="col-md-6 text-center form-group">
                        <label htmlFor="name" className="text-left d-flex">
                          Name Of Room:<span className="requirestar">*</span>{" "}
                        </label>
                        <input
                          name="name"
                          id="name"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.name}
                          className="form-control"
                        />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"name"} />
                        </span>
                      </div>
                      <div className="col-lg-6 text-center form-group">
                        <label htmlFor="slug" className="text-left d-flex">
                          Question Set:<span className="requirestar">*</span>
                        </label>
                        <div className="select-down-arrow ">
                          <Field
                            as="select"
                            id="question_set"
                            name={"question_set"}
                            className="form-control select2"
                          >
                            <option value="" label="Select question set">
                              Select{" "}
                            </option>
                            {roomBookingQueSetList &&
                              roomBookingQueSetList.map((option, i) => {
                                return (
                                  <option key={i} value={option._id}>
                                    {option.title}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"question_set"} />
                        </span>
                      </div>
                      <div className="col-lg-12 text-center form-group">
                        <label htmlFor="subject" className="text-left d-flex">
                          Description:
                          <span className="requirestar">*</span>
                        </label>
                        {dataLoaded ? (
                          <CustomCkeditor
                            fieldname={"description"}
                            setFieldValue={setFieldValue}
                            value={values.description}
                            setFieldTouched={setFieldTouched}
                          />
                        ) : null}
                        <span className="text-danger d-flex text-left">
                          {errors.description &&
                            touched.description &&
                            errors.description}
                        </span>
                      </div>
                      <div className="col-md-6 text-center form-group">
                        <label htmlFor="capacity" className="text-left d-flex">
                          Capacity:<span className="requirestar">*</span>{" "}
                        </label>
                        <input
                          name="capacity"
                          id="capacity"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.capacity}
                          className="form-control"
                        />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"capacity"} />
                        </span>
                      </div>
                      {/* <div className="col-md-6 text-center form-group">
                        <label
                          htmlFor="bookable_interval"
                          className="text-left d-flex"
                        >
                          Bookable Intervals:
                          <span className="requirestar">*</span>{" "}
                        </label>
                        <input
                          name="bookable_interval"
                          id="bookable_interval"
                          type="number"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.bookable_interval}
                          className="form-control"
                        />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"bookable_interval"} />
                        </span>
                      </div> */}
                       <div className='col-md-6 text-center form-group'>
                          <label htmlFor='bookable_interval' className='text-left d-flex'>Bookable Intervals:<span className="requirestar">*</span> </label>
                          <select
                            id="bookable_interval"
                            onChange={handleChange}
                            name={"bookable_interval"}
                            value={values.bookable_interval}
                            className={"form-control text-capitalize"}
                          >
                            <option label="Select bookable intervals">
                             ""
                            </option>
                            {hrminutesInterval &&
                              hrminutesInterval.map((option, i) => {
                                return (
                                  <option key={i} value={option.value}>
                                    {option.label}
                                  </option>
                                );
                              })}
                          </select>
                        <span className='text-danger d-flex text-left'><ErrorMessage name={"bookable_interval"} /></span>
                      </div>
                      <div className="col-md-6 text-center form-group">
                        <label
                          htmlFor="start_date"
                          className="text-left d-flex"
                        >
                          Start Date :<span className="requirestar">*</span>{" "}
                        </label>
                        <CustomDatetimepicker
                          placeholder={"Start date"}
                          setField={setFieldValue}
                          fieldname={`start_date`}
                          setFieldTouched={setFieldTouched}
                          value={values.start_date}
                          correspondingEndDate={formikRef.current?.values?.end_date}
                          correspondingEndDateName={'end_date'}
                          endDateValue={values.end_date}
                        />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"start_date"} />
                        </span>
                      </div>
                      <div className="col-md-6 text-center form-group">
                        <label
                          htmlFor="start_date"
                          className="text-left d-flex"
                        >
                          End Date :<span className="requirestar">*</span>{" "}
                        </label>
                        <CustomDatetimepicker
                          placeholder={"End date"}
                          setField={setFieldValue}
                          fieldname={`end_date`}
                          setFieldTouched={setFieldTouched}
                          value={values.end_date}
                          endDate={true}
                          correspondingStartDate={formikRef?.current?.values?.start_date}
                        />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"end_date"} />
                        </span>
                      </div>
                      <div className="col-lg-6 text-center form-group">
                        <label className="text-left d-flex">
                          Select Event:<span className="requirestar">*</span>
                        </label>
                        <div className="form-group">
                          <div className="select-down-arrow">
                            <select
                              className="form-control select2"
                              value={values.event_id}
                              onChange={(e) => {
                                setFieldValue("event_id", e.target.value);
                                setId(e?.target?.value);
                              }}
                            >
                              <option value="" label="Select">
                                Select{" "}
                              </option>
                              {eventOptions &&
                                eventOptions.length > 0 &&
                                eventOptions.map((option, i) => {
                                  return (
                                    <option key={i} value={option._id}>
                                      {option.title}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={"event_id"} />
                          </span>
                        </div>
                      </div>
                      <div className="mt-5">
                        <button
                          onClick={() => {
                            setSaveType("Save");
                          }}
                          className="btn btn-info mr-2"
                          type="submit"
                        >
                          <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setSaveType("");
                          }}
                          className="btn btn-success mr-2"
                          type="submit"
                        >
                          <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                          Save & Exit
                        </button>
                        <button
                          className="btn ripple btn-secondary"
                          type="button"
                          onClick={() => navigate(-1)}
                        >
                          <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default RoomManagementEdit;
