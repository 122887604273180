import React from 'react'

const Footer = () => {
    return (
        <div className="main-footer text-center">
            <div className="container">
                <div className="row row-sm">
                    <div className="col-md-12">
                        <span>Copyright © 2023 <a href="/admin/dashboard">Event Management</a>. Designed by <a href="/admin/dashboard">Event Management</a> All rights reserved.</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer;