import { getLocalKey } from "./commonfunction";

export const CKEDITOR_CONFIG = {
    filebrowserUploadUrl: `${process.env.REACT_APP_API_URL}/admin/gallery/upload-ck-image`,
    allowedContent: true,
    fileTools_requestHeaders: {
        Authorization: `Bearer ${localStorage.getItem("user")?JSON.parse(localStorage.getItem("user")).token:""}`,
    },
    extraAllowedContent: '*[*]',
    toolbar: [
        { name: 'source', items: ['Source'] },
        { name: 'functions', items: ['NewPage', 'ExportPdf', 'Print', 'Preview',] },
        { name: 'clipboard', items: ["Cut", "Copy", "Paste", "CopyFormatting"] },
        { name: 'miscellaneous', items: ["Undo", "Redo"] },
        { name: 'text-corrections', items: ['Find', 'Replace', 'SelectAll', 'Scayt'] },
        { name: 'basic-styles', items: ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript'] },
        { name: 'paragraph', items: ['NumberedList', 'BulletedList', 'Outdent', 'Indent', 'Blockquote'] },
        { name: 'alignment', items: ['JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', 'BidiLtr', 'BidiRtl'] },
        { name: 'links', items: ['Link', 'Unlink', 'Anchor', 'Image'] },
        { name: 'table', items: ['Table', 'TableToolbar'] },
        { name: 'styles', items: ['Font', 'FontSize', 'Format', 'TextColor', 'BGColor', 'PageBreak'] },
        { name: 'forms', items: ['Form', "Radio", "Checkbox", "TextField", "Textarea", "Select",'MediaButton'] },
        { name: 'configs', items: ['Smiley', 'SpecialChar', 'Iframe', 'Maximize'] }
    ]
};
export const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const DEVMODE = { USERNAME: "DEV@ADMIN", PASSWORD: "Dev@1234", SESSION_TIME: 300000 /** 5 MINUTES */ };
export const SWAL_SETTINGS = {
    customClass: 'swal-wide',
    position: 'top-right',
    showConfirmButton: false,
    timer: 7000,
    toast: true,
    showClass: {
        popup: 'animate__animated animate__bounceInRight'
    },
    hideClass: {
        popup: 'animate__animated animate__bounceOutRight'
    },
    showCloseButton: true,
    // timerProgressBar: true,
};
export const FONTOPTIONS = [
    { value: "Arial(sans - serif)", label: "Arial(sans - serif)" },
    { value: " Verdana(sans - serif)", label: " Verdana(sans - serif)" },
    { value: "Tahoma(sans - serif)", label: "Tahoma(sans - serif)" },
    { value: "Trebuchet MS(sans - serif)", label: "Trebuchet MS(sans - serif)" },
    { value: "Times New Roman(serif)", label: "Times New Roman(serif)" },
    { value: "Georgia(serif)", label: "Georgia(serif)" },
    { value: "Garamond(serif)", label: "Garamond(serif)" },
    { value: "Courier New(monospace)", label: "Courier New(monospace)" },
    { value: "Brush Script MT(cursive)", label: "Brush Script MT(cursive)" }
]
export const showFilterlist = [{ name: "Status", status__id: "" }, { name: "Active", status__id: "1" }, { name: "Inactive", status__id: "0" }]
export const showFilterOfInvitations = [{ name: "Type", status__id: "" }, { name: "Schedule", status__id: "schedule" }, { name: "Sent", status__id: "send_now" }, { name: "Send Later", status__id: "send_later" }]
export const categoryList = [{ key: "cat1", label: "Category1" }, { key: "cat2", label: "Category2" },]
export const LMOfficeInvitations = [{ name: "Status", status__id: "" }, { name: "Success", status__id: "success" }, { name: "Pending", status__id: "pending" }, { name: "Reject", status__id: "reject" }]

export const filterOfInvitations = [{ name: "Status", status__id: "" }, { name: "No Reply", status__id: 0 }, { name: "Will Attend", status__id: 1 }, { name: "Declined", status__id: 2 }]
export const TICKET_TYPES = {
    EVENT: "event",
    AGENDA: "agenda",
    HOTEL: "hotel"
};
export const SURVEY_TYPES = {
    SURVEY: "survey",
    QUESTION_SET: "question-set"
};
export const QUESTION_TYPES = {
    MULTIPLE_CHOICE: "multiple_choice",
    SINGLE_CHOICE: "single_choice",
    SHORT_ANS: "short_ans",
    LONG_ANS: "long_ans",
    SLIDER: "slider",
    EMOJI: "emoji",
    DROPDOWN: "dropdown",
    RATING: "rating",
    LIKE_DISLIKE: "likeStatus",
    TIME: "time",
    CALENDAR: "calendar",
    SIGNATURE: "signature",
    FREE_HAND_DRAWING: "free_hand_drawing",
    VOICE_MEMO: "voice_memo",
    PHOTO_UPLOAD: "photo_upload",
    VIDEO_UPLOAD: "video_upload",
    EXTRA_INFORMATION: "extra_information"
};

// export const GENDER = localStorage.getItem("i18nextLng","en") === "en" ? {
//     Mr: "Mr",
//     Mrs: "Mrs",
//     Mx: "Mx"
// } : {
//     Mr: "Herr",
//     Mrs: "Frau",
//     Mx: "Divers"
// };

export const GENDER_DE = {
    Mr: "Herr",
    Mrs: "Frau",
    Mx: "Divers"
}

export const GENDER = {
    Mr: "Mr",
    Mrs: "Mrs",
    Mx: "Mx"
} 


export const LEADTYPES = {
    Draft: "true",
    Completed: "false",
}

export const ROLE = {
    SUPER_ADMIN: 1,
    SUB_ADMIN: 2,
    USER: 3,
    STAFF: 4
};

export const USER_TYPE = {
    Primary: "primary",
    Guest: "secondary"
}

export const REGISTRATION_TYPE = {
    DEACTIVE: 0,
    ACTIVE: 1,
    CANCELLED: 2,
}

export const EMAIL_TEMPLATE_TYPES = {
    DEFAULT: "default",
    PROMOTIONAL: "promotional",
    LM_BACK_OFFICES: "lm_back_office",
    USER_INVITATION: "user_invitation",
};

export const EMAIL_STATUS = {
    PENDING: 0,
    IN_PROGRESS: 1,
    SUCCESS: 2,
    FAILED: 3
}

export const TRANSACTION_STATUS = {
    PENDING: 'pending',
    SUCCESS: 'success',
    FAILED: 'failed',
};

export const TRANSACTION_TYPES = {
    PAYMENT: 'payment',
    REFUND: 'refund'
};

export const LM_BACKOFFICE_INVITATION_STATUS = {
    PENDING: 0,
    ACCEPTED: 1,
    REJECTED: 2
}

export const CMS_PAGES_TYPES = {
    DEFAULT: "default",
    CUSTOM: "custom",
};

export const CMS_PAGES_POSITIONS = {
    HEADER: "header",
    FOOTER: "footer",
    LEAD_COORDINATOR: "lead_coordinator",
    LM_BACK_OFFICE: "lm_back_office",
    BOOTH_ATTENDANTS: "booth_attendants",
    INFO_COUNTER: "info_counter",
    OTHER: "other",
    // NONE: "none"
};

export const FOOTER_MENUS = {
    COMPANY: "company",
    IMPORTANT_LINKS: "important_links",

};

export const DEFAULT_EMAIL_OPTIONS = [
    'web_url',
    'name',
    'title',
    'first_name',
    'middle_name',
    'last_name',
    'email',
    'salutation',
    'role',
    'phone_number',
    'work_phone_number',
    'name_badge_question'
]

export const DRIP_CONTENT_EMAIL_OPTIONS = [
    'title',
    'salutation',
    'first_name',
    'last_name',
    'email',
    'unsubscribe_link'
]

export const INVITATION_STATUS = {
    PENDING: 0,
    ACCEPTED: 1,
    REJECTED: 2
}

export const DYNAMIC_VARIABLES = {
    SERVER_URL: process.env.REACT_APP_API_URL.replace('/webservice/api/v1', '')
}

export const MOBILE_NUMBER_REGEX = /^[0-9]+$/;
export const EMAIL_REGEX = /^[a-zA-Z0-9._'-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
export const ATLEAST_ONE_SMALL_REGEX = /[a-z]/g
export const ATLEAST_ONE_CAPITAL_REGEX = /[A-Z]/g
export const ATLEAST_ONE_NUMBER_REGEX = /[0-9]/g;
export const ATLEAST_ONE_SPECIAL_CHARACTER_REGEX = /[^\w\s]/;
export const URL_REGEX = /^(ftp|http|https):\/\/[^ "]+$/;
;

export const PASSWORD_MIN_LENGTH = 8
export const PASSWORD_MAX_LENGTH = 20

export const ROOM_TYPE = {
    Single_Room: "single_room",
    Double_Room: "double_room"
}
export const DEFAULT_SELECT_FIELD = [
    'Title', 'Email', 'Firstname', 'Lastname', 'Middlename'
]

export const INVITATION_TYPES = {
    SCHEDULE: "schedule",
    SEND_LATER: "send_later",
    SEND_NOW: "send_now"
};

export const STAFF_ROLE = {
    ADMIN: 1,
    SUB_ADMIN: 2,
    USER: 3,
    STAFF: 4,
    LM_BACK_OFFICE: 5,
    LEAD_COORDINATOR: 6,
    INFO_COUNTER: 7,
    BOOTH_ATTENDANTS: 8,
};

export const ANNOUNCEMENT_TYPES = {
    GENERAL: "general",
    EVENT: "event",

};

export const CURRENCY = [
    {
        name: "Dollar",
        code: "USD",
        sign: "$"
    },
    {
        name: "Euro",
        code: "EUR",
        sign: "€"
    },
    {
        name: "Pound",
        code: "GBP",
        sign: "£"
    }
]

export const EMAIL_TYPE = {
    Automatic: "automatic",
    Regular: "regular",
}
export const DEFAULT_MESSAGE_LIMIT = 10;

export const NOTIFICATIONS_TYPES = {
    DEFAULT: "default",
    UNREAD_CHAT_MESSAGE: "unread_chat_message"
};

export const REFUND_TYPES = {
    PARTIAL: "partial",
    FULL: "full",
};
export const MESSAGE_TYPES = {
    TEXT: "text",
    IMAGE: "image",
    EMOJI: "emoji",
    FILE: 'file',
};

export const COMMENT_TYPES = {
    TEXT: "text",
    IMAGE: "image",
    FILE: "file",
};

export const VIDEO_UPLOAD = {
    CHUNK_SIZE: 1048576
}

export const EXPORT_EXTENSION_TYPE = "csv";

export const WEB_LANGUAGES = [
    { name: "English", file_name: 'en.json', code: 'en' },
    { name: "Deutsch", file_name: 'de.json', code: 'de' },
];

export const getLangName = (fileName) => {
    let result = WEB_LANGUAGES.filter(item => fileName === item.file_name)
    if (result.length) {
        return result[0].name;
    }
    return "";
};

export const getType = (type) => {
    if (type === TRANSACTION_TYPES.PAYMENT) {
        return <span className="badge badge-info">Payment</span>
    }
    else if (type === TRANSACTION_TYPES.REFUND) {
        return <span className="badge badge-dark">Refund</span>
    }
}

export const CATEGORY_OPTIONS = [
    { value: "lead_coordinator_registration", label: "Lead coordinator registration" },
    { value: "trade_fair_questionnaire", label: "Trade fair questionnaire" },
    { value: "lm_back_office_registration", label: "LM Back office registration" },
    { value: "booth_attendants_registration", label: "Booth Attendants Registration" },
    { value: "info_counter_registration", label: "Info Counter Registration" },
    { value: "room_booking_registration", label: "Room Booking Registration" },
]
export const LM_USER_INVITATION_STATUS = {
    PENDING: 0,
    ACCEPTED: 1,
    REJECTED: 2,
};

export const QUESTION_SET_CATEGORIES = {
    LEAD_COORDINATOR_REGISTRATION: "lead_coordinator_registration",
    TRADE_FAIR_QUESTIONNAIRE: "trade_fair_questionnaire",
    LM_BACK_OFFICE_REGISTRATION: "lm_back_office_registration",
    BOOTH_ATTENDANTS_REGISTRATION: "booth_attendants_registration",
    INFO_COUNTER_REGISTRATION: "info_counter_registration",
    ROOM_BOOKING_REGISTRATION: "room_booking_registration"
};


export const EMAIL_LOG_CATEGORIES = {
    REGULAR: "default",
    INVITATION: "invitation",
    NEWSLETTER: "newsletter",
    LM_INVITATION: "lm-user-invitation",
    DRIP_CONTENT: "drip-content",
    BOOKING: "booking",
};

export const TIME = [{ key: "00", label: "00" }, { key: "15", label: "15" }, { key: "30", label: "30" }, { key: "45", label: "45" }]

export const HOURS = Array.from({ length: 73 }, (_, index) => ({
    label: `${index}`,
    key: `${index}`
}));

export const COOKIES_EXPIRATION = 7; /** In Days */
export const MAX_VOICE_MEMO_SECONDS = 30; /** In Seconds */
export const FILE_UPLOAD = {
    CHUNK_SIZE: 1048576, /** 1 MB */
};
export const EVENT_TYPES = {
    OPEN_TO_ALL: "open_to_all",
    INVITATION_ONLY: "invitation_only",
    OPEN_PENDING_APPROVAL: "open_pending_approval"
};

export const CHOOSE_SIZE = [{ key: "54*86", label: "54*86" }, { key: "75*40", label: "75*40" }, { key: "64*22", label: "64*22" }, { key: "74*105 (A7)", label: "74*105 (A7)" }, { key: "105*148.5 (A6)", label: "105*148.5 (A6)" }];
export const isProfileRequired = [
    { label: "Title", value: "title" },
    { label: "Salutation", value: "gender" },
    { label: "First Name", value: "first_name" },
    { label: "Middle Name", value: "middle_name" },
    { label: "Last Name", value: "last_name" },
    { label: "Function", value: "event_function" },
    { label: "Phone Number", value: "phone_number" },
    { label: "Phone Number Work", value: "phone_number_work" },
    { label: "Files", value: "files" },
    { label: "Extra Info", value: "extra_info" },
    { label: "Password", value: "password" },
    { label: "Confirm Password", value: "confirm_password" },
    { label: "Notes", value: "notes" },
]
export const BOOKING_STATUS = {
    PENDING: 0,
    ACTIVE: 1,
    CANCELLED: 2,
}

export const DEFAULT_USER_FIELD_OPTIONS = [
    'name',
    'title',
    'first_name',
    'middle_name',
    'last_name',
    'email',
    'salutation',
    'phone_number',
    'work_phone_number',
    'extra_info',
    'event_function'
]
export const MOBILE_MIN_WIDTH = 500;


export const DEFAULT_USER ={
    'name': 'John Doe',
    'title': 'abc',
    'first_name': 'John',
    'middle_name': '',
    'last_name': 'Doe',
    'email': 'johndoe@gmail.com',
    'salutation': 'Mr.',
    'phone_number': '+91 123123154',
    'work_phone_number': '',
    'extra_info': '',
    'event_function': ''
}

export const DYNAMIC_FORM_PREFIX = "{FORM-";
export const DYNAMIC_FORM_SUFFIX = "}";
export const DYNAMIC_FORM_PREFIX_REGEX = /\{FORM-[^\}]+\}/g;


export const DEFAULT_CAROUSEL_HTML=`<div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
<div class="carousel-indicators">
  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1">Slide 1</button>
  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2">Slide 2</button>
  <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3">Slide 3</button>
</div>
<div class="carousel-inner">
  <div class="carousel-item active"> 
    <img src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp" class="d-block w-100" alt="...">
    <div class="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
    </div>
  </div>
  <div class="carousel-item"> 
    <img  src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp" class="d-block w-100" alt="...">
    <div class="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
    </div>
  </div>
  <div class="carousel-item"> 
    <img src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp" class="d-block w-100" alt="...">
    <div class="carousel-caption d-none d-md-block">
        <h5>First slide label</h5>
        <p>Some representative placeholder content for the first slide.</p>
    </div>
  </div>
</div>
<button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
  <span class="visually-hidden">Previous</span>
</button>
<button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
  <span class="carousel-control-next-icon" aria-hidden="true"></span>
  <span class="visually-hidden">Next</span>
</button>
</div>`


export const DEFAULT_ACCORDION_HTML=`<div class="accordion" id="accordionExample">
<div class="accordion-item">
  <h2 class="accordion-header" id="headingOne">
    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
      Accordion Item #1
    </button>
  </h2>
  <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
    <div class="accordion-body">
      <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
    </div>
  </div>
</div>
<div class="accordion-item">
  <h2 class="accordion-header" id="headingTwo">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
      Accordion Item #2
    </button>
  </h2>
  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
    <div class="accordion-body">
      <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
    </div>
  </div>
</div>
<div class="accordion-item">
  <h2 class="accordion-header" id="headingThree">
    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
      Accordion Item #3
    </button>
  </h2>
  <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
    <div class="accordion-body">
      <strong>This is the third item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
    </div>
  </div>
</div>
</div>`


export const DEFAULT_TAB_HTML=`<nav>
<div class="nav nav-tabs" id="nav-tab" role="tablist">
  <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Home</button>
  <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</button>
  <button class="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</button>
</div>
</nav>
<div class="tab-content" id="nav-tabContent">
<div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">...</div>
<div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...</div>
<div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...</div>
</div>`


export const DEFAULT_GALLERY_HTML=`
<div class="row">
  <div class="col-lg-4 col-md-12 mb-4 mb-lg-0">
    <img
      src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Boat on Calm Water"
    />

    <img
      src="https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain1.webp"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Wintry Mountain Landscape"
    />
  </div>

  <div class="col-lg-4 mb-4 mb-lg-0">
    <img
      src="https://mdbcdn.b-cdn.net/img/Photos/Vertical/mountain2.webp"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Mountains in the Clouds"
    />

    <img
      src="https://mdbcdn.b-cdn.net/img/Photos/Horizontal/Nature/4-col/img%20(73).webp"
      class="w-100 shadow-1-strong rounded mb-4"
      alt="Boat on Calm Water"
    />
  </div>
</div>`

export const DEFAULT_COUNTER_HTML=`
<div class="sectiontitle">
    <h2>Projects statistics</h2>
    <span class="headerLine"></span>
</div>
<div class="sectionClass">
    <div class="fullWidth eight columns">
        <div class="projectFactsWrap" id="counter">
            <div class="item wow fadeInUpBig animated animated counter-value" data-number="12" style="visibility: visible;">
                <p>0</p>
                <span></span>
                <p>Number 1</p>
            </div>
            <div class="item wow fadeInUpBig animated animated counter-value" data-number="55" style="visibility: visible;">
                <p>0</p>
                <span></span>
                <p>Number 2</p>
            </div>
            <div class="item wow fadeInUpBig animated animated counter-value" data-number="359" style="visibility: visible;">
                <p>0</p>
                <span></span>
                <p>Number 3</p>
            </div>
            <div class="item wow fadeInUpBig animated animated counter-value" data-number="246" style="visibility: visible;">
                <p>0</p>
                <span></span>
                <p>Number 4</p>
            </div>
        </div>
    </div>
</div>
`


export const DEFAULT_COUNTER_JS=` 
import TourGuideClient from "https://cdn.skypack.dev/@sjmc11/tourguidejs@0.0.7";
window.addEventListener('scroll', function() {
    const counterElement = document.getElementById('counter');
    console.log("Hello",counterElement);
    if (!counterElement) return;
    const rect = counterElement.getBoundingClientRect();
    if (counterFlag === 0 && rect.top-window.outerHeight<0) {
      document.querySelectorAll('.counter-value').forEach(function(element) {
        const countTo = parseInt(element.getAttribute('data-number'), 10);
        const pElement = element.querySelector('p:first-of-type');
        animateCounter(pElement, countTo);
      });
      counterFlag = 1;
    }
  });
`;
export const DEFAULT_TOUR_HTML=`
<div class="container mt-5">
<div id="tourDiv">
    <div id="step2">Step 2 Target Element</div>
    <div id="image" ><img alt="image" src="https://via.placeholder.com/150" /></div>
    <button id="step3" class='start_btn'>Start Tour</button>
</div>
</div>
`


export const DEFAULT_TOUR_JS=`
import TourGuideClient from "https://cdn.skypack.dev/@sjmc11/tourguidejs@0.0.7";
window.TourGuideClient = TourGuideClient;
window.TourGuideClient = TourGuideClient;

setTimeout(() => {
  const tg = new TourGuideClient({
    progressBar: "#6c3",
    exitOnEscape: true,
    exitOnClickOutside: false,
    closeButton: true
  });
  tg.addSteps([
    {
      title: "First things first",
      target: "#step2",
      content: \`<h1>Hello 1</h1>\`
    },
    {
      title: "Do you know about",
      content: \`<h1>Looks like this</h1>\`,
      target: "#image",
     },
  ]);

  tg.onFinish(() => {
    console.log("Finished");
  });

  const startButton = document.getElementById('step3');
  if (startButton) {
      startButton.addEventListener('click', () => {
      tg.start();
    });
  }
}, 1000);
</script>
`
export const DEFAULT_CARD_HTML=` <div class="hover-card-container">
<div class="hover-card card-front" style="width: 18rem;">
  <img class="card-img-top" src="https://via.placeholder.com/150" alt="Card image cap">
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
</div>
<div class="hover-card card-back d-none" style="width: 18rem;">
    <div class="card-body">
        <h4 class="card-title">John's Profile</h4>
        <p class="card-text">Additional information about John Doe.</p>
        <a href="#" class="btn btn-primary">See Profile</a>
    </div>
</div>
</div>
`;


export const DEFAULT_USER_INVITATION_OPTIONS = [
  'web_url',
  'name',
  'title',
  'first_name',
  'middle_name',
  'last_name',
  'email',
  'salutation',
  'role',
  'phone_number',
  'work_phone_number',
  'event_id',
  'web_url',
  'event_title',
  'event_name',
  'hosting_company',
  'event_start_date',
  'event_end_date',
  'event_link',
  'event_price',
  'event_otp',
  'invitation_id',
  'primary_questions',
  'secondary_questions',
]

export const EMAIL_TEMPLATE_CATEGORY= {
  DEFAULT: "default",
  USER_INVITATION: "user_invitation",
};


export const DEFAULT_NAME_BADGE_OPTIONS = [
  'web_url',
  'name',
  'title',
  'first_name',
  'middle_name',
  'last_name',
  'email',
  'salutation',
  'role',
  'phone_number',
  'work_phone_number',
  'question_data'
]