import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import * as eventsService from "../../services/events.services";
import {
  formateDateWithMonth,
  removeSpecialCharacter,
  calculatePercentage,
  getFormatedTime,
  capitalizeFirstLetter,
  globalLoader,
  getFileNameFromURL,
} from "../../../../utils/commonfunction";
import Loader from "../../common/loader";
import Breadcrums from "../../common/breadcrumbs";
import QRCode from "react-qr-code";
import moment from "moment";
import LogoImage from "../../../../assets/user/images/logo.png";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ShowAnswer from "../../shared/ShowAnswer";
import { Modal } from "rsuite";
import VideoPlayer from "../../../user/shared/VideoPlayer";

const ViewRegistrationDetail = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [registeredUsers, setRegisteredUsers] = useState({});
  const location = useLocation();
  const { state } = location;
  const { event_id } = state || {};
  let [videoSourceType, setVideoSourceType] = useState("video");
  let [videoPlaySource, setVideoPlaySource] = useState(null);
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "View Event", url: "/admin/event-management/events/list/1" },
    {
      title: "View Registrations",
      url: `/admin/event-management/events/view/registrations/${event_id}/1`,
    },
    { title: "View Registered User info", url: "" },
  ];

  useEffect(() => {
    const { registrationId } = params;

    if (registrationId) {
      eventsService
        .RegistrationsDetails(registrationId)
        .then((response) => {
          if (response?.success) {
            setRegisteredUsers(response.data);
          }
        })
        .catch((err) => console.log("err", err));
    }
  }, [params]);

  function formatPhoneNumber(phoneNumberString) {
    try {
      let phoneNumber = phoneNumberString;
      if (typeof phoneNumberString == "string") {
        try {
          phoneNumber = JSON.parse(phoneNumberString);
        } catch (err) {
          console.log("Not able to parse phone number", err);
        }
      }
      if (phoneNumber.code && phoneNumber.number) {
        const formattedPhoneNumber = `${
          phoneNumber.code
        } ${phoneNumber.number.slice(0, 5)} ${phoneNumber.number.slice(5)}`;
        return formattedPhoneNumber;
      }
    } catch (error) {
      console.log(error);
    }
    return null;
  }

  const printTicket = (data, index) => {
    const printableContent = document.querySelector(`#queryTicket${index}`);
    console.log("printableContent", printableContent);
    const contentToPrint = printableContent.cloneNode(true);
    const printWindow = window.open("", "_blank");
    printWindow.document.open();
    printWindow.document.write(`
          <html>
            <head>
              <title>Print Ticket</title>
            </head>
            <body>
              ${contentToPrint.outerHTML}
            </body>
          </html>
        `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const handleDownloadClick = async (e, fileURL) => {
    let fileName = "";
    if (fileURL) {
      fileName = fileURL.replace(
        `${process.env.REACT_APP_SERVER_BASE_URL}/uploads/registrations/tickets/`,
        ""
      );
      e.preventDefault();
      globalLoader(true);
      try {
        const response = await fetch(fileURL);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await response.blob();
        const blobURL = URL.createObjectURL(blob);

        const a = document.createElement("a");
        a.style.display = "none";
        a.href = blobURL;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(blobURL);
        globalLoader(false);
      } catch (error) {
        globalLoader(false);
        console.error("Error downloading the file:", error);
        Swal.fire({
          title: "Download Error",
          text: `There was an error while downloading the file "${fileName}".`,
          icon: "error",
        });
      }
    } else {
      console.log("Error in file downloading");
    }
  };

  let globalData = useSelector((state) => state.globaldetail);

  const myGlobalData = useSelector((state) => state.globalData);
  const [getGlobalData, setGlobalData] = useState({});
  useEffect(() => {
    if(myGlobalData && myGlobalData?.data){
      setGlobalData(JSON.parse(myGlobalData?.data));
    }
  },[myGlobalData]);

  const playVideo = (video, type) => {
    setVideoSourceType(() => type);
    setVideoPlaySource(video);
  };
  const stopVideoPlayer = () => {
    setVideoSourceType(null);
    setVideoPlaySource(null);
  };
  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm viewregisration">
        <div className="col-lg-12 col-md-12">
          <div className="card custom-card main-content-body-profile">
            <div className="tab-content">
              <div
                className="main-content-body tab-pane p-4 border-top-0 active"
                id="about"
              >
                <div className="card-body p-0 p-0 rounded-10">
                <h4 className="tx-25 text-uppercase mb-3">Primary User</h4>
                  <div className="p-0">
                    {Object.keys(registeredUsers).length > 0 ? (
                      <>
                        <div className="mb-4">
                          <div className="border rounded p-4 d-flex flex-column flex-md-row align-items-start">
                            <div className="me-md-5">
                              <h4 className="tx-25 text-uppercase mb-3">
                                General Info
                              </h4>
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Name:
                                </label>
                                <p className="mb-0 line-height-normal text-capitalize">
                                  {registeredUsers?.user_data?.name
                                    ? registeredUsers.user_data.name
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Email:
                                </label>
                                <p className="mb-0 line-height-normal">
                                  {registeredUsers?.user_data?.email
                                    ? registeredUsers.user_data.email
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Phone Number:
                                </label>
                                {registeredUsers?.user_data?.phone_number ? (
                                  <p className="mb-0 line-height-normal">
                                    {formatPhoneNumber(
                                      registeredUsers?.user_data?.phone_number
                                    )}
                                  </p>
                                ) : (
                                  <p>N/A</p>
                                )}
                              </div>
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Address:
                                </label>
                                <p className="mb-0 line-height-normal">
                                  {registeredUsers?.city
                                    ? registeredUsers?.city
                                    : "N/A"}
                                  ,&nbsp;
                                  {registeredUsers?.address
                                    ? registeredUsers?.address
                                    : "N/A"}
                                  ,&nbsp;
                                  {registeredUsers?.country
                                    ? registeredUsers?.country
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-4">
                          {registeredUsers.primary_user?.allergic ||
                          registeredUsers.primary_user?.vegan ||
                          registeredUsers.primary_user?.vegetarian ? (
                            <div className="border rounded p-4">
                              <h4 className="tx-15 text-uppercase mb-3">
                                About Dietary Requirements
                              </h4>
                              <div className="mb-3">
                                <label className="tx-14 font-weight-bold">
                                  Dietary Preferences:
                                </label>
                                <ul className="option-list mb-3">
                                  {registeredUsers?.primary_user?.vegan && (
                                    <li>I am vegan</li>
                                  )}
                                  {registeredUsers?.primary_user
                                    ?.vegetarian && <li>I am vegetarian</li>}
                                  {registeredUsers?.primary_user?.allergic && (
                                    <li>I have allergies/others</li>
                                  )}
                                </ul>
                              </div>
                              {registeredUsers?.primary_user?.allergic && (
                                <div className="d-flex align-items-start">
                                  <label className="tx-14 font-weight-bold mb-0 me-2">
                                    Allergy Reason:
                                  </label>
                                  <p className="mb-0 line-height-normal text-capitalize">
                                    {registeredUsers?.primary_user
                                      ?.allergy_reason
                                      ? registeredUsers?.primary_user
                                          ?.allergy_reason
                                      : "N/A"}
                                  </p>
                                </div>
                              )}
                            </div>
                          ) : null}
                        </div>
                        <div className="mb-4">
                          <div className="border rounded p-4">
                            <h4 className="tx-15 text-uppercase mb-3">
                              About Event Attendance
                            </h4>
                            <div className="pt-10 pb-0">
                              <div className="d-flex align-items-start">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Event Dates:
                                </label>
                                <p className="mb-0 line-height-normal">
                                  {registeredUsers?.start_date
                                    ? formateDateWithMonth(
                                        registeredUsers?.start_date
                                      )
                                    : "N/A"}{" "}
                                  -{" "}
                                  {registeredUsers?.end_date
                                    ? formateDateWithMonth(
                                        registeredUsers?.end_date
                                      )
                                    : "N/A"}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-4">
                          <div className="border rounded p-4 d-flex flex-column flex-md-row align-items-start">
                            <div className="me-md-5">
                              <h4 className="tx-25 text-uppercase mb-3">
                                About Accommodation
                              </h4>
                              <div className="d-flex align-items-center">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Accommodation:
                                </label>
                                <p className="mb-0 line-height-normal">
                                  {registeredUsers?.accommodation
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                              {registeredUsers?.accommodation === true && (
                                <div>
                                  <div className="d-flex align-items-center">
                                    <label className="tx-14 font-weight-bold mb-0 me-2">
                                      Hotel Name:
                                    </label>
                                    <p className="text-primary mb-0 line-height-normal text-capitalize">
                                      {registeredUsers?.hotel_data?.name ? (
                                        <Link
                                          to={`/admin/event-management/events/view/hotels/${registeredUsers?.event_data?._id}/1`}
                                        >
                                          {registeredUsers?.hotel_data?.name}
                                        </Link>
                                      ) : (
                                        "N/A"
                                      )}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <label className="tx-14 font-weight-bold mb-0 me-2">
                                      Stay Dates:
                                    </label>
                                    <p className="mb-0 line-height-normal">
                                      {registeredUsers?.check_in
                                        ? formateDateWithMonth(
                                            registeredUsers?.check_in
                                          )
                                        : "N/A"}{" "}
                                      -{" "}
                                      {registeredUsers?.check_out
                                        ? formateDateWithMonth(
                                            registeredUsers?.check_out
                                          )
                                        : "N/A"}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <label className="tx-14 font-weight-bold mb-0 me-2">
                                      Room Type:
                                    </label>
                                    <p className="mb-0 line-height-normal text-capitalize">
                                      {registeredUsers?.room_type
                                        ? removeSpecialCharacter(
                                            registeredUsers?.room_type
                                          )
                                        : "N/A"}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="mb-4">
                          <div className="border rounded p-4 agenda-box">
                            <h4 className="tx-25 text-uppercase mb-3">
                              About Agenda
                            </h4>
                            {registeredUsers?.primary_user?.agenda_data?.map(
                              (agendaItem, index, array) => (
                                <div div key={index} className={index === array.length - 1 ? "" : "mb-3"}>
                                  <h4 className="reg-title">
                                    Agenda Name: {agendaItem?.name}
                                  </h4>
                                  <ul className="card-info-list pb-0">
                                    <li>
                                      <div className="date-info pb-0">
                                        <i className="ri-calendar-event-line black-icon" />
                                        {agendaItem.start_date &&
                                          formateDateWithMonth(
                                            agendaItem.start_date
                                          )}{" "}
                                      </div>
                                    </li>
                                    <li>
                                      <div className="card-info pb-0">
                                        <i className="ri-alarm-line black-icon" />
                                        {getFormatedTime(agendaItem.start_time)}{" "}
                                        - {getFormatedTime(agendaItem.end_time)}
                                      </div>
                                    </li>
                                  </ul>
                                  <div className="row">
                                    {agendaItem.elements?.map(
                                      (element, elementIndex) => (
                                        <div
                                          key={elementIndex}
                                          className="mt-3 col-md-3"
                                        >
                                          <div className="w-100 border p-3 rounded">
                                            <h5 className="text-capitalize">
                                              {element.name}
                                            </h5>
                                            <p>
                                              {
                                                registeredUsers?.event_data
                                                  ?.currency?.sign
                                              }
                                              {(
                                                element.price +
                                                calculatePercentage(
                                                  element.tax_rate,
                                                  element.price
                                                )
                                              ).toFixed(2)}{" "}
                                              <small>
                                                inclusive{" "}
                                                {element.tax_rate + "%"}
                                              </small>
                                            </p>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                        {registeredUsers?.primary_user?.questions_data &&
                        registeredUsers.primary_user.questions_data.length >
                          0 ? (
                          <div className="mb-4">
                            <div className="border rounded p-4">
                              <h4 className="tx-15 text-uppercase mb-3">
                                Extra Info
                              </h4>
                              <div className="pt-10 pb-0">
                                {registeredUsers?.primary_user?.questions_data.map(
                                  (questionItem, index) => (
                                    <div key={index} className="mb-2">
                                      <div className="d-flex align-items-center">
                                        <label className="tx-14 font-weight-bold mb-0 me-2">
                                          {questionItem?.question_title
                                            ? questionItem?.question_title
                                            : 'N/A'}
                                          :
                                        </label>
                                        <ShowAnswer question={questionItem} setVideoPlaySource={playVideo}></ShowAnswer>
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                        ) : null}
                        {registeredUsers?.company_name &&
                          registeredUsers?.address &&
                          registeredUsers?.city && (
                            <div>
                              <div className="border rounded p-4 d-flex flex-column flex-md-row align-items-start">
                                <div className="me-md-5">
                                  <h4 className="tx-15 text-uppercase mb-3">
                                    About Invoice Info
                                  </h4>
                                  <div className="d-flex align-items-center">
                                    <label className="tx-14 font-weight-bold mb-0 me-2">
                                      Company Name:
                                    </label>
                                    <p className="mb-0 line-height-normal text-capitalize">
                                      {registeredUsers?.company_name
                                        ? registeredUsers?.company_name
                                        : "N/A"}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <label className="tx-14 font-weight-bold mb-0 me-2">
                                      Address:
                                    </label>
                                    <p className="mb-0 line-height-normal text-capitalize">
                                      {registeredUsers?.address
                                        ? registeredUsers?.address
                                        : "N/A"}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <label className="tx-14 font-weight-bold mb-0 me-2">
                                      City:
                                    </label>
                                    <p className="mb-0 line-height-normal text-capitalize">
                                      {registeredUsers?.city
                                        ? registeredUsers?.city
                                        : "N/A"}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <label className="tx-14 font-weight-bold mb-0 me-2">
                                      Country:
                                    </label>
                                    <p className="mb-0 line-height-normal text-capitalize">
                                      {registeredUsers?.country
                                        ? registeredUsers?.country
                                        : "N/A"}
                                    </p>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <label className="tx-14 font-weight-bold mb-0 me-2">
                                      Postal Code:
                                    </label>
                                    <p className="mb-0 line-height-normal">
                                      {registeredUsers?.postal_code
                                        ? registeredUsers?.postal_code
                                        : "N/A"}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        {registeredUsers?.visa_invitation === true && (
                          <div className="">
                            <div className="border rounded p-4 d-flex flex-column flex-md-row align-items-start">
                              <div className="me-md-5">
                                <h4 className="tx-15 text-uppercase mb-3">
                                  About Visa Invitation
                                </h4>
                                <div className="d-flex align-items-center">
                                  <label className="tx-14 font-weight-bold mb-0 me-2">
                                    Visa Invitation:
                                  </label>
                                  <p className="mb-0 line-height-normal text-capitalize">
                                    {registeredUsers?.visa_invitation
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                                {registeredUsers?.visa_invitation && (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        Passport Number:
                                      </label>
                                      <p className="mb-0 line-height-normal">
                                        {registeredUsers?.primary_user
                                          ?.visa_info?.passport_number
                                          ? registeredUsers?.primary_user
                                              ?.visa_info?.passport_number
                                          : "N/A"}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        Surname:
                                      </label>
                                      <p className="mb-0 line-height-normal text-capitalize">
                                        {registeredUsers?.primary_user
                                          ?.visa_info?.surname
                                          ? registeredUsers?.primary_user
                                              ?.visa_info?.surname
                                          : "N/A"}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        First Name:
                                      </label>
                                      <p className="mb-0 line-height-normal text-capitalize">
                                        {registeredUsers?.primary_user
                                          ?.visa_info?.first_name
                                          ? registeredUsers?.primary_user
                                              ?.visa_info?.first_name
                                          : "N/A"}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        Place of Birth:
                                      </label>
                                      <p className="mb-0 line-height-normal">
                                        {registeredUsers?.primary_user
                                          ?.visa_info?.place_of_birth
                                          ? registeredUsers?.primary_user
                                              ?.visa_info?.place_of_birth
                                          : "N/A"}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        Date of Birth:
                                      </label>
                                      <p className="mb-0 line-height-normal">
                                        {formateDateWithMonth(
                                          registeredUsers?.primary_user
                                            ?.visa_info?.date_of_birth
                                        )
                                          ? formateDateWithMonth(
                                              registeredUsers?.primary_user
                                                ?.visa_info?.date_of_birth
                                            )
                                          : "N/A"}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        Date of Expiry:
                                      </label>
                                      <p className="mb-0 line-height-normal">
                                        {formateDateWithMonth(
                                          registeredUsers?.primary_user
                                            ?.visa_info?.date_of_expiry
                                        )
                                          ? formateDateWithMonth(
                                              registeredUsers?.primary_user
                                                ?.visa_info?.date_of_expiry
                                            )
                                          : "N/A"}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        Date of Issue:
                                      </label>
                                      <p className="mb-0 line-height-normal">
                                        {formateDateWithMonth(
                                          registeredUsers?.primary_user
                                            ?.visa_info?.date_of_issue
                                        )
                                          ? formateDateWithMonth(
                                              registeredUsers?.primary_user
                                                ?.visa_info?.date_of_issue
                                            )
                                          : "N/A"}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        Nationality:
                                      </label>
                                      <p className="mb-0 line-height-normal text-capitalize">
                                        {registeredUsers?.primary_user
                                          ?.visa_info?.nationality
                                          ? registeredUsers?.primary_user
                                              ?.visa_info?.nationality
                                          : "N/A"}
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        Authority:
                                      </label>
                                      <p className="mb-0 line-height-normal text-capitalize">
                                        {registeredUsers?.primary_user
                                          ?.visa_info?.authority
                                          ? registeredUsers?.primary_user
                                              ?.visa_info?.authority
                                          : "N/A"}
                                      </p>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {registeredUsers?.guest_user ? (
        <div className="row row-sm viewregisration">
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card main-content-body-profile">
              <div className="tab-content">
                <div
                  className="main-content-body tab-pane p-4 border-top-0 active"
                  id="about"
                >
                  <div className="card-body p-0  p-0 rounded-10">
                  <h4 className="tx-25 text-uppercase mb-3">Guest User</h4>
                    <div className="">
                      {Object.keys(registeredUsers).length > 0 ? (
                        <>
                          <div className="mb-4">
                            <div className="border rounded p-4 d-flex flex-column flex-md-row align-items-start">
                              <div className="me-md-5">
                                <h4 className="tx-25 text-uppercase mb-3">
                                  General Info
                                </h4>
                                <div className="d-flex align-items-start">
                                  <label className="tx-14 font-weight-bold mb-0 me-2">
                                    Name:
                                  </label>
                                  <p className="mb-0 line-height-normal text-capitalize">
                                    {registeredUsers?.guest_user?.first_name
                                      ? registeredUsers.guest_user?.first_name
                                      : "N/A"}
                                  </p>
                                </div>
                                <div className="d-flex align-items-start">
                                  <label className="tx-14 font-weight-bold mb-0 me-2">
                                    Email:
                                  </label>
                                  <p className="mb-0 line-height-normal">
                                    {registeredUsers?.guest_user?.email
                                      ? registeredUsers.guest_user.email
                                      : "N/A"}
                                  </p>
                                </div>
                                <div className="d-flex align-items-start">
                                  <label className="tx-14 font-weight-bold mb-0 me-2">
                                    Phone Number:
                                  </label>
                                  {registeredUsers?.guest_user?.phone_number ? (
                                    <p className="mb-0 line-height-normal">
                                      {
                                        registeredUsers?.guest_user
                                          ?.phone_number
                                      }
                                    </p>
                                  ) : (
                                    <p>N/A</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mb-4">
                            {registeredUsers.guest_user?.allergic ||
                            registeredUsers.guest_user?.vegan ||
                            registeredUsers.guest_user?.vegetarian ? (
                              <div className="border rounded p-4">
                                <h4 className="tx-15 text-uppercase mb-3">
                                  About Dietary Requirements
                                </h4>
                                <div className="mb-3">
                                  <label className="tx-14 font-weight-bold">
                                    Dietary Preferences:
                                  </label>
                                  <ul className="option-list mb-3">
                                    {registeredUsers?.guest_user?.vegan && (
                                      <li>I am vegan</li>
                                    )}
                                    {registeredUsers?.guest_user
                                      ?.vegetarian && <li>I am vegetarian</li>}
                                    {registeredUsers?.guest_user?.allergic && (
                                      <li>I have allergies/others</li>
                                    )}
                                  </ul>
                                </div>
                                {registeredUsers?.guest_user?.allergic && (
                                  <div className="d-flex align-items-start">
                                    <label className="tx-14 font-weight-bold mb-0 me-2">
                                      Allergy Reason:
                                    </label>
                                    <p className="mb-0 line-height-normal text-capitalize">
                                      {registeredUsers?.guest_user
                                        ?.allergy_reason
                                        ? registeredUsers?.guest_user
                                            ?.allergy_reason
                                        : "N/A"}
                                    </p>
                                  </div>
                                )}
                              </div>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <div className="border rounded p-4 agenda-box">
                              <h4 className="tx-25 text-uppercase mb-3">
                                About Agenda
                              </h4>
                              {registeredUsers?.guest_user?.agenda_data?.map(
                                (agendaItem, index, array) => (
                                  <div key={index} className={index === array.length - 1 ? "" : "mb-3"}>
                                    <h4 className="reg-title">
                                      Agenda Name: {agendaItem?.name}
                                    </h4>
                                    <ul className="card-info-list pb-0">
                                      <li>
                                        <div className="date-info pb-0">
                                          <i className="ri-calendar-event-line black-icon" />
                                          {agendaItem.start_date &&
                                            formateDateWithMonth(
                                              agendaItem.start_date
                                            )}{" "}
                                        </div>
                                      </li>
                                      <li>
                                        <div className="card-info pb-0">
                                          <i className="ri-alarm-line black-icon" />
                                          {getFormatedTime(
                                            agendaItem.start_time
                                          )}{" "}
                                          -{" "}
                                          {getFormatedTime(agendaItem.end_time)}
                                        </div>
                                      </li>
                                    </ul>
                                    <div className="row">
                                      {agendaItem.elements?.map(
                                        (element, elementIndex) => (
                                          <div
                                            key={elementIndex}
                                            className="mt-3 col-md-3"
                                          >
                                            <div className="w-100 border p-3 rounded">
                                              <h5 className="text-capitalize">
                                                {element.name}
                                              </h5>
                                              <p>
                                                {
                                                  registeredUsers?.event_data
                                                    ?.currency?.sign
                                                }
                                                {(
                                                  element.price +
                                                  calculatePercentage(
                                                    element.tax_rate,
                                                    element.price
                                                  )
                                                ).toFixed(2)}{" "}
                                                <small>
                                                  inclusive{" "}
                                                  {element.tax_rate + "%"}
                                                </small>
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                          {registeredUsers?.guest_user?.questions_data &&
                          registeredUsers.guest_user.questions_data.length >
                            0 ? (
                            <div className="">
                              <div className="border rounded p-4">
                                <h4 className="tx-15 text-uppercase mb-3">
                                  Extra Info
                                </h4>
                                <div className="pt-10 pb-0">
                                  {registeredUsers?.guest_user?.questions_data.map(
                                    (questionItem, index) => (
                                      <div key={index} className="mb-2">
                                        <div className="d-flex align-items-center">
                                          <label className="tx-14 font-weight-bold mb-0 me-2">
                                            {questionItem?.question_title
                                              ? questionItem?.question_title
                                              : 'N/A'}
                                            :
                                          </label>
                                          <ShowAnswer question={questionItem} setVideoPlaySource={playVideo}></ShowAnswer>
                                        </div>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {registeredUsers?.guest_user?.visa_invitation ===
                            true && (
                            <div className="">
                              <div className="border rounded p-4 d-flex flex-column flex-md-row align-items-start">
                                <div className="me-md-5">
                                  <h4 className="tx-15 text-uppercase mb-3">
                                    About Visa Invitation
                                  </h4>
                                  <div className="d-flex align-items-center">
                                    <label className="tx-14 font-weight-bold mb-0 me-2">
                                      Visa Invitation:
                                    </label>
                                    <p className="mb-0 line-height-normal text-capitalize">
                                      {registeredUsers?.visa_invitation
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </div>
                                  {registeredUsers?.guest_user
                                    ?.visa_invitation && (
                                    <>
                                      <div className="d-flex align-items-center">
                                        <label className="tx-14 font-weight-bold mb-0 me-2">
                                          Passport Number:
                                        </label>
                                        <p className="mb-0 line-height-normal">
                                          {registeredUsers?.guest_user
                                            ?.visa_info?.passport_number
                                            ? registeredUsers?.guest_user
                                                ?.visa_info?.passport_number
                                            : "N/A"}
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <label className="tx-14 font-weight-bold mb-0 me-2">
                                          Surname:
                                        </label>
                                        <p className="mb-0 line-height-normal text-capitalize">
                                          {registeredUsers?.guest_user
                                            ?.visa_info?.surname
                                            ? registeredUsers?.guest_user
                                                ?.visa_info?.surname
                                            : "N/A"}
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <label className="tx-14 font-weight-bold mb-0 me-2">
                                          First Name:
                                        </label>
                                        <p className="mb-0 line-height-normal text-capitalize">
                                          {registeredUsers?.guest_user
                                            ?.visa_info?.first_name
                                            ? registeredUsers?.guest_user
                                                ?.visa_info?.first_name
                                            : "N/A"}
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <label className="tx-14 font-weight-bold mb-0 me-2">
                                          Place of Birth:
                                        </label>
                                        <p className="mb-0 line-height-normal">
                                          {registeredUsers?.guest_user
                                            ?.visa_info?.place_of_birth
                                            ? registeredUsers?.guest_user
                                                ?.visa_info?.place_of_birth
                                            : "N/A"}
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <label className="tx-14 font-weight-bold mb-0 me-2">
                                          Date of Birth:
                                        </label>
                                        <p className="mb-0 line-height-normal">
                                          {formateDateWithMonth(
                                            registeredUsers?.guest_user
                                              ?.visa_info?.date_of_birth
                                          )
                                            ? formateDateWithMonth(
                                                registeredUsers?.guest_user
                                                  ?.visa_info?.date_of_birth
                                              )
                                            : "N/A"}
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <label className="tx-14 font-weight-bold mb-0 me-2">
                                          Date of Expiry:
                                        </label>
                                        <p className="mb-0 line-height-normal">
                                          {formateDateWithMonth(
                                            registeredUsers?.guest_user
                                              ?.visa_info?.date_of_expiry
                                          )
                                            ? formateDateWithMonth(
                                                registeredUsers?.guest_user
                                                  ?.visa_info?.date_of_expiry
                                              )
                                            : "N/A"}
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <label className="tx-14 font-weight-bold mb-0 me-2">
                                          Date of Issue:
                                        </label>
                                        <p className="mb-0 line-height-normal">
                                          {formateDateWithMonth(
                                            registeredUsers?.guest_user
                                              ?.visa_info?.date_of_issue
                                          )
                                            ? formateDateWithMonth(
                                                registeredUsers?.guest_user
                                                  ?.visa_info?.date_of_issue
                                              )
                                            : "N/A"}
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <label className="tx-14 font-weight-bold mb-0 me-2">
                                          Nationality:
                                        </label>
                                        <p className="mb-0 line-height-normal text-capitalize">
                                          {registeredUsers?.guest_user
                                            ?.visa_info?.nationality
                                            ? registeredUsers?.guest_user
                                                ?.visa_info?.nationality
                                            : "N/A"}
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <label className="tx-14 font-weight-bold mb-0 me-2">
                                          Authority:
                                        </label>
                                        <p className="mb-0 line-height-normal text-capitalize">
                                          {registeredUsers?.guest_user
                                            ?.visa_info?.authority
                                            ? registeredUsers?.guest_user
                                                ?.visa_info?.authority
                                            : "N/A"}
                                        </p>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </>
                      ) : (
                        <Loader />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {registeredUsers?.tickets_data?.length > 0 ? (
        <div className="row row-sm viewregisration">
          <div className="col-lg-12 col-md-12">
            <div className="card custom-card main-content-body-profile">
              <div className="tab-content">
                <div
                  className="main-content-body tab-pane p-4 border-top-0 active"
                  id="about"
                >
                  <h4 className="tx-15 text-uppercase mb-3">Tickets Info</h4>
                  <div className="row row-sm tickets">
                    {registeredUsers.tickets_data.map((data, index) => (
                      <div
                        className="col-6 mb-4"
                        id={`queryTicket${index}`}
                        key={index}
                      >
                        <div className="card-body p-0 border p-0 rounded-10 h-100">
                          <div className="p-4">
                            <div className="row">
                              <table width="100%">
                                <tbody>
                                  <tr>
                                    <td
                                      width="70%"
                                      style={{ paddingRight: 30 }}
                                    >
                                      <table
                                        width="100%"
                                        style={{ borderCollapse: "collapse" }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td>
                                              <div
                                                className="btn-group button-admin"
                                                role="group"
                                                aria-label="First group"
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-dark"
                                                  onClick={() =>
                                                    printTicket(data, index)
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-print"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                                <button
                                                  type="button"
                                                  className="btn btn-dark"
                                                  href="#"
                                                  onClick={(e) =>
                                                    handleDownloadClick(
                                                      e,
                                                      data?.ticket_pdf
                                                    )
                                                  }
                                                >
                                                  <i
                                                    className="fa fa-download"
                                                    aria-hidden="true"
                                                  ></i>
                                                </button>
                                              </div>
                                              <img
                                                src={LogoImage}
                                                style={{ marginBottom: 20 }}
                                                alt="logo"
                                              />
                                              <h4
                                                style={{
                                                  fontSize: 22,
                                                  margin: 0,
                                                  paddingBottom: 5,
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                {registeredUsers?.event_data
                                                  ?.title
                                                  ? registeredUsers?.event_data
                                                      ?.title
                                                  : null}
                                              </h4>
                                              <p
                                                style={{
                                                  fontSize: 14,
                                                  margin: 0,
                                                  paddingBottom: 20,
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                {registeredUsers?.event_data
                                                  ?.start_date
                                                  ? formateDateWithMonth(
                                                      registeredUsers
                                                        ?.event_data?.start_date
                                                    )
                                                  : null}{" "}
                                                -{" "}
                                                {registeredUsers?.event_data
                                                  ?.end_date
                                                  ? formateDateWithMonth(
                                                      registeredUsers
                                                        ?.event_data?.end_date
                                                    )
                                                  : null}
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              width="70%"
                                              style={{
                                                borderTop: "2px solid #6fbe44",
                                                borderBottom:
                                                  "2px solid #6fbe44",
                                                borderRight:
                                                  "2px solid #6fbe44",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  paddingTop: 8,
                                                  margin: 0,
                                                  fontSize: 11,
                                                  paddingBottom: 8,
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                {data && data?.type === "event"
                                                  ? "NAME OF THE EVENT"
                                                  : data?.type === "agenda"
                                                  ? "NAME OF THE AGENDA ELEMENT"
                                                  : "NAME OF THE HOTEL"}
                                              </p>
                                              {data &&
                                              data?.type === "event" ? (
                                                <p
                                                  style={{
                                                    margin: 0,
                                                    paddingBottom: 30,
                                                    fontSize: 15,
                                                    fontWeight: 500,
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  {capitalizeFirstLetter(
                                                    registeredUsers?.event_data
                                                      ?.title
                                                  )}
                                                </p>
                                              ) : null}
                                              {data &&
                                              data?.type === "hotel" ? (
                                                <p
                                                  style={{
                                                    margin: 0,
                                                    paddingBottom: 30,
                                                    fontSize: 15,
                                                    fontWeight: 500,
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  {capitalizeFirstLetter(
                                                    registeredUsers?.hotel_data
                                                      ?.name
                                                  )}
                                                </p>
                                              ) : null}
                                              {data &&
                                              data?.type === "agenda" ? (
                                                <p
                                                  style={{
                                                    margin: 0,
                                                    paddingBottom: 30,
                                                    fontSize: 15,
                                                    fontWeight: 500,
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  {(function () {
                                                    let elementData =
                                                      data &&
                                                      data?.agenda_data &&
                                                      data?.agenda_data?.elements.filter(
                                                        (item) =>
                                                          String(
                                                            data?.element_id
                                                          ) ===
                                                          String(item?._id)
                                                      )[0];
                                                    return capitalizeFirstLetter(
                                                      elementData?.name
                                                    );
                                                  })()}
                                                </p>
                                              ) : null}
                                            </td>
                                            <td
                                              style={{
                                                borderTop: "2px solid #6fbe44",
                                                borderBottom:
                                                  "2px solid #6fbe44",
                                                paddingLeft: 10,
                                              }}
                                            >
                                              <p
                                                style={{
                                                  paddingTop: 8,
                                                  margin: 0,
                                                  fontSize: 11,
                                                  paddingBottom: 8,
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                VALID :
                                              </p>
                                              <p
                                                style={{
                                                  margin: 0,
                                                  paddingBottom: 30,
                                                  fontSize: 15,
                                                }}
                                              >
                                                {registeredUsers?.event_data
                                                  ?.end_date
                                                  ? moment(
                                                      registeredUsers
                                                        ?.event_data?.end_date
                                                    ).format(
                                                      "DD.MM.YYYY | hh:mm"
                                                    )
                                                  : null}
                                              </p>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td colSpan={2}>
                                              <h3
                                                style={{
                                                  margin: 0,
                                                  paddingTop: 20,
                                                  fontWeight: 400,
                                                  paddingBottom: 5,
                                                  fontSize: 18,
                                                  textTransform: "uppercase",
                                                }}
                                              >
                                                PARTICIPANT NAME:
                                              </h3>
                                              {data &&
                                              data?.guest_type ===
                                                "secondary" ? (
                                                <h2
                                                  style={{
                                                    margin: 0,
                                                    paddingTop: 20,
                                                    fontWeight: 400,
                                                    paddingBottom: 5,
                                                    fontSize: 18,
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  {registeredUsers?.guest_user
                                                    ?.name
                                                    ? registeredUsers
                                                        ?.guest_user?.name
                                                    : "N/A"}
                                                </h2>
                                              ) : null}
                                              {data &&
                                              data?.guest_type === "primary" ? (
                                                <h2
                                                  style={{
                                                    margin: 0,
                                                    paddingTop: 20,
                                                    fontWeight: 400,
                                                    paddingBottom: 5,
                                                    fontSize: 18,
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  {registeredUsers?.user_data
                                                    ?.name
                                                    ? registeredUsers?.user_data
                                                        ?.name
                                                    : "N/A"}
                                                </h2>
                                              ) : null}
                                              {data && !data?.guest_type ? (
                                                <h2
                                                  style={{
                                                    margin: 0,
                                                    paddingTop: 20,
                                                    fontWeight: 400,
                                                    paddingBottom: 5,
                                                    fontSize: 18,
                                                    textTransform: "uppercase",
                                                  }}
                                                >
                                                  {registeredUsers?.user_data
                                                    ?.name
                                                    ? registeredUsers?.user_data
                                                        ?.name
                                                    : "N/A"}
                                                </h2>
                                              ) : null}
                                              <div
                                                style={{
                                                  fontSize: 12,
                                                  lineHeight: "1.5",
                                                }}
                                                dangerouslySetInnerHTML={{
                                                  __html: registeredUsers
                                                    ?.event_data?.description
                                                    ? registeredUsers
                                                        ?.event_data
                                                        ?.description
                                                    : "N/A",
                                                }}
                                              ></div>
                                              <div
                                                className=""
                                                id="ticket_footer_text"
                                                dangerouslySetInnerHTML={{ __html: getGlobalData?.settings?.ticket_footer_text
                                                    ? getGlobalData?.settings?.ticket_footer_text
                                                    : "N/A",
                                                }}
                                              ></div>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                    <td valign="top">
                                      <table
                                        width="100%"
                                        style={{ borderCollapse: "collapse" }}
                                      >
                                        <tbody>
                                          <tr>
                                            <td
                                              style={{
                                                background: "#e7e7e8",
                                                textAlign: "center",
                                                padding: "30px 10px",
                                              }}
                                            >
                                              {window.location.host}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td
                                              style={{
                                                background: "#6fbe44",
                                                textAlign: "center",
                                                padding: "30px 30px",
                                              }}
                                            >
                                              <table
                                                width="100%"
                                                style={{ background: "#fff" }}
                                              >
                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <p
                                                        style={{
                                                          marginTop: "5px",
                                                        }}
                                                      >
                                                        {data &&
                                                        data?.ticket_number
                                                          ? data.ticket_number
                                                          : "N/A"}
                                                      </p>
                                                      <p className="qr-code">
                                                        <QRCode
                                                          size={256}
                                                          style={{
                                                            height: "auto",
                                                            maxWidth: "100%",
                                                            width: "100%",
                                                          }}
                                                          value={
                                                            data?.ticket_number
                                                          }
                                                          viewBox={`0 0 256 256`}
                                                        />
                                                      </p>
                                                      <p>
                                                        {data && data?.type
                                                          ? capitalizeFirstLetter(
                                                              data.type
                                                            )
                                                          : "N/A"}
                                                      </p>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="mt-5">
                    <button
                      className="btn ripple btn-dark"
                      type="button"
                      onClick={() => navigate(-1)}
                    >
                      <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Modal size="lg" backdrop="static" className="invitation-modal modal-height-auto" open={Boolean(videoPlaySource)} onClose={stopVideoPlayer} >
        <Modal.Header>
            <Modal.Title>Playing : {getFileNameFromURL(videoPlaySource)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {videoPlaySource ?
                <VideoPlayer src={videoPlaySource} type={videoSourceType} />
                : null
            }
        </Modal.Body>
    </Modal>
    </>
  );
};

export default ViewRegistrationDetail;
