import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery'
import Swal from 'sweetalert2'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getLocalKey, getUser, isUser, logOutUser, setLocalKey } from '../../../utils/commonfunction';
import { useSelector } from 'react-redux';
import * as commonServices from "../../user/services/common.services";
import { CMS_PAGES_POSITIONS, STAFF_ROLE} from '../../../utils/Constants';
import { useTranslation } from 'react-i18next';
import { EventOptions } from '../../user/services/lead.services';
import { useDispatch } from 'react-redux';
import { eventData } from '../../../redux/slices/manageLmBackoffice';
import LeadCoordinatorSettingMenu from '../pages/leadCoordinatorSettingMenu';
import { UserDetails } from '../../user/services/webuser.service';

const LMUsersHeader = () => {
    const loc = useLocation();
    let dispatch = useDispatch();
    const closeMenuBTN = useRef();
    let { i18n, t } = useTranslation();
    const leadData = useSelector((state) => state.designportal);
    const [toggle, setToggle] = useState(false);
    const [userData] = useState(getUser()||{});
    const [menuOptions, setMenuOptions] = useState([]);
    const [assignedEvents, setAssignedEvents] = useState([]);
    const [showMobileNav, setShowMobileNav] = useState(false);
    const [getuserkey, setGetUserkey] = useState();
    const navigate = useNavigate();
    
    let reducerData = useSelector(state => state.manageLmBackOffice);

    const [getGlobalData, setGlobalData] = useState({});
    const myGlobalData = useSelector((state) => state.globalData);
 
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        setGlobalData(JSON.parse(myGlobalData?.data));
      }
    },[myGlobalData]);

    useEffect(() => {
        if(window.innerWidth > 500){
            setShowMobileNav(false);
        }else{
            setShowMobileNav(true);
        }
    },[])

    /** Get assigned events for logged in user */
    useEffect(() => {
        EventOptions().then((response) => {
            if (response && response?.success) {
                setAssignedEvents(response && response.data ? response?.data: []);
                if(!getLocalKey("selectedEventId")){
                    dispatch(eventData(response?.data?.length > 0 ? response?.data[0]._id : ''));
                    setLocalKey("selectedEventId", response?.data[0]._id);
                  }else{
                    dispatch(eventData(getLocalKey("selectedEventId")));
                  }
            } else {
                console.log("error");
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    useEffect(() => {
        
    },[])

    useEffect(() => {
        $(document).ready(function () {
            $('.showpop').click(function () {
                $('.main-profile-menu').addClass("show");
            })
            $('.fixed_bg').click(function () {
                $('.main-profile-menu').removeClass("show");
            })
        })
    }, [loc])

    useEffect(() => {
        const userLocalData = getUser();
        if(userLocalData && userLocalData.token){
            UserDetails().then(response => {
                if(response.success){
                    setGetUserkey(response.data || {});
                }
            }).catch(error => {
                console.log("Error:", error);
            });
        }
    }, []);

    useEffect(() => {
        let position = CMS_PAGES_POSITIONS.LEAD_COORDINATOR  ;
        commonServices.menuOptions({ position: position }).then((response) => {
            if(response?.success){
                setMenuOptions(response?.data)
            }
        }).catch((error) => {
            console.log("error", error);
        })
    }, [loc])

    const logout = () => {
        Swal.fire({
            customClass: 'swal-wide',
            title:  t('web_swal_are_you_sure'),
            text: t('lbl_logout_text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: getGlobalData?.portal_design?.background_colors?.primary_active_color,
            cancelButtonColor: getGlobalData?.portal_design?.colors?.danger,
            confirmButtonText: t('web_swal_yes'),
            cancelButtonText: t('web_swal_no')
        }).then((result) => {
            if (result.isConfirmed) {
                window.ReactNativeWebView?.postMessage("LoggedOut");
                logOutUser(navigate);
            }
        })
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng); /* Change the language using i18next */
        setLocalKey("system_language", lng);
        window.location.reload();
    };

    function isActive(url){
        if(loc.pathname.includes(url)){
            return " active";
        }
        return "";
    }

    function handleEventChange(e){
        if(e.target.value){
            dispatch(eventData(e.target.value));
            setLocalKey("selectedEventId", e.target.value);
        }
    }

    function closeMenu(){
        closeMenuBTN?.current?.click();
      }

    return (
        <>
        {
            showMobileNav?
                <LeadCoordinatorSettingMenu showMenu={toggle} onClose={setToggle}/>
            :null
        }
        <header className="topHeader " id="fixed-header">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg " id="navbar-scrollspy">
                    <div className="navbar-inner-box d-flex align-items-center justify-content-between">
                        <div className="nav-L d-flex">
                            {/* <Link className="navbar-brand desktop-view-logo" to={"/"}>
                                <img style={{ height: "51px" }} src={leadData?.data?.logo_transparent} alt="logo" />
                            </Link> */}
                                <img style={{ height: "51px" }} src={getGlobalData?.portal_design?.logo_transparent} alt="logo" />
                            <button
                                ref={closeMenuBTN}
                                className="navbar-toggler"
                                id='toggle_button'
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                            >
                                <span className="navbar-toggler-icon">
                                    <i className="ri-menu-3-line" />
                                    <i style={{ display:"none"}} className="ri-close-line"></i>
                                </span>
                            </button>
                                
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                <li className="nav-item">
                                        <Link onClick={closeMenu} className={"nav-link" + isActive("/lead-coordinator/home")} aria-current="page" to="/lead-coordinator/home">{t('web_lbl_home')}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link onClick={closeMenu} className={"nav-link" + isActive("/lead-coordinator/leads/add")} aria-current="page" to="/lead-coordinator/leads/add">{t('web_lbl_add_lead_manually')}</Link>
                                    </li>
                                    {/* Showing the scan lead if logged in user is LEAD_COORDINATOR */}
                                    {
                                        getuserkey?.role === STAFF_ROLE.LEAD_COORDINATOR || getuserkey?.role === STAFF_ROLE.BOOTH_ATTENDANTS?
                                        <li className="nav-item">
                                            <Link onClick={closeMenu} className={"nav-link" + isActive("/lead-coordinator/leads/scan")} aria-current="page" to="/lead-coordinator/leads/scan">{t('web_lbl_scan_lead')}</Link>
                                        </li>
                                        :null
                                    }
                                    <li className="nav-item">
                                        <Link onClick={closeMenu} className={"nav-link" + isActive("/lead-coordinator/leads/list")} aria-current="page" to="/lead-coordinator/leads/list/1">{t('web_lbl_leads')}</Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link className={"nav-link" + isActive("/lead-coordinator/draft-customers/list")} aria-current="page" to="/lead-coordinator/draft-customers/list/1">Draft Leads</Link>
                                    </li> */}
                                    {
                                        menuOptions.map((menu, i)=>{
                                            return <li className="nav-item" key={i}>
                                                    <Link onClick={closeMenu} className={`nav-link` + (loc.pathname === "/lead-coordinator/org-info/"+menu?.slug ? " active" : "")} to={"/lead-coordinator/org-info/"+menu?.slug}>{menu?.name}</Link>
                                                </li>
                                        })
                                    }
                                </ul>
                                <div className="d-flex p-2">
                                    <select className="form-select" aria-label="Select Event" onChange={handleEventChange} value={reducerData ? reducerData : ""}>
                                        <option value="">{t('web_lbl_select_event')}</option>
                                        {
                                            assignedEvents.map((event, i)=>{
                                                return <option key={i} value={event?._id}>{event?.title}</option>;
                                            })
                                        }
                                    </select>
                                </div>
                            </div>


                        </div>
                        {isUser(3) &&
                            (
                                <div className="nav-R">
                                    <div className="d-flex align-items-center"> 
                                       <div className="btn-group mobile-btn-group lang-box">
                                            <a className="nav-icon-button" data-bs-toggle="dropdown" id="dropdownLangButton" aria-expanded="false" href='/' onClick={(e)=>e.preventDefault()}>
                                             <i className="ri-global-fill"></i>
                                            </a>
                                            <ul className="dropdown-menu" aria-labelledby="dropdownLangButton">
                                             <li>
                                              <button onClick={() => changeLanguage("en")} className="dropdown-item">English</button>   
                                            </li>
                                            <li>
                                              <button onClick={() => changeLanguage("de")} className="dropdown-item">Deutsch</button> 
                                            </li>
                                          </ul>
                                        </div>
                                        <div className="btn-group mobile-btn-group">
                                            <a className="nav-icon-button"  href='/'  onClick={(e)=>{e.preventDefault() ;setToggle(!toggle)}}>
                                             <i className="ri-user-fill"></i>
                                            </a>
                                        </div>

                                        

                                        <div className="dropdown profile-list-drop">
                                            <button
                                                className="dropdown-toggle text-capitalize"
                                                type="button"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                            >
                                               <span className='user-name' id='lead-coordinator-user-name'>{getuserkey?.name}  </span><i className="ri-arrow-down-s-fill" />
                                                <figure>
                                                    <img src={getuserkey?.profile_image} id='lead-coordinator-user-profile' className='web-user-profile rounded-circle' alt='web-user-profile' />
                                                </figure>
                                            </button>
                                            <ul className="dropdown-menu" style={{ minWidth: "7rem" }}>
                                                <li
                                                >
                                                    <Link className="dropdown-item" to={"/lead-coordinator/settings/profile"}>
                                                    {t('web_header_user_profile')}
                                                    </Link>
                                                </li>
                                                <li
                                                >
                                                    <a className="dropdown-item cp" href='/' onClick={(e) => {e.preventDefault();logout()}}>
                                                    {t('web_header_user_logout')}

                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </nav>
            </div>
        </header>
    </>
    )
}

export default LMUsersHeader