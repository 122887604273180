import React, { useEffect } from 'react';
import likeImage from "../../../../assets/user/images/thump-icon.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { getDate } from '../../../../utils/commonfunction';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export default function SurveyThanks() {
   const navigate = useNavigate();
    const location = useLocation();
    const { state } = location;
    const {t} = useTranslation();
    const myGlobalData = useSelector((state) => state.globalData);
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        const settingsData = JSON.parse(myGlobalData?.data);
        if(!settingsData?.settings?.surveys){
            navigate("/404");
        }
      }
    },[myGlobalData]);
  return (
    <div className='wraper-inner cpt bg-grey'>
      <section className="inner-space space-b">
      <div className="container">
         <div className="row">
            <div className="col-md-6 m-auto thanku-card-main">
               <div className="dash-box-shadow pb-5">
                  <div className="thanku-card text-center mb-4 ">
                     <figure className="mb-3"><img src={likeImage}/></figure>
                     <figcaption>
                        <h1>{t('web_lbl_thank_you')}</h1>
                        <span>{t('web_lbl_submitted_survey')}</span>
                     </figcaption>
                  </div>
                  <h4 className="text-center s-title">{state?.title}</h4>
                  <div className="survey-info">
                     <h3>{t('web_lbl_submitted_survey_on')}</h3>
                     <div className="date-info">
                        <i className="ri-calendar-event-line"></i> {getDate(state?.createdAt)}
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
    </div>
  )
}
