import React from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";

const QrCodeStatus = () => {
const {t} = useTranslation();
let reducerData = useSelector(state => state.manageLmBackOffice);
const userDetails= localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
const data=JSON.stringify({user_id:userDetails._id,event_id:reducerData})

  return (
    <>
      <div className="row">
        <div className="col-md-9 m-auto">
          <h3 className="dash-title">{t('web_status_for_qr_code')}</h3>
          <div className="dash-box-shadow space-b">
            <div className="row">
              <div class="text-center">
                <div class="qr-code-booth-attendants">
                  <QRCode
                    size={256}
                    style={{
                      height: "auto",
                      maxWidth: "100%",
                      width: "100%",
                    }}
                    value={data}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QrCodeStatus;
