import axiosInstance from "../../../utils/axios";

const path = "admin/topic"

export const List = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const Add = async (values) => {
    return await axiosInstance().post(`${path}/add`, values);
}

export const Edit = async (values) => {
    return await axiosInstance().post(`${path}/edit`, values);
}

export const IsFav = async (values) => {
    return await axiosInstance().post(`${path}/manage-likes`, values)
}

export const Addcomments = async (values) => {
    return await axiosInstance().post(`${path}/comment`, values)
}


export const Delete = async (values) => {
    return await axiosInstance().post(`${path}/delete`, values)
}

export const Details = async (id) => {
    return await axiosInstance().get(`${path}/details?o_id=${id}`);
}

export const Status = async (values) => {
    return await axiosInstance().post(`${path}/change-status`, values)
}

export const ListComments = async (values) => {
    return await axiosInstance().post(`${path}/comments-list`, values);
}

export const CommentDelete = async (values) => {
    return await axiosInstance().post(`${path}/comments-delete`, values)
}

