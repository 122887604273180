import React, { useEffect, useState, useRef } from "react";
import { Formik } from "formik";
import * as eventServices from '../../services/events.services';
import Swal from "sweetalert2";
import {  SWAL_SETTINGS } from "../../../../utils/Constants";
import { handleServerValidations, globalLoader, formateDate, addSpecialCharacter, removeCommas, writeMyExcel } from "../../../../utils/commonfunction";
import { capitalizeFirstLetter } from '../../../../utils/commonfunction';
import { Link, useLocation, useParams } from "react-router-dom";
import CustomRangepicker from "../../common/rangepicker";
import Sorting from "../../common/sorting";
import Loader from "../../common/loader";
import CustomPagination from "../../common/custompagination";

const ViewTicketDetails = () => {
    const location = useLocation();
    const [showdefault, setShowDefault] = useState(null);
    const [refreshDetails, setRefreshDetails] = useState(0);
    const params = useParams();
    const formikRef = useRef()
    const [csvHeaders, setCsvHeaders] = useState(["Date", "Salutation", "Title", "First Name", "Middle Name", "Last Name", "Email Address", "Ticket Number", "Purpose", "Check In", "Check Out","Check In Date","Check Out Date", "Extra Info - Employee-Code", "Extra Info - Employee-Number"]);
    const [allUsersExportFlag] = useState(false);
    const [search, setSearch] = useState({});
    const [globalsearch, setGlobalSearch] = useState("");
    const [resetdate, setResetDate] = useState(false);
    const [list, setList] = useState([]);
    const [statsupdate, setStatusUpdate] = useState(false);
    const [datalength, setDataLength] = useState("");
    const [itemperpage] = useState(10);
    const [sorting, setSorting] = useState({});
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (search) {
            setLoader(true);
            const formData = new FormData();
            formData.append("page", params.pgno);
            formData.append("per_page", itemperpage);
            formData.append("sort", JSON.stringify(sorting));
            formData.append("search", JSON.stringify(search));
            formData.append("global_search", globalsearch);
            formData.append("event_id", params.id);
            eventServices
                .checkInCheckOutList(formData)
                .then((data) => {
                    setDataLength(data.data.total_records);
                    setList(data && data.data && data.data.list ? data.data.list : []);
                    setPage(data && data.data && data.data.page ? data.data.page : 1);
                    setLoader(false);
                })
                .catch((error) => {
                    console.log("error ====> ", error);
                });
        }
    }, [location, statsupdate, sorting, search, globalsearch,params.id,page]);

// sorting function start
    const handleSort = (e, column) => {
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    };
// sorting end

    // search or filter function start
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    };
    // search or filer function  end

    // reset filter function start
    const resetFilter = (e) => {
        e.preventDefault();
        setGlobalSearch("");
        prepareSearch();
        setSearch({});
        setResetDate(!resetdate);
        // $("#defaultstatus")[0].selectedIndex = 0;
        // $("#resetFilter")[0].selectedIndex = 0;
    };
    // reset filter function end
    const handleCheckIn = () => {
        globalLoader(true);
        setStatusUpdate(false);
        eventServices.CheckIn({ ticket_number: formikRef.current?.values?.ticket_number, event_id: params.id })
            .then(response => {
                globalLoader(false);
                if (response.success) {
                    setRefreshDetails((new Date()).getTime());
                    Swal.fire({
                        icon: 'success',
                        text: response.message,
                        ...SWAL_SETTINGS
                    })
                    setStatusUpdate(true);
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS
                    })
                }
            })
            .catch((error) => {
                globalLoader(false);
                console.log("error=====>", error);
            });
    }

    const showConfirmationCheckIn = (title, text, confirmCallback) => {
        Swal.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
        }).then((result) => {
            if (result.isConfirmed) {
                confirmCallback();
            }
        });
    };

    const handleCheckInClick = () => {
        showConfirmationCheckIn('Are you sure?', 'Do you want to check in?', handleCheckIn);
    };

    useEffect(() => {
        if (refreshDetails) {
            globalLoader(true);
            eventServices.TicketDetails({ ticket_number: formikRef.current?.values?.ticket_number, event_id: params.id })
                .then(response => {
                    globalLoader(false);
                    if (response.success) {
                        setShowDefault(response.data)
                    } else {
                        Swal.fire({
                            icon: "error",
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS,
                        });
                    }
                })
                .catch((error) => {
                    globalLoader(false);
                    console.log("error=====>", error);
                });
        }
    }, [refreshDetails]);

    /** This function is used to export all user's data */
    const exportAllUsers = (event_id, done) => {
        globalLoader(true);
        const formData = new FormData();
        formData.append("page",1);
        formData.append("per_page", datalength);
        formData.append("sort", JSON.stringify(sorting));
        formData.append("search", JSON.stringify(search));
        formData.append("global_search", globalsearch);
        formData.append("event_id", params?.id);
        eventServices
            .checkInCheckOutList(formData)
            .then((response) => {
                let data = response && response?.data && response?.data?.list ? response.data.list : [];
                let row = data.map((item => {
                    const tempRow = [
                        removeCommas(formateDate(item?.createdAt)),
                        removeCommas(item?.user_data?.gender) ? removeCommas(item?.user_data?.gender) : "N/A",
                        removeCommas(item?.user_data?.title) ? removeCommas(item?.user_data?.title) : "N/A",
                        removeCommas(item?.user_data?.first_name) ? removeCommas(item?.user_data?.first_name) : "N/A",
                        removeCommas(item?.user_data?.middle_name) ? removeCommas(item?.user_data?.middle_name) : "N/A",
                        removeCommas(item?.user_data?.last_name) ? removeCommas(item?.user_data?.last_name) : "N/A",
                        removeCommas(item?.user_data?.email) ? removeCommas(item?.user_data?.email) : "N/A",
                        removeCommas(item?.ticket_number) ? removeCommas(item?.ticket_number) : "N/A",
                        removeCommas(item?.tickets_data?.type) ? removeCommas(item?.tickets_data?.type): "N/A",
                        item && typeof item?.checked_in != "undefined" && item?.checked_in === true ? "Success" : "Pending",
                        item && typeof item?.checked_out != "undefined" && item?.checked_out === true ? "Success" : "Pending",
                        removeCommas(formateDate(item?.check_in_date)),
                        removeCommas(formateDate(item?.check_out_date)),
                        removeCommas(item?.user_data?.extra_info?.employee_code) ? removeCommas(item?.user_data?.extra_info?.employee_code) : "N/A",
                        removeCommas(item?.user_data?.extra_info?.employee_number) ? removeCommas(item?.user_data?.extra_info?.employee_number) : "N/A",
                    ];
                    const tempData = [...csvHeaders]
                    setCsvHeaders([...tempData])
                    return tempRow
                }));
                writeMyExcel([csvHeaders, ...row], `Check-in+Check-out-${addSpecialCharacter(list[0]?.event_data?.title)}.xlsx`).then(() => {
                    setTimeout(() => {
                      globalLoader(false);
                    }, 1000);
                  }).catch((err) => {
                    console.log(err);
                  });
            }).catch((error) => {
                console.log("error ====> ", error);
                globalLoader(false);
            });
    };

    const handleCheckOut = () => {
        globalLoader(true);
        setStatusUpdate(false);
        eventServices.CheckOut({ ticket_number: formikRef.current?.values?.ticket_number, event_id: params.id })
            .then(response => {
                globalLoader(false);
                if (response.success) {
                    setRefreshDetails((new Date()).getTime());
                    Swal.fire({
                        icon: 'success',
                        text: response.message,
                        ...SWAL_SETTINGS
                    })
                    setStatusUpdate(true);
                } else {
                    Swal.fire({
                        icon: 'error',
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS
                    })
                }
            })
            .catch((error) => {
                globalLoader(false);
                console.log("error=====>", error);
            });
    }
    const showConfirmationCheckOut = (title, text, confirmCallback) => {
        Swal.fire({
            title: title,
            text: text,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
        }).then((result) => {
            if (result.isConfirmed) {
                confirmCallback();
            }
        });
    };

    const handleCheckOutClick = () => {
        showConfirmationCheckOut('Are you sure?', 'Do you want to check out?', handleCheckOut);
    };

    return (
        <>
            <Formik
                innerRef={formikRef}
                validateOnChange={true}
                enableReinitialize
                initialValues={{
                    ticket_number: "",
                }}
                validate={(values) => {
                    const error = {};
                    if (!values.ticket_number) {
                        error.ticket_number = "Ticket number is required";
                    }
                    return error;
                }}
                onSubmit={(values) => {
                    setRefreshDetails((new Date()).getTime());
                    setShowDefault(null);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row row-sm">
                            <div className="col-lg-12 col-md-12 animation_fade">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <div className="row row-sm">
                                            <div className="col-md-6 text-center form-group">
                                                <label htmlFor="ticket_number" className="text-left d-flex">
                                                    Ticket Number:<span className="requirestar">*</span>
                                                </label>
                                                <div className='d-flex align-items-center'>
                                                    <input
                                                        name="ticket_number"
                                                        type="text"
                                                        id="ticket_number"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.ticket_number}
                                                        className="form-control w-75"
                                                    />
                                                    <button className="btn ripple btn-main-primary signbtn ms-3" type="submit">Next</button>
                                                </div>
                                                <span className="text-danger d-flex text-left">
                                                    {errors.ticket_number &&
                                                        touched.ticket_number &&
                                                        errors.ticket_number}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {showdefault && Object.keys(showdefault).length > 0 ?
                                    <div className="row row-sm viewregisration">
                                        <div className="col-lg-12 col-md-12 animation_fade">
                                            <div className="card custom-card">
                                                <div className="card-body pb-0">
                                                    <button
                                                        className="btn ripple btn-success"
                                                        type="button"
                                                        style={{ position: 'absolute', top: '20px', right: '20px', zIndex: '2' }}
                                                        onClick={handleCheckInClick}
                                                    >
                                                        Check In
                                                    </button>
                                                    <button
                                                        className="btn ripple btn-success"
                                                        type="button"
                                                        style={{ position: 'absolute', top: '70px', right: '20px', zIndex: '2' }}
                                                        onClick={handleCheckOutClick}
                                                    >
                                                        Check Out
                                                    </button>

                                                    <div className='row'>
                                                        <div className="col-lg-12 form-group">
                                                            <div className="mb-4">
                                                                <h6 className="main-content-label mb-3">User Info</h6>
                                                                <p className="mb-1"><label>Name :</label> {showdefault && showdefault?.user_data?.name ? capitalizeFirstLetter(showdefault.user_data.name) : "N/A"}
                                                                </p>
                                                                {showdefault.guest_type === "primary" ?
                                                                    <p><label>Email :</label> {showdefault && showdefault?.user_data?.email ? <Link to={`/admin/user-management/users/view/${showdefault.user_data._id}`}>{showdefault.user_data?.email}</Link> : "N/A"}</p>
                                                                    :
                                                                    <p className="mb-1"><label>Email :</label> {showdefault && showdefault?.user_data?.email ? showdefault.user_data.email : "N/A"}
                                                                    </p>
                                                                }
                                                                <p className="mb-1"><label>Phone Number :</label> {showdefault && showdefault?.user_data?.phone_number?.number ? showdefault.user_data?.phone_number.number : "N/A"}
                                                                </p>
                                                            </div>
                                                            <div className="mb-4">
                                                                <h6 className="main-content-label mb-3">Event Info</h6>
                                                                <p className="mb-1"><label>Title :</label> {showdefault && showdefault?.event_data?.title ? capitalizeFirstLetter(showdefault.event_data.title) : "N/A"}
                                                                </p>
                                                                <p className="mb-1"><label>Start Date :</label> {showdefault && showdefault.event_data?.start_date ? (formateDate(showdefault.event_data.start_date)) : "N/A"}</p>
                                                                <p className="mb-1"><label>End Date :</label> {showdefault && showdefault.event_data?.end_date ? (formateDate(showdefault.event_data.end_date)) : "N/A"}</p>
                                                            </div>
                                                            <div className="mb-4">
                                                                <h6 className="main-content-label mb-3">Ticket Info</h6>
                                                                <p className="mb-1"><label># :</label> {showdefault && showdefault?.ticket_number ? showdefault.ticket_number : "N/A"}
                                                                </p>
                                                                <p className="mb-1"><label>Title :</label> {showdefault && showdefault?.title ? capitalizeFirstLetter(showdefault.title) : "N/A"}
                                                                </p>
                                                                <p className="mb-1"><label>Type :</label> {showdefault && showdefault?.type ? capitalizeFirstLetter(showdefault.type) : "N/A"}</p>
                                                                {showdefault && showdefault?.type === "hotel" ? (null) : (
                                                                    <p>
                                                                        <label>Guest Type :</label>{" "}
                                                                        {showdefault && showdefault?.guest_type ? capitalizeFirstLetter(showdefault.guest_type) : "N/A"}
                                                                    </p>
                                                                )}
                                                                <p className="mb-1"><label>Price :</label> {showdefault && showdefault?.price ? showdefault?.event_data?.currency?.sign + showdefault.price.toFixed(2) : "0.00"}
                                                                </p>
                                                                {/* <p className="mb-1"><label>Checked In :</label> {showdefault && typeof showdefault?.checked_in != "undefined" && showdefault?.checked_in == true ? <span className="badge badge-success">Success</span> : <span className="badge badge-danger">Pending</span>}</p>
                                                                <p className="mb-1"><label>Checked Out :</label> {showdefault && typeof showdefault?.checked_out != "undefined" && showdefault?.checked_out == true ? <span className="badge badge-success">Success</span> : <span className="badge badge-danger">Pending</span>}</p>
                                                                {showdefault && typeof showdefault.checked_in !== "undefined" && showdefault.checked_in === true ? (
                                                                    <>
                                                                        <p className="mb-1"><label>Checked In Date :</label> {showdefault && showdefault.check_in_date ? (formateDate(showdefault.check_in_date)) : "N/A"}</p>
                                                                    </>
                                                                ) : (
                                                                    null
                                                                )}
                                                                {showdefault && typeof showdefault.checked_out !== "undefined" && showdefault.checked_out === true ? (
                                                                    <>
                                                                        <p className="mb-1"><label>Checked Out Date :</label> {showdefault && showdefault.check_out_date ? (formateDate(showdefault.check_out_date)) : "N/A"}</p>
                                                                    </>
                                                                ) : (
                                                                    null
                                                                )} */}
                                                                <p className="mb-1 pb-0"><label>Created Date :</label> {showdefault && showdefault.createdAt ? (formateDate(showdefault.createdAt)) : "N/A"}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> : <></>}
                            </div>
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center mb-4">
                                    <h6 className="main-content-label">CheckIn {"&"} CheckOut</h6>
                                    <div className="d-flex align-items-center">
                                        <div className="form-group mb-0 me-3">
                                            <div className="form-group mb-0 rangepicker_container filter_design">
                                                <i className="fa fa-search calender_icon"></i>
                                                <input
                                                    type="search"
                                                    className="form-control"
                                                    value={globalsearch}
                                                    placeholder="Search"
                                                    onChange={(e) => {
                                                        setGlobalSearch(e.target.value);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="me-3">
                                            <CustomRangepicker
                                                GetDateRange={(e) => {
                                                    prepareSearch("createdAt", e);
                                                }}
                                                resetdate={resetdate}
                                            />
                                        </div>
                                        <button
                                            type="reset"
                                            value="Reset"
                                            onClick={resetFilter}
                                            className="btn btn-warning float-right mr-2"
                                        >
                                            Reset Filter
                                        </button>
                                        {
                                            allUsersExportFlag ?
                                                <>
                                                    <button className="btn ripple btn-main-primary signbtn mr-2" type="button"  disabled={datalength === 0} onClick={() => exportAllUsers()}>Export Report</button>
                                                </>
                                                : <button className="btn ripple btn-main-primary signbtn mr-2" type="button" disabled={datalength === 0} onClick={() => exportAllUsers()}>Export Report</button>
                                        }
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div className="sorting_column">
                                                        <span>Title</span>
                                                        <Sorting
                                                            sort={sorting}
                                                            handleSort={handleSort}
                                                            column="title"
                                                        />
                                                    </div>
                                                </th>
                                                <th>Email</th>
                                                <th>Ticket number</th>
                                                <th>CheckIn</th>
                                                <th>CheckOut</th>
                                                <th>Checkin date</th>
                                                <th>Checkout date</th>
                                                <th>
                                                    <div className="sorting_column">
                                                        <span>Created  Date</span>
                                                        <Sorting
                                                            sort={sorting}
                                                            handleSort={handleSort}
                                                            column="createdAt"
                                                        />
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loader ? (
                                                <tr>
                                                    <td colSpan={8}>
                                                        <Loader />
                                                    </td>
                                                </tr>
                                            ) : (
                                                <>
                                                    {list.length ? (
                                                        list.map((row, i) => {
                                                            return (
                                                                <tr key={i} >
                                                                    <td>{row?.user_data?.name ? row?.user_data?.name : "N/A"}</td>
                                                                    <td>{row?.user_data?.email ? row?.user_data?.email : "N/A"}</td>
                                                                    <td>{row?.ticket_number ? row?.ticket_number : "N/A"}</td>
                                                                    <td>{row && typeof row?.checked_in != "undefined" && row?.checked_in === true ? <span className="badge badge-success">Success</span> : <span className="badge badge-danger">Pending</span>}</td>
                                                                    <td>{row && typeof row?.checked_out != "undefined" && row?.checked_out === true ? <span className="badge badge-success">Success</span> : <span className="badge badge-danger">Pending</span>}</td>
                                                                    <td>{row?.check_in_date ? formateDate(row?.check_in_date) : "N/A"}</td>
                                                                    <td>{row?.check_out_date ? formateDate(row?.check_out_date) : "N/A"}</td>
                                                                    <td>
                                                                        {row?.createdAt
                                                                            ? formateDate(row?.createdAt)
                                                                            : "N/A"}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })
                                                    ) : (
                                                        <tr>
                                                            <td colSpan={8} className="text-center">
                                                                No records
                                                            </td>
                                                        </tr>
                                                    )}
                                                </>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div
                                    className=""
                                    id="example1_info"
                                    role="status"
                                    aria-live="polite"
                                >
                                    <b>Total Records : {datalength ? datalength : "0"}</b>
                                </div>
                                {datalength && datalength > 0 ? (
                                    <CustomPagination
                                        datalength={datalength}
                                        itemperpage={itemperpage}
                                        currentPage={page}
                                        setPage={setPage}
                                        pageRoute={[
                                            {
                                                name: "CheckIn_CheckOut",
                                                path: `/admin/event-management/events/view/checkIn-checkOut/${params.id}`,
                                            },
                                        ]}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default ViewTicketDetails;