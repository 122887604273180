import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import bannerImage from "../../../assets/user/images/banner-card.png";
import { useTranslation } from "react-i18next";
import {
  SpecialCharacter,
  TrimText,
  capitalizeFirstLetterOfEachWord,
  formateDate,
  formateDateWithMonth,
  getSessionKey,
  handleServerValidations,
  removeSessionKey,
  setSessionKey,
  replaceNameBadge
} from "../../../utils/commonfunction";
import $ from "jquery";
import CustomPagination from "../../admin/common/custompagination";
import { DEFAULT_USER, EVENT_TYPES, showFilterlist } from "../../../utils/Constants";
import {
  NameBadgeOptions,
  SISUserList,
  SISUserStatus,
} from "../services/common.services";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import { SWAL_SETTINGS } from "../../../utils/Constants";
import { Modal } from "rsuite";
import { Formik } from "formik";
import ReactToPrint from "react-to-print";
import FrontLoader from "../../globalComponents/FrontLoader";

const Table = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [list, setList] = useState([]);
  const [itemperpage] = useState(8);
  const [page, setPage] = useState(1);
  const [datalength, setDataLength] = useState("");
  const { t } = useTranslation();
  const [globalsearch, setGlobalSearch] = useState("");
  const [search, setSearch] = useState({});
  const [statsupdate, setStatusUpdate] = useState("false");
  let reducerData = useSelector((state) => state.manageLmBackOffice);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedAllDataUser, setSelectedAllDataUser] = useState(false);
  const selectAllCheckbox = useRef();
  const printBadgeTrigger = useRef();
  const [nameBadgeOptions, setNameBadgeOptions] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [id, setId] = useState("");
  const [showBackImage, setShowBackImage] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const modalContentRef = useRef();
  const [selectedIndividualUser, setSelectedIndividualUser] = useState(null);
  const [cardDescription, setCardDescription] = useState(null);
  const [backcardDescription, setBackCardDescription] = useState(null);
  const printMultipleBadgeTrigger = useRef();
  const modalContentMultiRef = useRef();
  const [allSelectedUserDetail, setAllSelectedUserDetail] = useState([]);
  const [selectedTemplateDetail, setSelectedTemplateDetail] = useState("");
  const [defaultcardDescription, setDefaultCardDescription] = useState(null);
  const [defaultBackcardDescription, setDefaultBackCardDescription] = useState(null);
  const [loader ,setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", itemperpage);
    formData.append("global_search", globalsearch);
    formData.append("search", JSON.stringify(search));
    formData.append("event_id", reducerData);
    SISUserList(formData)
      .then((response) => {
        setList(
          response && response.data && response.data.list
            ? response.data.list
            : []
        );
        setDataLength(response?.data?.total_records);
        setPage(
          response && response?.data && response?.data?.page
            ? response?.data?.page
            : 1
        );
        setSelectedAllDataUser(
          response && response.data && response.data.data_ids
            ? response.data.data_ids
            : []
        );
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  }, [itemperpage, params.pgno, globalsearch, search, statsupdate, reducerData]);

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    setSearch({});
    $("#defaultstatus")[0].selectedIndex = 0;
  };

  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };

  const ChangeStatus = (data, Num) => {
    let ids = Array.isArray(data) ? data : [data];
    const formData = new FormData();
    formData.append("o_id", JSON.stringify(ids));
    formData.append("status", Num);
    Swal.fire({
      customClass: "swal-wide",
      title: t("web_swal_are_you_sure"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: t("web_swal_yes"),
      cancelButtonText: t("web_swal_no"),
    }).then((result) => {
      if (result.isConfirmed) {
        SISUserStatus(formData)
          .then((response) => {
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setStatusUpdate(!statsupdate);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("error===>");
          });
      }
    });
  };

  useEffect(() => {
    if (selectedUser.length === datalength) {
      selectAllCheckbox.current.indeterminate = false;
      selectAllCheckbox.current.checked = true;
    } else if (selectedUser.length) {
      selectAllCheckbox.current.indeterminate = true;
    } else {
      selectAllCheckbox.current.indeterminate = false;
    }
  }, [selectedUser]);

  const selectUser = (userData, check) => {
    if (check) {
      setAllSelectedUserDetail((previousState) => [...previousState, userData]);
      setSelectedUser((previousState) => [...previousState, userData._id]);
    } else {
      setSelectedUser((previousState) =>
        previousState.filter((item) => item !== userData._id)
      );
      setAllSelectedUserDetail((previousState) =>
        previousState.filter((item) => item._id !== userData._id)
      );
    }
  };
  const handleSelect = (check) => {
    if (check) {
      setSelectedUser(selectedAllDataUser);
      setAllSelectedUserDetail(list)
    } else {
      setSelectedUser([]);
      setAllSelectedUserDetail([])
    }
  };
  const isSelectedNew = (item) => {
    return selectedUser.filter((data) => item._id === data).length > 0;
  };

  const selectRow = (item) => {
    setSelectedIndividualUser(item)
    let id = item?._id;
    $("#" + id + "label").trigger("click");
  };

  const handleCloseModal = () => {
    const closeButton = document.getElementById("close_button");
    closeButton.click();
    setPreviewData([]);
    setSelectedTemplate("");
    setSelectedTemplateDetail("")
    setId("");
  };

  const handleClick = () => {
    printBadgeTrigger.current.click();
  };

  const handleForMultiClick = () => {
    printMultipleBadgeTrigger.current.click();
  };


  const handleCloseModalForMultiBadge = () => {
    const closeButton = document.getElementById("close_button_multi");
    closeButton.click();
    // setAllSelectedUserDetail([])
    setSelectedTemplate("")
    setSelectedTemplateDetail(null)
    // setSelectedUser([]);
    setId("");
  };

  useEffect(() => {
    const formData = new FormData();
    NameBadgeOptions(formData)
      .then((response) => {
        setNameBadgeOptions(
          response && response.data && response.data.list
            ? response.data.list
            : []
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const togglePreviewImage = () => {
    let previewImage = !showBackImage;
    setShowBackImage(previewImage);
  };

  const getBadgeSize = (size) => {
    if (size) {
      let [width, height] = size.split("*");
      return { width: `${width}mm`, height: `${height}mm` };
    }
    return {};
  };

  const handleTemplateChange = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    setSelectedTemplate(e.target.value);
    setSelectedTemplateDetail(nameBadgeOptions[selectedIndex - 1])
    setPreviewData([nameBadgeOptions[selectedIndex - 1]]);
    setId(e.target.options[selectedIndex].getAttribute("data-id"));
    let frontDescription = getNewDescription(nameBadgeOptions[selectedIndex - 1]?.description_front_badge, selectedIndividualUser)
    setCardDescription(frontDescription)
    let defaultFrontdescription =  getNewDescription(nameBadgeOptions[selectedIndex - 1]?.description_front_badge, DEFAULT_USER)
    setDefaultCardDescription(defaultFrontdescription)
    if (nameBadgeOptions[selectedIndex - 1]?.description_back_badge) {
      let backDescription = getNewDescription(nameBadgeOptions[selectedIndex - 1]?.description_back_badge, selectedIndividualUser)
      setBackCardDescription(backDescription);
      let defaultBackFrontdescription =  getNewDescription(nameBadgeOptions[selectedIndex - 1]?.description_back_badge, DEFAULT_USER)
      setDefaultBackCardDescription(defaultBackFrontdescription)
    }
  };

  const getNewDescription = (des, userDetail) => {
    let newData = replaceNameBadge(des, userDetail)
    return newData;
  }

  return (
    <>
      {/* Showing User list */}
      <div className="wraper-inner cpt bg-grey">
        <section className="inner-space pt-0 mt-5 ">
          <div className="container" style={{ minHeight: "60vh" }}>
            <div className="user-box-head d-flex justify-content-between mb-4">
              <h3 className="inner-title mb-0">{t("web_lbl_list")}</h3>
              <div className="user-right filter-responsive d-flex align-items-center">
                <div className="select-check-container">
                  <label
                    htmlFor="selectAllCheck"
                    style={{ lineHeight: "unset" }}
                    className="ckbox cp"
                  >
                    <input
                      ref={selectAllCheckbox}
                      id="selectAllCheck"
                      checked={datalength && datalength === selectedUser.length}
                      onChange={(e) => handleSelect(e.target.checked)}
                      type="checkbox"
                    />
                    <span className="ms-1 no-select no-before">{t('web_sis_user_select_all')}</span>
                  </label>
                </div>
                <div className="search-box ms-1">
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder={t("web_lbl_search")}
                    value={globalsearch}
                    onChange={(e) => {
                      setGlobalSearch(e.target.value);
                    }}
                  />
                  <button className="btn-search">
                    <i className="ti ti-search"></i>
                  </button>
                </div>
                <div className="search-box ms-1">
                  <select
                    className="form-select cp"
                    onChange={(e) => prepareSearch("status", e.target.value)}
                    id="defaultstatus"
                  >
                    {/* <option value="">{t("web_lbl_status")}</option> */}
                    {showFilterlist &&
                      showFilterlist.length > 0 &&
                      showFilterlist.map((option, i) => {
                        return (
                          <option key={i} value={option.status__id}>
                            {option.name}
                          </option>
                        );
                      })}
                  </select>
                  <button className="btn-search"></button>
                </div>
                <button
                  className="btn btn-info ms-1 "
                  onClick={() => navigate(`/info-counter/sis-user/add`)}
                >
                  {t("web_lbl_lead_add")}
                </button>

                <button
                  className="btn btn-warning ms-1"
                  onClick={(e) => {
                    resetFilter(e);
                  }}
                >
                  {t("web_lbl_reset_filter")}
                </button>
                
              </div>
            </div>
            {loader ? 
            <FrontLoader/>
            :
            <>
            {list && list.length > 0 ? (
              <div className="row users-rows">
                {list.map((user, i) => {
                  return (
                    <div
                      className="col-lg-3 col-md-4 col-sm-6 users-box"
                      key={i}
                      onClick={() => selectRow(user)}
                    >
                      <div className="dash-box-shadow text-center h-100 d-flex flex-column">
                        <figure className="mb-auto">
                          <img
                            className="join"
                            src={user?.profile_image}
                            alt="UserProfile"
                          />
                        </figure>
                        <figcaption className="mt-auth">
                          <span className="user_name  d-block mt-1">
                            {user?.name}
                          </span>
                          <span className="user_name d-block">
                            {user?.email ? TrimText(user?.email, 20) : null}
                          </span>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() =>
                                navigate(
                                  `/info-counter/sis-user/edit/${user?._id}`
                                )
                              }
                            >
                              {t("web_lbl_edit")}
                            </button>
                            <div className="ms-1">
                              <button
                                type="button"
                                className=" btn bg-secondary text-white dropdown-toggle"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {t('web_lbl_more')}
                              </button>
                              <ul className="dropdown-menu">
                                <li>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => handleClick()}
                                  >
                                    {t('web_lbl_print_name_badge')}
                                  </button>
                                </li>
                                <li>
                                  {user?.status === 1 ? (
                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        ChangeStatus(user?._id, 0);
                                      }}
                                      style={{ color: 'rgb(64, 63, 173)' }}
                                    >
                                      {t("web_status_active")}
                                    </button>
                                  ) : (
                                    <button
                                      className="dropdown-item"
                                      onClick={() => {
                                        ChangeStatus(user?._id, 1);
                                      }}
                                      style={{ color: 'red' }}
                                    >
                                      {t("web_lbl_in_active")}
                                    </button>
                                  )}
                                </li>
                              </ul>
                            </div>
                          </div>
                        </figcaption>
                        <input
                          style={{ position: "absolute" }}
                          type="checkbox"
                          onChange={(e) => selectUser(user, e?.target?.checked)}
                          id={"select-checkbox"}
                          checked={isSelectedNew(user)}
                          className="cp"
                        />
                        <label htmlFor={"select-checkbox"}></label>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <h2 style={{ textAlign: "center", fontSize: "25px" }}>
                {t("web_lbl_no_records")}
              </h2>
            )}
            </>
            }
          </div>
          {datalength && datalength > 0 ? (
            <div className="mt-4 text-right pagination-main d-flex justify-content-end  pe-4">
              <CustomPagination
                datalength={datalength}
                itemperpage={itemperpage}
                currentPage={page}
                setPage={setPage}
                pageRoute={[
                  { name: "users", path: "/info-counter/sis-user/list" },
                ]}
              />
            </div>
          ) : (
            ""
          )}
        </section>
      </div>
      {/* Showing footer for selected */}
      {selectedUser.length ? (
        <div className="bulk_actions">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ fontSize: "23px", color: "#35b159" }}
              ></i>
              <h3 className="ms-2 mt-1 mb-0">
                {t('web_dynamic_question_selected')} {selectedUser.length}{" "}
                {selectedUser.length > 1 ? "records" : "record"} of {datalength}
              </h3>
            </div>
            <div>
              <ul>
                <li>
                  <button
                    className="btn text-white"
                    onClick={() => {
                      ChangeStatus(selectedUser, 1);
                    }}
                    style={{ backgroundColor: 'rgb(64, 63, 173)' }}
                  >
                    {t("web_status_active")}
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      ChangeStatus(selectedUser, 0);
                    }}
                  >
                    {t("web_lbl_in_active")}
                  </button>
                </li>
                <li>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      handleForMultiClick()
                    }}
                  >

                    {t('web_lbl_print_name_badge')}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* triger print fo single selected user */}
      <a
        className="d-none"
        ref={printBadgeTrigger}
        data-bs-toggle="modal"
        href="#exampleModalCenter"
        role="button"
      >
        <span></span>{" "}
      </a>
      <div
        class="modal fade"
        id="exampleModalCenter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title" id="exampleModalLongTitle">
                {" "}
                {t('web_lbl_print_name_badge')}
              </h3>
              <button
                type="button"
                className="btn-close"
                id="close_button"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleCloseModal();
                }}
              ></button>
            </div>
            <div class="modal-body">
              <div className="row ms-1">
                <div className="col-lg-12 text-center form-group ps-0 mt-3">
                  <label className="text-left d-flex">
                    {t('web_lbl_info_counter_select_template')}:
                  </label>
                  <div className="form-group">
                    <div className="select-down-arrow">
                      <select
                        className="form-control select2"
                        name="name_badge_template"
                        value={selectedTemplate}
                        onChange={handleTemplateChange}
                      >
                        <option value="" label="Select">
                          {t('web_lbl_select')}{" "}
                        </option>
                        {nameBadgeOptions &&
                          nameBadgeOptions.length > 0 &&
                          nameBadgeOptions.map((option, i) => {
                            return (
                              <option
                                key={i}
                                data-id={option?.size}
                                value={option.size}
                              >
                                {option.title}{" "}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                {id && previewData && previewData.length ? (
                  previewData.map((row, i) => (
                    <React.Fragment key={i}>
                      <h3 className="mb-3 mt-4">{t('web_lbl_preview')}</h3>
                      <div
                        className="border p-4 rounded mx-auto"
                        style={getBadgeSize(row?.size)}
                      >
                        <div className="name-badge-preview">
                          {!showBackImage ? (
                            <>
                              <img
                                src={row?.front_image}
                                className="pre-img"
                                alt="Preview image"
                              />
                              <div
                                className="name-badge-content text-white"
                                style={{ overflow: "hidden" }}
                                dangerouslySetInnerHTML={{
                                  __html: cardDescription
                                    ? cardDescription
                                    : null,
                                }}
                              ></div>
                            </>
                          ) : (
                            <>
                              <img
                                src={row?.back_image}
                                className="pre-img"
                                alt="Preview image"
                              />
                              <div
                                className="name-badge-content text-white"
                                style={{ overflow: "hidden" }}
                                dangerouslySetInnerHTML={{
                                  __html: backcardDescription
                                    ? backcardDescription
                                    : null,
                                }}
                              ></div>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="d-flex mt-4 justify-content-center">
                        {row?.is_badge_double_sided && row?.back_image ? (
                          <>
                            {" "}
                            <button
                              className="btn ripple btn-danger mr-2 mr-2"
                              type="button"
                              onClick={togglePreviewImage}
                            >
                              {t('web_lbl_rotate_image')}{" "}
                            </button>
                          </>
                        ) : null}
                      </div>
                    </React.Fragment>
                  ))
                ) : (
                  <div
                    style={{ height: "40vh", width: '98.5%' }}
                    className="border p-4 rounded justify-content-center align-items-center d-flex mt-4"
                  >
                    <h1>{t('web_lbl_preview')}</h1>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              {previewData && previewData.length > 0 ? (
                previewData.map((row, i) =>
                  row?.front_image ? (
                    <ReactToPrint
                      key={i}
                      trigger={() => (
                        <button className="btn btn-primary">
                          {t('web_lbl_print')}</button>
                      )}
                      content={() => modalContentRef.current}
                      onAfterPrint={() => handleCloseModal()}
                    />
                  ) : (
                    <button key={i} className="btn btn-primary" type="button" disabled>

                      {t('web_lbl_print')}
                    </button>
                  )
                )
              ) : (
                <button
                  className="btn btn-primary"
                  type="button"
                  disabled
                >

                  {t('web_lbl_print')}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>


      <div style={{ display: "none" }}>
        <div ref={modalContentRef}>
          <div className="name-badge-container">
            {previewData && previewData.map((item, index) => (
              <React.Fragment key={index}>
                {item?.front_image && (
                  <div
                    className="border p-4 rounded mx-auto"
                    style={getBadgeSize(item?.size)}
                  >
                    <div className="name-badge-preview name-badge-centered">
                      <img
                        src={item?.front_image}
                        className="pre-img"
                        alt="Front Preview image"
                      />
                      <div
                        className="name-badge-content text-white"
                        style={{ overflow: "hidden" }}
                        dangerouslySetInnerHTML={{
                          __html: cardDescription
                            ? cardDescription
                            : null,
                        }}
                      ></div>
                    </div>
                  </div>
                )}
                {item?.back_image && (
                  <div
                    className="border p-4 rounded mx-auto name-badge-below-centered mt-4"
                    style={getBadgeSize(item?.size)}
                  >
                    <div className="name-badge-preview">
                      <img
                        src={item?.back_image}
                        className="pre-img"
                        alt="Back Preview image"
                      />
                      <div
                        className="name-badge-content text-white"
                        style={{ overflow: "hidden" }}
                        dangerouslySetInnerHTML={{
                          __html: backcardDescription
                            ? backcardDescription
                            : null,
                        }}
                      ></div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>


      {/* triger print fo multi selected user */}
      <a
        className="d-none"
        ref={printMultipleBadgeTrigger}
        data-bs-toggle="modal"
        href="#multipleBadgeTrigger"
        role="button"
      >
        <span></span>{" "}
      </a>
      <div
        class="modal fade"
        id="multipleBadgeTrigger"
        tabindex="-1"
        role="dialog"
        aria-labelledby="multipleBadgeTriggerTitle"
        aria-hidden="true"
        data-backdrop="static"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title" id="exampleModalLongTitle">
                {" "}

                {t('web_lbl_print_name_badge')}
              </h3>
              <button
                type="button"
                className="btn-close"
                id="close_button_multi"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  handleCloseModalForMultiBadge();
                }}
              ></button>
            </div>
            <div class="modal-body">
              <div className="row ms-1">
                <div className="col-lg-12 text-center form-group ps-0 mt-3">
                  <label className="text-left d-flex">
                    {t('web_lbl_info_counter_select_template')}:
                  </label>
                  <div className="form-group">
                    <div className="select-down-arrow">
                      <select
                        className="form-control select2"
                        name="name_badge_template"
                        value={selectedTemplate}
                        onChange={handleTemplateChange}
                      >
                        <option value="" label="Select">
                          {t('web_lbl_select')}{" "}
                        </option>
                        {nameBadgeOptions &&
                          nameBadgeOptions.length > 0 &&
                          nameBadgeOptions.map((option, i) => {
                            return (
                              <option
                                key={i}
                                data-id={option?.size}
                                value={option.size}
                              >
                                {option.title}{" "}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                {selectedTemplate && selectedTemplateDetail ? 
                <React.Fragment>
                  <h3 className="mb-3 mt-4">{t('web_lbl_preview')}</h3>
                  <div
                    className="border p-4 rounded mx-auto"
                    style={getBadgeSize(selectedTemplate)}
                  >
                    <div className="name-badge-preview">
                      {!showBackImage ? (
                        <>
                          <img
                            src={selectedTemplateDetail?.front_image}
                            className="pre-img"
                            alt="Preview image"
                          />
                          <div
                            className="name-badge-content text-white"
                            style={{ overflow: "hidden" }}
                            dangerouslySetInnerHTML={{
                              __html: defaultcardDescription
                                ? defaultcardDescription
                                : null,
                            }}
                          ></div>
                        </>
                      ) : (
                        <>
                          <img
                            src={selectedTemplateDetail?.back_image}
                            className="pre-img"
                            alt="Preview image"
                          />
                          <div
                            className="name-badge-content text-white"
                            style={{ overflow: "hidden" }}
                            dangerouslySetInnerHTML={{
                              __html: defaultBackcardDescription
                                ? defaultBackcardDescription
                                : null,
                            }}
                          ></div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="d-flex mt-4 justify-content-center">
                    {selectedTemplateDetail?.is_badge_double_sided && selectedTemplateDetail?.back_image ? (
                      <>
                        {" "}
                        <button
                          className="btn ripple btn-danger mr-2 mr-2"
                          type="button"
                          onClick={togglePreviewImage}
                        >
                          {t('web_lbl_rotate_image')}{" "}
                        </button>
                      </>
                    ) : null}
                  </div>
                </React.Fragment>
                 : (
                  <div
                    style={{ height: "40vh", width: '98.5%' }}
                    className="border p-4 rounded justify-content-center align-items-center d-flex mt-4"
                  >
                    <h1>{t('web_lbl_preview')}</h1>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <ReactToPrint
                // key={i}
                trigger={() => (
                  <button className="btn btn-primary">{t('web_lbl_print')}</button>
                )}
                onAfterPrint={() => handleCloseModalForMultiBadge()}
                content={() => modalContentMultiRef.current}
              />
            </div>
          </div>
        </div>
      </div>


      <div style={{ display: "none" }}>
        <div ref={modalContentMultiRef}>
          {allSelectedUserDetail && allSelectedUserDetail.map((item, index) => (
            <div className="name-badge-container mb">
              <React.Fragment key={index}>
                {selectedTemplateDetail?.front_image && (
                  <div
                    className="border p-4 rounded mx-auto"
                    style={getBadgeSize(selectedTemplate)}
                  >
                    <div className="name-badge-preview name-badge-centered">
                      <img
                        src={selectedTemplateDetail?.front_image}
                        className="pre-img"
                        alt="Front Preview image"
                      />
                      <div
                        className="name-badge-content text-white"
                        style={{ overflow: "hidden" }}
                        dangerouslySetInnerHTML={{
                          __html: getNewDescription(selectedTemplateDetail?.description_front_badge, item)
                        }}
                      ></div>
                    </div>
                  </div>
                )}
                {selectedTemplateDetail?.back_image && (
                  <div
                    className="border p-4 rounded mx-auto name-badge-below-centered mt-4 mb-4"
                    style={getBadgeSize(selectedTemplate)}
                  >
                    <div className="name-badge-preview">
                      <img
                        src={selectedTemplateDetail?.back_image}
                        className="pre-img"
                        alt="Back Preview image"
                      />
                      <div
                        className="name-badge-content text-white"
                        style={{ overflow: "hidden" }}
                        dangerouslySetInnerHTML={{
                          __html: getNewDescription(selectedTemplateDetail?.description_back_badge, item)
                        }}
                      ></div>
                    </div>
                  </div>
                )}
              </React.Fragment>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Table;
