import React, { useState } from "react";
import { useParams, useLocation, Outlet, Link } from "react-router-dom";
import Breadcrums from "../../common/breadcrumbs";

const EventsAdd = (props) => {
  const loc = useLocation();
  const params = useParams();

  const [id, setId] = useState(params?.id);



  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Events", url: "/admin/event-management/events/list/1" },
    { title: "Add", url: "" },
  ];
  
  return (
    <>
      <Breadcrums data={breadcrumbs} />
        <div className="row row-sm">
          <div className="col-lg-12 col-md-12 animation_fade">
            <div className="card custom-card">
              <div className="card-body">
                <div className="profile-tab tab-menu-heading mb-4">
										<nav className="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
											<Link
												className={"nav-link linkactive " + (loc.pathname === "/admin/event-management/events/add" ? " active" : "") + 
                        (id && loc.pathname === "/admin/event-management/events/add/" + id ? " active" : "")}
												aria-current="page"
												to={id ? "/admin/event-management/events/add/" + id : "/admin/event-management/events/add"}
											>
												Event
											</Link>
											<Link
												className={"nav-link linkactive " + (id ? "" : 'disabled-link') +(loc.pathname.includes("/admin/event-management/events/add/hotels/" + id) ? " active" : "")}
												to={id ? "/admin/event-management/events/add/hotels/" + id : ""}
											>
												Hotels
											</Link>
											<Link
												className={"nav-link linkactive " + (id ? "" : 'disabled-link') +(loc.pathname.includes("/admin/event-management/events/add/agenda/" + id) ? " active" : "")}
                        to={id ? "/admin/event-management/events/add/agenda/" + id : ""}
											>
												Agenda
											</Link>
										</nav>
									</div>
                <Outlet context={[id, setId]} />                
              </div>
            </div>
          </div>
        </div>
    </>
  );
};

export default EventsAdd;
