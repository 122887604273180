import axiosInstance from "../../../utils/axios";

const path = "web/leads"

export const EventOptions = async () => {
    return await axiosInstance().get(`${path}/event-list`);
}

export const LeadList = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}
export const LeadView = async (id) => {
    return await axiosInstance().get(`${path}/details?o_id=${id}`);
  }

export const LeadAdd = async (values) => {
    return await axiosInstance().post(`${path}/add`, values);
}
export const LeadEdit = async (values) => {
  return await axiosInstance().post(`${path}/edit`, values);
}
export const DraftList = async (values) => {
  return await axiosInstance().post(`${path}/list?is_draft=true`, values);
}
export const SaveDraft = async (values) => {
  return await axiosInstance().post(`${path}/save-draft`, values);
}

export const Count = async (id) => {
  return await axiosInstance().get(`${path}/leads-counts?event_id=${id}`);
}

export const LeadDelete = async (values) => {
  return await axiosInstance().post(`${path}/delete`, values)
}

export const RecentLeads = async (id,type) => {
  return await axiosInstance().get(`${path}/recent-leads?event_id=${id}&type=${type}`);
}

export const InvitationList = async (values) => {
  return await axiosInstance().post(`${path}/invitation-list`, values);
}

export const RegistrationList = async (values) => {
  return await axiosInstance().post(`${path}/registration-list`, values);
}
export const RegistrationView = async (id) => {
  return await axiosInstance().get(`${path}/registration-detail?o_id=${id}`);
}

