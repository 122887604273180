// import { createSlice } from '@reduxjs/toolkit';

// export const manageLmBackOfficeSlice = createSlice({
//     name: 'eventData',
//     initialState: null,
//     reducers: {
//         eventData: (state, action) => {
//             return state = action.payload;
//         },
//     }
// })

// export const { eventData } = manageLmBackOfficeSlice.actions;
// export default manageLmBackOfficeSlice.reducer;


import { createSlice } from '@reduxjs/toolkit';

export const manageLmBackOfficeSlice = createSlice({
    name: 'eventData',
    initialState: "",
    reducers: {
        eventData: (state, action) => {
            return state = action.payload;
        },
    }
})

export const { eventData } = manageLmBackOfficeSlice.actions;
export default manageLmBackOfficeSlice.reducer;