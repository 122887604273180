import React, { useRef, useState,useEffect } from 'react';
import { ErrorMessage, Formik } from 'formik';
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom';
import * as senderEmailService from '../../../services/senderEmail.services';
import Breadcrums from '../../../common/breadcrumbs';
import { SWAL_SETTINGS } from '../../../../../utils/Constants';
import { handleServerValidations } from '../../../../../utils/commonfunction';
import * as Yup from 'yup';
import * as eventServices from "../../../services/events.services";
import { TagPicker } from 'rsuite';

const SenderEmailAdd = () => {
    const navigate = useNavigate()
    const [submitted, setSubmitted] = useState(false);
    const [eventOptions, setEventOptions] = useState([]);
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Sender Emails", url: "/admin/cms/sender-emails/list/1" }, { title: "Add", url: "" },]


  useEffect(() => {
    eventServices.Options().then((response) => {
      if (response.success) {
        const data = response && response.data.map((item) => ({ label: item?.title, value: item?._id }));
        setEventOptions(data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

    const validationSchema = Yup.object().shape({
        email_name:Yup.string().required('Email name is required'),
        event_id: Yup.array().min(1, 'Event ID is required') 
    });

    

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <Formik
                enableReinitialize
                initialValues={{
                    email_name: "",
                    event_id: []
                }}
                validateOnBlur={false}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    console.log("values",values)
                    setSubmitted(true)
                    let formData = new FormData();
                    formData.append('email_name', values.email_name);
                    formData.append('event_id', JSON.stringify(values.event_id));
                    senderEmailService.Add(formData).then(response => {
                        setSubmitting(false);
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            setTimeout(() => {
                                resetForm({ values: '' });
                                navigate(`/admin/cms/sender-emails/list/1`)
                            }, 2000);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                            setSubmitted(false)
                        }
                    }).catch(error => {
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,

            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">Add Sender Email Name </h6>
                                    </div>
                                    <div className="row row-sm">
                                        <div className='col-md-12 text-center form-group'>
                                            <label htmlFor='email_name' className='text-left d-flex'>Email Name:<span className="requirestar">*</span> </label>
                                            <input name='email_name' id='email_name' type='text' onChange={handleChange} onBlur={handleBlur} value={values.email_name} className='form-control' />
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"email_name"} /></span>
                                        </div>
                                        <div className="col-12 text-center form-group">
                                            <label htmlFor="event_id" className="text-left d-flex"> Event IDs:{" "}</label>
                                            <div className="select-down-arrow text-left">
                                                <TagPicker
                                                    data={eventOptions}
                                                    defaultValue={values.event_id}
                                                    className="w-100 rsuite-input"
                                                    onChange={(e) =>
                                                        setFieldValue("event_id", e)
                                                    }
                                                    cleanable={false}
                                                    value={values.event_id}
                                                />
                                            </div>
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"event_id"} /></span>
                                        
                                        </div>
                                        <div className="">
                                            <button className="btn btn-main-primary signbtn mr-2" type="submit" disabled={submitted ? true : null}>
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Submit
                                            </button>
                                            <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>
    )
}

export default SenderEmailAdd;