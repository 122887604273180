import React, { useEffect, useState } from 'react';
import { useSearchParams } from "react-router-dom";
import {  globalLoader, handleServerValidations } from '../../../utils/commonfunction'
import {   InvitationDetails } from '../services/common.services';
import likeImage from "../../../assets/user/images/thump-icon.png";
import disLikeImage from "../../../assets/user/images/cross.png";
import Swal from 'sweetalert2';
import {
    LM_USER_INVITATION_STATUS,
    SWAL_SETTINGS,
  } from "../../../utils/Constants";
import { useTranslation } from 'react-i18next';

const LMUserInvitation = () => {
    const [searchParams] = useSearchParams();
    const [invitationData, setInvitationData] = useState(null);
    const { t } = useTranslation();


    useEffect(()=>{
        globalLoader(true);
        InvitationDetails(searchParams.get('_id')).then( async (response) => {
            if (response?.success) {
                if(response?.data){
                    setInvitationData(response?.data);
                    if(response?.data?.status === LM_USER_INVITATION_STATUS.PENDING && response?.data?.user_already_exist){
                        globalLoader(false);
                    }else{
                        globalLoader(false);
                    }
                }
            } else {
                Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                });
                globalLoader(false);
            }
        })
        .catch(error => {
            console.log("error ====> ", error);
            globalLoader(false);
        });
    },[]);
  
    return (
        <>
            {invitationData?.status === LM_USER_INVITATION_STATUS.ACCEPTED && invitationData?.user_already_exist ?
                <div className="wraper-inner cpt bg-grey">
                <section className="inner-space space-b">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 m-auto thanku-card-main">
                                <div className="dash-box-shadow pb-5">
                                    <div className="thanku-card text-center mb-4 ">
                                                <figure className="mb-3">
                                                    <img src={likeImage} alt="like" />
                                                </figure>
                                                <h3>{t('web_lbl_invitation_accepted')}</h3>
                                    </div>
                             </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            : null }
               {invitationData?.status === LM_USER_INVITATION_STATUS.REJECTED ?
                <div className="wraper-inner cpt bg-grey">
                <section className="inner-space space-b">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 m-auto thanku-card-main">
                                <div className="dash-box-shadow pb-5">
                                    <div className="thanku-card text-center mb-4 ">
                                                <figure className="mb-3">
                                                    <img src={disLikeImage} alt="dislike" />
                                                </figure>
                                                <h3>{t('web_lbl_invitation_rejected')}</h3>
                                    </div>
                             </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            : null }
        </>
    )
}

export default LMUserInvitation