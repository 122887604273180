import axiosInstance from "../../../utils/axios";

const path = "admin/push-notification-templates"

export const List = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const Add = async (values) => {
    return await axiosInstance().post(`${path}/add`, values);
}

export const Edit = async (values) => {
    return await axiosInstance().post(`${path}/edit`, values);
}

export const Details  = async (id) => {
    return await axiosInstance().get(`${path}/details?o_id=${id}`);
}
export const Delete = async (values) => {
    return await axiosInstance().post(`${path}/delete`, values)
}

export const TemplateOptions = async (values) => {
    return await axiosInstance().post(`${path}/options`, values)
}

export const Status = async (values) => {
    return await axiosInstance().post(`${path}/change-status`, values)
}