import axiosInstance from "../../../utils/axios";

const path = "admin/dashboard"


export const Counts = async () => {
    return await axiosInstance().get(`${path}/counts`);
}

export const recentEventRegistration = async () => {
    return await axiosInstance().get(`${path}/event-registration/list`);
}

export const recentUserRegistration = async () => {
    return await axiosInstance().get(`${path}/user-registration/list`);
}
export const UserOverview = async (time_limit,custom_range) => {
    return await axiosInstance().get(`${path}/users-overview?time_limit=${time_limit}&custom_range=${custom_range}`);
}
export const RegistrationOverview = async (time_limit,custom_range) => {
    return await axiosInstance().get(`${path}/registrations-overview?time_limit=${time_limit}&custom_range=${custom_range}`);
}

