import axiosInstance from "../../../utils/axios";

const path = "admin/rooms"

export const RoomsList = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const RoomsAdd = async (values) => {
    return await axiosInstance().post(`${path}/add`, values);
}

export const  RoomsEdit = async (values) => {
    return await axiosInstance().post(`${path}/edit`, values);
}

export const  RoomsDelete = async (values) => {
    return await axiosInstance().post(`${path}/delete`, values)
}

export const  RoomsDetails  = async (id) => {
    return await axiosInstance().get(`${path}/details?o_id=${id}`);
}

export const RoomsStatus = async (values) => {
    return await axiosInstance().post(`${path}/change-status`, values)
}

export const AddBooking = async (values) => {
    return await axiosInstance().post(`${path}/bookings/add`, values)
}
export const UpdateBooking = async (values) => {
    return await axiosInstance().post(`${path}/bookings/update`, values)
}
export const GetUserOptions = async (values) => {
    return await axiosInstance().get(`${path}/user-options`, values)
}
export const GetRoomQuestions = async (id) => {
    return await axiosInstance().get(`${path}/questions?o_id=${id}`);
}
export const BookingList = async (values) => {
    return await axiosInstance().post(`${path}/bookings/list`, values);
}
export const  BookingsDetails  = async (id) => {
    return await axiosInstance().get(`${path}/bookings/details?o_id=${id}`);
}
export const  BookingsCancel  = async (values) => {
    return await axiosInstance().post(`${path}/bookings/cancel`,values);
}

export const ReadUsersExcel = async (values) => {
    return await axiosInstance().post(`${path}/read-excel`, values)
}