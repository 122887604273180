import React, { useState, useEffect } from "react";
import * as galaryService from "../../services/galary.services";
import Breadcrums from "../../common/breadcrumbs";
import $ from "jquery";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../common/loader";
import UseImageLoad from "../../common/imageloader";
import { Tooltip, Whisper } from 'rsuite';
import Swal from "sweetalert2";
import { handleServerValidations, hasPermission, TrimText } from "../../../../utils/commonfunction";
import { SWAL_SETTINGS } from "../../../../utils/Constants";

const Galary = () => {
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Media", url: "" },
  ];
  const [globalsearch, setGlobalSearch] = useState("");
  const [resetdate, setResetDate] = useState(false);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(24);
  const navigate = useNavigate();
  const [choosefiletype, setChooseFileType] = useState("");

  const [fileType] = useState([
    { label: "Image", value: "image" },
    { label: "Video", value: "video" },
    { label: "Audio", value: "audio" },
    { label: "Docs", value: "docs" },
    { label: "Pdf", value: "pdf" },
    { label: "Excel", value: "excel" },
  ]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page]);

  // Function to handle scroll events
  const handleScroll = () => {
    const scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    const scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    const clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    const scrolledToBottom =
      Math.ceil(scrollTop + clientHeight) >= scrollHeight;
    if (scrolledToBottom && !isLoading) {
      fetchData();
    }
  };

  const fetchData = async () => {
    try {
      setIsLoading(true);
      galaryService
        .List({
          limit: page,
          filter: choosefiletype,
          global_search: globalsearch,
        })
        .then((data) => {
          const imgarr =
            data && data.data && data.data.list ? data.data.list : [];
          setData(imgarr);
          // setData((prevData) => [...prevData, ...imgarr])
          setPage((prevPage) => prevPage + 10);
          setIsLoading(false);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [choosefiletype, resetdate, globalsearch]);

 

  const resetFilter = (e) => {
    e.preventDefault();
    setChooseFileType("");
    setGlobalSearch("");
    setResetDate(!resetdate);
    $("#statusfilter")[0].selectedIndex = 0;
  };

  const handleFilterChange = (e) => {
    setChooseFileType(e.target.value);
  };

  function Deletefunction(selectedItem) {
    if (selectedItem) {
      Swal.fire({
        customClass: "swal-wide",
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#403fad",
        cancelButtonColor: "#f1388b",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          galaryService
            .Delete({ file_path: selectedItem?.filePath })
            .then((response) => {
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: "Image deleted successfully",
                  ...SWAL_SETTINGS,
                });
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
              }
              setData((prevData) =>
                prevData.filter(
                  (image) => image.filePath !== selectedItem.filePath
                )
              );
              fetchData();
              console.log("API Response:", response);
            })
            .catch((error) => {
              console.error("Error calling Delete API:", error);
            });
        }
      });
    }
  }

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="animation_fade">
        <div className="card custom-card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="main-content-label">Media</h6>
              <div className="d-flex align-items-center">
                <div className="form-group mb-0 me-3">
                  <div className="form-group mb-0 rangepicker_container filter_design">
                    <i className="fa fa-search calender_icon"></i>
                    <input
                      type="search"
                      className="form-control"
                      value={globalsearch}
                      placeholder="Search"
                      onChange={(e) => {
                        setGlobalSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {/* <div className="me-3">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("createdAt", e) }} resetdate={resetdate} />
                                </div> */}
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select
                        className="form-control cp"
                        onChange={handleFilterChange}
                        id="statusfilter"
                        style={{ width: "93px" }}
                      >
                        <option value="">Type</option>
                        {fileType &&
                          fileType.length > 0 &&
                          fileType.map((option, i) => {
                            return (
                              <option key={i} value={option.value}>
                                {option.label}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  type="reset"
                  value="Reset"
                  onClick={(e) => {
                    resetFilter(e);
                  }}
                  className="btn btn-warning float-right mr-2"
                >
                  Reset Filter
                </button>
                <button
                  className="btn ripple btn-main-primary signbtn"
                  onClick={() => navigate(`/admin/media/add`)}
                >
                  Add New
                </button>
                {/* <button
                  className="btn ripple btn-secondary mx-2"
                  onClick={() => deleteMedia()}
                >
                  Delete
                </button> */}
              </div>
            </div>
            <div className="">
              {data && data.length > 0 ? (
                <ul className="list-unstyled row mb-0">
                  {data &&
                    data.length > 0 &&
                    data.map((item, index) => {
                      var fileName = item.fileName;
                      fileName = fileName.substring(0, fileName.indexOf("."));
                      return (
                        <li
                          key={index}
                          style={{ flexDirection: "column" }}
                          className="col-xs-6 col-sm-4 col-md-4 col-xl-2 mb-4 gallery-image"
                        >
                          {/* <span className="fileName-gallery" onClick={() => Deletefunction(item)} style={{cursor:'pointer'}}>
                            { TrimText(fileName, 70) }
                          </span> */}
                          <span className="fileName-gallery">
                            { TrimText(fileName, 70) }
                          </span>
                          {hasPermission('/admin/media/delete') ?
                          <div className="delete-btn">
                            <Whisper placement='top' controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Delete an Image</Tooltip>}>
                              {/* <a style={{border:'0px'}} onClick={() => Deletefunction(item)}>
                                <i className="fa fa-trash mx-2" style={{color:'#f1388b', fontSize:'25px'}}></i>
                              </a> */}
                              <button
                                className="btn btn-warning pt-1 mt-3 pb-0"
                                style={{background:'#f1388b', border:"0px"}}
                                onClick={() => Deletefunction(item)}
                              >
                                <i className="fa fa-trash" style={{color:'', fontSize:'16px', margin:'0px', padding:'0px'}}></i>
                              </button>
                            </Whisper>
                          </div>
                          :null}
                          <Link
                            className=" w-100 d-flex justify-content-center align-items-center media-box"
                            to="/admin/media/details"
                            state={item}
                          >
                            <UseImageLoad item={item} index={index} />
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <p style={{ textAlign: "center", fontSize: "18px" }}>
                  No records
                </p>
              )}
              {isLoading ? <Loader /> : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Galary;
