import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { DT, globalLoader, isApp, TrimText } from "../../../utils/commonfunction";
import * as otherService from "../services/webother.service";
import { formateDate, formatFileSize } from "../../../utils/commonfunction";
import CustomPagination from "../../admin/common/custompagination";

import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-transitions.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-zoom.css';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lg-autoplay.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import 'lightgallery/css/lg-rotate.css';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import 'lightgallery/css/lg-fullscreen.css';
import { useTranslation } from 'react-i18next';
import FrontLoader from "../../globalComponents/FrontLoader";




const SharedFiles = () => {

  const params = useParams();
  const [list, setList] = useState([]);
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(9);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const [loader,setLoader] = useState(true);

  useEffect(() => {
    // globalLoader(true);
    setLoader(true);
    const formData = new FormData()
    formData.append("page", params.pgno)
    formData.append("per_page", itemperpage)
    otherService.sharedFiles(formData).then(res => {
      if (res && res.success) {
        setList(res?.data?.list);
        setDataLength(res?.data?.total_records);
        setPage(res && res?.data && res?.data?.page ? res?.data?.page : 1);
        // globalLoader(false);
        setLoader(false);
      } else {
        // globalLoader(false);
        setLoader(false);
      }
    }).catch(err => console.log("err", err))
  }, [page,itemperpage,params.pgno])

  const getType = (type) => {
    if (type === "jpeg" || type === "jpg" || type === "png") {
      return t("image");
    } else if (type === "pdf") {
      return t("pdf");
    } else if (type === "Word document") {
      return t('word_document');
    } else if (type === "csv") {
      return t('csv_file')
    }
    else if (type === "svg") {
      return t('svg');
    }
    else{
      return t('file');
    }
  }
  const handleDownloadClick = async (e, item) => {
    e.preventDefault();
    if(isApp()){
      const appData = {action:'download-file', data:item};
      window.ReactNativeWebView?.postMessage(JSON.stringify(appData));
      Swal.fire({
        title: t('download_success'),
        text: DT(t('success_file_download'), [item?.file_name]),
        icon: 'success',
      });
    }else{
      globalLoader(true);
      try {
        const response = await fetch(item.file_path);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
  
        const blob = await response.blob();
        const blobURL = URL.createObjectURL(blob);
  
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobURL;
        a.download = item?.file_name;
  
        document.body.appendChild(a);
        a.click();
  
        document.body.removeChild(a);
        URL.revokeObjectURL(blobURL);
        globalLoader(false);
      } catch (error) {
        globalLoader(false);
        console.error('Error downloading the file:', error);
        Swal.fire({
          title: t('download_error'),
          text: DT(t('error_file_download'), [item?.file_name]),
          icon: 'error',
        });
      }
    }
  };
  return (
    <div className="dash-main-R">
      <div className="row">
        <div className="col-md-9 m-auto">
        <h3 className="dash-title">{t('web_heading_shared_files')}</h3>

          <div className="dash-box-shadow" style={{ minHeight:"100px" }}>
          <div className="row">
            {loader ? 
             <FrontLoader/>
          :
          <>
              {list?.length ? (
               list.map((item, index) => (
                  <div className="col-md-4 my-2" >
                    <div className="grid-box">
                      <h3>{TrimText(item?.file_name,20)}</h3>
                      <p>{formatFileSize(item?.file_size)}</p>
                      <p>{item.file_type === "pdf" ? (
                          <i className="fa fa-file-pdf-o me-2" aria-hidden="true"></i>
                        ) : item?.file_type === "csv" ? (
                          <i className="fa fa-file-excel-o me-2" aria-hidden="true"></i>
                        ) : item?.file_type === "doc" ? (
                          <i className="fa fa-file-word-o me-2" aria-hidden="true"></i>
                        ) : item?.file_type === "excel" ? (
                          <i className="fa fa-file-excel-o me-2" aria-hidden="true"></i>
                        ) : item?.file_type === "jpeg" || item?.file_type === "jpg" || item?.file_type === "png" ? (
                          <i className="fa fa-file-image-o me-2" aria-hidden="true"></i>
                        ) : (
                         <i className="fa fa-file-o me-2" aria-hidden="true"></i>
                        )}
                        {getType(item?.file_type)}
                        </p>
                      <div className="date-info">
                        <i className="ri-calendar-event-line"></i> 
                        {formateDate(item?.createdAt)}
                      </div>
                      <div className="action-tag mt-4">
                      <a
                            className="btn-s green-btn"
                            href="/"
                            onClick={(e) => {e.preventDefault(); handleDownloadClick(e, item)}}
                          >
                            <i className="ri-download-2-line me-2"></i>{t('web_lbl_button_download')}
                          </a>
                          {getType(item?.file_type) === "Image" ?
                            <LightGallery speed={500} plugins={[lgThumbnail, lgZoom, lgFullscreen, lgRotate]}>
                              <a className="btn-s green-btn" href={item?.file_path}>{t('web_lbl_button_view')}
                              </a>
                            </LightGallery>
                            :
                            null
                          }
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-md-12 my-2">
                  <div className="text-center">
                  {t('web_lbl_no_records')}
                  </div>
                </div>
              )}
          </>
            }
              </div>
                {datalength && datalength > 0 ?
                  <div className="mt-4 text-right pagination-main d-flex justify-content-end  pe-4">
                    <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "Sharemedia", path: "/settings/shared-files" }]} /> 
                  </div>
              : ""}
          </div>
        </div>
      </div>
    </div>
    )
};

export default SharedFiles;