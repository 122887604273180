import React, { useEffect, useRef, useState } from "react";
import {  useParams, useSearchParams } from "react-router-dom";
import * as eventsService from "../../services/events.services";
import * as questionServices from "../../services/questions";
import Loader from "../../common/loader";
import {
  SpecialCharacter,
  formateDate,
  globalLoader,
  handleServerValidations,
  hasPermission,
} from "../../../../utils/commonfunction";
import CustomPagination from "../../common/custompagination";
import CustomRangepicker from "../../common/rangepicker";
import $ from "jquery";
import {
  DEFAULT_SELECT_FIELD,
  EMAIL_REGEX,
  filterOfInvitations,
  INVITATION_STATUS,
  INVITATION_TYPES,
  showFilterOfInvitations,
  STAFF_ROLE,
  SWAL_SETTINGS,
  EMAIL_TEMPLATE_CATEGORY
} from "../../../../utils/Constants";
import { Button, DatePicker, Modal, Steps } from "rsuite";
import Swal from "sweetalert2";
import { FieldArray, Form, Formik, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import * as templateServices from "../../services/email.template";
import * as senderEmailsServices from "../../services/senderEmail.services";
import {ContentBlocksOptions  } from '../../services/contentBlocks.services';

const ViewInvitations = (props) => {
  const params = useParams();
  const [sorting] = useState({});
  const [search, setSearch] = useState({});
  const [datalength, setDataLength] = useState("");
  const [globalSearch, setGlobalSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [itemperpage] = useState(10);
  const [page, setPage] = useState(1);
  const [invitationsList, setInvitationsList] = useState([]);
  const [resetdate, setResetDate] = useState(false);
  const [open, setOpen] = useState(false);
  const [secondModal, setSecondModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [questionData, setQuestionData] = useState([]);
  const [questionAllData, setQuestionAllData] = useState([]);
  const [showdefault, setShowDefault] = useState([]);
  const [selectedSubmitData, setSelectedSubmitData] = useState([]);
  const [invitationName, setInvitationName] = useState("");
  const [role, setRole] = useState(STAFF_ROLE.USER);
  const [saveDistributionList, setSaveDistributionList] = useState(false);
  const [distributionListTitle, setDistributionListTitle] = useState("");
  const [fileExcel, setExcelFile] = useState();
  const [emailType, setEmailType] = useState("");
  const [scheduledTime, setScheduledTime] = useState();
  const [statsupdate, setStatusUpdate] = useState(false);
  /********** MULTI SELECT >>>>>>********* */
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedAllData, setSelectedAllData] = useState(false);
  const selectAllCheckbox = useRef();
  /********** MULTI SELECT <<<<<<********* */
  const [query] = useSearchParams();
  const [roleOptions] = useState(Object.keys(STAFF_ROLE).filter(key => key === "USER" || key === "BOOTH_ATTENDANTS"));
  const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
  const [emailTemplate, setEmailTemplate] = useState([]);
  const [senderEmailOptions, setSenderEmailOptions] = useState([]);
  const [senderEmailId,setSenderEmailId] = useState("");
  const [contentClockOptions, setContentClockOptions] = useState([]);
  const [contentBlockId,setContentBlockId] = useState("");


  useEffect(() => {
    senderEmailsServices.Options().then((response) => {
      if (response.success) {
        setSenderEmailOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  useEffect(() => {
    ContentBlocksOptions().then((response) => {
      if (response.success) {
        setContentClockOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])
  useEffect(() => {
    // if (search) {
    setLoader(true);
    const formData = new FormData();
    // formData.append("page", params.pgno);
    formData.append("page", query.get('page'));
    formData.append("per_page", itemperpage);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("search", JSON.stringify(search));
    formData.append("global_search", globalSearch);
    formData.append("event_id", params.id);
    eventsService
      .InvitationsList(formData)
      .then((data) => {
        console.log("data", data);
        setDataLength(data.data.total_records);
        setInvitationsList(
          data && data.data && data.data.list ? data.data.list : []
        );
        /********** MULTI SELECT >>>>>>********* */
        setSelectedAllData(
          data && data.data && data.data.data_ids ? data.data.data_ids : []
        );
        /********** MULTI SELECT <<<<<<********* */
        setPage(data && data.data && data.data.page ? data.data.page : 1);
        setLoader(false);
      })
      .catch((error) => {
        console.log("error ====> ", error);
      });
    // }
  }, [sorting, search, globalSearch, page, statsupdate, open, params.id, itemperpage, params.pgno]);

  useEffect(() => {
    eventsService
      .Details(params.id)
      .then((response) => {
        if (response && response.success) {
          setShowDefault(response.data ? response.data : []);
        }
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, [params.id]);

  useEffect(() => {
    questionServices
      .Options({ question_set_id: showdefault?.question_group_primary })
      .then((response) => {
        if (response.success) {
          setQuestionData(response?.data);
          // setQuestionAllData(response?.data);
          // addQuestionIds();
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [showdefault]);

  function modifyData(data) {
    if (selectedSubmitData.length === 0) {
      let arr = [];
      data.forEach((item) => {
        arr.push({
          question: "",
          field: item,
        });
      });
      return arr;
    } else {
      let arr = [];
      selectedSubmitData.forEach((item) => {
        arr.push({
          question: item?.question ? item.question : "",
          field: item.field,
        });
      });
      return arr;
    }
  }


  const [step, setStep] = React.useState(0);

  const readExcelFile = (e) => {
    // console.log("e.target.files[0]", e);
    setExcelFile(e.target.files[0]);
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      eventsService
        .ReadExcel(formData)
        .then((response) => {
          console.log("response", response);
          if (response.success) {
            let isValid = false;
            for (let i = 0; i < response.data.list.length; i++) {
              if (response.data.list[i].email && response.data.list[i].firstname && response.data.list[i].lastname) {
                // console.log("item.email",item.email,item.firstname, item.lastname, item.title);
                if (EMAIL_REGEX.test(response.data.list[i].email)) {
                  if (i === (response.data.list.length - 1)) {
                    isValid = true;
                    // return;
                  }
                } else {
                  isValid = false;
                  break;
                }
              } else {
                isValid = false;
                break;
              }
            }
            // response.data.list.forEach((item, index)=>{
            // });
            if (isValid) {
              setSelectedUser(response?.data);
              setStep(step + 1);
            } else {
              Swal.fire({
                icon: "error",
                text: "Invalid file",
                ...SWAL_SETTINGS,
              });
              document.getElementById('file_reader').value = "";
            }
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
            setExcelFile();
          }
        })
        .catch((error) => {
          console.log("error", error);
          Swal.fire({
            icon: "error",
            text: handleServerValidations(error),
            ...SWAL_SETTINGS,
          });
        });
    }
  };

  const onSendClick = (values) => {
    // console.log("emailType",emailType);
    if (emailType) {
      if (emailType === "schedule" && scheduledTime) {
        globalLoader(true);
        let formData = new FormData();
        formData.append("event_id", params.id);
        formData.append("type", emailType);
        formData.append("invitation_title", invitationName);
        formData.append("schedule_date", scheduledTime);
        formData.append("file", fileExcel);
        formData.append("users", JSON.stringify(selectedUser?.list));
        formData.append("question_sequence", JSON.stringify(selectedSubmitData));
        formData.append("save_to_distribution_list", saveDistributionList);
        formData.append("distribution_list_title", distributionListTitle);
        formData.append("role", role);
        formData.append("email_template", emailTemplate);
        if(senderEmailId){
          formData.append("sender_email_name", senderEmailId);
        }
        if(contentBlockId){
          formData.append("content_block_id", contentBlockId);
        }
        eventsService
          .InviteUsers(formData)
          .then((response) => {
            globalLoader(false);
            if (response?.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setStep(step + 1);
              setStatusUpdate(!statsupdate);
            } else {
              Swal.fire({
                icon: "error",
                text: response.message,
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            globalLoader(false);
            Swal.fire({
              icon: "error",
              text: error,
              ...SWAL_SETTINGS,
            });
          });
      } else if (emailType === "schedule" && scheduledTime === null) {
        Swal.fire({
          icon: "error",
          text: "Please select date",
          ...SWAL_SETTINGS,
        });
      } else if (emailType === "send_later" || emailType === "send_now") {
        globalLoader(true);
        let formData = new FormData();
        formData.append("event_id", params.id);
        formData.append("type", emailType);
        formData.append("invitation_title", invitationName);
        formData.append("file", fileExcel);
        formData.append("users", JSON.stringify(selectedUser?.list));
        formData.append("question_sequence", JSON.stringify(selectedSubmitData));
        formData.append("save_to_distribution_list", saveDistributionList);
        formData.append("distribution_list_title", distributionListTitle);
        formData.append("role", role);
        formData.append("email_template", emailTemplate);
        if(senderEmailId){
          formData.append("sender_email_name", senderEmailId);
        }
        if(contentBlockId){
          formData.append("content_block_id", contentBlockId);
        }
        eventsService
          .InviteUsers(formData)
          .then((response) => {
            globalLoader(false);
            if (response?.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setStep(step + 1);
            } else {
              Swal.fire({
                icon: "error",
                text: response.message,
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            globalLoader(false);
            Swal.fire({
              icon: "error",
              text: error,
              ...SWAL_SETTINGS,
            });
          });
      }
    } else {
      Swal.fire({
        icon: "error",
        text: "Please select send type.",
        ...SWAL_SETTINGS,
      });
    }
  };

  const isDisabledDate = (date) => {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return date < currentDate;
  };

  const getQuestionData = (id) => {
    var data = questionData.filter((item) => (item?._id === id ? item : ""));
    return data[0].title;
  };
  const isAlreadySelected = (id) => {
    return questionAllData.indexOf(id) !== -1;
  };

  const modalData = (steps) => {
    if (steps === 0) {
      return (
        <div className="my-3 upload-button ">
          <form
            id="reader_id"
            style={{
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="text-center ">
              <label htmlFor="file_reader">
                <input
                  style={{ display: "none" }}
                  type={"file"}
                  id="file_reader"
                  onChange={readExcelFile}
                  accept=".xls,.xlsx,.csv"
                />
                <i
                  className="fa fa-upload cp"
                  aria-hidden="true"
                  style={{ fontSize: "7em", color: "#3598ff" }}
                ></i>
              </label>
              <p className="text-muted mb-0">Please upload excel file.</p>
              <a className="download-file my-0" href={`${process.env.REACT_APP_API_URL.replace('/webservice/api/v1', "")}/uploads/default/event_invitation_sample_file.xlsx`} download>Download sample file.</a>
            </div>
          </form>
        </div>
      );
    } else if (steps === 1) {
      return (
        <Formik
          initialValues={{
            excelData: modifyData(selectedUser?.headings && selectedUser?.headings ? selectedUser?.headings : []),
            invitation_title: invitationName,
            save_to_distribution_list: false,
            distribution_list_title: distributionListTitle,
            role:role,
            email_template:emailTemplate,
            sender_email_name:senderEmailId,
            content_block_id:contentBlockId
          }}
          onSubmit={(values) => {
            setSelectedSubmitData(values?.excelData);
            setStep(step + 1);
          }}
          validationSchema={inviteValidationUsers}
          validate={(values) => {
            const errors = {};
            if (saveDistributionList!==false) {
              if (!values.distribution_list_title) {
                errors.distribution_list_title = "Distribution list title is required"
              }
            }
            let selectedData = values?.excelData.filter((item) => {
              return item?.question !== "";
            });
            let selectedDataIds = selectedData.map((item, index) => {
              return item?.question;
            });
            setQuestionAllData(selectedDataIds);
            // return {};
            return errors;
          }}
        >
          {({ handleChange, handleSubmit, values, handleBlur, setFieldValue }) => (
            <div className="mt-4">
              {console.log("excelData", values.excelData)}
              <div className="row">
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">
                        Invitation Title:<span className="requirestar">*</span>
                      </label>
                      <input
                        name="invitation_title"
                        id="invitation_title"
                        type="text"
                        className="form-control"
                        value={values.invitation_title}
                        onChange={(e) => {
                          setFieldValue('invitation_title', e?.target?.value);
                          setInvitationName(e?.target?.value);
                        }}
                        onBlur={handleBlur}
                      />
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"invitation_title"} /></span>
                    </div>
                    <div className="col-lg-6 form-group mt-4">
                      <label className="text-left d-flex cp">
                       Save the uploaded file as a distribution list:
                      {/* </label> */}
                      <input
                        className="ms-2 cp"
                        name="save_to_distribution_list"
                        id="save_to_distribution_list"
                        type="checkbox"
                        checked={saveDistributionList}
                        value={values.save_to_distribution_list}
                        onChange={(e) => {
                          setFieldValue('save_to_distribution_list', e?.target?.checked);
                          setSaveDistributionList(e?.target?.checked);
                        }}
                        onBlur={handleBlur}
                      />
                      </label>
                    </div>
                    {/* <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='slug' className='text-left d-flex'>Role:<span className="requirestar">*</span></label>
                      <div className="select-down-arrow ">
                        <Field as='select' id="role" name={"role"} className="form-control select2"  
                        onChange={(e) => {
                          setFieldValue('role', e?.target?.value);
                          setRole(e?.target?.value);
                        }}>
                          <option value="" label="Select role">Select{" "}</option>
                          {roleOptions && roleOptions.map((option, i) => {
                            return (
                              <option className="text-capitalize" key={i} value={STAFF_ROLE[option]}>
                                {SpecialCharacter(option)}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"role"} /></span>
                    </div>
                    {values?.role == STAFF_ROLE.USER? */}  {/*  : null} */}
                    <div className="col-lg-6 text-center form-group">
                        <label className="text-left d-flex">Select Email Template:</label>
                        <div className="form-group">
                        <div className="select-down-arrow">
                          <select
                            className="form-control select2"
                            value={values.email_template}
                            onChange={(e) => {setFieldValue('email_template', e.target.value);setEmailTemplate(e?.target?.value);
                          }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {emailTemplateOptions &&
                              emailTemplateOptions.length > 0 &&
                              emailTemplateOptions.map((option, i) => {
                                return (
                                  <option key={i} value={option._id}>
                                    {option.title}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"email_template"} /></span>
                      </div>
                      </div>

                      <div className="col-lg-6 text-center form-group">
                        <label className="text-left d-flex">Select Sender Email Name:</label>
                        <div className="form-group">
                        <div className="select-down-arrow">
                          <select
                            className="form-control select2"
                            value={values.sender_email_name} 
                            onChange={(e) => {setFieldValue('sender_email_name', e.target.value);setSenderEmailId(e?.target?.value);
                          }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {senderEmailOptions &&
                              senderEmailOptions.length > 0 &&
                              senderEmailOptions.map((option, i) => {
                                return (
                                  <option key={i} value={option._id}>
                                    {option.email_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"sender_email_name"} /></span>
                      </div>
                      </div>



                      <div className="col-lg-6 text-center form-group">
                        <label className="text-left d-flex">Select Content Block:</label>
                        <div className="form-group">
                        <div className="select-down-arrow">
                          <select
                            className="form-control select2"
                            value={values.content_block_id} 
                            onChange={(e) => {setFieldValue('content_block_id', e.target.value);setContentBlockId(e?.target?.value);
                          }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {contentClockOptions &&
                              contentClockOptions.length > 0 &&
                              contentClockOptions.map((option, i) => {
                                return (
                                  <option key={i} value={option._id}>
                                    {option.title}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"content_block_id"} /></span>
                      </div>
                      </div>
                    
                    {saveDistributionList !== false  ? <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">
                        Distribution List Title:<span className="requirestar">*</span>
                      </label>
                      <input
                        name="distribution_list_title"
                        id="distribution_list_title"
                        type="text"
                        className="form-control"
                        value={values.distribution_list_title}
                        onChange={(e) => {
                          setFieldValue('distribution_list_title', e?.target?.value);
                          setDistributionListTitle(e?.target?.value);
                        }}
                        onBlur={handleBlur}
                      />
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"distribution_list_title"} /></span>
                    </div> : <></>}
                  </div>
                  <FieldArray
                    name="excelData"
                    render={(arrayHelper) => (
                      <div className="col-12 mb-4">
                        <div className="row">
                          {values.excelData.map((item, index) => (
                            <div className="col-12 " key={index}>
                              {/* {console.log("item",item)} */}
                              <div className="w-100 p-2 bg-group">
                                <div className="row">
                                  <div className="col-6">
                                    <input
                                      onChange={handleChange}
                                      type="text"
                                      value={item.field}
                                      name={`excelData[${index}].field`}
                                      className="form-check select-check"
                                      disabled={true}
                                    />
                                  </div>
                                  {DEFAULT_SELECT_FIELD.includes(item.field) ? (
                                    <></>
                                  ) : (
                                    <div className="col-md-6 text-center form-group mb-0 ">
                                      <div className="select-down-arrow">
                                        <select
                                          className="form-control "
                                          name={`excelData[${index}].question`}
                                          value={item.question}
                                          onChange={handleChange}
                                        >
                                          <option value="" label="Select">
                                            Select{" "}
                                          </option>
                                          {questionData &&
                                            questionData.length > 0 &&
                                            questionData.map((option, i) => {
                                              return (
                                                <option
                                                  disabled={isAlreadySelected(
                                                    option?._id
                                                  )}
                                                  key={i}
                                                  value={option._id}
                                                >
                                                  {option.title}
                                                </option>
                                              );
                                            })}
                                        </select>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  />
                  <Modal.Footer className="mt-4">
                    <Button
                      onClick={() => {
                        setOpen(false);
                        setQuestionAllData([]);
                        setInvitationName("");
                        setRole("");
                        setDistributionListTitle("");
                        setSaveDistributionList(!saveDistributionList);
                        setSelectedSubmitData([]);
                        setStep(0);
                      }}
                      appearance="subtle"
                    >
                      Cancel
                    </Button>
                    <Button
                      appearance="primary"
                      type="submit"
                    >
                      Next
                    </Button>
                  </Modal.Footer>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      );
    }
    else if (steps === 2) {
      return (
        <div className="mt-4">
          <div className="table-responsive">
            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
              <thead>
                <tr>
                  {selectedSubmitData &&
                    selectedSubmitData?.map((item, index) => {
                      return (
                        <th key={index}>
                          {item.question
                            ? getQuestionData(item.question)
                            : item.field}
                        </th>
                      );
                    })}
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <tr>
                    <td colSpan={selectedUser?.headings?.length}>
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {selectedUser.list.length ? (
                      selectedUser.list.map((row, i) => {
                        return (
                          <tr key={i}>
                            {selectedUser?.headings?.map((item, index) => {
                              return (
                                <td key={index}>{row[item.toString().toLowerCase()]}  {console.log(")))))", item)}</td>
                              );
                            })}
                          </tr>
                          // <tr key={i}>
                          //   {Object.keys(row).map((item, index) => {
                          //     return (
                          //       <td key={index}>{row[selectedSubmitData[index].field.toLowerCase()]} {selectedSubmitData[index].field.toLowerCase()} {console.log(")))))",selectedSubmitData,selectedUser)}</td>
                          //     );
                          //   })}
                          // </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={selectedUser?.headings?.length}
                          className="text-center"
                        >
                          No records
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <Modal.Footer className="mt-4">
            {emailType === "schedule" ? (
              <div className="d-flex justify-content-end">
                <div>
                  <DatePicker
                    format="yyyy-MM-dd HH:mm:ss"
                    id="date_picker"
                    placeholder={"Please select date"}
                    value={scheduledTime}
                    onChange={(e) => setScheduledTime(e)}
                    disabledDate={isDisabledDate}
                    oneTap={true}
                    editable={false}
                  />
                  {scheduledTime ? (
                    ""
                  ) : (
                    <span className="text-danger d-flex text-left">
                      Please select date
                    </span>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div className="chek-box-card mt-3 d-flex justify-content-end">
              <div className="form-check black-check ms-3">
                <input
                  name="send_type"
                  className="form-check-input"
                  type="radio"
                  value={"send_later"}
                  id="send_later"
                  onChange={(event) => {
                    console.log("event", event);
                    setEmailType(event.target.value);
                  }}
                />
                <label className="form-check-label cp" htmlFor="send_later">
                  Send Later
                </label>
              </div>
              <div className="form-check black-check ms-3">
                <input
                  name="send_type"
                  className="form-check-input"
                  type="radio"
                  value={"schedule"}
                  id="schedule"
                  onChange={(event) => {
                    console.log("event", event);
                    setEmailType(event.target.value);
                  }}
                />
                <label className="form-check-label cp" htmlFor="schedule">
                  Schedule
                </label>
              </div>
              <div className="form-check black-check  ms-3">
                <input
                  name="send_type"
                  className="form-check-input"
                  type="radio"
                  value={"send_now"}
                  id="send_now"
                  onChange={(event) => {
                    console.log("event", event);
                    setEmailType(event.target.value);
                  }}
                />
                <label className="form-check-label cp" htmlFor="send_now">
                  Send Now
                </label>
              </div>
            </div>
            <Button
              // disabled={emailType == "schedule" ? scheduledTime : "" ? true : false}
              appearance="primary"
              onClick={() => { setStep(step - 1); setScheduledTime(); }}
              type="submit"
              className="mt-2"
            >
              Previous
            </Button>
            <Button
              // disabled={emailType == "schedule" ? scheduledTime : "" ? true : false}
              appearance="primary"
              onClick={() => onSendClick()}
              type="submit"
              className="mt-2"
            >
              Submit
            </Button>
          </Modal.Footer>
        </div>
      );
    } else if (steps === 3) {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ height: '68vh' }}>
          <h1>Success</h1>
        </div>
      );
    }
  };

  /********** MULTI SELECT <<<<<<<<<********* */
  useEffect(() => {
    if (selectedRecords.length === datalength) {
      selectAllCheckbox.current.indeterminate = false;
      selectAllCheckbox.current.checked = true;
    } else if (selectedRecords.length) {
      selectAllCheckbox.current.indeterminate = true;
    } else {
      selectAllCheckbox.current.indeterminate = false;
    }
  }, [selectedRecords]);

  const selectRecord = (userData, check) => {
    if (check) {
      setSelectedRecords((previousState) => [...previousState, userData._id]);
    } else {
      setSelectedRecords((previousState) =>
        previousState.filter((item) => item !== userData._id)
      );
    }
  };

  const handleSelectAll = (check) => {
    console.log("selectedAllData", selectedAllData);
    if (check) {
      setSelectedRecords(selectedAllData);
    } else {
      setSelectedRecords([]);
    }
  };

  const isSelected = (data) => {
    return selectedRecords.filter((item) => String(data?._id) === String(item)).length > 0;
  };
  /********** MULTI SELECT <<<<<<<<<********* */

  function DeleteInvitationGroup(data) {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let ids = Array.isArray(data) ? data : [data];
        const formdata = new FormData();
        formdata.append("o_id", JSON.stringify(ids));
        eventsService
          .DeleteInvitationGroup(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              /** removing deleted ids from selectedRecords */
              setSelectedRecords((data) => data.filter((item) => {return ids.indexOf(item) === -1}));
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError");
          });
      }
    });
  }


  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    // reset customrangepicker & customstatusfilter state using jquery//
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
    $("#type")[0].selectedIndex = 0;
  };

  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };

  const inviteValidationSchema = Yup.object().shape({
    invitation_title: Yup.string()
      .required("Invitation title is required")
      .test("no-spaces", "Invitation title is required", (value) => value.trim()),
    // title: Yup.string()
    //   .required("Title is required")
    //   .test("no-spaces", "Title is required", (value) => value.trim()),
    firstname: Yup.string()
      .required("First name is required")
      .test("no-spaces", "First name is required", (value) => value.trim()),
    lastname: Yup.string()
      .required("Last name is required")
      .test("no-spaces", "Last name is required", (value) => value.trim()),
    middlename: Yup.string(),
    email: Yup.string().email().required("Email is required"),
    role :Yup.string(),
    // .required("Role is required"),
    email_template:Yup.string()
  });

  const inviteValidationUsers = Yup.object().shape({
    invitation_title: Yup.string()
      .required("Invitation title is required")
      .test("no-spaces", "Invitation title is required", (value) => value.trim()),
    role:Yup.string()
      .required("Role is required")
  });

  const showInviteType = (type) => {
    if (type === INVITATION_TYPES.SEND_NOW) {
      return <span className="badge badge-success">Sent</span>;
    } else if (type === INVITATION_TYPES.SCHEDULE) {
      return <span className="badge badge-info">Scheduled</span>;
    } else if (type === INVITATION_TYPES.SEND_LATER) {
      return <span className="badge badge-warning">Send Later</span>;
    }
  }

  const getStatus = (status) => {
    if (status === INVITATION_STATUS.PENDING) {
      return <span className="badge badge-warning">No reply</span>;
    } else if (status === INVITATION_STATUS.ACCEPTED) {
      return <span className="badge badge-success">Will attend</span>;
    } else if (status === INVITATION_STATUS.REJECTED) {
      return <span className="badge badge-danger">Declined</span>;
    }
  };


  const deleteInviteesFunction = (data) => {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const formdata = new FormData();
        formdata.append("o_id", data);
        eventsService
          .DeleteInvitees(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError");
          });
      }
    });
  };

  const resendInviteesEmail = (_id) => {
    globalLoader(true);
    eventsService
      .ResendInviteesEmail({ invitees_id: _id })
      .then((response) => {
        if (response.success) {
          globalLoader(false);
          Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((error) => {
        globalLoader(false);
        console.log("error", error);
      });
  };

  useEffect(() => {
    templateServices.Options({category:EMAIL_TEMPLATE_CATEGORY.USER_INVITATION}).then((response) => {
      if (response.success) {
        setEmailTemplateOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])
  return (
    <div className="row">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="main-content-label"><span></span></h6>
        <div className="d-flex align-items-center">
          <div className="form-group mb-0 me-3">
            <div className="form-group mb-0 rangepicker_container filter_design">
              <i className="fa fa-search calender_icon"></i>
              <input
                type="search"
                className="form-control"
                value={globalSearch}
                placeholder="Search"
                onChange={(e) => {
                  setGlobalSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="me-3">
            <CustomRangepicker
              GetDateRange={(e) => {
                prepareSearch("createdAt", e);
              }}
              resetdate={resetdate}
            />
          </div>
          <div className="me-3">
            <div className="form-group mb-0 filter_icon_container filter_design">
              <i className="fa fa-filter mr-2 filter_icon"></i>
              <div className="select-down-arrow">
                <select className="form-control cp" onChange={(e) => prepareSearch('status', e.target.value)} id="defaultstatus" style={{ width: "130px" }}>
                  {filterOfInvitations && filterOfInvitations.length > 0 && filterOfInvitations.map((option, i) => {
                    return (
                      <option key={i} value={option.status__id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <div className="me-3">
            <div className="form-group mb-0 filter_icon_container filter_design">
              <i className="fa fa-filter mr-2 filter_icon"></i>
              <div className="select-down-arrow">
                <select className="form-control cp" onChange={(e) => prepareSearch('type', e.target.value)} id="type" style={{ width: "130px" }}>
                  {showFilterOfInvitations && showFilterOfInvitations.length > 0 && showFilterOfInvitations.map((option, i) => {
                    return (
                      <option key={i} value={option.status__id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <button
            type="reset"
            value="Reset"
            onClick={resetFilter}
            className="btn btn-warning float-right mr-2"
          >
            Reset Filter
          </button>
          <button
            className="btn ripple btn-main-primary signbtn"
            onClick={() => setOpen(true)}
          >
            Invite Users
          </button>
          <button
            className="btn ripple btn-info ms-2"
            onClick={() => setSecondModal(true)}
          >
            Invite User
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
          <thead>
            <tr>
              <th className={"position-relative select_head " + (hasPermission('/admin/event-management/events/view/invitations/multi-select') ? '' : "d-none")}>
                <div className="select-check-container">
                  <label
                    htmlFor="selectAllCheck"
                    style={{ lineHeight: "unset" }}
                    className="ckbox  cp"
                  >
                    <input
                      ref={selectAllCheckbox}
                      id="selectAllCheck"
                      checked={
                        datalength && datalength === selectedRecords.length
                      }
                      onChange={(e) => handleSelectAll(e.target.checked)}
                      type="checkbox"
                    />
                    <span></span>
                  </label>
                </div>
              </th>
              <th>Invitation Title</th>
              <th>Name</th>
              <th>Email</th>
              <th>Type</th>
              <th className="status_head">Status</th>
              <th className="create_head">Created Date</th>
              <th className="action_head">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={8}>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {invitationsList.length ? (
                  invitationsList.map((row, i) => {
                    return (
                      <tr key={i} className={isSelected(row) ? "selected" : ""}>
                        <td className={"position-relative " + (hasPermission('/admin/event-management/events/view/invitations/multi-select') ? '' : "d-none")}>
                          <div className="select-check-container">
                            <label
                              htmlFor={row?._id + "input"}
                              style={{ lineHeight: "unset" }}
                              className="ckbox cp"
                            >
                              <input
                                id={row?._id + "input"}
                                checked={isSelected(row)}
                                onChange={(e) =>
                                  selectRecord(row, e?.target?.checked)
                                }
                                type="checkbox"
                                className="form-check select-check cp"
                              />
                              <span></span>
                            </label>
                          </div>
                        </td>
                        <td className="text-capitalize">{row?.invitation_data?.title ? row.invitation_data.title :"N/A" }</td>
                        <td className="text-capitalize">{row?.name ? row.name : "N/A"}</td>
                        <td>
                          {row?.email ? row.email : "N/A"}
                        </td>
                        <td className="text-capitalize">
                          {row?.invitation_data?.type ? showInviteType(row.invitation_data.type) : "N/A"}
                        </td>
                        <td className="text-capitalize">
                          {row?.invitation_data?.status ? getStatus(row.invitation_data.status) : "N/A"}
                        </td>
                        <td>
                          {row?.createdAt ? formateDate(row.createdAt) : "N/A"}
                        </td>
                              <td>
                          <div className="d-flex">
                            {hasPermission('/admin/event-management/events/view/invitations/delete') ?

                              <button
                                className="btn ripple btn-secondary ms-1"
                                onClick={() => {
                                  deleteInviteesFunction(row?._id);
                                }}
                              >
                                Delete
                              </button>
                              : null}
                            {hasPermission('/admin/event-management/events/view/invitations/resend') ?

                              <button
                                className="btn ripple btn-success mx-1"
                                onClick={() => {
                                  resendInviteesEmail(row?._id);
                                }}
                              >
                                Resend
                              </button>
                              : null}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={8} className="text-center">
                      No records
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="" id="example1_info" role="status" aria-live="polite">
        <b>Total Records : {datalength ? datalength : "0"}</b>
      </div>
      {datalength && datalength > 0 ? (
        <CustomPagination
          datalength={datalength}
          paramName={'page'}
          itemperpage={itemperpage}
          currentPage={page}
          setPage={setPage}
          query={"true"}
          pageRoute={[
            {
              name: "Invitations",
              path: `/admin/event-management/events/view/invitations/${params.id}`,
            },
          ]}
        />
      ) : (
        ""
      )}

      {/* Invite Users Modal */}
      <Modal
        size={"full"}
        className="invitation-modal"
        open={open}
        onClose={() => {
          setOpen(false);
          setStep(0);
          setQuestionAllData([]);
          setSelectedSubmitData([]);
          setInvitationName("");
          setDistributionListTitle("");
          setSaveDistributionList(!saveDistributionList);
          setScheduledTime();
          setEmailType("");
        }}
        backdrop={'static'}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Invite Users</Modal.Title>
        </Modal.Header>
        <div>
          <Steps current={step}>
            <Steps.Item title="Upload File" />
            <Steps.Item title="Attach Questions" />
            {/* <Steps.Item title="Attach Email Template" /> */}
            <Steps.Item title="Preview" />
            <Steps.Item title="Success" />
          </Steps>
          <div>{modalData(step)}</div>
        </div>
      </Modal>
      {/* Invite Users Modal */}

      {/* Invite User Modal */}

      <Modal
        size={"lg"}
        className="invitation-modal modal-height-auto"
        open={secondModal}
        onClose={() => setSecondModal(false)}
        backdrop={'static'}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Invite user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              validateOnBlur={false}
              initialValues={{
                email: "",
                title: "",
                firstname: "",
                middlename: "",
                lastname: "",
                invitation_title: "",
                role:"",
                email_template:"",
                sender_email_name:"",
                content_block_id:""
              }}
              validationSchema={inviteValidationSchema}
              onSubmit={(values) => {
                globalLoader(true);
                let formData = new FormData();
                formData.append("event_id", params.id);
                formData.append("type", "send_now");
                formData.append("invitation_title", values.invitation_title);
                formData.append("role",values.role ? values.rrole : STAFF_ROLE.USER);
                formData.append("users", JSON.stringify([values]));
                formData.append("email_template", values.email_template);
                if(values.sender_email_name){
                  formData.append("sender_email_name", values.sender_email_name);
                }
                if(values.content_block_id){
                  formData.append("content_block_id", values.content_block_id);
                }
                eventsService
                  .InviteUsers(formData)
                  .then((response) => {
                    globalLoader(false);
                    if (response?.success) {
                      Swal.fire({
                        icon: "success",
                        text: response.message,
                        ...SWAL_SETTINGS,
                      });
                      setStatusUpdate(!statsupdate);
                      setSecondModal(false);
                    } else {
                      Swal.fire({
                        icon: "error",
                        text: response.message,
                        ...SWAL_SETTINGS,
                      });
                    }
                  })
                  .catch((error) => {
                    globalLoader(false);
                    Swal.fire({
                      icon: "error",
                      text: error,
                      ...SWAL_SETTINGS,
                    });
                  });

              }}
            >
              {({
                values,
                errors,
                handleChange,
                setFieldValue,
                handleBlur,
                touched,
                handleSubmit,
              }) => (
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="row">
                    <div className="col-lg-6 text-center form-group mt-4">
                      <label className="text-left d-flex">Invitation Title:<span className="requirestar">*</span></label>
                      <input
                        name="invitation_title"
                        id="invitation_title"
                        type="text"
                        className="form-control"
                        value={values.invitation_title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"invitation_title"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group mt-4">
                      <label className="text-left d-flex">Title:</label>
                      <input
                        name="title"
                        id="title"
                        type="text"
                        className="form-control"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"title"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">First Name:<span className="requirestar">*</span></label>
                      <input
                        name="firstname"
                        id="firstname"
                        type="text"
                        className="form-control"
                        value={values.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"firstname"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">Middle Name:</label>
                      <input
                        name="middlename"
                        id="name"
                        type="text"
                        className="form-control"
                        value={values.middlename}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"middlename"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">Last Name:<span className="requirestar">*</span></label>
                      <input
                        name="lastname"
                        id="lastname"
                        type="text"
                        className="form-control"
                        value={values.lastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"lastname"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">Email:<span className="requirestar">*</span></label>
                      <input
                        name="email"
                        id="email"
                        type="text"
                        className="form-control"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"email"} />
                      </span>
                    </div>
                    {/* <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='slug' className='text-left d-flex'>Role:<span className="requirestar">*</span></label>
                      <div className="select-down-arrow ">
                        <Field as='select' id="role" name={"role"} className="form-control select2">
                          <option value="" label="Select role">Select{" "}</option>
                          {roleOptions && roleOptions.map((option, i) => {
                            return (
                              <option className="text-capitalize" key={i} value={STAFF_ROLE[option]}>
                                {SpecialCharacter(option)}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"role"} /></span>
                    </div> */}
                    {/* {values?.role == STAFF_ROLE.USER? */}
                    <div className="col-lg-6 text-center form-group">
                        <label className="text-left d-flex">Select Email Template:</label>
                        <div className="form-group">
                        <div className="select-down-arrow">
                          <select
                            className="form-control select2"
                            value={values.email_template}
                            onChange={(e) => {setFieldValue('email_template', e.target.value);
                          }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {emailTemplateOptions &&
                              emailTemplateOptions.length > 0 &&
                              emailTemplateOptions.map((option, i) => {
                                return (
                                  <option key={i} value={option._id}>
                                    {option.title}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"email_template"} /></span>
                      </div>
                      </div>

                      <div className="col-lg-6 text-center form-group">
                        <label className="text-left d-flex">Select Sender Email Name:</label>
                        <div className="form-group">
                        <div className="select-down-arrow">
                          <select
                            className="form-control select2"
                            value={values.sender_email_name}
                            onChange={(e) => {setFieldValue('sender_email_name', e.target.value);
                          }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {senderEmailOptions &&
                              senderEmailOptions.length > 0 &&
                              senderEmailOptions.map((option, i) => {
                                return (
                                  <option key={i} value={option._id}>
                                    {option.email_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"sender_email_name"} /></span>
                      </div>
                      </div>
                      <div className="col-lg-6 text-center form-group">
                        <label className="text-left d-flex">Select Content Block:</label>
                        <div className="form-group">
                        <div className="select-down-arrow">
                          <select
                            className="form-control select2"
                            value={values.content_block_id}
                            onChange={(e) => {setFieldValue('content_block_id', e.target.value);
                          }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {contentClockOptions &&
                              contentClockOptions.length > 0 &&
                              contentClockOptions.map((option, i) => {
                                return (
                                  <option key={i} value={option._id}>
                                    {option.title}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                    <span className='text-danger d-flex text-left'><ErrorMessage name={"content_block_id"} /></span>
                      </div>
                      </div>

                      {/* : null} */}
                  </div>
                  <Modal.Footer className="mt-4">
                    <Button
                      onClick={() => {
                        setSecondModal(false);
                      }}
                      appearance="subtle"
                    >
                      Close
                    </Button>
                    <Button
                      appearance="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Send Now
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
      {selectedRecords.length ? (
        <div className="bulk_actions">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ fontSize: "23px", color: "#35b159" }}
              ></i>
              <h6 className="ms-2 mt-1 mb-0">
                Selected {selectedRecords.length}{" "}
                {selectedRecords.length > 1 ? "records" : "record"} of{" "}
                {datalength}
              </h6>
            </div>
            <div>
              <ul>
                <li>
                  {hasPermission('/admin/event-management/events/view/invitations/delete') ?

                    <button
                      className="btn ripple btn-secondary"
                      onClick={() => {
                        DeleteInvitationGroup(selectedRecords);
                      }}
                    >
                      Delete
                    </button>
                    : null}
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/***********MULTI SELECTION OPTIONS <<<<<<*************/}
    </div>
  );
};

export default ViewInvitations;
