import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import toHtml from 'html-react-parser';
import * as activityServices from '../../services/activity.services';
import * as topicsService from '../../services/topic.services';
import Breadcrums from '../../common/breadcrumbs';
import Loader from '../../common/loader';
import { formateDate, handleServerValidations, hasPermission, showStatus, TrimText } from '../../../../utils/commonfunction';
import Swal from 'sweetalert2';
import { showFilterlist, SWAL_SETTINGS } from '../../../../utils/Constants';
import CustomPagination from '../../common/custompagination';
import CustomRangepicker from '../../common/rangepicker';
import StatusFilter from '../../common/statusFilter';
import $ from 'jquery';
import Sorting from '../../common/sorting';




const ActivitySetView = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [showdefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Activity Forums", url: "/admin/activity-forum/activities/list/1" }, { title: "View", url: "" }];
    const location = useLocation()
    const [list, setList] = useState([])
    const [statsupdate, setStatusUpdate] = useState("false");
    const [datalength, setDataLength] = useState("");
    const [itemperpage] = useState(10);
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalsearch, setGlobalSearch] = useState("");
    const [resetdate, setResetDate] = useState(false);
    const [query] = useSearchParams();



    useEffect(() => {
        activityServices.Details(params.id).then(response => {
            setShowDefault(response && response.data ? response.data : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    useEffect(() => {
        if (search) {
            setLoader(true);
            const formData = new FormData();
            formData.append("page", query.get('page'));
            formData.append("per_page", itemperpage);
            formData.append("sort", JSON.stringify(sorting));
            formData.append("search", JSON.stringify(search));
            formData.append("global_search", globalsearch);
            formData.append("activity_id", params?.id);
            topicsService.List(formData).then(data => {
                setDataLength(data.data.total_records);
                setList(data && data.data && data.data.list ? data.data.list : []);
                setPage(data && data.data && data.data.page ? data.data.page : 1);
                setLoader(false);
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsupdate, sorting, search, globalsearch])


    const viewfunction = (row) => {
        navigate(`/admin/activity-forum/topics/view/${row._id}?page=1`)
    }

    const goToEdit = (row) => {
        navigate(`/admin/activity-forum/topics/${query.get('page')}/edit/${row._id}`)
    }

    const ChangeStatus = (data, Num) => {
        let ids = Array.isArray(data) ? data : [data];
        const formData = new FormData()
        formData.append("o_id", JSON.stringify(ids))
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                topicsService.Status(formData).then(response => {
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                        setStatusUpdate(!statsupdate)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    function Deletefunction(data) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let ids = Array.isArray(data) ? data : [data];
                const formdata = new FormData()
                formdata.append("o_id", JSON.stringify(ids));
                topicsService.Delete(formdata).then(response => {
                    setStatusUpdate(!statsupdate)
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch(error => {
                    console.log("deleteError")
                });
            }
        })
    }

    // sorting function start
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }
    // sorting end

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        // reset customrangepicker & customstatusfilter state using jquery//
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    const goToDuplicate = (row) => {
        navigate(
            `/admin/activity-forum/topics/add`, { state: row }
        );
    };


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h6 className="main-content-label mb-3">View Activity</h6>
                            </div>
                            {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table  table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th>Title</th>
                                                <td>{showdefault && showdefault.title ? showdefault.title : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Title In German</th>
                                                <td>{showdefault && showdefault.title_de ? showdefault.title_de : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Created Date</th>
                                                <td>{showdefault && showdefault.createdAt ? (formateDate(showdefault.createdAt)) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{showdefault && showdefault.status === 0 ? toHtml(showStatus(showdefault.status)) : toHtml(showStatus(showdefault.status))}</td>
                                            </tr>
                                            <tr>
                                                <th>Modified Date</th>
                                                <td>{showdefault && showdefault.updatedAt ? formateDate(showdefault.updatedAt) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault.description ? showdefault.description : "N/A" }}></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault.description_de ? showdefault.description_de : "N/A" }}></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> : <Loader />}
                        </div>

                        <div className="">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className='d-flex justify-content-between align-items-center mb-4'>
                                        <h6 className="main-content-label">Topics</h6>
                                        <div className='d-flex align-items-center'>
                                            <div className='form-group mb-0 me-3'>
                                                <div className="form-group mb-0 rangepicker_container filter_design">
                                                    <i className="fa fa-search calender_icon"></i>
                                                    <input type="search" className="form-control" value={globalsearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                                </div>
                                            </div>
                                            <div className="me-3">
                                                <CustomRangepicker GetDateRange={(e) => { prepareSearch("createdAt", e) }} resetdate={resetdate} />
                                            </div>
                                            <div className="me-3">
                                                <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                            </div>
                                            <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-warning float-right mr-2">Reset Filter</button>
                                            <button className="btn ripple btn-main-primary signbtn" onClick={() => navigate(`/admin/activity-forum/topics/add`, { state: showdefault?._id })}>Add New</button>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <div className='sorting_column'>
                                                            <span>Title</span>
                                                            <Sorting sort={sorting} handleSort={handleSort} column="title" />
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='sorting_column'>
                                                            <span>Activity</span>
                                                        </div>
                                                    </th>
                                                    <th>
                                                        <div className='sorting_column'>
                                                            <span>Created Date</span>
                                                            <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="createdAt" />
                                                        </div>
                                                    </th>
                                                    <th>Created By</th>
                                                    <th>Updated By</th>
                                                    <th className='status_head'>Status</th>
                                                    <th className='action_head'>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loader ?
                                                    <tr>
                                                        <td colSpan={7}><Loader /></td>
                                                    </tr> : <>
                                                        {list.length ? list.map((row, i) => {
                                                            return <tr key={i}>
                                                                <td className="text-capitalize">{row?.title ? TrimText(row.title, 20) : "N/A"}</td>
                                                                <td>{row?.activity_data && row.activity_data ? <Link to={`/admin/activity-forum/activities/view/${row.activity_data._id}`}>{row.activity_data.title}</Link> : "N/A"}</td>
                                                                <td>{row?.createdAt ? formateDate(row.createdAt) : "N/A"}</td>
                                                                <td>{row?.createdBy?.name ? <Link to={`/admin/staff/view/${row.createdBy._id}`}>{row.createdBy.name}</Link> : "N/A"}</td>
                                                                <td>{row?.updatedBy?.name ? <Link to={`/admin/staff/view/${row.updatedBy._id}`}>{row.updatedBy.name}</Link> : "N/A"}</td>
                                                                {hasPermission('/admin/activity-forum/topics/status') ?
                                                                    <td>{row?.status === 1 ? <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(row?._id, 0) }}>Active</button>
                                                                        : <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(row?._id, 1) }}>Inactive</button>}
                                                                    </td>
                                                                    :
                                                                    <td>{row?.status === 1 ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Inactive</span>}</td>
                                                                }

                                                                <td>
                                                                    <div className="d-flex">
                                                                        <button className="btn ripple btn-main-primary signbtn" onClick={() => { viewfunction(row) }}>View</button>
                                                                        <button className="btn ripple btn-success mlAction" onClick={() => { goToEdit(row) }}>Edit</button>
                                                                        {hasPermission('/admin/activity-forum/topics/delete') ?
                                                                            <button className="btn ripple btn-secondary mlAction" onClick={() => { Deletefunction(row?._id) }}>Delete</button>
                                                                            : null}
                                                                        {hasPermission('/admin/activity-forum/topics/more') ?
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-dark dropdown-toggle mx-1"
                                                                                data-bs-toggle="dropdown"
                                                                                aria-expanded="false"
                                                                            >
                                                                                More
                                                                            </button>
                                                                            : null}
                                                                        <ul className="dropdown-menu">
                                                                            {hasPermission('/admin/activity-forum/topics/duplicate') ?
                                                                                <li>
                                                                                    <a href='/' style={{ color: "#647194" }}
                                                                                        className="dropdown-item"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            goToDuplicate(row);
                                                                                        }}
                                                                                    >
                                                                                        Duplicate
                                                                                    </a>
                                                                                </li>
                                                                                : null}
                                                                        </ul>
                                                                    </div>

                                                                </td>
                                                            </tr>
                                                        }) : <tr><td colSpan={7} className="text-center">No records</td></tr>}
                                                    </>}

                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                                    {datalength && datalength > 0 ?
                                        <CustomPagination datalength={datalength} paramName={'page'} itemperpage={itemperpage} currentPage={page} setPage={setPage} query={"true"} pageRoute={[{ name: "Activity", path: `/admin/activity-forum/activities/view/${params.id}` }]} /> : ""}
                                </div>
                                <div className="mt-5 back-button">
                                    <button className="btn ripple btn-dark" type='button' onClick={() => navigate(`/admin/activity-forum/activities/list/1`)}>
                                        <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                        Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ActivitySetView