import axiosInstance from "../../../utils/axios";

export const EventDetail = async (id,invitation=false) => {
    return await axiosInstance().get(`web/other/events/details?o_id=${id}&invitation=${invitation}`);
}

export const EventDetailBySlug = async (slug) => {
    return await axiosInstance().get(`web/other/events/details-by-slug?slug=${slug}`,);
}

export const EventRegistrationDetails = async (id) => {
    return await axiosInstance().get(`web/other/events/registrations/details?o_id=${id}`,);
}

export const CheckAvailability = async (values) => {
    return await axiosInstance().post(`web/other/event/check-availability`, values);
}

