import React, { useEffect,useRef,useState } from 'react';
import {useNavigate, useSearchParams } from "react-router-dom";
import {  capitalizeFirstLetter, globalLoader, handleServerValidations, prepareQuestionsFormData } from '../../../utils/commonfunction'
import { useTranslation } from 'react-i18next';
import { InvitationDetails, QuestionList, Registration } from '../services/common.services';
import Swal from 'sweetalert2';
import {
    LM_USER_INVITATION_STATUS,
    QUESTION_SET_CATEGORIES,
    STAFF_ROLE,
    SWAL_SETTINGS,
  } from "../../../utils/Constants";
import { Formik } from "formik";
import { Rating } from "react-simple-star-rating";
import DynamicQuestions from '../../user/shared/DynamicQuestions';


const BackOfficeRegistration = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const [invitationData, setInvitationData] = useState(null);
    const navigate = useNavigate();
    const [questionList, setQuestionList] = useState([]);
    const [eventId, setEventId] = useState('');
    const [userId, setUserId] = useState('');
    const [validationError, setValidationError] = useState("");
    const childRef = useRef();
    const [questionFilesUploadPath] = useState("lm-users");
    const [initialValues] = useState({
    })



    useEffect(()=>{
        globalLoader(true);
        InvitationDetails(searchParams.get('invitation_id')).then( async (response) => {
            if (response?.success) {
                if(response?.data){
                    setInvitationData(response?.data);
                    setEventId(response.data.event_id);
                    setUserId(response.data?._id);
                    if(response?.data?.status === LM_USER_INVITATION_STATUS.PENDING && response?.data?.user_already_exist){
                        globalLoader(false);
                    }else{
                        globalLoader(false);
                    }
                }
            } else {
                Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                });
                globalLoader(false);
            }
        })
        .catch(error => {
            console.log("error ====> ", error);
            globalLoader(false);
        });
    },[]);

    useEffect(() => {
      if(eventId){
        const formData = new FormData();
        formData.append("event_id", eventId);
        if(invitationData?.role === STAFF_ROLE.LM_BACK_OFFICE){
          formData.append("type", QUESTION_SET_CATEGORIES.LM_BACK_OFFICE_REGISTRATION);
        }
        else if(invitationData?.role === STAFF_ROLE.LEAD_COORDINATOR){
          formData.append("type", QUESTION_SET_CATEGORIES.LEAD_COORDINATOR_REGISTRATION);
        }
        QuestionList(formData)
          .then((response) => {
            setQuestionList(response?.data || []);
          })
          .catch((error) => {
            console.log("error ====> ", error);
          });
      }
    }, [eventId]);

    return (
        <>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            const formResult = childRef?.current?.submit();
          if (values?.is_draft === false && !formResult?.isValid) {
            return;
          }
          globalLoader(true);
            let formData = new FormData();
           formData.append("event_id", eventId);
           formData.append("user_id", userId);
           formData.append("invitation_id",searchParams.get('invitation_id'))
           let result = childRef?.current?.getValues();
           let finalForm = prepareQuestionsFormData(result, formData, questionFilesUploadPath);
           Registration(finalForm)
              .then((response) => {
              globalLoader(false);
                if (response?.success) {
                  Swal.fire({
                    icon: "success",
                    text: response?.message,
                    ...SWAL_SETTINGS,
                  });
                  resetForm({ values: "" });
                  navigate(`/back-office/home`)
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                }
              globalLoader(false);
              })
              .catch((error) => {
                console.log("error ====> ", error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <div className="wraper-inner cpt bg-grey">
              <section className="inner-space">
                <div className="w-100 px-3" style={{ minHeight: "60vh" }}>
                 <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-9 m-auto ">
                        <h3 className="inner-title mb-0">
                        {t('web_lbl_lm_back_office_registration')}
                        </h3>
                        <div className="dash-box-shadow space-b">
                          <div className="row">
                          {questionList.length > 0 ? (
                            <h4 className="mb-3">{t('web_lbl_more_info')}</h4>
                          ) : null}
                          <div className="form-group mb-4 col-md-12">
                            {questionList.length > 0
                              ?
                              <div>
                              <DynamicQuestions ref={childRef} questions={questionList} showCount={false}
                              questionTitleClass="" footer="" videoUploadPath={questionFilesUploadPath}/>
                              </div>
                              : null}
                          </div>
                           {questionList?.length > 0  ? 
                            <div className="d-flex mt-4">
                              <button
                                className="btn btn-primary ms-2"
                                type="submit"
                              >
                             {t('web_event_registration_continue')}
                              </button>
                              <button
                                className="btn btn-danger ms-2"
                                type="button"
                                onClick={() => navigate(-1)}
                              >
                              {t("web_lbl_button_cancel")}
                              </button>
                            </div>
                            :
                            <div
                            className="alert alert-danger rounded"
                            role="alert"
                          >
                            <i
                              className="fa fa-exclamation-triangle me-3"
                              aria-hidden="true"
                            ></i>
                           {t('web_lbl_no_question_found')}
                          </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </section>
            </div>
          )}
        </Formik>
        </>
    )
}

export default BackOfficeRegistration