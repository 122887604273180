import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Formik } from 'formik'
import { Link } from 'react-router-dom';
import Logo from '../../../../src/assets/admin/img/brand/icon.png'
import { decodeValue, handleServerValidations } from '../../../utils/commonfunction';
import * as authService from '../services/auth.services';
import { SWAL_SETTINGS } from '../../../utils/Constants';
import Swal from 'sweetalert2';


const OtpVerificationPage = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [loader, setLoader] = useState('false') 
    const [type, setType] = useState('')
    const [searchParams] = useSearchParams();
    const params = useParams()
    let decryemail = decodeValue(params.email)

    useEffect(() => {
        setType(searchParams.get('type'))
    }, [])


    const resentotpfunct = () => {
        let formData = new FormData();
        formData.append("email", decryemail)
        formData.append("type", type);
        authService.resendOtp(formData).then(response => {
            if (response.success) {
                Swal.fire({
                    icon: 'success',
                    text: response.message,
                    ...SWAL_SETTINGS
                })
            } else {
                Swal.fire({
                    icon: 'error',
                    text: response.message,
                    ...SWAL_SETTINGS
                })
            }
        }).catch(error => {
            console.log("error: ", error);
        })
    }

    return (
        <>
            <Formik
                initialValues={{ otp: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.otp) {
                        errors.otp = 'OTP is required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setLoader(true)
                    let formData = new FormData();
                    formData.append("email", decryemail)
                    formData.append("otp", values.otp);
                    formData.append("type", type);
                    authService.otpVerification(formData).then(response => {
                        if (response.success && location.pathname.includes("otp-verification")) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            setTimeout(() => {
                                setLoader(false)
                                navigate('/admin/reset-password', { state: response.data.validate_string })
                            }, 2000);
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                            setTimeout(() => {
                                setLoader(false)
                            }, 2000);
                        }
                    }).catch(error => {
                        console.log("error: ", error);
                    })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <div className='page main-signin-wrapper'>
                        <div className='innerbody'>
                            <div className="row signpages text-center">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="row row-sm">
                                            <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
                                                <div className="mt-5 pt-3 p-0 pos-absolute">
                                                    <img src={Logo} className="ht-100 mb-2" alt="logo" />
                                                    <div className="clearfix"></div>
                                                    <h5 className="mt-2 text-white">OTP verification</h5>
                                                    <span className="tx-white-6 tx-14 mb-5 mt-xl-0">Signup to create, discover and connect with the global community</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
                                                <div className="container-fluid">
                                                    <div className="row row-sm">
                                                        <div className="card-body mt-4 mb-4">
                                                            <img src="../assets/img/brand/logo.png" className=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo" />
                                                            <div className="clearfix"></div>
                                                            <h5 className="text-left mb-2">Otp verification</h5>
                                                            <p className="mb-4 text-muted tx-13 ml-0 text-left">OTP has been sent to  {decryemail}</p>
                                                            <form onSubmit={handleSubmit}>
                                                                <div className="form-group text-left">
                                                                    <label>OTP</label>
                                                                    <input className={"form-control" + (errors.otp && touched.otp ? " is-invalid state-invalid" : "")}
                                                                        placeholder="Enter OTP"
                                                                        type="text"
                                                                        name="otp"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.otp}
                                                                        autoComplete="off"
                                                                        autoFocus
                                                                    />
                                                                    <span className='text-danger text-left d-blockerr-spn'>{errors.otp && touched.otp && errors.otp}</span>
                                                                </div>
                                                                <button className={"btn ripple btn-main-primary btn-block signbtn" + (loader === true ? " disabled" : "")} type="submit">Verification Otp</button>
                                                                {loader === true ?
                                                                    <div className="spinner-border text-primary mt-2" role="status">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div> : ""}
                                                            </form>
                                                            <div className="text-left mt-3 ml-0">
                                                                <div className="mb-1"><button className='a' onClick={resentotpfunct}>Resend otp?</button></div>
                                                            </div>
                                                            <div className="text-left mt-0 ml-0">
                                                                <div className="mb-1"><Link to="/admin/login">Back to Login</Link></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
        </>
    )
}

export default OtpVerificationPage