import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SWAL_SETTINGS } from "../../../utils/Constants";
import { TrimText, formateDate, formateDateWithMonth, prepareQuestionsFormData } from "../../../utils/commonfunction";
import { RoomDetails, EditRoomBooking, GetUserList, ReadUsersExcel } from "../services/room.services";
import DynamicQuestions from "../../user/shared/DynamicQuestions";
import { BookingDetail } from "../services/common.services";
import moment from "moment";
import { Loader } from "rsuite";
import CustomPagination from "../../admin/common/custompagination";
import $ from "jquery";

const SISUserEditBooking = () => {
    const childRef = useRef();
    const formikRef = useRef();
    const params = useParams();
    const [questionList, setQuestionList] = useState([]);
    const [roomDetails, setRoomDetails] = useState({});
    const [roomId, setRoomId] = useState({});
    const [bookingDetails, setBookingDetails] = useState({});
    const [answerList, setAnswerList] = useState([]);
    const navigate = useNavigate();
    const [questionFilesUploadPath] = useState("room-bookings");
    const { t } = useTranslation();

    const inviteMemberTrigger = useRef();
    const [userData, setUserData] = useState([]);
    const selectAllCheckbox = useRef();
    const [userdatalength, setUserDataLength] = useState(0);
    const [itemperpage] = useState(20);
    const [page, setPage] = useState(1);
    const [selectedUser, setSelectedUser] = useState([]);
    const [selectedAllDataUser, setSelectedAllDataUser] = useState(false);
    const [showSelectedUsers, setShowSelectedUsers] = useState(false);
    const [globalsearch, setGlobalSearch] = useState("");
    const [search, setSearch] = useState({});
    const [dateFilterValue, setDateFilterValue] = useState('');
    let reducerData = useSelector((state) => state.manageLmBackOffice);


    const [step, setStep] = React.useState(0);
    const [file, setFile] = useState("");
    const [skippedUsers, setSkippedUsers] = useState(null);
    const [selectedManualUser, setSelectedManualUser] = useState([]);
    const [selectedNewManualUser, setSelectedNewManualUser] = useState([]);
    const [selectedPrevManualUser, setSelectedPrevManualUser] = useState([]);
    const [selectedManualUserData, setSelectedManualUserData] = useState(false);
    const [isExcelModelOpen, setIsExcelModelOpen] = useState(false)

    useEffect(() => {
        if (roomId) {
            RoomDetails(roomId).then((response) => {
                if (response?.success) {
                    setRoomDetails(response?.data);
                    setQuestionList(response?.data?.questions_data || []);
                }
            }).catch((err) => {
                console.log("err", err)
            })
        }
    }, [roomId]);


    useEffect(() => {
        let formData = new FormData();
        formData.append('global_search', globalsearch);
        formData.append("page", page);
        formData.append("per_page", itemperpage);
        formData.append("search", JSON.stringify(search));
        GetUserList(formData)
            .then((response) => {
                setPage(
                    response && response?.data && response?.data?.page
                        ? response?.data?.page
                        : 1
                );
                setUserDataLength(response?.data?.total_records);
                setUserData(response?.data?.list);
                setSelectedAllDataUser(response && response.data && response.data.data_ids ? response.data.data_ids : []);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, [page, globalsearch, search]);

    useEffect(() => {
        BookingDetail(params.id)
            .then((data) => {
                setBookingDetails(data && data.data ? data.data : []);
                setRoomId(data && data.data && data.data.room_data && data.data.room_data?._id ? data.data.room_data?._id : null)
                setAnswerList(data && data.data && data.data.questions_data);
                setShowSelectedUsers(data && data.data && data.data.invitees ? data.data.invitees : null);
                setSelectedUser(data && data.data && data.data.invitees ? data.data.invitees : null)
                setSelectedManualUser(data && data.data && data.data.manual_invitees ? data.data.manual_invitees : []);
                setSelectedPrevManualUser(data && data.data && data.data.manual_invitees ? data.data.manual_invitees : [])
                // isSelectedNew()
            })
            .catch((error) => {
                console.log("error=====>", error);
            });
    }, [params.id]);

    const validationSchema = Yup.object().shape({
        booking_title: Yup.string().required(t('web_sis_user_booking_title_required')).trim(),
        start_date: Yup.string().required(t('web_sis_user_start_date_required')).trim(),
        end_date: Yup.string().required(t('web_sis_user_end_date_required')).trim(),
    });


    const handleClick = () => {
        inviteMemberTrigger.current.click();
    };

    const handleCloseModal = () => {
        const closeButton = document.getElementById("close_button");
        closeButton.click();
        setSelectedUser([]);
    };

    /**********Handle members selection when invite members >>>>>>********* */

    useEffect(() => {
        if (selectedUser.length === userdatalength) {
            selectAllCheckbox.current.indeterminate = false;
            selectAllCheckbox.current.checked = true;
        } else if (selectedUser.length) {
            selectAllCheckbox.current.indeterminate = true;
        } else {
            selectAllCheckbox.current.indeterminate = false;
        }
    }, [selectedUser]);

    const selectUser = (userData, check) => {
        if (check) {
            setSelectedUser((previousState) => [...previousState, userData._id]);
        } else {
            setSelectedUser((previousState) =>
                previousState.filter((item) => item !== userData._id)
            );
        }
    };
    const handleSelect = (check) => {
        if (check) {
            setSelectedUser(selectedAllDataUser);
        } else {
            setSelectedUser([]);
        }
    };
    const isSelectedNew = (item) => {
        return selectedUser.filter((data) => item._id === data).length > 0;
    };

    /**********Handle members selection when invite members >>>>>>********* */

    const selectRow = (item) => {
        let id = item?._id;
        $("#" + id + "label").trigger("click");
    };

    const handleNextButtonClick = () => {
        setShowSelectedUsers(true);
        const closeButton = document.getElementById("done_button");
        closeButton.click();
    };

    const resetFilter = (e) => {
        e.preventDefault();
        setGlobalSearch("");
        setSearch({});
        prepareSearch();
    };

    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    };

    const formattedValue = (date) => {
        const formattedDate = moment(date).format("YYYY-MM-DD HH:mm:ss");
        return formattedDate;
    }

    const setEndDate = (e) => {
        let room_booking_interval = roomDetails && roomDetails.bookable_interval ? roomDetails.bookable_interval : null
        if (room_booking_interval) {
            const start_date = new Date(e.target.value);
            const end_date = new Date(start_date.getTime() + room_booking_interval * 60000);
            const formattedEndDate = moment(end_date).format("YYYY-MM-DD HH:mm:ss");
            console.log(formattedEndDate, "formattedEndDate");
            formikRef?.current?.setFieldValue('end_date', formattedEndDate)
        }
    }

    const readExcelFile = (e) => {
        // globalLoader(true);
        if (e.target.files[0]) {
            const formData = new FormData();
            formData.append("file", e.target.files[0]);
            ReadUsersExcel(formData)
                .then((response) => {
                    setTimeout(() => {
                        if (response?.success) {
                            setFile(e?.target?.files[0]);
                            let newManualUser = response?.data?.list && response?.data?.list.length > 0 ? response?.data?.list.map(user => user.email) : []
                            setSelectedNewManualUser(newManualUser);
                            const newUsers = newManualUser;
                            const prevUsers = selectedPrevManualUser;
                            const emails = new Set(prevUsers);
                            const filteredNewUsers = newUsers.filter(user => !emails.has(user));
                            const newData = [...prevUsers, ...filteredNewUsers]
                            setSelectedManualUser(newData);
                            setStep(step + 1);
                        } else {
                            Swal.fire({
                                icon: "error",
                                text: response.msg,
                                ...SWAL_SETTINGS,
                            });
                            document.getElementById("file_reader").value = "";
                        }
                        // globalLoader(false);
                    }, 1500);
                })
                .catch((error) => {
                    console.log("error", error);
                    Swal.fire({
                        icon: "error",
                        text: error,
                        ...SWAL_SETTINGS,
                    });
                    //   globalLoader(false);
                });
        }
    };

    const onOkayClick = () => {
        setSelectedManualUserData(JSON.stringify(selectedManualUser));
        setStep(step + 1);
    };

    const handleRemoveManualUser = (email) => {
        setSelectedManualUser(selectedManualUser.filter(user => user !== email));
    };


    const modalData = (steps) => {
        if (steps === 0) {
            return (
                <div className="my-3 upload-button" style={{ height: '70vh' }}>
                    <form
                        id="reader_id"
                        style={{
                            height: '300px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <div className="text-center">
                            <label htmlFor="file_reader">
                                <input
                                    style={{ display: 'none' }}
                                    type="file"
                                    id="file_reader"
                                    onChange={readExcelFile}
                                    accept=".xls,.xlsx,.csv"
                                />
                                <i
                                    className="fa fa-upload cp"
                                    aria-hidden="true"
                                    style={{ fontSize: '7em', color: '#3598ff' }}
                                ></i>
                            </label>
                            <p className="text-muted mb-0">{t('web_sis_user_upload_excel')}</p>
                            <a
                                className="download-file my-0"
                                href={`${process.env.REACT_APP_API_URL.replace('/webservice/api/v1', '')}/configs/default/LM-users-invitation-sample.xlsx`}
                                download
                            >
                                 {t('web_sis_user_download_sample')}
                            </a>
                        </div>
                    </form>
                </div>
            );
        } else if (steps === 1) {
            return (
                <div className="mt-4" style={{ height: '70vh' }}>
                    <div className="table-responsive">
                        <table className="table card-table table-vcenter text-nowrap mb-0 border">
                            <thead>
                                <tr>
                                    <th className="wd-lg-20p">{t('web_lbl_email')}</th>
                                </tr>
                            </thead>
                            <tbody>
                            <>
                  {selectedNewManualUser.length ? (
                    selectedNewManualUser.map((row, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {row.toLowerCase()}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={selectedManualUser?.headings?.length}
                        className="text-center"
                      >
                        {t('web_lbl_no_records')}
                      </td>
                    </tr>
                  )}
                </>

                            </tbody>
                        </table>
                    </div>

                    <div className="modal-footer mt-4 border-0">

                        <button type="button" class="btn btn-secondary" onClick={() => {
                            setStep(step - 1);
                        }}> {t('web_event_registration_previous')}</button>
                        <button type="submit" class="btn btn-primary ms-2" onClick={() => {
                            onOkayClick();
                            setIsExcelModelOpen(false); setStep(0);
                        }}>{t('web_lbl_submit')}</button>
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            <Formik
                enableReinitialize
                innerRef={formikRef}
                initialValues={{
                    start_date: bookingDetails && bookingDetails.start_date ? formattedValue(bookingDetails.start_date) : '',
                    end_date: bookingDetails && bookingDetails.end_date ? formattedValue(bookingDetails.end_date) : '',
                    booking_title: bookingDetails && bookingDetails.title ? bookingDetails.title : ''
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    console.log(selectedUser)
                    let formResult = childRef?.current?.submit();
                    if (formResult && formResult?.isValid) {
                        const formData = new FormData();
                        formData.append('event_id', reducerData);
                        formData.append('start_date', values?.start_date);
                        formData.append('end_date', values?.end_date);
                        formData.append('title', values?.booking_title);
                        formData.append('room_id', roomId);
                        formData.append('_id', params.id);
                        if (selectedUser && selectedUser.length > 0 && selectedUser != null) {
                            formData.append("invitees", JSON.stringify(selectedUser));
                        }


                        if (selectedManualUser && selectedManualUser.length > 0) {
                            let emails = selectedManualUser.map(user => user)
                            formData.append("manualInvitees", JSON.stringify(emails));
                        }
                        let result = childRef?.current?.getValues();
                        let finalForm = prepareQuestionsFormData(result, formData, questionFilesUploadPath);

                        EditRoomBooking(finalForm).then((response) => {
                            if (response?.success) {
                                Swal.fire({
                                    icon: 'success',
                                    text: response?.message,
                                    ...SWAL_SETTINGS
                                });
                                navigate("/sis-user/settings/booking-list/1")
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    text: response?.message,
                                    ...SWAL_SETTINGS
                                })
                            }
                        }).catch((error) => {
                            console.log('error', error);
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: (t('web_sis_user_all_required_information')),
                            ...SWAL_SETTINGS
                        })
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,
                }) => (

                    <div className="dash-main-R">
                        <div className="row">
                            <div className="col-md-9 m-auto">
                                <h3 className="dash-title">{t('web_lbl_edit_room_booking')}</h3>
                                <div className="dash-box-shadow">
                                    <form
                                        onSubmit={async (e) => {
                                            handleSubmit(e);
                                        }}
                                    >
                                        <div className="row add-coustomer">
                                            <div className="col-12">
                                                <div className="w-100 p-4 mb-4 rounded-2" style={{ backgroundColor: "var(--light_active_color)" }}>
                                                    <h3 className="text-capitalize mb-2">
                                                        {roomDetails?.name ? TrimText(roomDetails?.name) : null}
                                                    </h3>
                                                    <div className="date-info pb-0">
                                                        <i className="ri-calendar-event-line"></i>
                                                        {formateDateWithMonth(roomDetails?.start_date) +
                                                            " - " +
                                                            formateDateWithMonth(roomDetails?.end_date)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group  mb-4 col-md-6">
                                                <label className="form-label"> {t('web_sis_user_booking_title')}<small>*</small></label>
                                                <div>
                                                    <input
                                                        name='booking_title'
                                                        id='booking_title'
                                                        type='text'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.booking_title}
                                                        className='form-control' />
                                                </div>
                                                <span className="text-danger d-flex text-left">
                                                    {errors.booking_title && touched.booking_title && errors.booking_title}
                                                </span>
                                            </div>
                                            <div className="form-group  mb-4 col-md-6">
                                                <label className="form-label">{t('web_sis_user_start_date')}</label>
                                                <div>
                                                    <input
                                                        name='start_date'
                                                        id='start_date'
                                                        type='datetime-local'
                                                        onChange={(e) => { handleChange(e); setEndDate(e) }}
                                                        onBlur={handleBlur}
                                                        onClick={(e) => e.target.showPicker()}
                                                        value={values.start_date ? values.start_date : bookingDetails?.start_date ? bookingDetails?.start_date : ''}
                                                        className='form-control' />
                                                </div>
                                                <span className="text-danger d-flex text-left">
                                                    {errors.start_date && touched.start_date && errors.start_date}
                                                </span>
                                            </div>
                                            {/* <div className="form-group  mb-4 col-md-6">
                                                <label className="form-label">
                                                    End Date<small>*</small>
                                                </label>
                                                <div>
                                                    <input
                                                        name='end_date'
                                                        id='end_date'
                                                        type='datetime-local'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        onClick={(e) => e.target.showPicker()}
                                                        value={values.end_date}
                                                        className='form-control' />
                                                    <span className="text-danger d-flex text-left">
                                                        {errors.end_date && touched.end_date && errors.end_date}
                                                    </span>
                                                </div>
                                            </div> */}
                                            <div className="form-group  mb-4 col-md-12">
                                                <label className="form-label">
                                                    <span></span>
                                                </label>
                                                <div>
                                                    <button
                                                        className="btn btn-primary"
                                                        type="button"
                                                        onClick={() => handleClick()}
                                                    >
                                                         {t('web_sis_user_invite_member')}
                                                    </button>
                                                    {showSelectedUsers && selectedUser?.length > 0 && (
                                                        <span className="ml-auto ms-2">
                                                             {t('web_sis_user_total_selected_user')}: {selectedUser?.length}
                                                        </span>
                                                    )}
                                                    {selectedManualUser && selectedManualUser?.length > 0 && (
                                                        <span className="ml-auto ms-2">
                                                            {t('web_sis_user_total_selected_manual_member')}: {selectedManualUser?.length}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>

                                            {selectedManualUser && selectedManualUser.length > 0 && (
                                                <>
                                                    <h4>{t('web_sis_user_manual_users')}</h4>
                                                    <div className="manual-invitees-chips mt-2 mb-2">
                                                        {selectedManualUser.map((user, index) => (
                                                            <div key={index} className="chip">
                                                                {user}
                                                                <button className="close-btn" type="button" onClick={() => handleRemoveManualUser(user)}>
                                                                    &times;
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </>
                                            )}
                                            {questionList.length > 0 ? (
                                                <h4 className="mb-3">{t('web_lbl_more_info')}</h4>
                                            ) : null}
                                            <div className="form-group mb-4 col-md-12">
                                                {/* {questionList.length > 0
                                                    ? */}
                                                    <div>
                                                        <DynamicQuestions ref={childRef} questions={questionList} showCount={false} defaultValues={answerList}
                                                            questionTitleClass="" footer="" videoUploadPath={questionFilesUploadPath} />
                                                    </div>
                                                    {/* : null} */}
                                            </div>
                                            <div className="btn-group mt-4 col-md-12">
                                                <button
                                                    className="btn btn-primary"
                                                    type="submit"
                                                    onClick={handleSubmit}
                                                >
                                                  {t('web_lbl_button_update')}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Formik>
            <a
                className="d-none"
                ref={inviteMemberTrigger}
                href="#ChoosePayment"
                role="button"
                data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight"
            >
                <span></span>
            </a>

            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel" style={{ top: "100px", width: "80%", right: "50px", "bottom": "40px", "height": "80vh" }}>
                {isExcelModelOpen == false ?
                    <>
                        <div class="offcanvas-header">
                            <div className="user-right filter-responsive d-flex align-items-center">
                                <div className="select-check-container">
                                    <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }} className="ckbox cp">
                                        <input ref={selectAllCheckbox} id="selectAllCheck" checked={userdatalength && userdatalength === selectedUser.length}
                                            onChange={(e) => handleSelect(e.target.checked)} type="checkbox" />
                                        <span className="ms-1 no-select no-before">{t('web_sis_user_select_all')}</span>
                                    </label>
                                </div>
                                <div className="search-box ms-2">
                                    <input
                                        type="text"
                                        name=""
                                        className="form-control rounded-3"
                                        placeholder={t('web_lbl_search')}
                                        value={globalsearch}
                                        onChange={(e) => {
                                            setGlobalSearch(e.target.value);
                                        }}
                                    />
                                    <button className="btn-search">
                                        <i className="ti ti-search"></i>
                                    </button>
                                </div>
                                <div className="form-group ms-2">
                                    <input
                                        className="form-control"
                                        type="date"
                                        value={dateFilterValue}
                                        onChange={(e) => {
                                            setDateFilterValue(e.target.value);
                                            prepareSearch("createdAt", e.target.value);
                                        }}
                                        onClick={(e) => {
                                            e.target.showPicker();
                                        }}
                                        placeholder={t('web_lbl_filter_by_date')}
                                    ></input>
                                </div>
                                <button
                                    className="btn btn-warning ms-1"
                                    onClick={(e) => {
                                        resetFilter(e);
                                    }}
                                >
                                    {t("web_lbl_reset_filter")}
                                </button>
                                <button
                                    type="button"
                                    value="upload_excel"
                                    className="btn btn-success float-right ms-2"
                                    onClick={() => {
                                        setIsExcelModelOpen(true);
                                    }}
                                >
                                     {t('web_sis_user_invite_manually')}
                                </button>
                            </div>
                            <button type="button" class="btn-close text-reset" id="done_button" data-bs-dismiss="offcanvas" aria-label="Close"></button>


                        </div>
                        <div class="offcanvas-body">
                            <div className="row">
                                {userData.length ? (
                                    userData.map((item, i) => {
                                        return (
                                            <div key={i} onClick={() => selectRow(item)} className={"col-xl-3 col-lg-4 col-md-6"} >
                                                <div className="check_container w-100">
                                                    <div className="w-100 h-100 p-2">
                                                        <strong>{item?.name ? TrimText(item.name, 30) : "N/A"}</strong>
                                                        <span className="d-block text-muted">{item?.email ? item.email : "N/A"}</span>
                                                        <span className="d-block text-muted">{item.createdAt ? formateDate(item.createdAt) : "N/A"}</span>
                                                    </div>
                                                    <input type="checkbox" onChange={(e) => selectUser(item, e?.target?.checked)} id={"select-checkbox" + i} checked={isSelectedNew(item)} />
                                                    <label htmlFor={"select-checkbox" + i}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16" >
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                        </svg>
                                                    </label>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div colSpan={6} className="text-center">
                                        {t('web_lbl_no_records')}
                                    </div>
                                )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center mt-3">
                                <div id="example1_info" role="status" aria-live="polite" >
                                    <b>{t('web_sis_user_total_records')} : {userdatalength ? userdatalength : "0"}</b>
                                </div>
                                {userdatalength && userdatalength > 0 ? (
                                    <CustomPagination
                                        datalength={userdatalength}
                                        itemperpage={itemperpage}
                                        currentPage={page}
                                        setPage={setPage}
                                        modalPagination={"modalPagination"}
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="d-flex justify-content-between align-items-center w-100 mt-3">
                                <h4> {selectedUser.length ? "Selected " + selectedUser.length + " of records " + userdatalength : ""} </h4>

                                <button type="button" className="btn btn-primary" disabled={selectedUser.length === 0} data-bs-dismiss="offcanvas" aria-label="Close">{t('web_registration_next')}</button>

                            </div>
                        </div>
                    </>
                    : <>
                        <div class="offcanvas-header">
                            <h4 class="modal-title" id="exampleModalLongTitle">{t('web_sis_user_invite_manually')}</h4>

                            <button
                                type="button"
                                className="btn-close"
                                id="close_button_excel"
                                aria-label="Close"
                                onClick={() => { setIsExcelModelOpen(false); setStep(0) }}
                            ></button>
                        </div>
                        <div class="offcanvas-body">
                            {modalData(step)}
                        </div>
                    </>}

            </div>
        </>
    );

};
export default SISUserEditBooking;

