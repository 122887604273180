import React from 'react'
import {  Outlet } from 'react-router-dom'
import SettingMenu from '../shared/settingMenu'


const Settings = () => {

  return (
    <>
    <section className='dash-main'>
    <SettingMenu/>
    {/* <a className='sidebar-btn' onClick={(e)=>{toggleClass(true)}}><i className="ri-menu-unfold-line"></i></a> */}
    
    <div className="dash-main-R" style={{minHeight:'75vh'}}>
        <div className="dash-side-R-body">
            <Outlet/>
        </div>
    </div>
    </section>
    </>
  )
}

export default Settings