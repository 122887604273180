import React, { useState } from "react";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { handleServerValidations } from "../../../utils/commonfunction";
import { 
  SWAL_SETTINGS, 
  ATLEAST_ONE_CAPITAL_REGEX,
  ATLEAST_ONE_NUMBER_REGEX,
  ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
  PASSWORD_MIN_LENGTH,
  PASSWORD_MAX_LENGTH,
  ATLEAST_ONE_SMALL_REGEX
 } from "../../../utils/Constants";
import * as userService from "../services/webuser.service";
import * as Yup from "yup";
import { useTranslation } from 'react-i18next';


const UserChangePassword = () => {
  const [show, setShow] = useState("false");
  const [newshow, setNewShow] = useState("false");
  const [conformshow, setConformShow] = useState("false");
  const { t } = useTranslation();

  const handleshow = (event) => {
    if (event === "oldshw") {
      setShow(!show);
    }
    if (event === "newpwd") {
      setNewShow(!newshow);
    }
    if (event === "confirepwd") {
      setConformShow(!conformshow);
    }
  };

  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required(t('web_lbl_err_old_password')),
    newPassword: Yup.string()
    .required(t('web_lbl_err_new_password'))
    .matches(ATLEAST_ONE_SMALL_REGEX, (t('web_lbl_err_password_lower')))
    .matches(ATLEAST_ONE_CAPITAL_REGEX, (t('web_lbl_err_password_capital')))
    .matches(ATLEAST_ONE_NUMBER_REGEX, (t('web_lbl_err_password_number')))
    .matches(ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, (t('web_lbl_err_password_special_character')))
    .min(PASSWORD_MIN_LENGTH, (t('web_lbl_err_password_8_character_long')))
    .max(PASSWORD_MAX_LENGTH, (t('web_lbl_err_password_more_than'))),
    confirmPassword: Yup.string().required(t('web_lbl_err_confirm_password')).oneOf([Yup.ref("newPassword"), null], (t('web_lbl_err_password_and_confirm'))),
  })

  return (
    <>
        <Formik
          enableReinitialize
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            let formData = new FormData();
            formData.append("current_password", values.oldPassword);
            formData.append("new_password", values.newPassword);
            formData.append("confirm_newpassword", values.confirmPassword);
            userService
              .changePassword(formData)
              .then((response) => {
                setSubmitting(false);
                if (response.success) {
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                  resetForm({ values: "" });
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                }
              })
              .catch((error) => {
                console.log("error ====> ", error);
              });
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-9 m-auto ">
                  <h3 className="dash-title">{t('web_heading_change_password')}</h3>
                  <div className="dash-box-shadow space-b">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                      <label className="form-label">{t('web_lbl_current_password')}<small>*</small></label>
                        <div className="input-group cp">
                        <input
                          type={!show === true ? "text" : "password"}
                          className={"form-control" + (errors.oldPassword && touched.oldPassword ? " is-invalid state-invalid" : "")}
                          id="oldPassword"
                          name="oldPassword"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.oldPassword}
                          placeholder={t('web_lbl_enter_current_password')}
                        />
                        <span
                          className="input-group-text"
                          onClick={() => {
                            handleshow("oldshw");
                          }}
                        >
                          {!show === true ? (
                            <i className="far fa-eye-slash"></i>
                          ) : (
                            <i className="far fa-eye"></i>
                          )}
                        </span>
                      </div>
                      <span className="text-danger d-flex text-left">
                        {errors.oldPassword &&
                          touched.oldPassword &&
                          errors.oldPassword}
                      </span>
                      </div>
                      <div className="col-md-6 mb-3">
                      <label className="form-label">{t('web_lbl_new_password')}<small>*</small></label>

                        <div className="input-group cp">
                              <input
                                type={!newshow === true ? "text" : "password"}
                                id="newPassword"
                                name="newPassword"
                                className={"form-control" + (errors.newPassword && touched.newPassword ? " is-invalid state-invalid" : "")}
                                placeholder={t('web_lbl_enter_password')}
                                value={values.newPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <span
                                className="input-group-text"
                                onClick={() => {
                                  handleshow("newpwd");
                                }}
                              >
                                {!newshow === true ? (
                                  <i className="far fa-eye-slash"></i>
                                ) : (
                                  <i className="far fa-eye"></i>
                                )}
                              </span>
                            </div>
                            <span className="text-danger d-flex text-left">
                              {errors.newPassword &&
                                touched.newPassword &&
                                errors.newPassword}
                            </span>
                      </div>
                      <div className="col-md-6">
                      <label className="form-label">{t('web_lbl_confirm_new_password')}<small>*</small></label>

                        <div className="input-group cp">
                              <input
                                type={
                                  !conformshow === true ? "text" : "password"
                                }
                                id="confirmPassword"
                                name="confirmPassword"
                                className={"form-control" + (errors.confirmPassword && touched.confirmPassword ? " is-invalid state-invalid" : "")}
                                placeholder={t('web_lbl_enter_confirm_password')}
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                autoComplete="off"
                              />
                              <span
                                className="input-group-text"
                                onClick={() => {
                                  handleshow("confirepwd");
                                }}
                              >
                                {!conformshow === true ? (
                                  <i className="far fa-eye-slash"></i>
                                ) : (
                                  <i className="far fa-eye"></i>
                                )}
                              </span>
                            </div>
                            <span className="text-danger d-flex text-left">
                              {errors.confirmPassword &&
                                touched.confirmPassword &&
                                errors.confirmPassword }
                            </span>
                      </div>
                      <div className="col-md-12 mt-4">
                      <button className="btn btn-primary" type="submit">{t('web_heading_change_password')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          )}
        </Formik>
    </>
  );
};

export default UserChangePassword;