import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-transitions.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-autoplay.css';
import 'lightgallery/css/lg-rotate.css';
import 'lightgallery/css/lg-fullscreen.css';
import { useTranslation } from 'react-i18next';
import { formateDate } from "../../../utils/commonfunction";
import CustomPagination from "../../admin/common/custompagination";
import { InvitationList } from "../../user/services/lead.services";


const LeadCoordinatorInvitationList = () => {
  const params = useParams();
  const [list, setList] = useState([]);
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(9);
  const [page, setPage] = useState(1);
  const { t} = useTranslation();


  useEffect(() => {
    const formData = new FormData()
    formData.append("page", params.pgno)
    formData.append("per_page", itemperpage)
    InvitationList(formData).then(res => {
      if (res && res.success) {
        setList(res?.data?.list);
        setDataLength(res?.data?.total_records);
        setPage(res && res?.data && res?.data?.page ? res?.data?.page : 1);
      } else {
      }
    }).catch(err => console.log("err", err))
  }, [page,itemperpage,params.pgno])

  return (
      <div className="row">
        <div className="col-md-9 m-auto">
        <h3 className="dash-title">{t('web_heading_invitation_list')}</h3>
          <div className="dash-box-shadow">
          <div className="row">
              {list?.length ? (list.map((item, index) => (
                  <div className="col-md-4 my-2" key={index}>
                    <div className="grid-box">
                      <h3 className="text-capitalize">{item?.user_data?.name}</h3>
                      <strong>{item?.event_data?.title}</strong>
                      <div className="date-info">
                        <i className="ri-calendar-event-line"></i> 
                        {formateDate(item?.event_data?.start_date)}
                      </div>
                      <div className="date-info">
                        <i className="ri-calendar-event-line"></i> 
                        {formateDate(item?.event_data?.end_date)}
                      </div>
                      <p> 
                        {item?.status === 0 && (t('pending'))}
                        {item?.status === 1 && (t('accepted'))}
                        {item?.status === 2 && (t('rejected'))}
                      </p>
                      {/* <div className="action-tag mt-4">
                      <Link to={`/event-detail/${item?.event_id}?email=${item?.email}&invitation_id=${item?._id}`} className="btn-s green-btn cp">{t('web_lbl_button_view')}</Link>
                      </div> */}
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-md-12 my-2">
                  <div className="grid-box text-center">
                  {t('web_lbl_no_records')}
                  </div>
                </div>
              )}
              </div>
              {datalength && datalength > 0 ? (
              <div className="mt-4 text-right pagination-main d-flex justify-content-end  pe-4">
               <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "Sharemedia", path: "/lead-coordinator/settings/invitation-list" }]} />
             </div> ) : (
                ""
              )}
          </div>
        </div>
      </div>
  );
};
export default LeadCoordinatorInvitationList;