import React, { useEffect, useState } from "react";
import {
  TrimText,
  createMeta,
  formatDateFromTime,
  globalLoader,
  setTitle,
} from "../../../../utils/commonfunction";
import { cmsDetails } from "../../../user/services/common.services";
import {useNavigate } from "react-router-dom";
import { Count,RecentLeads } from "../../../user/services/lead.services";
import {useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const BackOfficeHome = () => {
  const [data, setData] = useState({});
  const [showCount, setShowCount] = useState({});
  const navigate = useNavigate();
  const [recentLeads, setRecentLeads] = useState([]);
	const [recentDraftLeads, setRecentDraftLeads] = useState([]);
  const { t } = useTranslation();
  let reducerData = useSelector((state) => state.manageLmBackOffice);

  useEffect(() => {
    globalLoader(true);
    cmsDetails("lm-users-home")
      .then((response) => {
        setData(response?.data);
        setTitle(response?.data?.title);
        createMeta(response?.data);
        globalLoader(false);
      })
      .catch((error) => {
        console.log("error of terms&conditions", error);
        globalLoader(false);
      });
  }, []);

  useEffect(() => {
    globalLoader(true);
    Count(reducerData)
      .then((response) => {
        setShowCount(response && response.data ? response.data : {});
        globalLoader(false);
      })
      .catch((error) => {
        console.log("error ====> ", error);
        globalLoader(false);
      });
  }, [reducerData]);

  useEffect(() => {
		RecentLeads(reducerData,false).then((response) => {
			setRecentLeads(response && response.data && response.data.list ? response.data.list : []);
		}).catch(error => {
			console.log("error ====> ", error);
		});
		RecentLeads(reducerData,true).then((response) => {
			setRecentDraftLeads(response && response.data && response.data.list ? response.data.list : []);
		}).catch(error => {
			console.log("error ====> ", error);
		});
	}, []);

  const handleDraftLeadsClick = () => {
    const to = `/back-office/leads/list/1`;
    const state = { typeFilter: "true" };
    navigate(to, { state });
  };

  return (
    <>
      <div className="wraper-inner cpt bg-grey">
        <section className="inner-space pt-0">
          <div className="w-100 px-3">
            <div className="row home-content">
              <div className="col-md-9 m-auto ">
                <h3 className="inner-title mb-0">{t('web_lbl_home')}</h3>
                <div className="row row-sm animation_fade">
                  <div className="col-sm-12 col-lg-12 col-xl-12">
                    <div className="row row-sm">
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3 my-2">
                        <div className="card custom-card cp">
                          <div className="card-body">
                            <div
                              className="card-item cp"
                              onClick={() => {
                                navigate(`/back-office/leads/list/1`);
                              }}
                            >
                              <div className="card-item-title mb-2">
                                <label className="main-content-label tx-13 font-weight-bold mb-1 cp">
                                  {/* Recent Leads */}
                                  {t('web_lbl_recent_leads')}
                                </label>
                              </div>
                              <div className="card-item-body">
                                <div className="card-item-stat">
                                  <h4 className="font-weight-bold">
                                    {showCount && showCount.confirmed
                                      ? showCount.confirmed
                                      : "0"}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6 col-lg-6 col-xl-3 my-2">
                        <div className="card custom-card cp">
                          <div className="card-body">
                            <div
                              className="card-item"
                              onClick={() => handleDraftLeadsClick()}
                            >
                              <div className="card-item-title mb-2">
                                <label className="main-content-label tx-13 font-weight-bold mb-1 cp">
                                  {/* Draft Leads
                                   */}
                                    {t('web_lbl_draft_leads')}
                                </label>
                              </div>
                              <div className="card-item-body">
                                <div className="card-item-stat">
                                  <h4 className="font-weight-bold">
                                    {showCount && showCount.drafted
                                      ? showCount.drafted
                                      : "0"}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row row-sm animation_fade">
                        <div className="col-lg-6 my-2">
                          <div className="card custom-card mg-b-20">
                            <div
                              className="card-body"
                              style={{ height: "351px" }}
                            >
                              <div className="d-flex">
                                <div>
                                  <label className="main-content-label mb-4">
                                  {t('web_lbl_recent_leads')}
                                  </label>
                                </div>
                                <button
                                  className="btn  btn-primary"
                                  onClick={() => {
                                    navigate(`/back-office/leads/list/1`);
                                  }}
                                  style={{
                                    position: "absolute",
                                    bottom: "10px",
                                    right: "10px",
                                    top:"5px"
                                  }}
                                >
                                  {/* View All
                                   */}
                                  {t('web_lbl_view_all')}
                                </button>
                              </div>
                              <div className="table-responsive tasks">
                                <table className="table card-table table-vcenter text-nowrap mb-0 border">
                                  <thead>
                                    <tr>
                                      <th className="wd-lg-10p">{t('web_lbl_name')}</th>
                                      <th className="wd-lg-20p">{t('web_lbl_email')}</th>
                                      <th className="wd-lg-20p">{t('web_lbl_created_by')}</th>
                                      <th className="wd-lg-20p">
                                      {t('web_lbl_created_at')}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
												{recentLeads.length ?
													recentLeads.map((item, index) => (
														<tr key={index}>
															<td>{item?.name ? TrimText(item.name,20) : "N/A"}</td>
															<td>{item?.email ? TrimText(item.email, 20) : "N/A"}</td>
                              <td>{item?.creator_data?.name ? item?.creator_data?.name : "N/A"}</td>
															<td>{item?.createdAt ? formatDateFromTime(item?.createdAt) : "N/A"}</td>
														</tr>
													)) : (
														<tr>
															<td colSpan={4} className="text-center">
                              {t('web_lbl_no_records')}
															</td>
														</tr>
													)}
											</tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6 my-2">
                          <div className="card custom-card mg-b-20">
                            <div
                              className="card-body"
                              style={{ height: "351px" }}
                            >
                              <div className="d-flex">
                                <div>
                                  <label className="main-content-label mb-4">
                                    {/* Recent Draft Leads
                                     */}
                                     {t('web_lbl_recent_draft_leads')}
                                  </label>
                                </div>
                                <button
                                  className="btn btn-primary"
                                  onClick={() => handleDraftLeadsClick()}
                                  style={{
                                    position: "absolute",
                                    bottom: "10px",
                                    right: "10px",
                                    top:"5px"
                                  }}
                                >
                                {t('web_lbl_view_all')}
                                </button>
                              </div>
                              <div className="table-responsive tasks">
                                <table className="table card-table table-vcenter text-nowrap mb-0 border">
                                  <thead>
                                    <tr>
                                      <th className="wd-lg-10p">{t('web_lbl_name')}</th>
                                      <th className="wd-lg-10p">{t('web_lbl_email')}</th>
                                      <th className="wd-lg-20p">{t('web_lbl_created_by')}</th>
                                      <th className="wd-lg-20p">
                                      {t('web_lbl_created_at')}
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
												{recentDraftLeads.length ?
													recentDraftLeads.map((item, index) => (
														<tr key={index}>
														  <td>{item?.name ? TrimText(item.name,20) : "N/A"}</td>
															<td>{item?.email ? TrimText(item.email, 20) : "N/A"}</td>
                              <td>{item?.creator_data?.name ? item?.creator_data?.name : "N/A"}</td>
															<td>{item?.createdAt ? formatDateFromTime(item?.createdAt) : "N/A"}</td>
														</tr>
													)) : (
														<tr>
															<td colSpan={4} className="text-center">
                              {t('web_lbl_no_records')}
															</td>
														</tr>
													)}
											</tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                <div
                  className="mt-4"
                  style={{ minHeight: "70vh" }}
                  dangerouslySetInnerHTML={{
                    __html: `${
                      data && data?.description ? data?.description : "N/A"
                    }`,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default BackOfficeHome;
