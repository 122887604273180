import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import * as eventServices from '../../services/events.services';
import { formateDate,  decideRole, formateDateWithMonth } from '../../../../utils/commonfunction';
import Breadcrums from '../../common/breadcrumbs';
import { Rating } from "react-simple-star-rating";
import Loader from "../../common/loader";


const ViewLMUserRegistrationDetails = () => {
    const navigate = useNavigate()
    const params = useParams();
    const [showdefault, setShowDefault] = useState({});
    const location = useLocation();
    const { state } = location;
    const { event_id } = state || {};
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "View Event", url: "/admin/event-management/events/list/1" }, { title: "View LM User Registration", url: `/admin/event-management/events/view/event-lm-user-registration-list/${event_id}/1` }, { title: "View LM User Registration Info", url: "" }];

    useEffect(() => {
        const { LMUserRegistrationId } = params;
        if (LMUserRegistrationId) {
            eventServices.LMUserRegistrationDetails(LMUserRegistrationId)
                .then((response) => {
                    if (response?.success) {
                        setShowDefault(response.data);
                    }
                })
                .catch(err => console.log("err", err));
        }
    }, [params]);

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm viewregisration">
        <div className="col-lg-12 col-md-12">
          <div className="card custom-card main-content-body-profile">
            <div className="tab-content">
              <div
                className="main-content-body tab-pane p-4 border-top-0 active"
                id="about"
              >
                <div className="card-body p-0 p-0 rounded-10">
                  <h4 className="tx-25 text-uppercase mb-3">LM User Registration Detail</h4>
                  <div className="p-0">
                    {Object.keys(showdefault).length > 0 ? (
                      <>
                        <div className="mb-4">
                          <div className="border rounded p-4 d-flex flex-column flex-md-row align-items-start">
                            <div className="me-md-5">
                              <h4 className="tx-25 text-uppercase mb-3">
                                General Info
                              </h4>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  First Name:
                                </label>
                                <p className="mb-0 line-height-normal text-capitalize">
                                {showdefault?.user_data?.first_name
                                    ? showdefault.user_data.first_name
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Last Name:
                                </label>
                                <p className="mb-0 line-height-normal text-capitalize">
                                {showdefault?.user_data?.last_name
                                    ? showdefault.user_data.last_name
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Email:
                                </label>
                                <p className="mb-0 line-height-normal">
                                {showdefault?.user_data?.email
                                    ? showdefault.user_data.email
                                    : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Phone Number:
                                </label>
                                {showdefault?.user_data?.phone_number?.number
                                    ? showdefault?.user_data.phone_number?.number
                                    : "N/A"}
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                 Type:
                                </label>
                                {decideRole(showdefault?.user_data?.role)}
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Created Date:
                                </label>
                                {showdefault && showdefault.createdAt
                                  ? formateDate(showdefault.createdAt)
                                  : "N/A"}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mb-4">
                          <div className="border rounded p-4">
                            <h4 className="tx-15 text-uppercase mb-3">
                              About Event
                            </h4>
                            <div className="pt-10 pb-0">
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Event Title:
                                </label>
                                <p className="mb-0 line-height-normal">
                                  {showdefault?.event_data?.title ? showdefault?.event_data?.title : "N/A"}
                                </p>
                              </div>
                              <div className="d-flex align-items-start mb-1">
                                <label className="tx-14 font-weight-bold mb-0 me-2">
                                  Event Dates:
                                </label>
                                {showdefault?.event_data?.start_date && showdefault?.event_data?.end_date ? 
                                <p className="mb-0 line-height-normal">
                                  {showdefault?.event_data?.start_date
                                    ? formateDateWithMonth(
                                      showdefault?.event_data?.start_date
                                    )
                                    : "N/A"}{" "}
                                  -{" "}
                                  {showdefault?.event_data?.end_date
                                    ? formateDateWithMonth(
                                      showdefault?.event_data?.end_date
                                    )
                                    : "N/A"}
                                </p>
                                :
                                <p className="mb-0 line-height-normal">
                                  N/A
                              </p>
                                 }
                              </div>
                            </div>
                          </div>
                        </div>
                        {showdefault?.lm_questions_data && showdefault.lm_questions_data.length > 0 && (
                          <div className="mb-4">
                            <div className="border rounded p-4">
                              <h4 className="tx-15 text-uppercase mb-3">Extra Info</h4>
                              <div className="pt-10 pb-0">
                                {showdefault.lm_questions_data.map((questionItem, index) => (
                                  <div key={index} className="mb-1">
                                    <div className="d-flex align-items-center">
                                      <label className="tx-14 font-weight-bold mb-0 me-2">
                                        {questionItem?.question_title || "N/A"}:
                                      </label>
                                      <div className="mb-0 line-height-normal text-capitalize">
                                        {(() => {
                                          switch (questionItem.question_type) {
                                            // case "photo_upload":
                                            //   if (previewImages && previewImages.length > 0) {
                                            //     return (
                                            //       <div className="d-flex flex-wrap">
                                            //         {previewImages.map((imageUrl, idx) => (
                                            //           <div key={idx} className="m-1 position-relative">
                                            //             <img src={imageUrl} alt={`Preview ${idx}`} className="img-fluid" style={{ height:"100px" }} />
                                            //           </div>
                                            //         ))}
                                            //       </div>
                                            //     );
                                            //   }
                                            //   break;
                                            // case "voice_memo":
                                            //   return (
                                            //     <div className="m-1 position-relative">
                                            //       <audio key={index} src={questionItem?.file_url} controls />
                                            //     </div>
                                            //   );
                                            case "rating":
                                              return (
                                                <Rating
                                                  id={"dynamicQuestion" + index}
                                                  initialValue={questionItem?.answer}
                                                />
                                              );
                                            case "likeStatus":
                                              return (
                                                <>
                                                  {questionItem?.answer == "1" ? (
                                                    <i className="ri-thumb-up-fill cp ms-2" style={{ fontSize: "24px" }}></i>
                                                  ) : (
                                                    <i className="ri-thumb-down-fill cp ms-2" style={{ fontSize: "24px" }}></i>
                                                  )}
                                                </>
                                              );
                                            case "multiple_choice":
                                              return (
                                                <>
                                                  <p className="mb-0">
                                                    {(questionItem.answer && questionItem.answer.length >= 1) ? questionItem.answer.join(', ') : 'N/A'}
                                                  </p>
                                                </>
                                              );
                                            // default:
                                            //   if (questionItem.file_url && questionItem.answer) {
                                            //     return (
                                            //       <div className="m-1 position-relative">
                                            //         <img src={questionItem.file_url} alt={`Question Image`} className="img-fluid" style={{ height:"100px" }} />
                                            //       </div>
                                            //     );
                                            //   } 
                                              default:
                                                return (
                                                  <p className="mb-0">
                                                    {questionItem?.answer || "N/A"}
                                                  </p>
                                                );
                                          }
                                        })()}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <Loader />
                    )}
                  </div>
                  <div className="mt-5">
                    <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                      <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    );
};

export default ViewLMUserRegistrationDetails;

