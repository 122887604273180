import React, { useState } from 'react';
import { useLocation, useParams, Link, Outlet } from 'react-router-dom';
import Breadcrums from '../../common/breadcrumbs';


const EventsEdit = () => {
	const params = useParams();
	const loc = useLocation();
	const [id, setId] = useState();
	const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Events", url: "/admin/event-management/events/list/1" }, { title: "Edit", url: "" }];

	return (
		<>
			<Breadcrums data={breadcrumbs} />
			<div className="row row-sm">
				<div className="col-lg-12 col-md-12 animation_fade">
					<div className="card custom-card">
						<div className="card-body">
							<div className="profile-tab tab-menu-heading mb-4">
								<nav className="nav main-nav-line p-3 tabs-menu profile-nav-line bg-gray-100">
									<Link
										className={"nav-link linkactive " + (loc.pathname === `/admin/event-management/events/edit/${params.id}` ? " active" : "")}
										aria-current="page"
										to={`/admin/event-management/events/edit/${params.id}`}
									>
										Event
									</Link>
									<Link
										className={"nav-link linkactive " + (loc.pathname.includes(`/admin/event-management/events/edit/hotels/${params.id}`) ? " active" : "")}
										to={`/admin/event-management/events/edit/hotels/${params.id}/1`}
									>
										Hotels
									</Link>
									<Link
										className={"nav-link linkactive " + (loc.pathname.includes(`/admin/event-management/events/edit/agenda/${params.id}`) ? " active" : "")}
										to={`/admin/event-management/events/edit/agenda/${params.id}/1`}
									>
										Agenda
									</Link>
								</nav>
							</div>
							<Outlet context={[id, setId]} />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default EventsEdit