import React, { useState, useEffect } from "react";
import { Field, Formik } from "formik";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrums from "../../common/breadcrumbs";
import CustomCkeditor from "../../common/customeditor";
import {
  DRIP_CONTENT_EMAIL_OPTIONS,
  HOURS,
  SWAL_SETTINGS,
  TIME,
} from "../../../../utils/Constants";
import {
  copyText,
  getSessionKey,
  handleServerValidations,
  setSessionKey,
} from "../../../../utils/commonfunction";
import * as eventServices from "../../services/events.services";
import { Button, Modal } from "rsuite";
import * as Yup from "yup";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

const AddDripContent = (props) => {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [questionData, setQuestionData] = useState([]);
  const [dripOption, setDripOption] = useState([]);
  const params = useLocation();
  const [modal, setModal] = useState(false);
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "View Event", url: "/admin/event-management/events/list/1" },
    {
      title: "Drip Content",
      url: `/admin/event-management/events/view/event-drip-content/${params?.state}?page=1&count_page=1`,
    },
    { title: "Manage Drip Content", url: "" },
  ];

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionEmail, setSelectedOptionEmail] = useState(null);
  const [dripOptionEmails, setDripOptionEmails] = useState([]);
  const [statsupdate, setStatusUpdate] = useState("false");
  const [options] = React.useState(DRIP_CONTENT_EMAIL_OPTIONS);
  const [linkModal, setLinkModal] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");

  const findOptionByEmailTitle = (options, selectedTitle) => {
    for (let i = 0; i < options.length; i++) {
      const option = options[i];
      for (let j = 0; j < option.emails.length; j++) {
        const email = option.emails[j];
        if (email.title === selectedTitle) {
          return email;
        }
      }
    }
    return null;
  };

  // Function to handle chip click event and open modal
  const handleChipClick = (option, selectedTitle) => {
    const selectedOptionEmail = findOptionByEmailTitle(
      dripOptionEmails,
      selectedTitle
    );
    if (selectedOptionEmail) {
      setSelectedOptionEmail(selectedOptionEmail);
      setSelectedOption(option);
      setModal(true);
    } else {
      console.log("Option not found for title:", selectedTitle);
    }
  };

  const findOptionIndex = () => {
    let indexOfOption = -1;
    if (dripOptionEmails) {
      indexOfOption = dripOptionEmails.findIndex(
        (option) => option.option === selectedOption
      );
    }
    console.log(selectedOption, indexOfOption);
    return indexOfOption;
  };

  useEffect(() => {
    if (params?.state) {
      eventServices
        .DripContentDetails(params?.state)
        .then((response) => {
          if (response?.success) {
            setQuestionData(response?.data?.options);
            setDripOption(response?.data?.options?.options);
            setDripOptionEmails(
              response.data?.detail?.options
                ? response.data?.detail?.options
                : []
            );
            let defaultOptions = response.data?.detail?.options?.filter(
              (item) => item.option === "default"
            );
            console.log(defaultOptions, "default");
            if (defaultOptions && defaultOptions?.length) {
              let opArr = [];
              defaultOptions.forEach((op) => {
                opArr.push(op.option);
              });

              if (response?.data?.options?.dedicatedQuestionFound) {
                setDripOption((prev) => [...prev, ...opArr]);
              } else {
                setDripOption((prev) => [...opArr]);
              }
            }
          }
        })
        .catch((err) => console.log("err", err));
    }
  }, [params?.state, statsupdate]);

  const addEmailSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .test("no-spaces", "Title is required", (value) => value.trim()),
    description: Yup.string()
      .required("Description is required")
      .test("no-spaces", "Description is required", (value) => value.trim()),
    // schedule_time: Yup.string()
    //   .required("Schedule time is required")
    //   .test("no-spaces", "Schedule time is required", (value) => value.trim()),
    schedule_time_in_hours: Yup.string()
      .required("Schedule time is required")
      .test("no-spaces", "Schedule time is required", (value) => value.trim()),
    schedule_time_in_minutes: Yup.string()
      .required("Schedule time is required")
      .test("no-spaces", "Schedule time is required", (value) => value.trim()),
  });

  const addLinkSchema = Yup.object().shape({
    // link: Yup.string()
    //   .required("Link is required")
    //   .test("no-spaces", "Link is required", (value) => value.trim()),
    link: Yup.string()
      .required("Link is required")
      .test("Valid link", "Invalid link(please check url)", (value) => {
        try {
          new URL(value.trim());
          return true;
        } catch (error) {
          return false;
        }
      }),
    link_text: Yup.string()
      .required("Link text is required")
      .test("no-spaces", "Link text is required", (value) => value.trim()),
  });

  const SortableItem = SortableElement(({ record, option }) => {
    const rowStyle = {
      cursor: "grab",
      zIndex: 99999,
    };

    const tdStyle = {
      padding: "8px 12px",
    };

    return (
      <tr style={rowStyle}>
        <td style={tdStyle}>{record?.ind + 1}</td>
        <td style={tdStyle}>{record?.title}</td>
        <td style={tdStyle}>
          <button
            className="btn ripple btn-success"
            type="button"
            onClick={() => handleChipClick(option, record.title)}
          >
            Edit
          </button>
          <button
            className="btn ripple btn-secondary ms-2"
            type="button"
            onClick={() => deleteEmail(option, record.title)}
            // onClick={() => deleteEmail(option, record.title)}
          >
            Delete
          </button>
        </td>
      </tr>
    );
  });

  const SortableList = SortableContainer(({ data, onItemReorder, option }) => (
    <tbody>
      {data?.length > 0 ? (
        data.map((value, index) => (
          <SortableItem
            option={option}
            key={`item-${index}`}
            index={index}
            record={{ ...value, ind: index }}
            onItemReorder={onItemReorder}
          />
        ))
      ) : (
        <tr style={{ textAlign: "center" }}>
          <td colSpan="5">No Records</td>
        </tr>
      )}
    </tbody>
  ));

  const onSortEnd = ({ newIndex, oldIndex }) => {
    try {
      let arrInd = +getSessionKey("sortable_email_chain_index");
      const updatedData = arrayMove(
        dripOptionEmails[arrInd].emails,
        oldIndex,
        newIndex
      );
      let newData = [...dripOptionEmails];
      newData[arrInd].emails = updatedData;
      setDripOptionEmails(newData);
    } catch (err) {}
  };

  const deleteEmail = (option, title) => {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let optionIndex = -1;
        let options = [...dripOptionEmails];
        options.forEach((item, i) => {
          if (item.option === option) {
            optionIndex = i;
          }
        });
        let emailIndex = -1;
        if (optionIndex >= 0) {
          options[optionIndex].emails.forEach((item, i) => {
            if (item.title === title) {
              emailIndex = i;
            }
          });
          if (emailIndex >= 0) {
            options[optionIndex].emails.splice(emailIndex, 1);
          }
        }
        setDripOptionEmails(options);
      }
    });
  };

  const deleteDefaultOptionFunction = () => {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const formdata = new FormData();
        formdata.append("event_id", params?.state);
        eventServices
          .DeleteDefaultOption(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError");
          });
      }
    });
  };

  const handleClick = (text) => {
    copyText(text);
    Swal.fire({
      icon: "success",
      text: "Copied",
      ...SWAL_SETTINGS,
    });
  };

  const copyToClipboard = (text) => {
    copyText(text);
    Swal.fire({
      icon: "success",
      text: "Copied",
      ...SWAL_SETTINGS,
    });
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <Formik
        enableReinitialize
        initialValues={{
          title: "",
        }}
        // validate={(values) => {
        //   const error = {};
        //   if (!values.title || !values.title.trim())
        //     error.title = "Title is required";
        //   return error;
        // }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitted(true);
          const updatedData = dripOptionEmails.map((option) => {
            return {
              option: option.option,
              emails: option.emails.map((email) => {
                return {
                  ...email,
                };
              }),
            };
          });
          let formData = new FormData();
          //   formData.append("title", values.title);
          formData.append("event_id", params?.state);
          formData.append("question_set_id", questionData?.question_set_id);
          formData.append("question_id", questionData?._id);
          formData.append(
            "dedicatedQuestionFound",
            questionData?.dedicatedQuestionFound
          );
          formData.append("options", JSON.stringify(updatedData));
          eventServices
            .AddDrip(formData)
            .then((response) => {
              setSubmitting(false);
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
                setTimeout(() => {
                  resetForm({ values: "" });
                }, 2000);
                navigate(
                  `/admin/event-management/events/view/event-drip-content/${params?.state}?page=1&count_page=1`
                );
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
                setSubmitted(false);
              }
            })
            .catch((error) => {
              console.log("error ====> ", error);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row row-sm">
              <div className="col-lg-12 col-md-12 animation_fade">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">
                        Manage Drip Content
                      </h6>
                    </div>
                    <div className="row row-sm">
                      {!questionData?.dedicatedQuestionFound ? (
                        <div className="col-12">
                          <div
                            className="alert alert-danger rounded"
                            role="alert"
                          >
                            <i
                              className="fa fa-exclamation-triangle me-3"
                              aria-hidden="true"
                            ></i>
                            No dedicated question found. Please add default
                            email chain.
                          </div>
                        </div>
                      ) : null}
                      {dripOption?.length > 0 &&
                        dripOption.map((option, index) => (
                          <div key={index} className="row row-sm">
                            <div className="col-md-12 text-center form-group">
                              <label
                                htmlFor={`option-${index}`}
                                className="text-left d-flex"
                              >
                                Option {index + 1} &nbsp;&nbsp;
                                {questionData?.dedicatedQuestionFound &&
                                option === "default" ? (
                                  <>
                                    (
                                    <span className="text-danger">
                                      Default email chain will be ignored in
                                      this case
                                    </span>
                                    )
                                  </>
                                ) : (
                                  ""
                                )}
                                :
                                {questionData?.dedicatedQuestionFound &&
                                option === "default" ? (
                                  <>
                                    <button
                                      onClick={() => {
                                        deleteDefaultOptionFunction();
                                      }}
                                      className="cp text-danger ms-3"
                                      title="Delete this option"
                                      type="button"
                                    >
                                      <i className="ri-delete-bin-6-fill"></i>
                                    </button>
                                  </>
                                ) : (
                                  ""
                                )}
                              </label>
                              <div className="d-flex">
                                <input
                                  name={`option-${index}`}
                                  id={`option-${index}`}
                                  type="text"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={option}
                                  className="form-control"
                                  disabled
                                />
                                <button
                                  className="btn bg-dark btn-dark ml-2"
                                  type="button"
                                  style={{ width: "8%" }}
                                  onClick={() => {
                                    setModal(true);
                                    setSelectedOption(option);
                                  }}
                                >
                                  <i
                                    className="fa fa-plus pr-2"
                                    aria-hidden="true"
                                  ></i>
                                  Add Email
                                </button>
                              </div>
                            </div>
                            <div className="col-md-12 form-group">
                              {dripOptionEmails &&
                              dripOptionEmails?.length > 0 ? (
                                <>
                                  {dripOptionEmails.map((opt, optIndex) =>
                                    opt.option === option &&
                                    opt.emails.length > 0 ? (
                                      <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                        <thead>
                                          <tr>
                                            <th className="sr_head">S.No</th>
                                            <th>
                                              <div className="sorting_column">
                                                <span>Title</span>
                                              </div>
                                            </th>
                                            <th>
                                              <div className="sorting_column">
                                                <span>Actions</span>
                                              </div>
                                            </th>
                                          </tr>
                                        </thead>
                                        <SortableList
                                          data={opt.emails}
                                          option={option}
                                          // setData={setData}
                                          onSortEnd={onSortEnd}
                                          onSortStart={() => {
                                            setSessionKey(
                                              "sortable_email_chain_index",
                                              optIndex
                                            );
                                          }}
                                        />
                                      </table>
                                    ) : null
                                  )}
                                </>
                              ) : null}
                            </div>
                          </div>
                        ))}

                      <div className="">
                        <button
                          className="btn btn-main-primary signbtn mr-2"
                          type="submit"
                          disabled={submitted ? true : null}
                        >
                          <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                          Submit
                        </button>
                        <button
                          className="btn ripple btn-secondary"
                          type="button"
                          disabled={submitted ? true : null}
                          onClick={() => navigate(-1)}
                        >
                          <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>

      <Modal
        size={"lg"}
        className="invitation-modal modal-height-auto"
        open={modal}
        onClose={() => {
          setSelectedOption(null);
          setModal(false);
        }}
        backdrop={"static"}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Add Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                title: selectedOptionEmail?.title || "",
                description: selectedOptionEmail?.description || "",
                // schedule_time: selectedOptionEmail?.schedule_time || "",
                schedule_time_in_minutes:
                  selectedOptionEmail?.schedule_time_in_minutes || "",
                schedule_time_in_hours:
                  selectedOptionEmail?.schedule_time_in_hours || "",
              }}
              validateOnBlur={false}
              validationSchema={addEmailSchema}
              onSubmit={(values, { resetForm }) => {
                const newEmail = {
                  option: selectedOption,
                  title: values.title,
                  description: values.description,
                  // schedule_time: values.schedule_time,
                  schedule_time_in_minutes: values.schedule_time_in_minutes,
                  schedule_time_in_hours: values.schedule_time_in_hours,
                };

                let optionIndex = findOptionIndex();
                let updatedOptions;

                if (optionIndex === -1) {
                  updatedOptions = [
                    ...dripOptionEmails,
                    {
                      option: selectedOption,
                      emails: [newEmail],
                    },
                  ];

                  setDripOptionEmails(updatedOptions);
                } else {
                  if (selectedOptionEmail) {
                    let localDripOptionEmails = dripOptionEmails;
                    const selectedOptionEmailIndex = dripOptionEmails[
                      optionIndex
                    ].emails.findIndex(
                      (email) => email.title === selectedOptionEmail?.title
                    );
                    if (selectedOptionEmailIndex >= 0) {
                      localDripOptionEmails[optionIndex].emails[
                        selectedOptionEmailIndex
                      ] = newEmail;
                    }
                    console.log(
                      localDripOptionEmails,
                      "selectedOptionEmailIndex"
                    );
                  } else {
                    let emailsForOption =
                      dripOptionEmails[optionIndex].emails.slice();
                    emailsForOption.push(newEmail);
                    updatedOptions = dripOptionEmails.slice();
                    updatedOptions[optionIndex] = {
                      ...updatedOptions[optionIndex],
                      emails: emailsForOption,
                    };
                    setDripOptionEmails(updatedOptions);
                  }
                }

                setSelectedOption(null);
                setSelectedOptionEmail(null);
                setModal(false);
                resetForm();
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
              }) => (
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="row">
                    <div className="col-lg-12 text-center form-group mt-4">
                      <label className="text-left d-flex">
                        Title:<span className="requirestar">*</span>
                      </label>
                      <input
                        name="title"
                        id="title"
                        type="text"
                        className="form-control"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        {errors.title && touched.title && errors.title}
                      </span>
                    </div>
                    <div className="col-12">
                      <label
                        htmlFor={"option" + (options.length - 1)}
                        className="text-left d-flex"
                      >
                        Dynamic Options:
                        <span className="text-warning ms-2">
                          (Click to copy)
                        </span>
                        {/* <Whisper
                          placement="top"
                          controlId="control-id-hover"
                          trigger="hover"
                          speaker={
                            <Tooltip>
                              Short Codes to add User Personalized Details such
                              as Name and Salutation - Click to Copy and then
                              Paste
                            </Tooltip>
                          }
                        >
                          <span className="field-more-info mt-1 ms-1 cp">
                            ?
                          </span>
                        </Whisper> */}
                      </label>
                    </div>
                    <div className="col-lg-12 mb-2 ">
                      {options &&
                        options.map((item, index) => (
                          <span
                            onClick={() =>
                              handleClick("{" + item.toUpperCase() + "}")
                            }
                            key={index}
                            className="badge badge-dark my-2 me-2 cp"
                          >
                            {"{" + item.toUpperCase() + "}"}
                          </span>
                        ))}
                      <span
                        className="badge badge-primary my-2 me-2 cp"
                        onClick={() => setLinkModal(true)}
                      >
                        Generate Countable Link
                      </span>
                    </div>
                    <div className="col-lg-12 text-center form-group">
                      <label htmlFor="subject" className="text-left d-flex">
                        Description:<span className="requirestar">*</span>
                      </label>
                      <CustomCkeditor
                        fieldname={"description"}
                        setFieldValue={setFieldValue}
                        value={values.description}
                        setFieldTouched={setFieldTouched}
                      />
                      <span className="text-danger d-flex text-left">
                        {errors.description &&
                          touched.description &&
                          errors.description}
                      </span>
                    </div>
                    <div className="col-md-6 text-center form-group">
                      <label
                        htmlFor="schedule_time_in_hours"
                        className="text-left d-flex"
                      >
                        Schedule Time (Hours):
                        <span className="requirestar">*</span>{" "}
                      </label>
                      <div className="select-down-arrow">
                        <Field
                          as="select"
                          id="schedule_time_in_hours"
                          name={"schedule_time_in_hours"}
                          className="form-control select2"
                        >
                          <option value="" label="Select hours">
                            Select
                          </option>
                          {HOURS.length > 0 &&
                            HOURS.map((option, i) => {
                              return (
                                <option key={i} value={option.key}>
                                  {option.label}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                      <span className="text-danger d-flex text-left">
                        {errors.schedule_time_in_hours &&
                          touched.schedule_time_in_hours &&
                          errors.schedule_time_in_hours}
                      </span>
                    </div>
                    <div className="col-md-6 text-center form-group">
                      <label
                        htmlFor="schedule_time_in_minutes"
                        className="text-left d-flex"
                      >
                        Schedule Time (Minutes):
                        <span className="requirestar">*</span>{" "}
                      </label>
                      <div className="select-down-arrow">
                        <Field
                          as="select"
                          id="schedule_time_in_minutes"
                          name={"schedule_time_in_minutes"}
                          className="form-control select2"
                        >
                          <option value="" label="Select minutes">
                            Select
                          </option>
                          {TIME.length > 0 &&
                            TIME.map((option, i) => {
                              return (
                                <option key={i} value={option.key}>
                                  {option.label}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                      <span className="text-danger d-flex text-left">
                        {errors.schedule_time_in_minutes &&
                          touched.schedule_time_in_minutes &&
                          errors.schedule_time_in_minutes}
                      </span>
                    </div>
                  </div>
                  <Modal.Footer className="mt-4">
                    <Button
                      onClick={() => {
                        setSelectedOption(null);
                        setModal(false);
                      }}
                      appearance="subtle"
                    >
                      Close
                    </Button>
                    <Button
                      appearance="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        size={"sm"}
        className="link-modal"
        open={linkModal}
        onClose={() => {
          setLinkModal(false);
          setGeneratedLink("");
        }}
        backdrop={"static"}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Add Link</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                link: "",
                link_text: "",
                category: "",
              }}
              validateOnBlur={false}
              validationSchema={addLinkSchema}
              onSubmit={(values, { resetForm }) => {
                const category = values.category ? values.category : "default";
                const eventId = `${params.state}`;
                let url = values.link.trim();
                let title = values.link_text.trim();
                let urlObj = new URL(url);
                let params2 = new URLSearchParams(urlObj.search);
                params2.set("countable", "true");
                params2.set("countable_event_id", eventId);
                params2.set("countable_category", category);
                urlObj.search = params2.toString();
                let combinedValue = `<a href="${urlObj.toString()}" title="${title}">${title}</a>`;
                setGeneratedLink(combinedValue);
              }}
            >
              {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
              }) => (
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="row">
                    <div className="col-lg-12 text-center form-group mt-4">
                      <label className="text-left d-flex">
                        Link:<span className="requirestar">*</span>
                      </label>
                      <input
                        name="link"
                        id="link"
                        type="text"
                        className="form-control"
                        value={values.link}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        {errors.link && touched.link && errors.link}
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">
                        Link Text:<span className="requirestar">*</span>
                      </label>
                      <input
                        name="link_text"
                        id="link_text"
                        type="text"
                        className="form-control"
                        value={values.link_text}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        {errors.link_text &&
                          touched.link_text &&
                          errors.link_text}
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">Category:</label>
                      <input
                        name="category"
                        id="category"
                        type="text"
                        className="form-control"
                        value={values.category}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <Modal.Footer>
                    {generatedLink ? 
                    <div
                      className="w-100"
                    >
                      <label className="text-left d-flex">
                        Generated Link: 
                        <span className="text-warning ms-2">
                          (Click to copy)
                        </span>
                      </label>
                      <div className="generated-link cp" onClick={() => copyToClipboard(generatedLink)}>{generatedLink}</div>
                    </div>
                    :null}
                    <Button
                      className="mt-3"
                      onClick={() => {
                        setLinkModal(false);
                        setGeneratedLink("");
                      }}
                      appearance="subtle"
                    >
                      Close
                    </Button>
                    <Button
                      className="mt-3"
                      appearance="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Generate Link
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddDripContent;
