import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';
// import $ from 'jquery';
import Swal from "sweetalert2";
import * as authService from '../services/auth.services';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import Logo from '../../../../src/assets/admin/img/brand/icon.png'
import { handleServerValidations, setLocalKey } from '../../../utils/commonfunction';
import { SWAL_SETTINGS } from '../../../utils/Constants';
import * as portalDesignService from '../../admin/services/portal.design.services';

const Login = () => {
    const navigate = useNavigate();
    const [show, setShow] = useState(true)
    const [loader, setLoader] = useState(false)
    const [logo, setLogo] = useState('');

    const handleshow = () => {
        setShow(!show)
    }

    useEffect(() => {
        portalDesignService.Details().then((response) => {
            if (response.success) {
                setLogo(response.data.logo_transparent)
            }
        }).catch((error) => {
            console.log("error=====>", error)
        })
    },[])

    // $(document).ready(function(){
    //     if(getSessionKey("session_expired") && getSessionKey("session_expired") === "true"){
    //         Swal.fire({
    //             customClass: 'swal-wide',
    //             title: "Session expired",
    //             icon: 'error',
    //             timer: 1500,
    //             showConfirmButton: false,
    //             toast: true,
    //             position: 'top-right',
    //         });
    //         removeSessionKey("session_expired");
    //     }
    // });



    return (

        <>
            <Formik
                initialValues={{ email: '', password: '' }}
                validate={values => {
                    const errors = {};
                    if (!values.email) {
                        errors.email = 'Email is required';
                    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
                        errors.email = 'Invalid email address';
                    }
                    if (!values.password) {
                        errors.password = 'Password is required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setLoader(true)
                    let formData = new FormData();
                    formData.append("email", values.email);
                    formData.append("password", values.password);
                    authService.login(formData).then(response => {
                        if (response.success) {
                            setLocalKey("user", JSON.stringify(response.data))
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            setLoader(false)
                            navigate(`/admin/dashboard`)
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                            setTimeout(() => {
                                setLoader(false)
                            }, 2000);
                        }
                    }).catch(error => {
                        console.log("error: ", error);
                    })
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    // and other goodies
                }) => (
                    <div className='page main-signin-wrapper'>
                        <div className='innerbody'>
                            <div className="row signpages text-center">
                                <div className="col-md-12">
                                    <div className="card">
                                        <div className="row row-sm">
                                            <div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
                                                <div className="mt-5 pt-4 p-2 pos-absolute ">
                                                    <img src={logo} className="ht-120 mb-4 admin-logo" alt="logo" style={{height:"65px"}}/>
                                                    <div className="clearfix"></div>
                                                    <h5 className="mt-4 text-white">Login Your Account</h5>
                                                    <span className="tx-white-6 tx-13 mb-5 mt-xl-0">Signup to create, discover and connect with the global community</span>
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
                                                <div className="container-fluid">
                                                    <div className="row row-sm">
                                                        <div className="card-body mt-2 mb-2">
                                                            {/* <img src="../assets/img/brand/logo.png" className=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo" /> */}
                                                            <div className="clearfix"></div>
                                                            <form onSubmit={handleSubmit}>
                                                                <h5 className="text-left mb-2">Signin to Your Account</h5>
                                                                <p className="mb-4 text-muted tx-13 ml-0 text-left">Signin to create, discover and connect with the global community</p>
                                                                <div className="form-group text-left">
                                                                    <label>Email</label>
                                                                    <input className={"form-control" + (errors.email && touched.email ? " is-invalid state-invalid" : "")}
                                                                        placeholder="Enter your email"
                                                                        type="email"
                                                                        name="email"
                                                                        onChange={handleChange}
                                                                        onBlur={handleBlur}
                                                                        value={values.email}
                                                                        autoComplete="off" />
                                                                    <span className='text-danger text-left d-blockerr-spn'>{errors.email && touched.email && errors.email}</span>
                                                                </div>
                                                                <div className="form-group text-left">
                                                                    <label>Password</label>
                                                                    <div className="input-group">
                                                                        <input className={"form-control" + (errors.password && touched.password ? " is-invalid state-invalid" : "")}
                                                                            placeholder="Enter your password"
                                                                            type={!show === true ? "text" : "password"}
                                                                            name="password"
                                                                            onChange={handleChange}
                                                                            onBlur={handleBlur}
                                                                            value={values.password}
                                                                            autoComplete="off" />
                                                                        <span className='input-group-text cp' onClick={handleshow}>{(!show === true ? <i className="far fa-eye-slash"></i> : <i className="far fa-eye"></i>)}</span>
                                                                    </div>
                                                                    <span className='text-danger text-left d-block err-spn'>{errors.password && touched.password && errors.password}</span>
                                                                </div>
                                                                <button className={"btn ripple btn-main-primary btn-block signbtn" + (loader === true ? " disabled" : "")} type="submit"> 
                                                                    {loader === true ?
                                                                    <div className="spinner-border mt-2 text-white" role="status" style={{height:"15px",width:"15px",marginRight:"15px"}}>
                                                                        <span className="sr-only ">Loading...</span>
                                                                    </div> : ""}
                                                                    Sign In
                                                                </button>
                                                            </form>
                                                            <div className="text-left mt-5 ml-0">
                                                                <div className="mb-1"><Link to="/admin/forget-password">Forgot password?</Link></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
            </Formik>
        </>
    )
}
export default Login;