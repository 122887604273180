import axiosInstance from "../../../utils/axios";

const path = "admin/language-files";


export const Details = async (type,fileName) => {
    return await axiosInstance().get(`${path}/details?type=${type}&file_name=${fileName}`);
}

export const Edit = async (values) => {
    return await axiosInstance().post(`${path}/edit`, values);
}

export const LanguageKeys = async () => {
    return await axiosInstance().get(`${path}/new-keys`);
}
export const UpdateLanguageKeys = async (data) => {
    return await axiosInstance().post(`${path}/sync`, data);
}