import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import toHtml from "html-react-parser";
import Breadcrums from "../../../common/breadcrumbs";
import Loader from "../../../common/loader";
import { SpecialCharacter, capitalizeFirstLetterOfEachWord, formateDate, globalLoader, showStatus } from "../../../../../utils/commonfunction";
import * as pageServices from "../../../services/pages.services";
import QRCode from "react-qr-code";
import Swal from "sweetalert2";

const PageView = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Pages", url: "/admin/cms/pages/list/1" },
    { title: "View", url: "" },
  ];

  console.log("showdefault", showdefault);
  useEffect(() => {
    pageServices
      .Details(params.id)
      .then((data) => {
        setShowDefault(data && data.data ? data.data : []);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, []);

  const handleDownloadClick = async (e, fileURL) => {
    let fileName = "";
    if(fileURL){
        fileName = fileURL.replace(`${process.env.REACT_APP_SERVER_BASE_URL}/uploads/cms/`, "");
        e.preventDefault();
        globalLoader(true);
        try {
        const response = await fetch(fileURL);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const blobURL = URL.createObjectURL(blob);
    
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobURL;
        a.download = fileName;
    
        document.body.appendChild(a);
        a.click();
    
        document.body.removeChild(a);
        URL.revokeObjectURL(blobURL);
        globalLoader(false);
        } catch (error) {
        globalLoader(false);
        console.error('Error downloading the file:', error);
        Swal.fire({
            title: 'Download Error',
            text: `There was an error while downloading the file "${fileName}".`,
            icon: 'error',
        });
        }
    }else{
        console.log("Error in file downloading");
    }
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="main-content-label mb-3">View Cms</h6>
              </div>
              {showdefault && Object.keys(showdefault)?.length > 0 ? (
                <div className="row">
                  <div className="col-lg-12 form-group">
                    <table
                      id="simple-table"
                      className="table  table-bordered table-hover"
                    >
                      <tbody>
                        <tr>
                          <th>Name (En)</th>
                          <td className="text-capitalize">
                            {showdefault && showdefault?.name
                              ? showdefault.name
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Name (De)</th>
                          <td className="text-capitalize">
                            {showdefault && showdefault?.name_de
                              ? showdefault.name_de
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Title (En)</th>
                          <td className="text-capitalize">
                            {showdefault && showdefault?.title
                              ? showdefault.title
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Title (De)</th>
                          <td className="text-capitalize">
                            {showdefault && showdefault?.title_de
                              ? showdefault.title_de
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Slug</th>
                          <td>
                            {showdefault && showdefault.slug
                              ? showdefault.slug
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Type</th>
                          <td>
                            {showdefault && showdefault?.type
                              ? showdefault.type
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Menu Location</th>
                          <td>
                            {showdefault && showdefault.position
                              ? SpecialCharacter(capitalizeFirstLetterOfEachWord(showdefault.position))
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Meta Tags</th>
                          <td>
                            {showdefault && showdefault?.meta_tags
                              ? showdefault.meta_tags
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Meta Keyword</th>
                          <td>
                            {showdefault && showdefault?.meta_keyword
                              ? showdefault.meta_keyword
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Meta Description</th>
                          <td>
                            {showdefault && showdefault?.metadescription
                              ? showdefault.metadescription
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Created Date</th>
                          <td>
                            {showdefault && showdefault.createdAt
                              ? formateDate(showdefault.createdAt)
                              : "N/A"}
                          </td>
                        </tr>
                        <tr>
                          <th>Status</th>
                          <td>
                            {showdefault && showdefault?.status === 0
                              ? toHtml(showStatus(showdefault.status))
                              : toHtml(showStatus(showdefault.status))}
                          </td>
                        </tr>
                        <tr>
                          <th>Without Login</th>
                          <td className="text-capitalize">
                            {(showdefault && showdefault?.without_login === "true") || showdefault?.without_login === true ? "Yes" : "No"
                             }
                          </td>
                        </tr>
                        <tr>
                          <th>Generated Qr</th>
                          <td className="text-capitalize">
                          {(showdefault && showdefault?.generate_qr === "true") || showdefault?.generate_qr === true ? "Yes" : "No"
                             }
                          </td>
                        </tr>
                        <tr>
                          <th>Modified Date</th>
                          <td>
                            {showdefault && showdefault?.updatedAt
                              ? formateDate(showdefault.updatedAt)
                              : "N/A"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <h6 className="main-content-label mb-3">Description:</h6>
                    {/* <div className="">{toHtml(showdefault && showdefault.description ? showdefault.description : "N/A")}</div> */}
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          showdefault && showdefault?.description
                            ? showdefault.description
                            : "N/A",
                      }}
                    ></div>
                    <h6 className="main-content-label mb-3">Description German:</h6>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          showdefault && showdefault?.description_de
                            ? showdefault.description_de
                            : "N/A",
                      }}
                    ></div>
                    {showdefault?.generate_qr === true ? (
                      <div className="qr-code-admin btn-group">
                        <QRCode
                          size={256}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={showdefault?.qr_url}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {showdefault?.generate_qr === true ?    
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="First group"
                      style={{ marginLeft:"20px"}}
                    >
                      <button
                        type="button"
                        className="btn btn-dark"
                        href="#"
                        onClick={(e) =>
                          handleDownloadClick(e, showdefault?.qr_pdf)
                        }
                      >
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                    :
                    <></>
                    }
                    <div className="mt-5">
                      <button
                        className="btn ripple btn-dark"
                        type="button"
                        onClick={() => navigate(-1)}
                      >
                        <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                        Back
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageView;
