import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import toHtml from 'html-react-parser'
import * as senderEmailService from '../../../services/senderEmail.services';
import Breadcrums from '../../../common/breadcrumbs';
import Loader from '../../../common/loader'
import { formateDate, showStatus } from '../../../../../utils/commonfunction';


const ViewSenderEmail = (props) => {
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Dashboard", url: "/dashboard" }, { title: "Sender Emails ", url: "/admin/cms/sender-emails/list/1" }, { title: "View", url: "" },]


    useEffect(() => {
        senderEmailService.Details(params.id).then(response => {
            setShowDefault(response && response.data ? response.data : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [params.id])


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h6 className="main-content-label mb-3">View Sender Email </h6>
                            </div>
                            {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table  table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th>Email Name</th>
                                                <td>{showdefault && showdefault.email_name ? showdefault.email_name : "N/A"}</td>
                                            </tr>
                                       <tr>
                                                <th>Events</th>
                                               <td>{showdefault &&
                    showdefault.event_data &&
                    showdefault.event_data.length > 0
                      ? showdefault.event_data.map((item, index) => {
                          return (
                            <span
                              key={index}
                              className={
                                index === 0
                                  ? "badge badge-pill badge-primary-light text-capitalize"
                                  : "badge badge-pill badge-primary-light text-capitalize ms-2"
                              }
                            >
                              {item?.title}
                            </span>
                          );
                        })
                      : "N/A"}</td> 
                                            </tr>
                                            <tr>
                                                <th>Created Date</th>
                                                <td>{showdefault && showdefault.createdAt ? (formateDate(showdefault.createdAt)) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{showdefault && showdefault.status === 0 ? toHtml(showStatus(showdefault.status)) : toHtml(showStatus(showdefault.status))}</td>
                                            </tr>

                                            
                                        </tbody>
                                    </table>
                                    <div className="mt-5">
                                        <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                                            <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </div> : <Loader />}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewSenderEmail