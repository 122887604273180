import React, { useEffect, useRef, useState } from "react";
import { Formik, ErrorMessage } from "formik";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import * as eventsService from "../../services/events.services";
import CustomCkeditor from "../../common/customeditor";
import { SWAL_SETTINGS } from "../../../../utils/Constants";
import {
  handleServerValidations,
  hasPermission,
} from "../../../../utils/commonfunction";
import CustomDatetimepicker from "../../common/customDatetimepicker";
import * as Yup from "yup";
import Sorting from "../../common/sorting";
import Loader from "../../common/loader";
import CustomPagination from "../../common/custompagination";
import { Tooltip, Whisper } from 'rsuite';
import moment from "moment";


const Hotels = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [submitted, setSubmitted] = useState(false);
  const [previewimage, setPreviewImage] = useState([]);
  const [sorting, setSorting] = useState({});
  const [loader, setLoader] = useState(false);
  const [hotelList, setHotelList] = useState([]);
  const [serialno] = useState("");
  const [images, setImages] = useState([]);
  const [itemperpage] = useState(10);
  const [workHotel, setWorkHotel] = useState("");
  const [page, setPage] = useState(1);
  const [hotelId, setHotelId] = useState();
  const [saveAndNext, setSaveAndNext] = useState(false);
  const customCkeditorRef = useRef(null);
  const imageInputRef = useRef(null);
  const [datalength, setDataLength] = useState("");


  const formikRef = useRef();

  useEffect(() => {
    setWorkHotel('Add');
    getList();
  }, [page]);

  const getList = () => {
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", itemperpage);
    // formData.append("sort", JSON.stringify(sorting));
    formData.append("event_id", params.id);
    eventsService
      .ListHotels(formData)
      .then((response) => {
        setLoader(false);
        if (response?.success) {
          setHotelList(response.data.list);
          setDataLength(response?.data?.total_records);
          setPage(response && response.data && response.data.page ? response.data.page : 1);
        }
      })
      .catch(err => console.log("err", err))
  }

  const handleImageDelete = (arr, name, setFieldValue) => {
    const remainingimages = Object.values(arr).filter((i) => i.name !== name)
    setPreviewImage(remainingimages);
    setImages(remainingimages);
    setFieldValue('images', remainingimages)
  };

  const hotelsInitialValues = {
    hotelName: "",
    description: "",
    // description_de: "",
    images: "",
    hotelStartDate: "",
    hotelEndDate: "",
    cityTax: "",
    tax: "",
    breakfastTax: "",
    hotelTotalDays: []
  }

  const hotelsValidationSchema = Yup.object().shape({
    hotelName: Yup.string()
      .required("Hotel name is required")
      .test("no-spaces", "Hotel name is required", (value) => value.trim()), 
    description: Yup.string()
      .required("Description is required")
      .test("no-spaces", "Description is required", (value) => value.trim()),
    images: Yup.mixed()
      .required("Image is required")
      .test("fileType", "Unsupported file format", (value) => {
        if (value?.name) {
          if (value?.name?.includes(".jpg") || value?.name?.includes(".png") || value?.name?.includes(".jpeg") || value?.name?.includes(".gif") || value?.name?.includes(".webp")) {
            return true
          } else {
            return false
          }
        } else {
          return true;
        }
      }),
    hotelStartDate: Yup.string()
      .required("Start date is required")
      .test("no-spaces", "Start date is required", (value) => value.trim()),
    hotelEndDate: Yup.string()
      .required("End date is required")
      .test("no-spaces", "End date is required", (value) => value.trim()),
    cityTax: Yup.string()
      .strict(false)
      .trim()
      .matches(/^[0-9]+$/, "Decimal values is not allowed in city tax")
      .required("City tax is required"),
    tax: Yup.string()
      .strict(false)
      .trim()
      .matches(/^[0-9]+$/, "Decimal values is not allowed in tax")
      .required("Tax is required"),
    breakfastTax: Yup.string()
      .strict(false)
      .trim()
      .matches(/^[0-9]+$/, "Decimal values is not allowed in breakfast tax")
      .required("Breakfast tax is required"),

    hotelTotalDays: Yup.array().min(1, 'Array must have at least 1 item').of(Yup.object().shape({
      checkforSingleRoom: Yup.boolean(),
      checkforDoubleRoom: Yup.boolean(),
      totalQuantitySingleRoom: Yup.string()
        .strict(false).when("checkforSingleRoom", {
          is: (checkforSingleRoom) => checkforSingleRoom === true,
          then: () => Yup.string().strict(false)
            .trim()
            .required("Total quantity is required")
            .matches(/^[0-9]+$/, "Total quantity should be valid"),
          otherwise: () => Yup.string()
        }),
      // .trim().matches(/^[0-9]+$/, "Total quantity should be valid").required('Total quantity is required'),
      priceSingleRoom: Yup.string()
        .strict(false).when("checkforSingleRoom", {
          is: (checkforSingleRoom) => checkforSingleRoom === true,
          then: () => Yup.string().strict(false)
            .trim()
            .required("price is required")
            .matches(/^[0-9]+$/, "Decimal values is not allowed in price "),
          otherwise: () => Yup.string()
        }),
      // .trim().matches(/^[0-9]+$/, "price should be valid").required('price is required'),
      breakfastPriceSingleRoom: Yup.string()
        .strict(false).when("checkforSingleRoom", {
          is: (checkforSingleRoom) => checkforSingleRoom === true,
          then: () => Yup.string().strict(false)
            .trim()
            .required("Break fast price is required")
            .matches(/^[0-9]+$/, "Decimal values is not allowed in break fast price"),
          otherwise: () => Yup.string(),
        }),
      // .trim().matches(/^[0-9]+$/, "Break fast price should be valid").required('Break fast price is required'),
      totalQuantityDoubleRoom: Yup.string()
        .strict(false).when("checkforDoubleRoom", {
          is: (checkforDoubleRoom) => checkforDoubleRoom === true,
          then: () => Yup.string().strict(false)
            .trim()
            .required("Total quantity is required")
            .matches(/^[0-9]+$/, "Total quantity should be valid"),
          otherwise: () => Yup.string(),
        }),
      // .trim().matches(/^[0-9]+$/, "Total quantity should be valid").required('Total quantity is required'),
      priceDoubleRoom: Yup.string()
        .strict(false).when("checkforDoubleRoom", {
          is: (checkforDoubleRoom) => checkforDoubleRoom === true,
          then: () => Yup.string().strict(false)
            .trim()
            .required("price is required")
            .matches(/^[0-9]+$/, "price should be valid"),
          otherwise: () => Yup.string(),
        }),
      breakfastPriceDoubleRoom: Yup.string()
        .strict(false).when("checkforDoubleRoom", {
          is: (checkforDoubleRoom) => checkforDoubleRoom === true,
          then: () => Yup.string().strict(false)
            .trim()
            .required("Break fast price is required")
            .matches(/^[0-9]+$/, "Break fast price should be valid"),
          otherwise: () => Yup.string(),
        }),
    }))
  });


  const handleSort = (e, column) => {
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };

  const goToEdit = async (item) => {
    setWorkHotel('edit');
    formikRef.current.setFieldValue("hotelName", item.name);
    formikRef.current.setFieldValue("description", item.description);
    // customCkeditorRef?.current?.setValue(item.description, "editor100");
    // formikRef.current.setFieldValue("description_de", item.description_de)
    // customCkeditorRefTwo?.current?.setValue(item.description_de, "editor99");

    formikRef.current.setFieldValue("hotelStartDate", new Date(item.start_date));
    formikRef.current.setFieldValue("hotelEndDate", new Date(item.end_date));
    formikRef.current.setFieldValue("images", 'edit');
    setPreviewImage(item.gallery_images);
    setImages(item.gallery_images);
    formikRef.current.setFieldValue("cityTax", item.city_tax);
    formikRef.current.setFieldValue("tax", item.tax);
    formikRef.current.setFieldValue("breakfastTax", item.breakfast_tax);
    const newTotalHotel = [];
    var date1 = new Date(item.start_date);

    for (let i = 0; i < item.quota.length; i++) {
      var tomorrow = new Date(date1);

      const obj = {
        id: i + 1,
        totalQuantitySingleRoom: item.quota[i].single_room.total_quantity,
        priceSingleRoom: item.quota[i].single_room.price,
        breakfastPriceSingleRoom: item.quota[i].single_room.breakfast_price,
        totalQuantityDoubleRoom: item.quota[i].double_room.total_quantity,
        priceDoubleRoom: item.quota[i].double_room.price,
        breakfastPriceDoubleRoom: item.quota[i].double_room.breakfast_price,
        checkforSingleRoom: item.quota[i].single_room.is_available,
        checkforDoubleRoom: item.quota[i].double_room.is_available,
        _id: item.quota[i]._id,
        date: moment(tomorrow).format("DD-MM-YYYY")
      }
      newTotalHotel.push(obj)

      tomorrow.setDate(date1.getDate() + 1)
      date1 = tomorrow
    }
    formikRef.current.setFieldValue("hotelTotalDays", newTotalHotel)

    // const file = await getImageFileFromUrl(item.gallery_images[0]);
    // formikRef.current.setFieldValue("images", file)
    setHotelId(item._id);
  }

  const deleteFunction = (item) => {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        eventsService
          .DeleteHotel({ o_id: item._id })
          .then(res => {
            if (res && res.success) {
              Swal.fire({
                icon: 'success',
                text: res.message,
                ...SWAL_SETTINGS
              })
              getList();
            } else {
              Swal.fire({
                icon: 'error',
                text: handleServerValidations(res),
                ...SWAL_SETTINGS
              })
            }
          })
          .catch(err => console.log("err", err))
      }
    }).catch(err => console.log("err", err))
  }

  const onFormSubmit = (values, resetForm) => {
    setSubmitted(true);
    let formData = new FormData();
    formData.append("name", values.hotelName);
    formData.append("description", values.description);
    // formData.append("description_de", values.description_de);

    for (let i = 0; i < images.length; i++) {
      formData.append("gallery_images", images[i]);
    }

    formData.append("start_date", values.hotelStartDate);
    formData.append("end_date", values.hotelEndDate);
    formData.append("city_tax", values.cityTax);
    formData.append("tax", values.tax);
    formData.append("breakfast_tax", values.breakfastTax);
    formData.append("event_id", params.id);

    if (!hotelId) {

      const quota = [];
      for (let i = 0; i < values.hotelTotalDays.length; i++) {
        quota.push({
          "single_room": {
            total_quantity: values.hotelTotalDays[i].totalQuantitySingleRoom,
            price: values.hotelTotalDays[i].priceSingleRoom,
            breakfast_price: values.hotelTotalDays[i].breakfastPriceSingleRoom,
            is_available: values.hotelTotalDays[i].checkforSingleRoom
          },
          "double_room": {
            total_quantity: values.hotelTotalDays[i].totalQuantityDoubleRoom,
            price: values.hotelTotalDays[i].priceDoubleRoom,
            breakfast_price: values.hotelTotalDays[i].breakfastPriceDoubleRoom,
            is_available: values.hotelTotalDays[i].checkforDoubleRoom
          },
          // date: moment(values.hotelTotalDays[i].date).format("DD-MM-YYYY")
          date: values.hotelTotalDays[i].date
        })
      }
      formData.append(`quota`, JSON.stringify(quota));

      eventsService
        .AddHotel(formData)
        .then((response) => {
          if (response?.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            setWorkHotel('add');
            resetForm()
            setPreviewImage([])
            customCkeditorRef.current.setValue("", 'editor100')
            // customCkeditorRefTwo.current.setValue("")
            imageInputRef.current.value = ""
            if (saveAndNext) {
              setSaveAndNext(false);
              setTimeout(() => {
                // resetForm({ values: "" });
                navigate(`/admin/event-management/events/add/agenda/${params.id}`);
              }, 2000);
            } else {
              getList();
            }
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
            setSubmitted(false);
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    } else {
      formData.append("o_id", hotelId);

      const quota = [];
      for (let i = 0; i < values.hotelTotalDays.length; i++) {
        quota.push({
          "single_room": {
            total_quantity: values.hotelTotalDays[i].totalQuantitySingleRoom,
            price: values.hotelTotalDays[i].priceSingleRoom,
            breakfast_price: values.hotelTotalDays[i].breakfastPriceSingleRoom,
            is_available: values.hotelTotalDays[i].checkforSingleRoom
          },
          "double_room": {
            total_quantity: values.hotelTotalDays[i].totalQuantityDoubleRoom,
            price: values.hotelTotalDays[i].priceDoubleRoom,
            breakfast_price: values.hotelTotalDays[i].breakfastPriceDoubleRoom,
            is_available: values.hotelTotalDays[i].checkforDoubleRoom
          },
          _id: values.hotelTotalDays[i]._id,
          date: values.hotelTotalDays[i].date
          // date: moment(values.hotelTotalDays[i].date).format("DD-MM-YYYY")
        })
      }
      console.log("quota",quota);
      formData.append(`quota`, JSON.stringify(quota));

      eventsService
        .EditHotel(formData)
        .then((response) => {
          // setSubmitting(false);
          if (response?.success) {
            Swal.fire({
              icon: "success",
              text: response.message,
              ...SWAL_SETTINGS,
            });
            resetForm();
            setPreviewImage([]);
            customCkeditorRef.current.setValue("", 'editor100');
            imageInputRef.current.value = ""
            if (saveAndNext) {
              setSaveAndNext(false);
              setTimeout(() => {
                // resetForm({ values: "" });
                navigate(`/admin/event-management/events/add/agenda/${params.id}`,);
              }, 2000);
            } else {
              getList()
            }
          } else {
            Swal.fire({
              icon: "error",
              text: handleServerValidations(response),
              ...SWAL_SETTINGS,
            });
            setSubmitted(false);
          }
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }

  return (
    <>
      <Formik
        validateOnBlur={false}
        innerRef={formikRef}
        enableReinitialize
        initialValues={hotelsInitialValues}
        validationSchema={hotelsValidationSchema}
        onSubmit={(values, { resetForm }) => {
          setWorkHotel('Add');
          onFormSubmit(values, resetForm)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="table-responsive">
                <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                  <thead>
                    <tr>
                      <th className="sr_head">S.No</th>
                      <th>
                        <div className="sorting_column">
                          <span>Name</span>
                          <Sorting
                            sort={sorting}
                            handleSort={handleSort}
                            column="title"
                          />
                        </div>
                      </th>
                      <th>City tax</th>
                      <th>Tax %</th>
                      <th>Breakfast tax %</th>
                      <th className="action_head">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loader ? (
                      <tr>
                        <td colSpan={7}>
                          <Loader />
                        </td>
                      </tr>
                    ) : (
                      <>
                        {hotelList.length ? (
                          hotelList.map((row, i) => {
                            return (
                              <tr key={i}>
                                <td>{serialno + (i + 1)}</td>
                                <td>{row.name ? row.name : "N/A"}</td>
                                <td>{row.city_tax ? row.city_tax : "N/A"}</td>
                                <td>{row.tax ? row.tax : "N/A"}</td>
                                <td>{row.breakfast_tax ? row.breakfast_tax : "N/A"}</td>
                                <td>
                                  <div className="d-flex">
                                    <button
                                      type="button"
                                      className="btn ripple btn-success mx-1"
                                      onClick={() => {
                                        goToEdit(row);
                                      }}
                                    >
                                      Edit
                                    </button>
                                    {hasPermission('/admin/event-management/events/edit/hotels/delete') ?
                                      <button
                                        type="button"
                                        className="btn ripple btn-secondary"
                                        onClick={() => {
                                          deleteFunction(row);
                                        }}
                                      >
                                        Delete
                                      </button>
                                      : null}
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr>
                            <td colSpan={7} className="text-center">
                              No records
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              <div
                className=""
                id="example1_info"
                role="status"
                aria-live="polite"
              >
                <b>Total Records : {datalength ? datalength : "0"}</b>
              </div>
              {datalength && datalength > 0 ? (
                <CustomPagination
                  datalength={datalength}
                  itemperpage={itemperpage}
                  currentPage={page}
                  setPage={setPage}
                  pageRoute={[
                    {
                      name: "Events",
                      path: `/admin/event-management/events/edit/hotels/${params.id}`,
                    },
                  ]}
                />
              ) : (
                ""
              )}

              <hr />
              <h6 className="main-content-label mb-3">{workHotel === 'edit' ? "Edit Hotel" : "Add Hotel"}</h6>
              <div className="col-md-12 text-center form-group">
                {/* <h4 style={{textAlign: "left"}} className="pb-3">Add a new hotel</h4> */}
                <label htmlFor="hotelName" className="text-left d-flex">
                  Hotel name :<span className="requirestar">*</span>{" "}
                </label>
                <input
                  name="hotelName"
                  id="hotelName"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.hotelName}
                  className="form-control"
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"hotelName"} />
                </span>
              </div>

              <div className='col-lg-12 text-center form-group'>
                <label htmlFor='description' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                {
                  workHotel === "edit" ?
                    <>
                      <CustomCkeditor
                        name={"editor100"}
                        fieldname={"description"}
                        setFieldValue={setFieldValue}
                        value={values?.description}
                        setFieldTouched={setFieldTouched}
                        ref={customCkeditorRef}
                      /></>

                    : <CustomCkeditor
                      fieldname={"description"}
                      setFieldValue={setFieldValue}
                      value={values.description}
                      ref={customCkeditorRef}
                    />
                }
                <span className='text-danger d-flex text-left'>{touched.description && errors.description}</span>
              </div>

              {/* <div className='col-lg-12 text-center form-group'>
                <label htmlFor='subject' className='text-left d-flex'>Description In German:</label>
                <CustomCkeditor
                  name={"editor99"}
                  fieldname={"description_de"}
                  setFieldValue={setFieldValue}
                  value={values.description_de}
                  setFieldTouched={setFieldTouched}
                  ref={customCkeditorRefTwo}
                />
                <span className='text-danger d-flex text-left'>{touched.description_de && errors.description_de}</span>
              </div> */}

              <div className="col-md-12 text-center form-group">
                <label htmlFor="images" className="text-left d-flex">
                  Image gallery :<span className="requirestar">*</span>{" "}
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Recommended Size (1290x486)</Tooltip>}>
                    <span className='field-more-info mt-1 ms-1 cp'>?</span>
                  </Whisper>
                </label>
                <input
                  ref={imageInputRef}
                  className="form-control h-auto"
                  id="images"
                  name="images"
                  accept="image/*"
                  type="file"
                  multiple={true}
                  onChange={(event) => {
                    setPreviewImage(Object.values(event.target.files));
                    setImages(Object.values(event.target.files));
                    setFieldValue('images', Object.values(event.target.files));
                  }}
                />
                <span
                  className="text-danger d-flex text-left"
                  id="errortext"
                >
                  {touched.images && errors.images}
                </span>
              </div>
              <div className='mt-3 file_preview_container'>
                {previewimage.map(item => (
                  <div className="file_preview_wrapper mb-4">
                    {workHotel === 'edit' ? (
                      <></>
                    ) : (
                      <span className='file_preview_close' onClick={() => handleImageDelete(previewimage, item.name, setFieldValue)}>
                        <i className="fa fa-times" aria-hidden="true"></i>
                      </span>
                    )}

                    <img src={item.name ? URL.createObjectURL(item) : item} style={{ height: "100px" }} alt="Preview" className="preview-image mx-2" />
                  </div>
                )
                )}
              </div>

              <div className="col-md-6 text-center form-group">
                <label htmlFor="hotelStartDate" className="text-left d-flex">
                  Start date :<span className="requirestar">*</span>{" "}
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>These define the earliest possible check-in date and the latest possible check-out date for the hotel quota.</Tooltip>}>
                    <span className='field-more-info mt-1 ms-1 cp'>?</span>
                  </Whisper>
                </label>
                <CustomDatetimepicker
                  disabled={workHotel === "edit" ? true : false}
                  placeholder={"Start date"}
                  setField={setFieldValue}
                  fieldname={`hotelStartDate`}
                  setFieldTouched={setFieldTouched}
                  // setHotelTotalDays={setHotelTotalDays}
                  formikRef={formikRef}
                  value={values.hotelStartDate}
                  correspondingEndDate={formikRef.current?.values?.hotelEndDate}
                  correspondingEndDateName={'hotelEndDate'}
                  endDateValue={values.hotelEndDate}
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"hotelStartDate"} />
                </span>
              </div>

              <div className="col-md-6 text-center form-group">
                <label htmlFor="hotelEndDate" className="text-left d-flex">
                  End date :<span className="requirestar">*</span>{" "}
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>These define the earliest possible check-in date and the latest possible check-out date for the hotel quota.</Tooltip>}>
                    <span className='field-more-info mt-1 ms-1 cp'>?</span>
                  </Whisper>
                </label>
                <CustomDatetimepicker
                  disabled={workHotel === "edit" ? true : false}
                  placeholder={"End date"}
                  setField={setFieldValue}
                  fieldname={`hotelEndDate`}
                  setFieldTouched={setFieldTouched}
                  // setHotelTotalDays={setHotelTotalDays}
                  formikRef={formikRef}
                  value={values.hotelEndDate}
                  endDate={true}
                  correspondingStartDate={formikRef?.current?.values?.hotelStartDate}
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"hotelEndDate"} />
                </span>
              </div>

              <div className="col-md-4 text-center form-group">
                <label htmlFor="cityTax" className="text-left d-flex">
                  City tax:<span className="requirestar">*</span>{" "}
                  <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>In locations without city tax, please enter 0.</Tooltip>}>
                    <span className='field-more-info mt-1 ms-1 cp'>?</span>
                  </Whisper>
                </label>
                <input
                  name="cityTax"
                  id="cityTax"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cityTax}
                  className="form-control"
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"cityTax"} />
                </span>
              </div>

              <div className="col-md-4 text-center form-group">
                <label htmlFor="tax" className="text-left d-flex">
                  Tax %:<span className="requirestar">*</span>{" "}
                </label>
                <input
                  name="tax"
                  id="tax"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tax}
                  className="form-control"
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"tax"} />
                </span>
              </div>

              <div className="col-md-4 text-center form-group">
                <label htmlFor="breakfastTax" className="text-left d-flex">
                  Breakfast tax %:<span className="requirestar">*</span>{" "}
                </label>
                <input
                  name="breakfastTax"
                  id="breakfastTax"
                  type="number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.breakfastTax}
                  className="form-control"
                />
                <span className="text-danger d-flex text-left">
                  <ErrorMessage name={"breakfastTax"} />
                </span>
              </div>

              {values.hotelTotalDays.map((item, index) => (<>
                <h4>Quota</h4>
                <h5>{item.date.split("T")[0]}</h5>
                <div className="col-md-12 text-center form-group">
                  <div className="d-flex justify-content-start align-items-start" >
                    {/* <i className="fa fa-check-circle pb-2" aria-hidden="true"></i> */}
                    <div className="d-flex align-items-center" style={{ height: 38, width: 140 }}>
                      <input
                        key={"checkforSingleRoom" + item?.id?.toString()}
                        type="checkbox"
                        className="mr-2"
                        defaultChecked={item.checkforSingleRoom}
                        value={item.checkforSingleRoom}
                        style={{ borderRadius: 50 }}
                        onChange={(event) => {
                          setFieldValue(`hotelTotalDays[${index}].checkforSingleRoom`, event.target.checked)
                        }}
                      />
                      <div className="flex-grow-1">
                        <p
                          key={"checkforSingleRoomp" + item?.id?.toString()}
                          className="fs-6 m-0">
                          Single room
                        </p>
                      </div>
                    </div>

                    {values.hotelTotalDays[index].checkforSingleRoom && (
                      <>
                        <div className="col-md-2 text-center form-group" >
                          <input
                            key={"totalQuantitySingleRoom" + item?.id?.toString()}
                            placeholder="Total quantity"
                            name={`hotelTotalDays[${index}].totalQuantitySingleRoom`}
                            id={`hotelTotalDays[${index}].totalQuantitySingleRoom`}
                            type="number"
                            onChange={(event) => setFieldValue(`hotelTotalDays[${index}].totalQuantitySingleRoom`, event.nativeEvent.target.value)}
                            onBlur={handleBlur}
                            value={item.totalQuantitySingleRoom}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={`hotelTotalDays[${index}].totalQuantitySingleRoom`} />
                          </span>
                        </div>

                        <div className="col-md-2 text-center form-group">
                          <input
                            key={"priceSingleRoom" + item?.id?.toString()}
                            placeholder="Price"
                            name={`hotelTotalDays[${index}].priceSingleRoom`}
                            id={`hotelTotalDays[${index}].priceSingleRoom`}
                            type="number"
                            onChange={(event) => setFieldValue(`hotelTotalDays[${index}].priceSingleRoom`, event.nativeEvent.target.value)}
                            onBlur={handleBlur}
                            value={item.priceSingleRoom}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={`hotelTotalDays[${index}].priceSingleRoom`} />
                          </span>
                        </div>

                        <div className="col-md-2 text-center form-group">
                          <input
                            key={"breakfastPriceSingleRoom" + item?.id?.toString()}
                            placeholder="Breakfast price"
                            name={`hotelTotalDays[${index}].breakfastPriceSingleRoom`}
                            id={`hotelTotalDays[${index}].breakfastPriceSingleRoom`}
                            type="number"
                            onChange={(event) => setFieldValue(`hotelTotalDays[${index}].breakfastPriceSingleRoom`, event.nativeEvent.target.value)}
                            onBlur={handleBlur}
                            value={item.breakfastPriceSingleRoom}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={`hotelTotalDays[${index}].breakfastPriceSingleRoom`} />
                          </span>
                        </div>
                      </>)}
                  </div>
                </div>
                <div className="col-md-12 text-center form-group">
                  <div className="d-flex justify-content-start align-items-start">
                    <div className="d-flex align-items-center" style={{ height: 38, width: 140 }}>
                      <input
                        key={"checkforDoubleRoom" + item.id.toString()}
                        type="checkbox"
                        className="mr-2"
                        defaultChecked={item.checkforDoubleRoom}
                        value={item.checkforDoubleRoom}
                        onChange={(event) => {
                          setFieldValue(`hotelTotalDays[${index}].checkforDoubleRoom`, event.target.checked)
                        }} />
                      <div className="flex-grow-1">
                        <p
                          key={"checkforDoubleRoom" + item?.id?.toString()}
                          className="fs-6 m-0">
                          Double room
                        </p>
                      </div>
                    </div>
                    {values.hotelTotalDays[index].checkforDoubleRoom && (
                      <>
                        <div className="col-md-2 text-center form-group ">
                          <input
                            key={"totalQuantityDoubleRoom" + item?.id?.toString()}
                            placeholder="Total quantity"
                            name={`hotelTotalDays[${index}].totalQuantityDoubleRoom`}
                            id={`hotelTotalDays[${index}].totalQuantityDoubleRoom`}
                            type="number"
                            onChange={(event) => setFieldValue(`hotelTotalDays[${index}].totalQuantityDoubleRoom`, event.nativeEvent.target.value)}
                            onBlur={handleBlur}
                            value={item.totalQuantityDoubleRoom}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={`hotelTotalDays[${index}].totalQuantityDoubleRoom`} />
                          </span>
                        </div>

                        <div className="col-md-2 text-center form-group">
                          <input
                            key={"priceDoubleRoom" + item?.id?.toString()}
                            placeholder="Price"
                            name={`hotelTotalDays[${index}].priceDoubleRoom`}
                            id={`hotelTotalDays[${index}].priceDoubleRoom`}
                            type="number"
                            onChange={(event) => setFieldValue(`hotelTotalDays[${index}].priceDoubleRoom`, event.nativeEvent.target.value)}
                            onBlur={handleBlur}
                            value={item.priceDoubleRoom}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={`hotelTotalDays[${index}].priceDoubleRoom`} />
                          </span>
                        </div>

                        <div className="col-md-2 text-center form-group">
                          <input
                            key={"breakfastPriceDoubleRoom" + item?.id?.toString()}
                            placeholder="Breakfast price"
                            name={`hotelTotalDays[${index}].breakfastPriceDoubleRoom`}
                            id={`hotelTotalDays[${index}].breakfastPriceDoubleRoom`}
                            type="number"
                            onChange={(event) => setFieldValue(`hotelTotalDays[${index}].breakfastPriceDoubleRoom`, event.nativeEvent.target.value)}
                            onBlur={handleBlur}
                            value={item.breakfastPriceDoubleRoom}
                            className="form-control"
                          />
                          <span className="text-danger d-flex text-left">
                            <ErrorMessage name={`hotelTotalDays[${index}].breakfastPriceDoubleRoom`} />
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>

                <hr />
              </>)
              )}

              <div className="mt-5">
                <button
                  className="btn btn-info mr-2"
                  type="submit"
                // disabled={submitted ? true : null}
                >
                  <i className="fa fa-floppy-o mx-1" aria-hidden="true"></i>
                  Save
                </button>
                <button
                  className="btn btn-success mr-2"
                  type="submit"
                  onClick={() => {
                    if (!Object.values(errors).length) {
                      setSaveAndNext(true);
                    }
                  }}
                // disabled={submitted ? true : null}
                >
                  <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                  Save & Next
                </button>
                <button
                  className="btn ripple btn-secondary"
                  type="button"
                  disabled={submitted ? true : null}
                  onClick={() => navigate(-1)}
                >
                  <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                  Cancel
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default Hotels;