import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { UserAnnouncementsView } from '../../services/webother.service';
import { getAnnouncement, getHomeUrl, getLocalKey } from '../../../../utils/commonfunction';
import { STAFF_ROLE } from '../../../../utils/Constants';

const UserViewAnnouncements = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const [getuserkey, setGetUserkey] = useState();
    const loc = useLocation();


    useEffect(() => {
        UserAnnouncementsView(params.id).then(data => {
            setShowDefault(data && data.data ? data.data : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [params.id])

    useEffect(() => {
        try {
            const getuserkey = JSON.parse(getLocalKey("user"));
            setGetUserkey(getuserkey);
        } catch (err) {
            setGetUserkey(null)
        }
    }, [loc])


    return (
        <>

            <div className="wraper-inner cpt bg-grey">
                <section className="inner-space">
                    <div className="container" style={{ minHeight: "60vh" }}>
                        <nav className="breadcrumb text-center mb-4">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                <Link to={getHomeUrl(getuserkey?.role)}>{t('web_header_heading_home')}</Link>
                                </li>
                                <li className="breadcrumb-item">
                                    <Link to={getAnnouncement(getuserkey?.role)}>{t('web_header_heading_announcements')}</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">{showdefault?.title}</li>
                            </ol>
                        </nav>
                        <div className="dash-box-shadow">
                            {showdefault && Object.keys(showdefault).length > 0 ? (
                                <>
                                    <ul className="announcements-box-list">
                                        <li>
                                            <figcaption>
                                                <h3 className='announcements-title'>{showdefault && showdefault?.title ? showdefault?.title : "N/A"}</h3>
                                                <p dangerouslySetInnerHTML={{ __html: showdefault.description }} />
                                            </figcaption>
                                        </li>
                                    </ul>
                                </>
                            ) : (
                                <></>
                            )}
                            <div className="mt-3">
                                <button className="btn btn-primary" type='button' onClick={() => navigate(-1)}>
                                    <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                    {t('web_lbl_back')}
                                </button>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default UserViewAnnouncements;