import React, { useEffect, useState } from 'react'
import { ErrorMessage, Field, Formik } from 'formik'
import Swal from "sweetalert2"
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrums from '../../common/breadcrumbs';
import { GENDER, SWAL_SETTINGS } from '../../../../utils/Constants';
import { blockInvalidChar, getNameFromList, handleServerValidations } from '../../../../utils/commonfunction';
import * as usermanagement from '../../services/usermgmt.services';
import * as Yup from 'yup';
import { getCountryCallingCode } from 'react-phone-number-input'
import ReactCountryFlag from 'react-country-flag'
import { getCountries } from 'react-phone-number-input'
import { useSelector } from 'react-redux';




const UserEdit = (props) => {
	const navigate = useNavigate();
	const params = useParams();
	const [showdefault, setShowDefault] = useState({});
	const [previewimage, setPreviewImage] = useState("");
	const [saveType, setSaveType] = useState('')
	const [dynamicValidations, setDynamicValidations] = useState({});
	const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Users", url: "/admin/user-management/users/list/1" }, { title: "Edit", url: "" }];
	let globalData = useSelector((state) => state.globaldetail);

	const myGlobalData = useSelector((state) => state.globalData);
	const [getGlobalData, setGlobalData] = useState({});
	useEffect(() => {
	  if(myGlobalData && myGlobalData?.data){
		setGlobalData(JSON.parse(myGlobalData?.data));
	  }
	},[myGlobalData]);


	useEffect(() => {
		usermanagement.Details(params.id).then(response => {
			setShowDefault(response && response.data ? response.data : []);
			setPreviewImage(response.data.profile_image);
		}).catch(error => {
			console.log("error=====>", error)
		})
	}, [params.id]);

	const showDropDown = (country) => {
		return (
			<label>{"   " + getNameFromList(country)} {" +"}{getCountryCallingCode(country)}</label>
		)
	}

	useEffect(() => {
		let obj = {};
		getGlobalData && getGlobalData?.settings?.profile_required_fields && getGlobalData?.settings?.profile_required_fields.map((item, index) => {
		  if(item == 'gender'){
			obj.gender = Yup.string().oneOf(Object.values(GENDER),'Invalid salutation selection').required('Salutation is required')
		  }
		  else if(item == 'title'){
			obj.title = Yup.string().trim().required('Title is required');
		  }
		  else if(item == 'first_name'){
			obj.first_name = Yup.string().trim().required('First name is required');
		  }
		  else if(item == 'middle_name'){
			obj.middle_name = Yup.string().trim().required('Middle name is required');
		  }
		  else if(item == 'last_name'){
			obj.last_name = Yup.string().trim().required('Last name is required')
		  }
		  else if(item == 'event_function'){
			obj.event_function = Yup.string().trim().required('Function is required');
		  }
		  else if(item == 'phone_number'){
			obj.phone_number = Yup.string()
			.trim()
			.required('Phone number is required')
			.test("min-length", "Phone number minimum length should be 4 digits", (value) => {
			  if(value?.trim()?.length < 4){
				return false
			  }else{
				return true
			  }
			})
			.test("max-length", "Phone number cannot be more than 13 digits", (value) => {
			  if(value?.trim()?.length > 13){
				return false
			  }else{
				return true
			  }
			})
		  }else if(item == 'phone_number_work'){
			obj.phone_number_work = Yup.string()
			.trim()
			.required('Phone number work is required')
			.test("min-length", "Phone number minimum length should be 4 digits", (value) => {
			  if(value?.trim()?.length < 4){
				return false
			  }else{
				return true
			  }
			})
			.test("max-length", "Phone number cannot be more than 13 digits", (value) => {
			  if(value?.trim()?.length > 14){
				return false
			  }else{
				return true
			  }
			})
		  }
		  else if(item == 'files'){
			obj.files = Yup.mixed().required('Image is required').test(
			  'fileType',
			  'Unsupported file format',
			  (value) => {
				if (!value) {
				  const supportedFormats = ['image/jpeg', 'image/png', 'image/gif'];
				  return supportedFormats.includes(value.type);
				} else {
				  return true
				}
			  }
			)
		  }
		  else if(item == 'extra_info'){
			obj.extra_info = Yup.object().shape({
			  employee_number: Yup.string().trim().required('This field is required'),
			  employee_code: Yup.string().trim().required('This field is required')
			})
		  }
		  else if(item == 'notes'){
			obj.notes = Yup.string().trim().required('Notes is required');
		  }
		})
		setDynamicValidations(obj);
	  },[getGlobalData]);

	const validationSchema = Yup.object().shape({
		email: Yup.string().trim().required("Email is required"),
		...dynamicValidations
	});

	const isFieldRequired = (fieldName) => {
		return getGlobalData && getGlobalData?.settings?.profile_required_fields && getGlobalData?.settings?.profile_required_fields.includes(fieldName);
	};




	return (
		<>
			<Breadcrums data={breadcrumbs} />
			<Formik
				enableReinitialize
				initialValues={{
					email: showdefault && showdefault.email ? showdefault.email : "",
					files: showdefault && showdefault.profile_image ? showdefault.profile_image : "",
					title: showdefault && showdefault.title ? showdefault.title : "",
					gender: showdefault && showdefault.gender ? showdefault.gender : "",
					first_name: showdefault && showdefault.first_name ? showdefault.first_name : "",
					middle_name: showdefault && showdefault.middle_name ? showdefault.middle_name : "",
					last_name: showdefault && showdefault.last_name ? showdefault.last_name : "",
					phone_number: showdefault && showdefault?.phone_number && showdefault?.phone_number.number ? showdefault?.phone_number.number : "",
					countryCodePhone: showdefault && showdefault?.phone_number && showdefault?.phone_number.countryCode ? showdefault?.phone_number.countryCode : "",
					dialCodePhone: showdefault && showdefault?.phone_number && showdefault?.phone_number.code ? showdefault?.phone_number.code : "+" + getCountryCallingCode(getCountries()[0]),
					phone_number_work: showdefault && showdefault?.phone_number_work && showdefault?.phone_number_work.number ? showdefault?.phone_number_work.number : "",
					countryCodePhoneWork: showdefault && showdefault?.phone_number_work && showdefault?.phone_number_work.countryCode ? showdefault?.phone_number_work.countryCode : "",
					dialCodePhoneWork: showdefault && showdefault?.phone_number_work && showdefault?.phone_number_work.code ? showdefault?.phone_number_work.code : "+" + getCountryCallingCode(getCountries()[0]),
					event_function: showdefault && showdefault?.event_function ? showdefault.event_function : "",
					notes: showdefault && showdefault.notes ? showdefault.notes : "",
					extra_info: {
						employee_code: showdefault && showdefault?.extra_info?.employee_code ? showdefault?.extra_info?.employee_code : "",
						employee_number: showdefault && showdefault?.extra_info?.employee_number ? showdefault.extra_info?.employee_number : "",
					}
				}}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					let formData = new FormData();
					formData.append('o_id', params.id);
					formData.append('title', values.title);
					formData.append('first_name', values.first_name);
					formData.append('middle_name', values.middle_name);
					formData.append('last_name', values.last_name);
					formData.append('gender', values.gender);
					formData.append('email', values.email);
					if (values.files && values.files.name) {
						formData.append('image', values.files);
					}
					console.log("values.phone_number", values.phone_number, values.dialCodePhone);
					if (values.phone_number && values.dialCodePhone) {
						formData.append('phone_number', JSON.stringify({
							code: values.dialCodePhone,
							number: values.phone_number,
							countryCode: values.countryCodePhone
						}));
					}
					if (values.phone_number_work && values.dialCodePhoneWork) {
						formData.append('phone_number_work', JSON.stringify({
							code: values.dialCodePhoneWork,
							number: values.phone_number_work,
							countryCode: values.countryCodePhoneWork
						}));
					}
					formData.append('extra_info', JSON.stringify({ employee_code: values.extra_info.employee_code, employee_number: values.extra_info.employee_number }));
					formData.append('event_function', values.event_function);
					formData.append('notes', values.notes);
					usermanagement.Edit(formData).then(response => {
						setSubmitting(false);
						if (response.success) {
							Swal.fire({
								icon: 'success',
								text: response.message,
								...SWAL_SETTINGS
							})
							if (saveType !== 'Save') {
								navigate(`/admin/user-management/users/list/1`)
							}
						} else {
							Swal.fire({
								icon: 'error',
								text: handleServerValidations(response),
								...SWAL_SETTINGS
							})
						}
					}).catch(error => {
						Swal.fire({
							icon: 'error',
							text: handleServerValidations(error),
							...SWAL_SETTINGS
						})
						console.log("error ====> ", error);
					})
				}}
			>{({
				values,
				errors,
				touched,
				handleChange,
				handleBlur,
				handleSubmit,
				setFieldValue,
				setFieldTouched,
				isSubmitting,
			}) => (
				<form onSubmit={handleSubmit}>
					<div className="row row-sm">
						<div className="col-lg-12 col-md-12 animation_fade">
							<div className="card custom-card">
								<div className="card-body">
									<div>
										<h6 className="main-content-label mb-3">Edit User</h6>
									</div>
									<div className="row row-sm">
										<div className='col-md-6 text-center form-group'>
											<label htmlFor='title' className='text-left d-flex'>Title:{isFieldRequired('title') && <span className="requirestar">*</span>}</label>
											<input
												name='title'
												id='title'
												type='text'
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.title}
												className='form-control' />
											<span className="text-danger d-flex text-left">
												<ErrorMessage name={"title"} />
											</span>
										</div>
										<div className="col-md-6 text-center form-group">
											<label htmlFor='gender' className='text-left d-flex'>Salutation:{isFieldRequired('gender') && <span className="requirestar">*</span>} </label>
											<div className="select-down-arrow ">
												<Field as='select' id="gender" name={"gender"} className="form-control select2 text-capitalize">
													<option value="" label="Select Salutation">Select{" "}</option>
													{GENDER && Object.values(GENDER).map((option, i) => {
														return (
															<option key={i} value={option}>
																{option}
															</option>
														);
													})}
												</Field>
											</div>
											<span className='text-danger d-flex text-left'><ErrorMessage name={"gender"} /></span>
										</div>
										<div className='col-md-6 text-center form-group'>
											<label htmlFor='first_name' className='text-left d-flex'>First Name:{isFieldRequired('first_name') && <span className="requirestar">*</span>} </label>
											<input
												name='first_name'
												id='first_name'
												type='text'
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.first_name}
												className='form-control' />
											<span className='text-danger d-flex text-left'><ErrorMessage name={"first_name"} /></span>
										</div>
										<div className='col-md-6 text-center form-group'>
											<label htmlFor='middle_name' className='text-left d-flex'>Middle Name:{isFieldRequired('middle_name') && <span className="requirestar">*</span>} </label>
											<input
												name='middle_name'
												id='middle_name'
												type='text'
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.middle_name}
												className='form-control' />
											<span className='text-danger d-flex text-left'><ErrorMessage name={"middle_name"} /></span>
										</div>
										<div className='col-md-6 text-center form-group'>
											<label htmlFor='last_name' className='text-left d-flex'>Last Name:{isFieldRequired('last_name') && <span className="requirestar">*</span>} </label>
											<input
												name='last_name'
												id='last_name'
												type='text'
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.last_name}
												className='form-control' />
											<span className='text-danger d-flex text-left'><ErrorMessage name={"last_name"} /></span>
										</div>

										<div className="col-lg-6 text-center form-group">
											<label htmlFor='email' className='text-left d-flex'>Email:<span className="requirestar">*</span> </label>
											<input
												name='email'
												id='email'
												type='text'
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.email}
												className='form-control'
											/>
											<span className='text-danger d-flex text-left'><ErrorMessage name={"email"} /></span>
										</div>
										<div className='col-lg-6 text-center form-group'>
											<label htmlFor='files' className='text-left d-flex'>Profile:{isFieldRequired('files') && <span className="requirestar">*</span>}</label>
											<input className="form-control imgInput"
												id="files"
												name="files"
												accept="image/*"
												type="file"
												onChange={(event) => {
													setFieldValue("files", event.currentTarget.files[0] || "");
													event.currentTarget.files.length === 1
														? setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))
														: setPreviewImage("");
												}}
											/>
											<span className='text-danger d-flex text-left' id="errortext">{errors.files && touched.files && errors.files}</span>
										</div>
										{
											previewimage ?
												<div className='mb-4'>
													<img src={previewimage} style={{ height: "100px" }} alt={""} />
												</div> : ""
										}
										<div className="col-lg-6 text-center form-group">
											<label className="text-left d-flex" htmlFor='phone_number'>Phone number:{isFieldRequired('phone_number') && <span className="requirestar">*</span>}</label>
											<div className="form-control d-flex">
												<select
													className='selectInput'
													style={{ height: "100%", paddingLeft: 0, paddingTop: 0, marginTop: 0, border: 0, width: 40, }}
													value={values.countryCodePhone}
													onChange={(event) => {
														console.log("event", event);
														setFieldValue("countryCodePhone", event.target.value)
														setFieldValue("dialCodePhone", "+" + getCountryCallingCode(event.target.value))
													}}
												>
													{getCountries().map((country) => (<>
														<option key={country} value={country} >
															<ReactCountryFlag countryCode={country} />
															{showDropDown(country)}
														</option>
													</>))}
												</select>
												<label style={{ padding: 0, margin: 0, paddingTop: 2 }}>{values.dialCodePhone}</label>
												<input
													className={"phoneInput"}
													name="phone_number"
													style={{ border: 0 }}
													onChange={event => {
														setFieldValue(`phone_number`, event.target.value)
													}}
													value={`${values.phone_number}`}
													onKeyDown={blockInvalidChar}
												/>
											</div>
											<span className='text-danger d-flex text-left'>{errors.phone_number && errors.phone_number}</span>
										</div>
										<div className="col-lg-6 text-center form-group">
											<label className="text-left d-flex" htmlFor='phone_number'>Phone number work:{isFieldRequired('phone_number_work') && <span className="requirestar">*</span>}</label>
											<div className="form-control d-flex">
												<select
													className='selectInput'
													style={{ height: "100%", paddingLeft: 0, paddingTop: 0, marginTop: 0, border: 0, width: 40, }}
													value={values.countryCodePhoneWork}
													onChange={(event) => {
														console.log("event", event);
														setFieldValue("countryCodePhoneWork", event.target.value)
														setFieldValue("dialCodePhoneWork", "+" + getCountryCallingCode(event.target.value))
													}}
												>
													{getCountries().map((country) => (<>
														<option key={country} value={country} >
															<ReactCountryFlag countryCode={country} />
															{showDropDown(country)}
														</option>
													</>))}
												</select>
												<label style={{ padding: 0, margin: 0, paddingTop: 2 }}>{values.dialCodePhone}</label>
												<input
													className={"phoneInput"}
													name="phone_number_work"
													style={{ border: 0 }}
													onChange={event => {
														setFieldValue(`phone_number_work`, event.target.value)
													}}
													value={`${values.phone_number_work}`}
													onKeyDown={blockInvalidChar}
												/>
											</div>
											<span className='text-danger d-flex text-left'>{errors.phone_number_work && errors.phone_number_work}</span>
										</div>
										{/* {
											previewimage ?
												<div className='mb-4'>
													<img src={previewimage} style={{ height: "100px" }} alt={""} />
												</div> : ""
										} */}
										<div className="col-md-6 text-center form-group">
											<label htmlFor="event_function" className="text-left d-flex">
												Function:{isFieldRequired('event_function') && <span className="requirestar">*</span>}
											</label>
											<input
												name="event_function"
												id="event_function"
												type="text"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.event_function}
												className="form-control"
											/>
											<span className='text-danger d-flex text-left'>{errors.event_function && errors.event_function}</span>

										</div>
										<div className="col-md-6 ">
											<label className="form-label">Extra Information:{isFieldRequired('extra_info') && <span className="requirestar">*</span>}</label>
											<input className={"form-control mb-2"}
												placeholder="Enter your employee code"
												type="number"
												name="extra_info.employee_code"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.extra_info.employee_code}
												autoComplete="off" />
											<span className='text-danger d-flex text-left'><ErrorMessage name={"extra_info.employee_code"} /></span>
											<input className={"form-control"}
												placeholder="Enter your employee number"
												type="number"
												name="extra_info.employee_number"
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.extra_info.employee_number}
												autoComplete="off" />
											<span className='text-danger d-flex text-left'><ErrorMessage name={"extra_info.employee_number"} /></span>

										</div>
										<div className='col-md-6 text-center form-group'>
											<label htmlFor='last_name' className='text-left d-flex'>Notes:{isFieldRequired('notes') && <span className="requirestar">*</span>}</label>
											<textarea
												name='notes'
												id='notes'
												type='text'
												onChange={handleChange}
												onBlur={handleBlur}
												value={values.notes}
												className='form-control' />
											<span className='text-danger d-flex text-left'><ErrorMessage name={"notes"} /></span>

										</div>
										<div className="mt-5" >
											<button onClick={() => { setSaveType('Save') }} className="btn btn-info mr-2" type="submit" >
												<i className="ace-icon fa fa-check bigger-110 mx-1"></i>
												Save
											</button>
											<button onClick={() => { setSaveType('') }} className="btn btn-success mr-2" type="submit">
												<i className="ace-icon fa fa-check bigger-110 mx-1"></i>
												Save & Exit
											</button>
											<button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
												<i className="ace-icon fa fa-times bigger-110 mx-1"></i>
												Cancel
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</form>
			)}
			</Formik>
		</>
	)
}

export default UserEdit