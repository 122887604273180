import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { calculatePercentage, capitalizeFirstLetter, formateDateWithMonth, getFormatedTime, removeSpecialCharacter, globalLoader, getFileNameFromURL } from "../../../utils/commonfunction";
import * as eventServices from "../services/eventdetailservice";
import QRCode from "react-qr-code";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import LogoImage from '../../../assets/user/images/logo.png'
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ShowAnswer from "../../admin/shared/ShowAnswer";
import VideoPlayer from "../shared/VideoPlayer";
const EventRegistrationView = () => {

  const params = useParams();

  const [eventDetailsData, setEventDetailsData] = useState([]);
  const [ticketData, setTicketData] = useState([]);
  const { t, i18n } = useTranslation();
  let [videoSourceType, setVideoSourceType] = useState("video");
  let [videoPlaySource, setVideoPlaySource] = useState(null);
  const videoPlayerToggler = useRef();


  useEffect(() => {
    eventServices.EventRegistrationDetails(params?.id).then((response) => {
      if (response.success) {
        setEventDetailsData(response?.data);
        setTicketData(response?.data?.tickets_data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  const printTicket = (data, index) => {
    const printableContent = document.querySelector(`#queryTicket${index}`);
    console.log("printableContent", printableContent);
    const contentToPrint = printableContent.cloneNode(true);
    const printWindow = window.open('', '_blank');
    printWindow.document.open();
    printWindow.document.write(`
      <html>
        <head>
          <title>Print Ticket</title>
        </head>
        <body>
          ${contentToPrint.outerHTML}
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
    printWindow.close();
  };

  const handleDownloadClick = async (e, fileURL) => {
    let fileName = "";
    if (fileURL) {
      fileName = fileURL.replace(`${process.env.REACT_APP_SERVER_BASE_URL}/uploads/registrations/tickets/`, "");
      e.preventDefault();
      globalLoader(true);
      try {
        const response = await fetch(fileURL);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const blobURL = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobURL;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
        URL.revokeObjectURL(blobURL);
        globalLoader(false);
      } catch (error) {
        globalLoader(false);
        console.error('Error downloading the file:', error);
        Swal.fire({
          title: 'Download Error',
          text: `There was an error while downloading the file "${fileName}".`,
          icon: 'error',
        });
      }
    } else {
      console.log("Error in file downloading")
    }
  };

  let globalData = useSelector((state) => state.globaldetail);

  const [getGlobalData, setGlobalData] = useState({});
  const myGlobalData = useSelector((state) => state.globalData);
  useEffect(() => {
    if(myGlobalData && myGlobalData?.data){
      setGlobalData(JSON.parse(myGlobalData?.data));
    }
  },[myGlobalData]);

  const playVideo = (video,type) => {
    console.log("kkkkk",video,type);
    setVideoPlaySource(video);
    setVideoSourceType(type);
    videoPlayerToggler?.current?.click();
  };


  return (
    <div className="row">
      <div className="col-md-8 m-auto">
        <h3 className="dash-title">{t('web_heading_event_registration')}</h3>
        <div className="dash-box-shadow">
          <div className="about-event">
            <h4 className="reg-title">
              {t('web_event_registration_attendance')}
              <button className="edit-btn">
                <i className="ti ti-pencil-minus" />
              </button>
            </h4>
            <h5 className="reg-sub-title mb-3">
              {t('web_event_registration_attend')}
            </h5>
            <ul className="card-info-list pb-0">
              <li>
                <div className="date-info pb-0">
                  <i className="ri-calendar-event-line black-icon" />
                  {formateDateWithMonth(eventDetailsData?.start_date) + " - " + formateDateWithMonth(eventDetailsData?.end_date)}
                </div>
              </li>
            </ul>
          </div>
          <hr className="sep" />
          <div className="about-accommodation">
            <h4 className="reg-title">
              {t('web_event_registration_accommodation')}
              <button className="edit-btn">
                <i className="ti ti-pencil-minus" />
              </button>
            </h4>
            <div className="answer-question-box mb-3">
              <h5 className="reg-sub-title mb-1">{t('web_event_registration_need_accommodation')}</h5>
              <span className="ans-question">{eventDetailsData?.accommodation ? "Yes" : "No"}</span>
            </div>
            {eventDetailsData?.accommodation ? (
              <>
                <ul className="card-info-list pb-3">
                  <li>
                    <div className="date-info pb-0 align-items-start">
                      <i className="ri-calendar-event-line black-icon" />
                      {t('web_event_registration_check_in')} <br />
                      {formateDateWithMonth(eventDetailsData?.check_in)}
                    </div>
                  </li>
                  <li>
                    <div className="date-info pb-0 align-items-start">
                      <i className="ri-calendar-event-line black-icon" />
                      {t('web_event_registration_check_out')}
                      <br />
                      {formateDateWithMonth(eventDetailsData?.check_out)}
                    </div>
                  </li>
                </ul>
                <ul className="text-info">
                  <li>
                    <b>{t('web_event_registration_hotel_name')}</b>
                    <p>{eventDetailsData?.hotel_data?.name ? eventDetailsData?.hotel_data?.name : "--------"}</p>

                  </li>
                  <li>
                    <b>{t('web_event_registration_room_type')}</b>
                    <p className="text-capitalize">{eventDetailsData?.room_type ? removeSpecialCharacter(eventDetailsData?.room_type) : "--------"}</p>

                  </li>
                </ul>
              </>
            ) : (
              <></>
            )}
            <hr className="sep" />
            {eventDetailsData?.primary_user?.allergic || eventDetailsData?.primary_user?.vegan || eventDetailsData?.primary_user?.vegetarian ? (
              <div className="about-dietary mb-5">
                <h4 className="reg-title">
                  {t('web_event_registration_dietary')}
                  <button className="edit-btn">
                    <i className="ti ti-pencil-minus" />
                  </button>
                </h4>
                <h5 className="reg-sub-title mb-2">
                  {t('web_event_registration_diet')}
                </h5>
                <ul className="option-list mb-3">
                  {eventDetailsData?.primary_user?.vegan ? (
                    <li>{t('web_event_registration_vegan')}
                    </li>
                  ) : (
                    <></>
                  )}
                  {eventDetailsData?.primary_user?.vegetarian ? (
                    <li>{t('web_event_registration_vegitarian')}
                    </li>
                  ) : (
                    <></>
                  )}
                  {eventDetailsData?.primary_user?.allergic ? (
                    <li>{t('web_event_registration_allergic')}</li>
                  ) : (
                    <></>
                  )}
                </ul>
                {eventDetailsData?.primary_user?.allergic ? (
                  <ul className="text-info">
                    <li>
                      <b>{t('web_event_registration_other_information')}</b>
                      <p>{eventDetailsData?.primary_user?.allergy_reason ? eventDetailsData?.primary_user?.allergy_reason : "--------"}
                      </p>

                    </li>
                  </ul>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
            {eventDetailsData?.primary_user?.agenda_data && eventDetailsData?.primary_user?.agenda_data.length ? <>
              <div className="about-agenda">
                <h4 className="reg-title">
                  {t('web_event_registration_agenda')}
                  <button className="edit-btn">
                    <i className="ti ti-pencil-minus" />
                  </button>
                </h4>
                {eventDetailsData?.primary_user?.agenda_data && eventDetailsData?.primary_user?.agenda_data.map((item, index) => (
                  <div className="Agenda-box mb-4">
                    <h4 className="reg-title">{capitalizeFirstLetter(item?.name)}</h4>
                    <ul className="card-info-list">
                      <li>
                        <div className="date-info pb-0">
                          <i className="ri-calendar-event-line black-icon" />
                          {item?.start_date && formateDateWithMonth(item?.start_date)}
                        </div>
                      </li>
                      <li>
                        <div className="card-info pb-0">
                          <i className="ri-alarm-line black-icon" />
                          {item?.start_time && getFormatedTime(item?.start_time)} - {item?.end_time && getFormatedTime(item?.end_time)}
                        </div>
                      </li>
                    </ul>
                    <div className="select-option-main">
                      {item?.elements.map((element, index) => {
                        return (
                          <div className="select-option mb-2">
                            <h5>{capitalizeFirstLetter(element?.name)}</h5>

                            {element?.price != 0 ? (
                              <span>
                                {eventDetailsData?.event_data?.currency?.sign}{" "}{(element?.price + calculatePercentage(element.tax_rate, element?.price)).toFixed(2)} <small>{t('web_lbl_inclusive')} {element.tax_rate + "%"} {t('web_lbl_tax')}</small>
                              </span>
                            ) : (
                              <span className="text-success">{t('web_lbl_span')}</span>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </> : ""}
            {eventDetailsData?.primary_user?.questions_data ? (
              <div className="few-information mt-5">
                <h4 className="reg-title">
                  {t('web_event_registration_more')}
                  <button className="edit-btn">
                    <i className="ti ti-pencil-minus" />
                  </button>
                </h4>
                <ul className="text-info text-list">
                  {eventDetailsData?.primary_user?.questions_data && eventDetailsData?.primary_user?.questions_data.map((item, index) => {
                    if (item?.answer) {
                      return (
                        <li className="text-dark">
                          <b>{index + 1}. {item?.question_title}</b>
                          <ShowAnswer question={item} setVideoPlaySource={playVideo} parentClass="user-view-registration"/>
                        </li>
                      )
                    }
                    else {
                      return <></>
                    }

                  })}
                </ul>
              </div>
            ) : (
              <></>
            )}
			<hr className="sep" />
            <div className="about-invoice">
              <h4 className="reg-title">
                {t('web_event_registration_invoice')}

                <button className="edit-btn">
                  <i className="ti ti-pencil-minus" />
                </button>
              </h4>
              <h5 className="reg-sub-title mb-2">
                {t('web_event_registration_billing')}

              </h5>
              <div className="content-info">
                <p>{eventDetailsData?.company_name ? eventDetailsData?.company_name : "--------"}</p>
                <p>{eventDetailsData?.address ? eventDetailsData?.address : "--------"},{eventDetailsData?.city ? eventDetailsData?.city : "--------"}</p>
                <p>{eventDetailsData?.country ? eventDetailsData?.country : "--------"},{eventDetailsData?.postal_code ? eventDetailsData?.postal_code : "--------"}</p>


              </div>
            </div>
            <hr className="sep" />
            <div className="about-invitation mb-4">
              <h4 className="reg-title">
                {t('web_event_registration_letter')}

                <button className="edit-btn">
                  <i className="ti ti-pencil-minus" />
                </button>
              </h4>
              <div className="answer-question-box mb-3">
                <h5 className="reg-sub-title mb-1">
                  {t('web_event_registration_do_letter')}

                </h5>
                <span className="ans-question">{eventDetailsData?.visa_invitation ? "Yes" : "No"}</span>
              </div>
              {eventDetailsData?.visa_invitation && eventDetailsData?.visa_invitation ? (
                <ul className="table-list">
                  <li>
                    <span> {t('web_event_registration_passport_number')}</span>
                    <small>
                      <i className="ri-more-2-line" />
                    </small>
                    <p>{eventDetailsData?.primary_user?.visa_info?.passport_number ? eventDetailsData?.primary_user?.visa_info?.passport_number : "--------"}</p>

                  </li>
                  <li>
                    <span> {t('web_event_registration_first_name')}</span>
                    <small>
                      <i className="ri-more-2-line" />
                    </small>
                    <p>{eventDetailsData?.primary_user?.visa_info?.first_name ? eventDetailsData?.primary_user?.visa_info?.first_name : "--------"}</p>

                  </li>
                  <li>
                    <span> {t('web_event_registration_surname')}</span>
                    <small>
                      <i className="ri-more-2-line" />
                    </small>
                    <p>{eventDetailsData?.primary_user?.visa_info?.surname ? eventDetailsData?.primary_user?.visa_info?.surname : "--------"}</p>

                  </li>
                  <li>
                    <span> {t('web_event_registration_dob')}</span>
                    <small>
                      <i className="ri-more-2-line" />
                    </small>
                    <p>{eventDetailsData?.primary_user?.visa_info?.date_of_birth ? formateDateWithMonth(eventDetailsData?.primary_user?.visa_info?.date_of_birth) : "--------"}</p>

                  </li>
                  <li>
                    <span> {t('web_event_registration_pob')}</span>
                    <small>
                      <i className="ri-more-2-line" />
                    </small>
                    <p>{eventDetailsData?.primary_user?.visa_info?.place_of_birth ? eventDetailsData?.primary_user?.visa_info?.place_of_birth : "--------"}</p>

                  </li>
                  <li>
                    <span> {t('web_event_registration_nationality')}</span>
                    <small>
                      <i className="ri-more-2-line" />
                    </small>
                    <p>{eventDetailsData?.primary_user?.visa_info?.nationality ? eventDetailsData?.primary_user?.visa_info?.nationality : "--------"}</p>

                  </li>
                  <li>
                    <span> {t('web_event_registration_doi')}</span>
                    <small>
                      <i className="ri-more-2-line" />
                    </small>
                    <p>{eventDetailsData?.primary_user?.visa_info?.date_of_issue ? formateDateWithMonth(eventDetailsData?.primary_user?.visa_info?.date_of_issue) : "--------"}</p>
                  </li>
                  <li>
                    <span> {t('web_event_registration_doe')}</span>
                    <small>
                      <i className="ri-more-2-line" />
                    </small>
                    <p>{eventDetailsData?.primary_user?.visa_info?.date_of_expiry ? formateDateWithMonth(eventDetailsData?.primary_user?.visa_info?.date_of_expiry) : "--------"}</p>
                  </li>
                  <li>
                    <span> {t('web_event_registration_authority')}</span>
                    <small>
                      <i className="ri-more-2-line" />
                    </small>
                    <p>{eventDetailsData?.primary_user?.visa_info?.authority ? eventDetailsData?.primary_user?.visa_info?.authority : "--------"}</p>
                  </li>
                </ul>
              ) : (
                <></>
              )}
            </div>
            {eventDetailsData?.add_guest ? (
              <div className="dash-box-shadow-2">
                <div className="about-guests mb-3">
                  <h4 className="reg-title">
                    {t('web_event_registration_guest')}
                    <button className="edit-btn">
                      <i className="ti ti-pencil-minus" />
                    </button>
                  </h4>
                  <ul className="table-list">
                    <li>
                      <span>{t('web_lbl_title')}</span>
                      <small>
                        <i className="ri-more-2-line" />
                      </small>
                      <p>{eventDetailsData?.guest_user?.title ? eventDetailsData?.guest_user?.title : "--------"}</p>

                    </li>
                    <li>
                      <span>{t('web_lbl_salutation')} </span>
                      <small>
                        <i className="ri-more-2-line" />
                      </small>
                      <p>{eventDetailsData?.guest_user?.gender ? eventDetailsData?.guest_user?.gender : "--------"}</p>
                    </li>

                    <li>
                      <span>{t('web_event_registration_first_name')}</span>
                      <small>
                        <i className="ri-more-2-line" />
                      </small>
                      <p>{eventDetailsData?.guest_user?.first_name ? eventDetailsData?.guest_user?.first_name : "--------"}</p>

                    </li>
                    <li>
                      <span>{t('web_event_registration_middle_name')}</span>
                      <small>
                        <i className="ri-more-2-line" />
                      </small>
                      <p>{eventDetailsData?.guest_user?.middle_name ? eventDetailsData?.guest_user?.middle_name : "--------"}</p>
                    </li>
                    <li>
                      <span>{t('web_lbl_last_name')}</span>
                      <small>
                        <i className="ri-more-2-line" />
                      </small>
                      <p>{eventDetailsData?.guest_user?.last_name ? eventDetailsData?.guest_user?.last_name : "--------"}</p>

                    </li>
                    <li>
                      <span>{t('web_event_registration_phone_number')}</span>
                      <small>
                        <i className="ri-more-2-line" />
                      </small>
                      <p>{eventDetailsData?.guest_user?.phone_number ? eventDetailsData?.guest_user?.phone_number : "--------"}</p>

                    </li>
                    <li>
                      <span>{t('web_event_registration_email_address')}</span>
                      <small>
                        <i className="ri-more-2-line" />
                      </small>
                      <p>{eventDetailsData?.guest_user?.email ? eventDetailsData?.guest_user?.email : "--------"}</p>

                    </li>
                  </ul>
                </div>
                {eventDetailsData?.guest_user?.allergic || eventDetailsData?.guest_user?.vegan || eventDetailsData?.guest_user?.vegetarian ? (
                  <div className="about-dietary mb-5">
                    <h4 className="reg-title">
                      {t('web_event_registration_dietary')}
                      <button className="edit-btn">
                        <i className="ti ti-pencil-minus" />
                      </button>
                    </h4>
                    <h5 className="reg-sub-title mb-2">
                      {t('web_event_registration_diet')}
                    </h5>
                    <ul className="option-list mb-3">
                      {eventDetailsData?.guest_user?.vegan ? (
                        <li> {t('web_event_registration_vegan')}</li>
                      ) : (
                        <></>
                      )}
                      {eventDetailsData?.guest_user?.vegetarian ? (
                        <li> {t('web_event_registration_vegitarian')}</li>
                      ) : (
                        <></>
                      )}
                      {eventDetailsData?.guest_user?.allergic ? (
                        <li> {t('web_event_registration_allergic')}</li>
                      ) : (
                        <></>
                      )}
                    </ul>
                    {eventDetailsData?.guest_user?.allergic ? (
                      <ul className="text-info">
                        <li>
                          <b> {t('web_event_registration_other_information')}</b>
                          <p>
                            <p>{eventDetailsData?.guest_user?.allergy_reason ? eventDetailsData?.guest_user?.allergy_reason : "--------"}</p>

                          </p>
                        </li>
                      </ul>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                {eventDetailsData?.guest_user?.agenda_data && eventDetailsData?.guest_user?.agenda_data.length > 0 ? (
                  <div className="about-agenda mb-4">
                    <h4 className="reg-title">
                      {t('web_event_registration_agenda')}
                      <button className="edit-btn">
                        <i className="ti ti-pencil-minus" />
                      </button>
                    </h4>
                    {eventDetailsData?.guest_user?.agenda_data.map((agenda, index) => {
                      return (
                        <div className="Agenda-box mb-4">
                          <h4 className="reg-title">{capitalizeFirstLetter(agenda?.name)}</h4>
                          <ul className="card-info-list">
                            <li>
                              <div className="date-info pb-0">
                                <i className="ri-calendar-event-line black-icon" />
                                {agenda?.start_date && formateDateWithMonth(agenda?.start_date)}
                              </div>
                            </li>
                            <li>
                              <div className="card-info pb-0">
                                <i className="ri-alarm-line black-icon" />
                                {agenda?.start_time && getFormatedTime(agenda?.start_time)} - {agenda?.end_time && getFormatedTime(agenda?.end_time)}
                              </div>
                            </li>
                          </ul>
                          <div className="select-option-main">
                            {agenda?.elements.map((element, index) => {
                              return (
                                <div className="select-option mb-2">
                                  <h5>{capitalizeFirstLetter(element?.name)}</h5>
                                  {element?.price != 0 ? (
                                    <span>
                                      {eventDetailsData?.event_data?.currency?.sign}{" "}{(element?.price + calculatePercentage(element.tax_rate, element?.price)).toFixed(2)} <small>{t('web_lbl_inclusive')} {element.tax_rate + "%"} {t('web_lbl_tax')}</small>
                                    </span>
                                  ) : (
                                    <span className="text-success">{t('web_lbl_span')}</span>
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                ) : (
                  <></>
                )}
                {eventDetailsData?.guest_user?.questions_data ? (
                  <div className="few-information mb-4">
                    <h4 className="reg-title">
                      {t('web_event_registration_more')}
                      <button className="edit-btn">
                        <i className="ti ti-pencil-minus" />
                      </button>
                    </h4>
                    <ul className="text-info text-list">
                      {eventDetailsData?.guest_user?.questions_data && eventDetailsData?.guest_user?.questions_data.map((item, index) => {
                        if (item?.answer) {
                          return (
                            <li className="text-dark">
                              <b>{index + 1}. {item?.question_title}</b>
                              <ShowAnswer question={item} setVideoPlaySource={playVideo} parentClass="user-view-registration"/>
                            </li>
                          )
                        }
                        else {
                          return <></>
                        }

                      })}
                    </ul>
                  </div>
                ) : (
                  <></>
                )}

                <div className="about-invitation ">
                  <h4 className="reg-title">
                    {t('web_event_registration_letter')}
                    <button className="edit-btn">
                      <i className="ti ti-pencil-minus" />
                    </button>
                  </h4>
                  <div className="answer-question-box mb-3">
                    <h5 className="reg-sub-title mb-1">
                      {t('web_event_registration_do_letter')}
                    </h5>
                    <span className="ans-question">{eventDetailsData?.guest_user?.visa_invitation ? "Yes" : "No"}</span>
                  </div>
                  {eventDetailsData?.guest_user?.visa_invitation && eventDetailsData?.guest_user?.visa_invitation ? (
                    <ul className="table-list">
                      <li>
                        <span>{t('web_event_registration_passport_number')}</span>
                        <small>
                          <i className="ri-more-2-line" />
                        </small>
                        <p>{eventDetailsData?.guest_user?.visa_info?.passport_number ? eventDetailsData?.guest_user?.visa_info?.passport_number : "--------"}</p>

                      </li>
                      <li>
                        <span> {t('web_event_registration_first_name')}</span>
                        <small>
                          <i className="ri-more-2-line" />
                        </small>
                        <p>{eventDetailsData?.guest_user?.visa_info?.first_name ? eventDetailsData?.guest_user?.visa_info?.first_name : "--------"}</p>

                      </li>
                      <li>
                        <span> {t('web_event_registration_surname')}</span>
                        <small>
                          <i className="ri-more-2-line" />
                        </small>
                        <p>{eventDetailsData?.guest_user?.visa_info?.surname ? eventDetailsData?.guest_user?.visa_info?.surname : "--------"}</p>

                      </li>
                      <li>
                        <span> {t('web_event_registration_dob')}</span>
                        <small>
                          <i className="ri-more-2-line" />
                        </small>
                        <p>{eventDetailsData?.guest_user?.visa_info?.date_of_birth ? formateDateWithMonth(eventDetailsData?.guest_user?.visa_info?.date_of_birth) : "--------"}</p>
                      </li>
                      <li>
                        <span> {t('web_event_registration_pob')}</span>
                        <small>
                          <i className="ri-more-2-line" />
                        </small>
                        <p>{eventDetailsData?.guest_user?.visa_info?.place_of_birth ? eventDetailsData?.guest_user?.visa_info?.place_of_birth : "--------"}</p>

                      </li>
                      <li>
                        <span> {t('web_event_registration_nationality')}</span>
                        <small>
                          <i className="ri-more-2-line" />
                        </small>
                        <p>{eventDetailsData?.guest_user?.visa_info?.nationality ? eventDetailsData?.guest_user?.visa_info?.nationality : "--------"}</p>

                      </li>
                      <li>
                        <span> {t('web_event_registration_doi')}</span>
                        <small>
                          <i className="ri-more-2-line" />
                        </small>
                        <p>{eventDetailsData?.guest_user?.visa_info?.date_of_issue ? formateDateWithMonth(eventDetailsData?.guest_user?.visa_info?.date_of_issue) : "--------"}</p>

                      </li>
                      <li>
                        <span> {t('web_event_registration_doe')}</span>
                        <small>
                          <i className="ri-more-2-line" />
                        </small>
                        <p>{eventDetailsData?.guest_user?.visa_info?.date_of_expiry ? formateDateWithMonth(eventDetailsData?.guest_user?.visa_info?.date_of_expiry) : "--------"}</p>

                      </li>
                      <li>
                        <span> {t('web_event_registration_authority')}</span>
                        <small>
                          <i className="ri-more-2-line" />
                        </small>
                        <p>{eventDetailsData?.guest_user?.visa_info?.authority ? eventDetailsData?.guest_user?.visa_info?.authority : "--------"}</p>

                      </li>
                    </ul>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            <hr className="sep" />
            {ticketData?.length > 0 ? (
              <div className="about-ticket">
                <h4 className="reg-title">
                  {t('web_event_registration_tickets')}
                </h4>
                <div className="row row-sm tickets">
                  {ticketData && ticketData.map((data, index) => (
                    <div className="col-12 mb-4" id={`queryTicket${index}`} key={index}>
                      <div className="card-body p-0 border p-0 rounded-10 h-100">
                        <div className="p-4">
                          <div className="row">
                            <table width="100%">
                              <tbody>
                                <tr>
                                  <td width="70%" style={{ paddingRight: 30 }}>
                                    <table width="100%" style={{ borderCollapse: "collapse" }}>
                                      <tbody>
                                        <tr>
                                          <td>
                                            <div className="btn-group user-ticket" role="group" aria-label="First group">
                                              <button type="button" className="btn btn-dark" onClick={() => printTicket(data, index)}><i className="fa fa-print" aria-hidden="true"></i>
                                              </button>
                                              <button type="button"
                                                className="btn btn-dark"
                                                href="#"
                                                onClick={(e) => handleDownloadClick(e, data?.ticket_pdf)}
                                              >
                                                <i className="fa fa-download" aria-hidden="true"></i>
                                              </button>
                                            </div>
                                            <img src={LogoImage} style={{ marginBottom: 20, marginLeft:"-209px" }} />
                                            <h4 style={{
                                              fontSize: 22,
                                              margin: 0,
                                              paddingBottom: 5,
                                              textTransform: "uppercase"
                                            }}

                                            >
                                              {eventDetailsData?.event_data?.title ? eventDetailsData?.event_data?.title : null}
                                            </h4>
                                            <p
                                              style={{
                                                fontSize: 14,
                                                margin: 0,
                                                paddingBottom: 20,
                                                textTransform: "uppercase"
                                              }}
                                            >
                                              {eventDetailsData?.event_data?.start_date
                                                ? formateDateWithMonth(eventDetailsData?.event_data?.start_date)
                                                : null} - {eventDetailsData?.event_data?.end_date
                                                  ? formateDateWithMonth(eventDetailsData?.event_data?.end_date)
                                                  : null}
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            width="70%"
                                            style={{
                                              borderTop: "2px solid #6fbe44",
                                              borderBottom: "2px solid #6fbe44",
                                              borderRight: "2px solid #6fbe44"
                                            }}
                                          >
                                            <p
                                              style={{
                                                paddingTop: 8,
                                                margin: 0,
                                                fontSize: 11,
                                                paddingBottom: 8,
                                                textTransform: "uppercase"
                                              }}
                                            >
                                              {data && data?.type === "event" ? t('web_lbl_event_name') : data?.type === "agenda" ? t('web_lbl_agenda_name') : t('web_lbl_hotel_name')}
                                            </p>
                                            {data && data?.type === "event" ?
                                              <p
                                                style={{
                                                  margin: 0,
                                                  paddingBottom: 30,
                                                  fontSize: 15,
                                                  fontWeight: 500,
                                                  textTransform: "uppercase"
                                                }}
                                              >
                                                {capitalizeFirstLetter(eventDetailsData?.event_data?.title)}
                                              </p> : null
                                            }
                                            {data && data?.type === "hotel" ?
                                              <p
                                                style={{
                                                  margin: 0,
                                                  paddingBottom: 30,
                                                  fontSize: 15,
                                                  fontWeight: 500,
                                                  textTransform: "uppercase"
                                                }}
                                              >
                                                {capitalizeFirstLetter(eventDetailsData?.hotel_data?.name)}
                                              </p> : null
                                            }
                                            {data && data?.type === "agenda" ?
                                              <p
                                                style={{
                                                  margin: 0,
                                                  paddingBottom: 30,
                                                  fontSize: 15,
                                                  fontWeight: 500,
                                                  textTransform: "uppercase"
                                                }}
                                              >
                                                {function () {
                                                  let currentElement = {};
                                                  data && data?.agenda_data.forEach((item, index) => {
                                                    currentElement = item?.elements && item?.elements.filter(element => data?.element_id == element?._id)[0]
                                                  })
                                                  return capitalizeFirstLetter(currentElement?.name);
                                                }()}
                                              </p> : null
                                            }

                                          </td>
                                          <td
                                            style={{
                                              borderTop: "2px solid #6fbe44",
                                              borderBottom: "2px solid #6fbe44",
                                              paddingLeft: 10
                                            }}
                                          >
                                            <p
                                              style={{
                                                paddingTop: 8,
                                                margin: 0,
                                                fontSize: 11,
                                                paddingBottom: 8,
                                                textTransform: "uppercase"
                                              }}
                                            >
                                              {t('web_lbl_valid')} :
                                            </p>
                                            <p style={{ margin: 0, paddingBottom: 30, fontSize: 15 }}>
                                              {eventDetailsData?.event_data?.end_date
                                                ? moment(eventDetailsData?.event_data?.end_date).format('DD.MM.YYYY | hh:mm')
                                                : null}
                                            </p>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td colSpan={2}>
                                            <h3
                                              style={{
                                                margin: 0,
                                                paddingTop: 20,
                                                fontWeight: 400,
                                                paddingBottom: 5,
                                                fontSize: 18,
                                                textTransform: "uppercase"
                                              }}
                                            >
                                              {t('web_lbl_participant_name')}
                                            </h3>
                                            {data && data?.guest_type === "secondary" ?
                                              <h2
                                                style={{
                                                  margin: 0,
                                                  paddingTop: 20,
                                                  fontWeight: 400,
                                                  paddingBottom: 5,
                                                  fontSize: 18,
                                                  textTransform: "uppercase"
                                                }}
                                              >
                                                {eventDetailsData?.guest_user?.name ? eventDetailsData?.guest_user?.name : "N/A"}
                                              </h2> : null
                                            }
                                            {data && data?.guest_type === "primary" ?
                                              <h2
                                                style={{
                                                  margin: 0,
                                                  paddingTop: 20,
                                                  fontWeight: 400,
                                                  paddingBottom: 5,
                                                  fontSize: 18,
                                                  textTransform: "uppercase"
                                                }}
                                              >
                                                {eventDetailsData?.primary_user?.primary_user_information[0]?.name ? eventDetailsData?.primary_user?.primary_user_information[0]?.name : "N/A"}
                                              </h2>
                                              : null}
                                            {data && !data?.guest_type ?
                                              <h2
                                                style={{
                                                  margin: 0,
                                                  paddingTop: 20,
                                                  fontWeight: 400,
                                                  paddingBottom: 5,
                                                  fontSize: 18,
                                                  textTransform: "uppercase"
                                                }}
                                              >
                                                {eventDetailsData?.primary_user?.primary_user_information[0]?.name ? eventDetailsData?.primary_user?.primary_user_information[0]?.name : "N/A"}
                                              </h2>
                                              : null}
                                            <div style={{ fontSize: 12, lineHeight: "1.5" }} dangerouslySetInnerHTML={{ __html: eventDetailsData?.event_data?.description ? eventDetailsData?.event_data?.description : "N/A" }}>
                                            </div>
                                            <div className="" id='ticket_footer_text' dangerouslySetInnerHTML={{ __html: getGlobalData && getGlobalData?.settings && getGlobalData?.settings?.ticket_footer_text ? getGlobalData?.settings?.ticket_footer_text : "N/A" }}></div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td valign="top">
                                    <table width="100%" style={{ borderCollapse: "collapse" }}>
                                      <tbody>
                                        <tr>
                                          <td
                                            style={{
                                              background: "#e7e7e8",
                                              textAlign: "center",
                                              padding: "30px 10px"
                                            }}
                                          >
                                            {window.location.host}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            style={{
                                              background: "#6fbe44",
                                              textAlign: "center",
                                              padding: "30px 30px"
                                            }}
                                          >
                                            <table width="100%" style={{ background: "#fff" }}>
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <p style={{ marginTop: "5px" }}>
                                                      {data && data?.ticket_number ? data.ticket_number : "N/A"}
                                                    </p>
                                                    <p className="qr-code">
                                                      <QRCode
                                                        size={256}
                                                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                        value={data?.ticket_number}
                                                        viewBox={`0 0 256 256`}
                                                      />
                                                    </p>
                                                    <p>
                                                      {data && data?.type ? capitalizeFirstLetter(data.type) : "N/A"}
                                                    </p>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
          <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#videoPlayerModal" ref={videoPlayerToggler}></button>
           <div className="modal fade" id="videoPlayerModal" data-bs-backdrop="false" data-bs-keyboard="false" aria-labelledby="videoPlayerModalLabel" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered modal-lg">
                  <div className="modal-content">
                      <div className="modal-header">
                          <h5 className="modal-title" id="videoPlayerModalLabel">Playing : {getFileNameFromURL(videoPlaySource)}</h5>
                          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onMouseDown={(event)=>{
                              event.preventDefault();
                              return false;
                          }} onClick={() => {
                              setTimeout(() => {
                              setVideoPlaySource(null);
                              }, 300);
                          }}></button>
                      </div>
                      <div className="modal-body">
                          {videoPlaySource ?
                              <VideoPlayer src={videoPlaySource} type={videoSourceType}/>
                              : null
                          }
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  );
};

export default EventRegistrationView;