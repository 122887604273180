import React, { useEffect, useState } from 'react'
import { ErrorMessage, Field, Formik } from 'formik'
import Swal from "sweetalert2"
import { useNavigate, useParams } from 'react-router-dom'
import Breadcrums from '../../common/breadcrumbs';
import { SWAL_SETTINGS } from '../../../../utils/Constants';
import { SpecialCharacter, handleServerValidations, } from '../../../../utils/commonfunction';
import * as staffService from '../../services/staff.services';
import * as Yup from 'yup';
import { STAFF_ROLE } from "../../../../utils/Constants";
import { useSelector } from 'react-redux';



const StaffsEdit = (props) => {
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const [previewimage, setPreviewImage] = useState("")
    const [saveType, setSaveType] = useState('')
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Staff", url: "/admin/staff/list/1" }, { title: "Edit", url: "" }]
    let globalData = useSelector((state) => state.globaldetail);
    const myGlobalData = useSelector((state) => state.globalData);
    const [getGlobalData, setGlobalData] = useState({});

    const [role, setRole] = useState(Object.keys(STAFF_ROLE));
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        const globalSettingData=JSON.parse(myGlobalData?.data)
        setGlobalData(JSON.parse(myGlobalData?.data));
        setRole(Object.keys(STAFF_ROLE)
        .filter(key => {
            if (globalSettingData?.settings?.enable_sis_management === true &&  globalSettingData?.settings?.enable_lead_management === true  ) {
                return key !== "USER";
            } 
            else if(globalSettingData?.settings?.enable_sis_management === false &&  globalSettingData?.settings?.enable_lead_management === true){
              return key !== "USER" &&  key !== "INFO_COUNTER" && key !== "BOOTH_ATTENDANTS";
            }
            else if(globalSettingData?.settings?.enable_sis_management === true &&  globalSettingData?.settings?.enable_lead_management === false){
              return  key !== "USER" && key !== "LM_BACK_OFFICE" && key !== "LEAD_COORDINATOR";
            }
            else{
                return key !== "USER" && key !== "INFO_COUNTER" && key !== "BOOTH_ATTENDANTS" && key !== "LM_BACK_OFFICE" && key !== "LEAD_COORDINATOR" ;
              } 
        }))
      }
    },[myGlobalData]);

    useEffect(() => {
        staffService.Details(params.id).then(response => {
            console.log("details", response.data)
            setShowDefault(response && response.data ? response.data : [])
            setPreviewImage(response.data.profile_image)
        }).catch(error => {
            console.log("error=====>", error)
        })

    }, [params.id])

    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('First Name is required'),
        last_name: Yup.string().required('Last Name is required'),
        role: Yup.string().required('Role is required'),
        email: Yup.string().email("Invalid email address").required('Email is required'),

    });

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <Formik
                enableReinitialize
                initialValues={{
                    first_name: showdefault && showdefault.first_name ? showdefault.first_name : "",
                    last_name: showdefault && showdefault.last_name ? showdefault.last_name : "",
                    role: showdefault.role ? showdefault.role : "",
                    email: showdefault && showdefault.email ? showdefault.email : "",
                    profile_image: showdefault && showdefault.profile_image ? showdefault.profile_image : "",

                }}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    let formData = new FormData();
                    formData.append('o_id', params.id)
                    formData.append('first_name', values.first_name);
                    formData.append('last_name', values.last_name);
                    formData.append('email', values.email);
                    formData.append('role', values.role);
                    console.log("values.profile_image.name", values.profile_image);
                    if (values.profile_image && values.profile_image.name) {
                        formData.append('profile_image', values.profile_image);
                    }
                    staffService.Edit(formData).then(response => {
                        setSubmitting(false);
                        if (response.success) {
                            Swal.fire({
                                icon: 'success',
                                text: response.message,
                                ...SWAL_SETTINGS
                            })
                            if (saveType !== 'Save') {
                                setTimeout(() => {
                                    navigate(`/admin/staff/list/${params.pgno}`)
                                }, 1000);
                            }
                        } else {
                            Swal.fire({
                                icon: 'error',
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS
                            })
                        }
                    }).catch(error => {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(error),
                            ...SWAL_SETTINGS
                        })
                        console.log("error ====> ", error);
                    })
                }}
            >{({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldValue,
                setFieldTouched,
                isSubmitting,
            }) => (
                <form onSubmit={handleSubmit}>
                    <div className="row row-sm">
                        <div className="col-lg-12 col-md-12 animation_fade">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div>
                                        <h6 className="main-content-label mb-3">EDIT STAFF</h6>
                                    </div>
                                    <div className="row row-sm">
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='title' className='text-left d-flex'> First Name:<span className="requirestar">*</span> </label>
                                            <input
                                                name='first_name'
                                                id='first_name'
                                                type='text'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.first_name}
                                                className='form-control' />
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"first_name"} /></span>
                                        </div>
                                        <div className='col-md-6 text-center form-group'>
                                            <label htmlFor='title' className='text-left d-flex'> Last Name:<span className="requirestar">*</span> </label>
                                            <input
                                                name='last_name'
                                                id='last_name'
                                                type='text'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.last_name}
                                                className='form-control' />
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"last_name"} /></span>
                                        </div>
                                        <div className="col-lg-6 text-center form-group">
                                            <label htmlFor='title' className='text-left d-flex'>Email:<span className="requirestar">*</span> </label>
                                            <input
                                                name='email'
                                                id='email'
                                                type='text'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                                className='form-control'
                                            />
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"email"} /></span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group'>
                                            <label htmlFor='slug' className='text-left d-flex'>Role:<span className="requirestar">*</span></label>
                                            <div className="select-down-arrow ">
                                                <Field as='select' id="role" name={"role"} className="form-control select2">
                                                    <option value="" label="Select role">Select{" "}</option>
                                                    {role && role.map((option, i) => {
                                                        return (
                                                            <option className="text-capitalize" key={i} value={STAFF_ROLE[option]}>
                                                                {SpecialCharacter(option)}
                                                            </option>
                                                        );
                                                    })}
                                                </Field>
                                            </div>
                                            <span className='text-danger d-flex text-left'><ErrorMessage name={"role"} /></span>
                                        </div>
                                        <div className='col-lg-6 text-center form-group'>
                                            <label htmlFor='profile_image' className='text-left d-flex'>Profile:</label>
                                            {/* <input className='form-control imgInput' id="profile_image" name="profile_image" accept="image/*" type="file" onChange={(event) => {
                                                setFieldValue("profile_image", event.currentTarget.files[0] || "");
                                                { event.currentTarget.files.length === 1 ? (setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]))) : (setPreviewImage("")) }
                                            }} /> */}
                                            <input className='form-control imgInput' id="profile_image" name="profile_image" accept="image/*" type="file" onChange={(event) => {
                                                setFieldValue("profile_image", event.currentTarget.files[0] || "");
                                                if (event.currentTarget.files.length === 1) {
                                                    setPreviewImage(URL.createObjectURL(event.currentTarget.files[0]));
                                                } else {
                                                    setPreviewImage("");
                                                }
                                            }} />

                                            <span className='text-danger d-flex text-left' id="errortext">{errors.profile_image && touched.profile_image && errors.profile_image}</span>
                                        </div>
                                        {
                                            previewimage ?
                                                <div className='mb-4'>
                                                    <img src={previewimage} style={{ height: "100px" }}  alt={""}/>
                                                </div> : ""
                                        }
                                               
                                        {/* multiple image end */}
                                        <div className="mt-5" >
                                            <button onClick={() => { setSaveType('Save') }} className="btn btn-info mr-2" type="submit" >
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Save
                                            </button>
                                            <button onClick={() => { setSaveType('') }} className="btn btn-success mr-2" type="submit">
                                                <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                Save & Exit
                                            </button>
                                            <button className="btn ripple btn-secondary" type='button'  onClick={() => navigate(-1)}>
                                                <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            </Formik>
        </>
    )
}

export default StaffsEdit