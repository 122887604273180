import React, { useState, useEffect, useRef } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as pushnotificationService from '../../../services/pushnotification.services';
import CustomPagination from '../../../common/custompagination';
import Sorting from '../../../common/sorting'
import Loader from '../../../common/loader'
import Breadcrums from "../../../common/breadcrumbs"
import $ from 'jquery'
import { SWAL_SETTINGS, showFilterlist, } from '../../../../../utils/Constants';
import { formateDate, handleServerValidations, hasPermission, TrimText } from '../../../../../utils/commonfunction';
import StatusFilter from '../../../common/statusFilter';
import CustomRangepicker from '../../../common/rangepicker';



const PushNotificationTable = () => {
    const params = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const [list, setList] = useState([])
    const [statsupdate, setStatusUpdate] = useState("false")
    const [datalength, setDataLength] = useState("")
    const [itemperpage] = useState(10)
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true)
    const [search, setSearch] = useState({})
    const [globalsearch, setGlobalSearch] = useState("")
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Push Notifications", url: "" }]
    const [resetdate, setResetDate] = useState(false);
    /********** MULTI SELECT >>>>>>********* */
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [selectedAllData, setSelectedAllData] = useState(false);
    const selectAllCheckbox = useRef();
    /********** MULTI SELECT <<<<<<********* */


    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.pgno)
            formData.append("per_page", itemperpage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalsearch)
            pushnotificationService.List(formData).then(data => {
                setDataLength(data.data.total_records)
                setList(data && data.data && data.data.list ? data.data.list : []);
                /********** MULTI SELECT >>>>>>********* */
                setSelectedAllData(data && data.data && data.data.data_ids ? data.data.data_ids : []);
                /********** MULTI SELECT <<<<<<********* */
                setPage(data && data.data && data.data.page ? data.data.page : 1);
                setLoader(false)
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsupdate, sorting, search, globalsearch,itemperpage,params.pgno])


    const viewfunction = (row) => {
        navigate(`/admin/cms/push-notification-template/view/${row._id}`)
    }

    const goToEdit = (row) => {
        navigate(`/admin/cms/push-notification-template/${params.pgno}/edit/${row._id}`)
    }

    // const goToDuplicate = (row) => {
    //     navigate(
    //         `/admin/cms/push-notification-template/add`, { state: row }
    //     );
    // };

    const ChangeStatus = (data, Num) => {
        let ids = Array.isArray(data) ? data : [data];
        const formData = new FormData();
        formData.append("o_id", JSON.stringify(ids))
        formData.append("status", Num)
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                pushnotificationService.Status(formData).then(response => {
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                        setStatusUpdate(!statsupdate)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    function Deletefunction(data) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                let ids = Array.isArray(data) ? data : [data];
                const formdata = new FormData();
                formdata.append("o_id", JSON.stringify(ids));
                pushnotificationService.Delete(formdata).then(response => {
                    setStatusUpdate(!statsupdate)
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                        /** removing deleted ids from selectedRecords */
                        setSelectedRecords((data) => data.filter((item) => {
                            return ids.indexOf(item) === -1;
                        }));
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch((error) => {
                    console.log("deleteError", error)
                });
            }
        })
    }

    /********** Sorting Function Start >>>>>>********* */
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }
    /********** Sorting Function End >>>>>>********* */

    /********** Search & Filter  >>>>>>********* */
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    /********** Search & Filter End  >>>>>>********* */

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    /********** MULTI SELECT >>>>>>********* */
    useEffect(() => {
        if (selectedRecords.length === datalength) {
            selectAllCheckbox.current.indeterminate = false;
            selectAllCheckbox.current.checked = true;
        } else if (selectedRecords.length) {
            selectAllCheckbox.current.indeterminate = true;
        } else {
            selectAllCheckbox.current.indeterminate = false;
        }
    }, [selectedRecords]);
    const selectRecord = (userData, check) => {
        if (check) {
            setSelectedRecords((previousState) => [...previousState, userData._id]);
        } else {
            setSelectedRecords((previousState) => previousState.filter((item) => item !== userData._id));
        }
    };
    const handleSelectAll = (check) => {
        if (check) {
            setSelectedRecords(selectedAllData);
        } else {
            setSelectedRecords([]);
        }
    };
    const isSelected = (data) => {
        return (selectedRecords.filter((item) => data?._id === item).length > 0);
    };
    /********** MULTI SELECT <<<<<<<<<********* */



    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="animation_fade">
                <div className="card custom-card">
                    <div className="card-body">
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <h6 className="main-content-label">Push Notifications</h6>
                            <div className='d-flex align-items-center'>
                                <div className='form-group mb-0 me-3'>
                                    <div className="form-group mb-0 rangepicker_container filter_design">
                                        <i className="fa fa-search calender_icon"></i>
                                        <input type="search" className="form-control" value={globalsearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="me-3">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("createdAt", e) }} resetdate={resetdate} />
                                </div>
                                <div className="me-3">
                                    <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-warning float-right mr-2">Reset Filter</button>
                                {hasPermission('/admin/cms/push-notification-template/add') ?
                                    <>
                                        <button className="btn ripple btn-main-primary signbtn" onClick={() => navigate(`/admin/cms/push-notification-template/add`)}>Add New</button>

                                    </>
                                    : null}
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                <thead>
                                    <tr>
                                        <th className={"position-relative select_head " + (hasPermission('/admin/cms/push-notification-template/multi-select') ? '' : "d-none")}>
                                            <div className="select-check-container">
                                                <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }}
                                                    className="ckbox  cp">
                                                    <input
                                                        ref={selectAllCheckbox}
                                                        id="selectAllCheck"
                                                        checked={
                                                            datalength && datalength === selectedRecords.length
                                                        }
                                                        onChange={(e) => handleSelectAll(e.target.checked)} type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </div>
                                        </th>

                                        <th>
                                            <div className='sorting_column'>
                                                <span>Title</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="title" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Slug</span>

                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Subject</span>

                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} handleSort={handleSort} defaultSorting={defaultSorting} column="createdAt" />
                                            </div>
                                        </th>
                                        <th>
                                            Updated By
                                        </th>
                                        <th className='status_head'>Status</th>
                                        <th className='action_head'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={10}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => {
                                                return <tr key={i} className={isSelected(row) ? "selected" : ""}>
                                                    <td className={"position-relative " + (hasPermission('/admin/cms/push-notification-template/multi-select') ? '' : "d-none")}>
                                                        <div className="select-check-container">
                                                            <label htmlFor={row?._id + "input"} style={{ lineHeight: 'unset' }}
                                                                className="ckbox cp">
                                                                <input
                                                                    id={row?._id + "input"}
                                                                    checked={isSelected(row)}
                                                                    onChange={(e) =>
                                                                        selectRecord(row, e?.target?.checked)
                                                                    }
                                                                    type="checkbox"
                                                                    className="form-check select-check cp" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>{row.title ? TrimText(row.title, 20) : "N/A"}</td>
                                                    <td>{row.slug ? row.slug : "N/A"}</td>
                                                    <td title={row.subject}>{row.subject ? row.subject : "N/A"}</td>
                                                    <td>{row.createdAt ? formateDate(row.createdAt) : "N/A"}</td>
                                                    <td>{row?.updatedBy?.name ? <Link to={`/admin/staff/view/${row.updatedBy._id}`}>{row.updatedBy.name}</Link> : "N/A"}</td>
                                                    {hasPermission('/admin/cms/push-notification-template/status') ?
                                                        <td>{row.status === 1 ? <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(row?._id, 0) }}>Active</button>
                                                            : <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(row?._id, 1) }}>Inactive</button>}
                                                        </td>
                                                        :
                                                        <td>{row?.status === 1 ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Inactive</span>}</td>
                                                    }

                                                    <td>
                                                        <div className="d-flex">
                                                            <button className="btn ripple btn-main-primary signbtn" onClick={() => { viewfunction(row) }}>View</button>
                                                            {hasPermission('/admin/cms/push-notification-template/*/edit/*') ?
                                                                <button className="btn ripple btn-success mlAction" onClick={() => { goToEdit(row) }}>Edit</button>
                                                                : null}
                                                            {hasPermission('/admin/cms/push-notification-template/delete') ?
                                                                <>
                                                                    <button className="btn ripple btn-secondary mlAction" onClick={() => { Deletefunction(row?._id) }}>Delete</button>

                                                                </>
                                                                : null}
                                                            {/* {hasPermission('/admin/cms/push-notification-template/more') ?
                                                                <div className="btn-group mlAction">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-dark dropdown-toggle"
                                                                        data-bs-toggle="dropdown"
                                                                        aria-expanded="false"
                                                                    >
                                                                        More
                                                                    </button>
                                                                    <ul className="dropdown-menu">
                                                                        <li>
                                                                            {hasPermission('/admin/cms/push-notification-template/duplicate') ?
                                                                                <button
                                                                                    type="button"
                                                                                    className="dropdown-item"
                                                                                    onClick={() => goToDuplicate(row)}
                                                                                >
                                                                                    Duplicate
                                                                                </button>
                                                                                : null}
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                : null} */}
                                                        </div>

                                                    </td>
                                                </tr>
                                            }) : <tr><td colSpan={10} className="text-center">No records</td></tr>}
                                        </>}

                                </tbody>
                            </table>
                        </div>
                        <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                        {datalength && datalength > 0 ?
                            <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "push-notifications ", path: "/admin/cms/push-notification-template/list" }]} /> : ""}
                    </div>
                </div>
            </div>

            {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
            {selectedRecords.length ?
                <div className="bulk_actions">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='d-flex align-items-center'>
                            <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "23px", color: "#35b159" }}></i>
                            <h6 className='ms-2 mt-1 mb-0'>Selected {selectedRecords.length} {selectedRecords.length > 1 ? "records" : "record"} of {datalength}</h6>
                        </div>
                        <div>
                            <ul>
                                {hasPermission('/admin/cms/push-notification-template/status') ?
                                    <li>
                                        <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(selectedRecords, 1) }}>Active</button>
                                    </li>
                                    : null}
                                {hasPermission('/admin/cms/push-notification-template/status') ?
                                    <li>
                                        <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(selectedRecords, 0) }}>Inactive</button>
                                    </li>
                                    : null}
                                <li>
                                    {hasPermission('/admin/cms/push-notification-template/delete') ?
                                        <>
                                            <button className="btn ripple btn-secondary" onClick={() => { Deletefunction(selectedRecords) }}>Delete</button>

                                        </>
                                        : null}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                : ""
            }
            {/***********MULTI SELECTION OPTIONS <<<<<<*************/}
        </>
    )
}

export default PushNotificationTable