import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import * as newsLetterService from "../../../services/newsletterservice";
import CustomPagination from "../../../common/custompagination";
import Sorting from "../../../common/sorting";
import Loader from "../../../common/loader";
import Breadcrums from "../../../common/breadcrumbs";
import $ from "jquery";
import {
  SWAL_SETTINGS,
  showFilterlist,
} from "../../../../../utils/Constants";
import {
  formateDate,
  globalLoader,
} from "../../../../../utils/commonfunction";
import StatusFilter from "../../../common/statusFilter";
import CustomRangepicker from "../../../common/rangepicker";

const PromotionsView = () => {
  const params = useParams();
  const location = useLocation();
  const [list, setList] = useState([]);
  const [statsupdate] = useState("false");
  const [serialno, setSerialNo] = useState("");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [defaultSorting, setDefaultSorting] = useState(true);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Promotions", url: "/admin/news-letter/promotions/1" },
    { title: "View", url: "" },
  ];
  const [resetdate, setResetDate] = useState(false);

  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      formData.append("o_id", params.id);
      newsLetterService
        .PromotionsView(formData)
        .then((data) => {
          setDataLength(data.data.total_records);
          setSerialNo(data.data.offset);
          setList(data && data.data && data.data.list ? data.data.list : []);
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [location, statsupdate, sorting, search, globalsearch,itemperpage,params.pgno,params.id]);

  const resendEmail = (log_id) => {
    globalLoader(true)
    newsLetterService.PromotionResendEmail({log_id:log_id}).then((response) => {
      console.log("response",response)
      if(response.success){
        globalLoader(false)
        Swal.fire({
            icon: "success",
            text: response.message,
            ...SWAL_SETTINGS,
        });
      }
    }).catch((error) => {
        globalLoader(false)
        console.log("error",error);
    })
    }

  // sorting function start
  const handleSort = (e, column) => {
    setDefaultSorting(false);
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  // search or filter function
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  // search or filer end

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    // reset customrangepicker & customstatusfilter state using jquery//
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      {/* table section */}
      <div className="animation_fade">
        <div className="card custom-card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="main-content-label">Promotions List</h6>
              <div className="d-flex align-items-center">
                <div className="form-group mb-0 me-3">
                  <div className="form-group mb-0 rangepicker_container">
                    <i className="fa fa-search calender_icon"></i>
                    <input
                      type="search"
                      className="form-control"
                      value={globalsearch}
                      placeholder="Search"
                      onChange={(e) => {
                        setGlobalSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="me-3">
                  <CustomRangepicker
                    GetDateRange={(e) => {
                      prepareSearch("createdAt", e);
                    }}
                    resetdate={resetdate}
                  />
                </div>
                <div className="me-3">
                  <StatusFilter
                    data={showFilterlist}
                    prepareSearch={prepareSearch}
                  />
                </div>
                <button
                  type="reset"
                  value="Reset"
                  onClick={resetFilter}
                  className="btn btn-warning float-right mr-2"
                >
                  Reset Filter
                </button>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                <thead>
                  <tr>
                    <th className="sr_head">S.No</th>
                    <th>
                      <div className="sorting_column">
                        <span>Name</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="title"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="sorting_column">
                        <span>Email</span>
                      </div>
                    </th>
                    <th>
                      <div className="sorting_column">
                        <span>Created Date</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          defaultSorting={defaultSorting}
                          column="createdAt"
                        />
                      </div>
                    </th>
                    <th className="status_head">Status</th>
                    <th className="action_head">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={7}>
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {list.length ? (
                        list.map((row, i) => {
                          return (
                            <tr key={i}>
                              <td>{serialno + (i + 1)}</td>
                              <td>{row.name ? row.name : "N/A"}</td>
                              <td title={row?.email}>
                                {row?.email ? row?.email : "N/A"}
                              </td>
                              <td>
                                {row.createdAt
                                  ? formateDate(row.createdAt)
                                  : "N/A"}
                              </td>
                              <td>
                                {row.status === 1 ? (
                                  <span
                                    className="badge badge-primary"
                                    href="#"
                                  >
                                    Active
                                  </span>
                                ) : (
                                  <span className="badge badge-danger" href="#">
                                    De Active
                                  </span>
                                )}
                              </td>
                              <td>
                                <div className="d-flex">
                                  <button
                                    disabled={row.status === 1 ? true : false}
                                    className="btn ripple btn-success mx-1"
                                    onClick={() => {
                                        resendEmail(row?._id)
                                    }}
                                  >
                                    Resend
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={7} className="text-center">
                            No records
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              id="example1_info"
              role="status"
              aria-live="polite"
            >
              <b>Total Records : {datalength ? datalength : "0"}</b>
            </div>
            {datalength && datalength > 0 ? (
              <CustomPagination
                datalength={datalength}
                itemperpage={itemperpage}
                currentPage={page}
                setPage={setPage}
                pageRoute={[
                  {
                    name: "promotions",
                    path: "/admin/news-letter/promotions/list",
                  },
                ]}
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PromotionsView;
