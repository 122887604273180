import axiosInstance from "../../../utils/axios";
const path = "web/auth"

export const signup = async (values) => {
    return await axiosInstance().post(`${path}/sign-Up`, values);
}

export const otpVerification = async (values) => {
    return await axiosInstance().post(`${path}/verify-otp`, values);
}

export const login = async (values) => {
    return await axiosInstance().post(`${path}/login`, values);
}

export const completeProfile = async (values) => {
    return await axiosInstance().post(`${path}/complete-profile`, values);
}

export const forgetPassword = async (values) => {
    return await axiosInstance().post(`${path}/forget-password`, values);
}


export const resetPassword = async (values) => {
    return await axiosInstance().post(`${path}/reset-password`, values);
}

export const resendOtp = async (values) => {
    return await axiosInstance().post(`${path}/resend-otp`, values);
}

export const checkLeadCoordinator = async (values) => {
    return await axiosInstance().get(`${path}/check-lead-coordinator?_id=${values}`);
}