import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getQuestions, saveData } from '../user/services/dynamic-forms.service';
import { globalLoader, handleServerValidations, prepareQuestionsFormData } from '../../utils/commonfunction';
import { SWAL_SETTINGS } from '../../utils/Constants';
import Swal from 'sweetalert2';
import DynamicQuestions from '../user/shared/DynamicQuestions';
import { useTranslation } from 'react-i18next';
import FrontLoader from './FrontLoader';

export default function DynamicFormWrapper() {
  const params = useParams();
  const childRef = useRef();
  const navigate = useNavigate();
  const {t} =  useTranslation();
  const [questionFilesUploadPath] = useState("dynamic-forms");
  const [questions, setQuestions] = useState([]);
  // const [questionSetInfo, setQuestionsSetInfo] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getQuestions(params.formId).then((response)=>{
      if(response.success){
        setQuestions(response?.data?.questions);
        // setQuestionsSetInfo(response?.data?.question_set);
        setLoading(false);
      }else{
        Swal.fire({
          icon: 'error',
          text: handleServerValidations(response),
          ...SWAL_SETTINGS
        })
      }
    }).catch((err)=>{
      console.log("Error in get question list", err);
    })
  }, []);

  function handleSubmit() {
    const formResult = childRef?.current?.submit();
    if (formResult && formResult?.isValid) {
      /** Starting loader >>>> */
      const ev = new CustomEvent('dynamicFormManageLoading',{
        detail:{
          action:true
        }
      });
      document.querySelector('body').dispatchEvent(ev);
      /** Starting loader <<<< */
      const formData = new FormData();
      formData.append('question_set_id', params.formId);
      let result = childRef?.current?.getValues();
      let finalForm = prepareQuestionsFormData(result, formData, questionFilesUploadPath);
      saveData(finalForm).then((response) => {
        /** Stopping loader >>>> */
      const ev = new CustomEvent('dynamicFormManageLoading',{
        detail:{
          action:false
        }
      });
      document.querySelector('body').dispatchEvent(ev);
      /** Stopping loader <<<< */
        if (response.success) {
          // return;
          const ev = new CustomEvent('dynamicFormSuccess',{
            detail:{
              message: btoa(response?.message)
            }
          });
          document.querySelector('body').dispatchEvent(ev);
        } else {
          Swal.fire({
            icon: 'error',
            text: handleServerValidations(response),
            ...SWAL_SETTINGS
          })
        }
      });
    }else{
      const ev = new CustomEvent('triggerFrameLoaded',{
        detail:{
          increasableHeight: Object.keys((formResult?.errors?.questions || {})).length * 18
        }
      });
      document.querySelector('body').dispatchEvent(ev);
    }
  }
  
  return (
    <>
      {!loading ?
        <div className='w-100 p-5' id={'form-wrapper-'+params.formId+params.index}>
          {questions.length > 0 ? (
            <div>
              <DynamicQuestions ref={childRef} questions={questions} showCount={true}
                questionTitleClass="dynamic-cms-forms-title" footer="" videoUploadPath={questionFilesUploadPath} />
              <button className="btn btn-primary mt-4" onClick={handleSubmit} >
                {t('web_text_dynamic_form_submit_button')}
              </button>
            </div>
          ) : (
            <p className="text-center">{t('web_lbl_no_question')}</p>
          )}
        </div>
        : <div className='pt-5'>
            <FrontLoader/>
          </div>}
    </>
  )
}
