import React, { useEffect, useState } from 'react'
import * as Topicservices from "../../services/topic.services"
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { formateDate, getSessionKey, globalLoader, removeSessionKey, setSessionKey } from '../../../../utils/commonfunction'
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux'
import FrontLoader from '../../../globalComponents/FrontLoader'


const Topics = () => {
  const params = useParams();
  let { state } = useLocation();
  const navigate = useNavigate()
  const [topiclist, setTopicList] = useState([])
  const [update] = useState("false")
	const { t } = useTranslation();
  const [loader,setLoader]= useState(true);
  const myGlobalData = useSelector((state) => state.globalData);
  useEffect(() => {
    if(myGlobalData && myGlobalData?.data){
      const settingsData = JSON.parse(myGlobalData?.data);
      if(!settingsData?.settings?.activity_forum){
          navigate("/404");
      }
    }
  },[myGlobalData]);


  useEffect(() => {
    document.addEventListener('updateTopicHeight',(e)=>{
        let id = e.detail.id;
        let height = e.detail.height;
        let index = e.detail.index;
        if (!getSessionKey(id) || getSessionKey(id) !== height) {
            setSessionKey(id,height);
            let element = document.getElementById("showMoreTopicContainerbutton"+index);
            if(element){
                removeSessionKey(id);
                if(height>100){
                    element.classList.remove('d-none');
                }

            }
        }
    });
    return () => {
      // console.log("Component will unmount");
      for(let i = 0; i <= 20; i++){ 
        removeSessionKey("showMoreTopicContainer"+i);
      }
    };
}, []);

  useEffect(() => {
    // globalLoader(true);
    setLoader(true);
    const formData = new FormData()
    formData.append("activity_id", params.id)
    Topicservices.List(formData).then((response) => {
      console.log("response", response);
      setTopicList(response && response.data && response.data.list ? response.data.list : [])
      // globalLoader(false);
      setLoader(false);
    }).catch((err) => {
      console.log(err)
      // globalLoader(false);
      setLoader(false);
    })
  }, [update,params.id])



  const handleShowMore = (e, id) => {
    if (e.target.classList.contains('active')) {
      e.target.classList.remove('active');
      document.getElementById(id).classList.add('max-100');
      e.target.innerText = "Show More";

    } else {
      e.target.classList.add('active');
      document.getElementById(id).classList.remove('max-100');
      e.target.innerText = "Show Less";
    }
  }

  return (
    <>
      <div className="wraper-inner cpt bg-grey">
        <section className="topics-listing grid-box-main mt-5 pb-5">
          <div className="container">
            <div className="row">

              <div className="col-md-12">
                <div className="top-head-title">
                  <a className="back-btn cp" href="/">
                    <i className="ri-arrow-left-line"  onClick={(e) => { e.preventDefault(); navigate(-1)} }>
                    </i>
                  </a>
                  <div className="content-breadcum">
                    <h3 className="text-capitalize">
                      {state?.data?.title}
                    </h3>
                    <nav className="breadcrumb text-center">
                      <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                          <Link to={`/`}>{t('web_header_heading_home')}</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to={`/activity-forum/1`}>{t('web_lbl_view_forum')}</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">{state?.data?.title}</li>
                      </ol>
                    </nav>
                  </div>
                </div>
              </div>
              {loader ? 
             <FrontLoader/>
            :
            <>
              {topiclist && topiclist?.length > 0 ? topiclist.map((topic, index) => {
                console.log("topiclist", topiclist);
                return (
                  <div className="col-md-4" key={index}>
                  <div className="grid-back" />
                  <div className="grid-box">
                    <h3>{topic.title}</h3>
                    <div className="date-info">
                      <i className="ri-calendar-event-line" /> {formateDate(topic.createdAt)}
                    </div>
                    {/* <div dangerouslySetInnerHTML={{ __html: topic.description }} ></div> */}
                      <div className='surveyPage max-100'  id={'showMoreTopicContainer' + index} ref={(el) => {
                        if (el) {
                          const evt = new CustomEvent('updateTopicHeight', {
                            detail: {
                              id: 'showMoreTopicContainer' + index,
                              index: index,
                              height: el.scrollHeight
                            },
                          });
                          document.dispatchEvent(evt);
                        }
                      }} dangerouslySetInnerHTML={{ __html: topic?.description }}>
                        {/* Description */}
                      </div>
                      {/* Show more integration */}
                      <div className='d-flex justify-content-end w-100 d-none mt-2' id={'showMoreTopicContainerbutton' + index}>
                        <button type='button' className='show-more' onClick={(e) => { handleShowMore(e, 'showMoreTopicContainer' + index) }}>Show More</button>
                      </div>
                    <div className="like-info">
                      <ul className="card-info-list border-box-card">
                        <li>
                          <div className="date-info">
                            {topic.isFavourite === true ? (
                              <span><i className="ri-thumb-up-fill cp"></i></span>
                            ) : (
                              <span><i className="ri-thumb-up-line black-icon cp"></i></span>
                            )}
                            <span className='me-2 cp'>{topic.likes_count} {t('web_lbl_likes')}</span>
                          </div>
                        </li>
                        <li>
                          <div className="card-info">
                            <i className="ri-chat-3-line black-icon" /> {topic.comments_count} {t('web_lbl_view_comment')}
                          </div>
                        </li>
                      </ul>
                    </div>
                    <button onClick={() => navigate(`/activity-forum/topicView/${topic?._id}`, { state: { topic } })} className="btn btn-primary cp">{t('web_lbl_view_topic')}</button>
                  </div>
                </div>                
                )
              }) : 
              <h2 style={{ textAlign: "center", fontSize: "25px" }}>
              {t('web_lbl_no_records')}
              </h2>
              }
            </>
              }
            </div>
          </div>
        </section>
      </div>
    </>
  )
}

export default Topics;