import React, { useEffect, useRef, useState } from "react";
import { Field, FieldArray, Formik } from "formik";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import * as questionService from "../../services/questions";
import * as questionSetServices from "../../services/questionset";
import Breadcrums from "../../common/breadcrumbs";
import CustomCkeditor from "../../common/customeditor";
import { QUESTION_TYPES, SWAL_SETTINGS, CATEGORY_OPTIONS } from "../../../../utils/Constants";
import { SpecialCharacter, blockInvalidCharWithNumber, capitalizeFirstLetter, handleServerValidations } from "../../../../utils/commonfunction";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import CustomAccordion from "../../../user/shared/customAccordion";

const QuestionEdit = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});
  const [data, setData] = useState({});
  const [options, setOptions] = useState([]);
  const [optionsDe, setOptionsDe] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [saveType, setSaveType] = useState("");
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Question sets", url: "/admin/question-settings/sets/list/1" },
    {
      title: "View",
      url: `/admin/question-settings/sets/view/${showdefault?.questionsets_data?._id}?page=1&survey_page=1`,
    },
    {
      title: "Questions",
      url: `/admin/question-settings/sets/view/${showdefault?.questionsets_data?._id}?page=1&survey_page=1`,
    },
    { title: "Edit", url: "" },
  ];
  const [shouldCloseEmojiBox, setShouldCloseEmojiBox] = useState(false);
  const [openEmojiBox, setOpenEmojiBox] = useState(false);
  const formikRef = useRef();
  const [showCustomElement, setShowCustomElement] = useState({ carousel: false, accordion: false, tab: false, gallery: false, counter: false,tour:false,hoverBox:false });
  const [isEdit, setIsEdit] = useState(false)
  let innerRef = useRef();


  const handleSave = (values) => {
      setIsEdit(false)
      setShowCustomElement({  accordion: false })
  };

  useEffect(() => {
      if (innerRef && innerRef?.current) {
          console.log("innerRef?.current", innerRef?.current)
      }
  }, [innerRef?.current])

  const handelCustomElementSelect = (item) => {
      setShowCustomElement({
          accordion: item == "accordion" ? true : false,
      });
  };

  useEffect(() => {
    questionService
      .Details(params.id)
      .then((response) => {
        setShowDefault(response && response.data ? response.data : []);
        setOptions(
          response && response.data.options ? response.data.options : []
        );
        setOptionsDe(
          response && response.data.options_de ? response.data.options_de : []
        );
        setTimeout(() => {
          setDataLoaded(true);
        }, 100);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
    questionSetServices
      .Options()
      .then((response) => {})
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, [params.id]);

  useEffect(() => {
    setTimeout(() => {
      if (openEmojiBox) {
        setShouldCloseEmojiBox(true);
      }
    }, 1000);
  }, [openEmojiBox]);

  useEffect(() => {
    questionSetServices.Details(showdefault?.questionsets_data?._id).then(response => {
       setData(response && response.data ? response.data : []);
    }).catch(error => {
        console.log("Error:", error);
    });
}, [showdefault?.questionsets_data?._id]);

  function insertEmoji(data) {
    let olderData = formikRef?.current?.values.emoji_options;
    console.log("olderData", olderData);
    let handleOldData = olderData ? olderData : "";
    let newData = (handleOldData += data.native + ",");
    console.log("newData", newData);
    formikRef?.current?.setFieldValue(`emoji_options`, newData);
  }

  function filterQuestionTypes(isDedicatedQuestion,isNameBadgeQuestion) {
    let allowedTypes = QUESTION_TYPES;
    if (showdefault.category !== CATEGORY_OPTIONS[1].value) {
      allowedTypes = Object.values(QUESTION_TYPES);
    }
    if(isDedicatedQuestion === "true" || isDedicatedQuestion === true){
      allowedTypes = [QUESTION_TYPES.SINGLE_CHOICE, QUESTION_TYPES.MULTIPLE_CHOICE, QUESTION_TYPES.DROPDOWN];
    }
    if(isNameBadgeQuestion === "true" || isNameBadgeQuestion === true){
      allowedTypes = [QUESTION_TYPES.SHORT_ANS, QUESTION_TYPES.LONG_ANS];
    }
    return Object.values(allowedTypes);
  }

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={{
          title: showdefault && showdefault.title ? showdefault.title : "",
          title_de:
            showdefault && showdefault.title_de ? showdefault.title_de : "",
          questionsetid:
            showdefault && showdefault.questionsets_data
              ? showdefault.questionsets_data._id
              : "",
          question_Type:
            showdefault && showdefault.question_type
              ? showdefault.question_type
              : "",
          richtext:
            showdefault && showdefault.description
              ? showdefault.description
              : "",
          richtext_de:
            showdefault && showdefault.description_de
              ? showdefault.description_de
              : "",
          min_slider_value:
            showdefault && showdefault.min_slider_value
              ? showdefault.min_slider_value
              : "",
          max_slider_value:
            showdefault && showdefault.max_slider_value
              ? showdefault.max_slider_value
              : "",
          options:
            showdefault && showdefault.options && showdefault.options.length > 0
              ? showdefault.options
              : [""],
          options_de:
            showdefault && showdefault.options_de && showdefault.options_de.length > 0
              ? showdefault.options_de
              : [""],
          emoji_options:
            showdefault &&
            showdefault.emoji_options &&
            showdefault.emoji_options.length
              ? showdefault.emoji_options.join(",") + ","
              : "",
          is_required:
            showdefault && String(showdefault.is_required)
              ? showdefault.is_required
              : "",
          is_dedicated_questions:
            showdefault && String(showdefault.is_dedicated_questions)
              ? showdefault.is_dedicated_questions
              : "",
           is_name_badge_question:
            showdefault && String(showdefault.is_name_badge_question)
              ? showdefault.is_name_badge_question
              : "",
        }}
        validate={(values) => {
          const error = {};
          if (!values.title) error.title = "Title is required";
          if (!values.question_Type)
            error.question_Type = "Question type is required";
          if (
            values.question_Type === "single_choice" ||
            values.question_Type === "multiple_choice" ||
            values.question_Type === "dropdown"
        ) {
          if (!values.options || values.options.some(option => option.trim() === "")) {
            error.options = "Options is required";
        } else {
                const uniqueOptions = new Set(values.options.filter(Boolean));
                if (uniqueOptions.size !== values.options.length) {
                    error.options = error.options || "Options must be unique";
                }
            }
        }
        if( values.question_Type === "single_choice" || values.question_Type === "multiple_choice" || values.question_Type === "dropdown") {
          if(values.options_de && values.options_de.length > 0 &&  values.options_de.some(option => option.trim() !== "")){
            const uniqueOptions = new Set(values.options_de.filter(Boolean));
            if (values.options_de && uniqueOptions.size !== values.options_de.length) {
              error.options_de = "Options german must be unique";
            }
          }
        }
          if (values.question_Type === "slider") {
            if (!values.min_slider_value) {
              error.min_slider_value = "Min value is required";
            }
            if (!values.max_slider_value) {
              error.max_slider_value = "Max value is required";
            }
            if (values.min_slider_value && values.max_slider_value) {
              if (
                parseInt(values.min_slider_value) >
                parseInt(values.max_slider_value)
              ) {
                error.min_slider_value =
                  "Min value cannot be more than max value";
                error.max_slider_value =
                  "Max value cannot be less than min value";
              }
            }
          }
          if (values.question_Type === "emoji") {
            if (!values.emoji_options) {
              error.emoji_options = "Emoji is required";
            }
          }
          if (!String(values.is_required) && values.question_Type !== "extra_information")
            error.is_required = "This filed is required";

            if (data.category === CATEGORY_OPTIONS[1].value) {
              if (!String(values.is_dedicated_questions) &&  values.question_Type !== "extra_information")
            error.is_dedicated_questions = "This filed is required";
            }
            if (!String(values.is_name_badge_question))
            {
              error.is_name_badge_question = "This filed is required";
            }
          if (!values.richtext) error.richtext = "Description is required";
          return error;
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          
          let formData = new FormData();
          formData.append("o_id", params.id);
          formData.append("title", values.title);
          formData.append("title_de", values.title_de);
          formData.append("question_set_id", values.questionsetid);
          formData.append("question_type", values.question_Type);
          formData.append("is_dedicated_questions", values.is_dedicated_questions);
          formData.append("is_name_badge_question", values.is_name_badge_question);
          formData.append("description", values.richtext);
          formData.append("description_de", values.richtext_de);
          formData.append("min_slider_value", values.min_slider_value);
          formData.append("max_slider_value", values.max_slider_value);
          formData.append("options", JSON.stringify(values.options));
          if(values.question_Type !== QUESTION_TYPES.EXTRA_INFORMATION ){
            formData.append("is_required",  values.is_required);
          }
          formData.append("options_de", JSON.stringify(values.options_de));
          if (values.question_Type === "emoji") {
            formData.append(
              "emoji_options",
              JSON.stringify(
                values.emoji_options
                  .split(",")
                  .splice(0, values.emoji_options.split(",").length - 1)
              )
            );
          }
          questionService
            .Edit(formData)
            .then((response) => {
              setSubmitting(false);
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
                if (saveType !== "Save") {
                  setTimeout(() => {
                    resetForm({ values: "" });
                    navigate(`/admin/question-settings/sets/view/${showdefault?.questionsets_data?._id}?page=1&survey_page=1`)
                  }, 2000);
                }
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
              }
            })
            .catch((error) => {
              console.log("error ====> ", error);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row row-sm">
              <div className="col-lg-12 col-md-12 animation_fade">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">Edit Question</h6>
                    </div>
                    <div className="row row-sm">
                      <div className="col-md-12 text-center form-group">
                        <label htmlFor="title" className="text-left d-flex">
                          Title:<span className="requirestar">*</span>{" "}
                        </label>
                        <input
                          name="title"
                          id="title"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          className="form-control"
                        />
                        <span className="text-danger d-flex text-left">
                          {errors.title && touched.title && errors.title}
                        </span>
                      </div>
                      <div className="col-md-12 text-center form-group">
                        <label htmlFor="title_de" className="text-left d-flex">
                          Title In German:
                        </label>
                        <input
                          name="title_de"
                          id="title_de"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title_de}
                          className="form-control"
                        />
                        {/* <span className='text-danger d-flex text-left'>{errors.title_de && touched.title_de && errors.title_de}</span> */}
                      </div>
                      {(values?.question_Type !== 'extra_information')&&data.category=== CATEGORY_OPTIONS[1].value  ? 
                      <div className="col-lg-6 text-center form-group">
                        <label htmlFor="slug" className="text-left d-flex">
                          Is Dedicated Question:
                          <span className="requirestar">*</span>
                        </label>
                        <div className="select-down-arrow ">
                          <Field
                            as="select"
                            id="is_dedicated_questions"
                            name={"is_dedicated_questions"}
                            className="form-control select2"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            <option className="text-capitalize" value={true}>
                              True
                            </option>
                            <option className="text-capitalize" value={false}>
                              False
                            </option>
                          </Field>
                        </div>
                        <span className="text-danger d-flex text-left">
                          {errors.is_dedicated_questions &&
                            touched.is_dedicated_questions &&
                            errors.is_dedicated_questions}
                        </span>
                      </div>
                      :null}
                       <div className="col-lg-6 text-center form-group">
                        <label htmlFor="slug" className="text-left d-flex">
                          Is Name Badge Question:
                          <span className="requirestar">*</span>
                        </label>
                        <div className="select-down-arrow ">
                        <Field as='select' id="is_name_badge_question" name={"is_name_badge_question"} className="form-control select2" onChange={(e) => {
                                handleChange(e);
                              }}>
                              {values.is_dedicated_questions === true || values.is_dedicated_questions === "true" ?
                              <option className="text-capitalize" value={false}>
                              False
                            </option>
                            :
                            <>
                                <option value="" label="Select">Select{" "}</option>
                                <option className="text-capitalize" value={true}>
                                  True
                                </option>
                                <option className="text-capitalize" value={false}>
                                  False
                                </option>
                            </>
                              }
                              </Field>
                        </div>
                        <span className="text-danger d-flex text-left">
                          {errors.is_name_badge_question &&
                            touched.is_name_badge_question &&
                            errors.is_name_badge_question}
                        </span>
                      </div>
                      <div className="col-lg-6 text-center form-group">
                        <label
                          htmlFor="question_Type"
                          className="text-left d-flex"
                        >
                          Question Type:<span className="requirestar">*</span>{" "}
                        </label>
                        <div className="form-group select-down-arrow">
                          <Field
                            as="select"
                            id="question_Type"
                            name={"question_Type"}
                            className="form-control select2"
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            {QUESTION_TYPES &&
                              Object.values(QUESTION_TYPES).length && filterQuestionTypes(values.is_dedicated_questions,values.is_name_badge_question).map((option, i) => {
                                return (
                                  <option key={i} value={option}>
                                    {SpecialCharacter(
                                      capitalizeFirstLetter(option)
                                    )}
                                  </option>
                                );
                              })}
                          </Field>
                          <span className="text-danger d-flex text-left">
                            {errors.question_Type &&
                              touched.question_Type &&
                              errors.question_Type}
                          </span>
                        </div>
                      </div>
                      {(values?.question_Type !== QUESTION_TYPES.EXTRA_INFORMATION) ? (
                      <div className="col-lg-6 text-center form-group">
                        <label htmlFor="slug" className="text-left d-flex">
                          Is Required:<span className="requirestar">*</span>
                        </label>
                        <div className="select-down-arrow ">
                          <Field
                            as="select"
                            id="is_required"
                            name={"is_required"}
                            className="form-control select2"
                          >
                            <option value="" label="Select">
                              Select{" "}
                            </option>
                            <option className="text-capitalize" value={true}>
                              True
                            </option>
                            <option className="text-capitalize" value={false}>
                              False
                            </option>
                          </Field>
                        </div>
                        <span className="text-danger d-flex text-left">
                          {errors.is_required &&
                            touched.is_required &&
                            errors.is_required}
                        </span>
                      </div>
                      ):null}
                        {(values?.question_Type === QUESTION_TYPES.EXTRA_INFORMATION && ( values?.is_name_badge_question === false || values?.is_name_badge_question === 'false') ) ? (
                        <div className='col-lg-6 mb-2'>
                         <button className="btn ripple btn-main-primary signbtn" type="button" 
                         onClick={() => handelCustomElementSelect('accordion')}
                          >
                        <i className="ace-icon fa fa-plus bigger-110 mx-1"></i>Add Accordion
                        </button>
                        </div>
                        ):null}
                       {showCustomElement.accordion && (
                      <CustomAccordion ref={innerRef} save={handleSave} open={showCustomElement.accordion}  isEdit={isEdit} />
                      )}
                      {values.question_Type && (values.question_Type === QUESTION_TYPES.SINGLE_CHOICE ||
                        values.question_Type === QUESTION_TYPES.MULTIPLE_CHOICE ||
                        values.question_Type === QUESTION_TYPES.DROPDOWN) ? (
                        <div className="col-lg-12 mb-2">
                          <label
                            htmlFor={"option" + (options.length - 1)}
                            className="text-left d-flex"
                          >
                            Dynamic Options:
                            <span className="requirestar">*</span>{" "}
                          </label>
                          <FieldArray
                            name="options"
                            render={(arrayHelpers) => (
                              <div className="row">
                                {values.options && values.options.length > 0
                                  ? values.options.map((friend, index) => (
                                      <div className="col-lg-6" key={index}>
                                        <div className="text-center form-group">
                                          <div className="d-flex">
                                            <div
                                              className="option_input"
                                              style={{ flex: 1 }}
                                              key={index}
                                            >
                                              <Field
                                                className="form-control select2"
                                                name={`options.${index}`}
                                              />
                                            </div>
                                            {values.options.length > 1 ? (
                                              <button
                                                type="button"
                                                className="ml-2 op_button danger"
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                } // remove a friend from the list
                                              >
                                                <i className="ti-close"></i>
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                            {index ===
                                            values.options.length - 1 ? (
                                              <button
                                                type="button"
                                                className="ml-2 op_button success"
                                                onClick={() =>
                                                  arrayHelpers.push("")
                                                } // push an empty string in an array
                                              >
                                                <i className="ti-plus"></i>
                                              </button>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  : null}
                              </div>
                            )}
                          />
                          <span className="text-danger d-flex text-left">
                            {errors.options &&
                              touched.options &&
                              errors.options}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                        {values.question_Type &&
                      (values.question_Type === QUESTION_TYPES.SINGLE_CHOICE ||
                        values.question_Type === QUESTION_TYPES.MULTIPLE_CHOICE ||
                        values.question_Type === QUESTION_TYPES.DROPDOWN) ? (
                        <div className="col-lg-12 mb-2">
                          <label
                            htmlFor={"option_de" + (optionsDe.length - 1)}
                            className="text-left d-flex"
                          >
                            Dynamic Options German:
                          </label>
                          <FieldArray
                            name="options_de"
                            render={(arrayHelpers) => (
                              <div className="row">
                                {values.options_de && values.options_de.length > 0
                                  ? values.options_de.map((friend, index) => (
                                      <div className="col-lg-6" key={index}>
                                        <div className="text-center form-group">
                                          <div className="d-flex">
                                            <div
                                              className="option_input"
                                              style={{ flex: 1 }}
                                              key={index}
                                            >
                                              <Field
                                                className="form-control select2"
                                                name={`options_de.${index}`}
                                              />
                                            </div>
                                            {values.options_de.length > 1 ? (
                                              <button
                                                type="button"
                                                className="ml-2 op_button danger"
                                                onClick={() =>
                                                  arrayHelpers.remove(index)
                                                } // remove a friend from the list
                                              >
                                                <i className="ti-close"></i>
                                              </button>
                                            ) : (
                                              ""
                                            )}
                                            {index ===
                                            values.options_de.length - 1 ? (
                                              <button
                                                type="button"
                                                className="ml-2 op_button success"
                                                onClick={() =>
                                                  arrayHelpers.push("")
                                                } // push an empty string in an array
                                              >
                                                <i className="ti-plus"></i>
                                              </button>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  : null}
                              </div>
                            )}
                          />
                           <span className="text-danger d-flex text-left">
                            {errors.options_de &&
                              touched.options_de &&
                              errors.options_de}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                      {values.question_Type &&
                        (values.question_Type === QUESTION_TYPES.EMOJI ? (
                          <div className="col-lg-12 text-center form-group">
                            <label
                              htmlFor="options"
                              className="text-left d-flex"
                            >
                              Select Emoji:{" "}
                              <span className="requirestar">*</span>
                            </label>
                            <div className="d-flex">
                              <input
                                name="emoji_options"
                                id="emoji_options"
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.emoji_options}
                                className="form-control"
                                onKeyDown={blockInvalidCharWithNumber}
                              />
                              <button
                                className="chat-emoji-button"
                                type="button"
                                onClick={() => {
                                  setOpenEmojiBox(true);
                                }}
                              >
                                <i
                                  className="fa fa-smile-o emoji-btn"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                            <span className="text-danger d-flex text-left">
                              {errors.emoji_options &&
                                touched.emoji_options &&
                                errors.emoji_options}
                            </span>
                            {openEmojiBox && (
                              <div className="emoji-picker-container">
                                <Picker
                                  data={data}
                                  onEmojiSelect={insertEmoji}
                                  onClickOutside={() => {
                                    if (shouldCloseEmojiBox) {
                                      setOpenEmojiBox(false);
                                      setShouldCloseEmojiBox(false);
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        ))}
                      {values.question_Type &&
                        (values.question_Type === QUESTION_TYPES.SLIDER ? (
                          <div className="col-lg-6 text-center form-group">
                            <label
                              htmlFor="min_slider_value"
                              className="text-left d-flex"
                            >
                              Min Value: <span className="requirestar">*</span>
                            </label>
                            <input
                              name="min_slider_value"
                              id="min_slider_value"
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.min_slider_value}
                              className="form-control"
                            />
                            <span className="text-danger d-flex text-left">
                              {errors.min_slider_value &&
                                touched.min_slider_value &&
                                errors.min_slider_value}
                            </span>
                          </div>
                        ) : (
                          ""
                        ))}
                      {values.question_Type &&
                        (values.question_Type === QUESTION_TYPES.SLIDER ? (
                          <div className="col-lg-6 text-center form-group">
                            <label
                              htmlFor="max_slider_value"
                              className="text-left d-flex"
                            >
                              Max Value: <span className="requirestar">*</span>
                            </label>
                            <input
                              name="max_slider_value"
                              id="max_slider_value"
                              type="number"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.max_slider_value}
                              className="form-control"
                            />
                            <span className="text-danger d-flex text-left">
                              {errors.max_slider_value &&
                                touched.max_slider_value &&
                                errors.max_slider_value}
                            </span>
                          </div>
                        ) : (
                          ""
                        ))}

                      <div className="col-lg-12 text-center form-group">
                        <label htmlFor="subject" className="text-left d-flex">
                          Description:<span className="requirestar">*</span>
                        </label>
                        {dataLoaded ? (
                          <CustomCkeditor
                            fieldname={"richtext"}
                            setFieldValue={setFieldValue}
                            value={values.richtext}
                            setFieldTouched={setFieldTouched}
                          />
                        ) : (
                          ""
                        )}
                        <span className="text-danger d-flex text-left">
                          {errors.richtext &&
                            touched.richtext &&
                            errors.richtext}
                        </span>
                      </div>
                      <div className="col-lg-12 text-center form-group">
                        <label htmlFor="subject" className="text-left d-flex">
                          Description In German:
                        </label>
                        {dataLoaded ? (
                          <CustomCkeditor
                            fieldname={"richtext_de"}
                            setFieldValue={setFieldValue}
                            value={values.richtext_de}
                            setFieldTouched={setFieldTouched}
                          />
                        ) : (
                          ""
                        )}
                        {/* <span className='text-danger d-flex text-left'>{errors.richtext_de && touched.richtext_de && errors.richtext_de}</span> */}
                      </div>
                      <div className="">
                        <button
                          onClick={() => {
                            setSaveType("Save");
                          }}
                          className="btn btn-info mr-2"
                          type="submit"
                        >
                          <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setSaveType("");
                          }}
                          className="btn btn-success mr-2"
                          type="submit"
                        >
                          <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                          Save & Exit
                        </button>
                        <button
                          className="btn ripple btn-secondary"
                          type="button"
                          onClick={() => navigate(-1)}
                        >
                          <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default QuestionEdit;
