import axiosInstance from "../../../utils/axios";

export const menuOptions = async (values) => {
    return await axiosInstance().post(`web/other/cms/options`,values);
}

export const markInvitation = async (values) => {
    return await axiosInstance().post(`web/other/mark-invitation`,values);
}

export const createPayment = async (values) => {
    return await axiosInstance().post(`web/other/payment`,values);
}

export const executePayment = async (values) => {
    return await axiosInstance().post(`web/other/execute-payment`,values);
}

export const globalSetting = async () => {
    return await axiosInstance().get(`web/other/global-settings/details`);
}

export const cmsDetails = async (data) => {
    return await axiosInstance().get(`web/other/cms/details?slug=${data}`);
}

export const cmsBanner = async (data) => {
    return await axiosInstance().get(`web/other/cms/banners`);
}

export const checkUser = async (data) => {
    return await axiosInstance().get(`web/other/is-registered-user?email=${data}`);
}

export const contactUs = async (values) => {
    return await axiosInstance().post(`web/other/contact-us/save`,values);
}

export const UserList = async (values) => {
    return await axiosInstance().post(`web/other/users/list`,values);
}

export const ReceiverInfo = async (userId) => {
    return await axiosInstance().get(`web/other/users/receiver-info?user_id=${userId}`);
}

export const UploadChatImage = async (data) => {
    return await axiosInstance().post(`web/other/chat/upload-image`,data);
}

export const DeleteMessage = async (data) => {
    return await axiosInstance().post(`web/other/chat/delete-message`,data);
}

export const GetMessages = async (room, limit) => {
    return await axiosInstance().get(`web/other/users/messages?room=${room}&limit=${limit}`);
}

export const InvitationsDetailsByEmail = async (o_id) => {
    return await axiosInstance().get(`web/other/events/invitations/details?o_id=${o_id}`);
}

export const EventRegistration = async (values) => {
    return await axiosInstance().post(`web/other/event/registration`,values);
}

export const EditEventRegistration = async (values) => {
    return await axiosInstance().post(`web/other/event/registrations/edit`,values);
}

export const NotificationsList = async (values) => {
    return await axiosInstance().post(`web/other/notifications/list`,values);
}

export const NotificationsMarkAsRead = async (values) => {
    return await axiosInstance().post(`web/other/notifications/mark-as-read`,values);
}

export const getASCToken = async (slug) => {
    return await axiosInstance().get(`web/other/get-ASC-token?slug=${slug}`);
}
