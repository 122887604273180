import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { EventDetail } from "../../services/eventdetailservice";
import SpeakerImage from '../../../../assets/user/images/speker-icon.png'
import ZoomImage from '../../../../assets/user/images/zoom-icon.svg'
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-transitions.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-zoom.css';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lg-autoplay.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import 'lightgallery/css/lg-rotate.css';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import 'lightgallery/css/lg-fullscreen.css';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  formateDateWithMonth,
  getUser,
  logOutUser,
  setSessionKey,
  TrimText
} from "../../../../utils/commonfunction";
import * as commonServices from "../../services/common.services";
import Swal from "sweetalert2";
import { SWAL_SETTINGS } from "../../../../utils/Constants";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next';



const EventDetailsPage = () => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();
  const { id } = useParams();  
  const [eventDetail, setEventDetail] = useState([]);
  const [UserAnnouncements, setUserAnnouncements] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const { t } = useTranslation();
  const url = window.location.href;
  let urlNew = url;
  let urlObj = new URL(urlNew);
  let params2 = new URLSearchParams(urlObj.search);
  var invitationValue = params2.get("invitation") ?params2.get("invitation") : params2.get("invitation_id") ?  params2.get("invitation_id") :null;
  useEffect(() => {
      EventDetail(id,invitationValue)
      .then((response) => {
        console.log(response)
        if (response?.success) {
          setEventDetail(response?.data);
          setUserAnnouncements(response && response.data && response.data.announcements_data ? response.data.announcements_data : [])
          setGalleryImages(response?.data?.gallery_images);
          if(invitationValue === "true"){
            if (!response?.data?.invitation_data) {
              logOutUser(navigate);
            }
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // }
  }, [id]);

  const leadData = useSelector((state) => state.designportal);

  const onDecline = () => {
    Swal.fire({
        customClass: 'swal-wide',
        title: 'Are you sure?',
        text: "You want to decline your invitation!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: leadData?.data?.background_colors?.primary_active_color,
        cancelButtonColor: leadData?.data?.colors?.danger,
        confirmButtonText: 'Yes'
    }).then((result) => {
        if (result.isConfirmed) {
          commonServices
            .markInvitation({
              action: 2,
              event_id: id,
              email: getUser().email /* for rejected */,
            })
            .then((response) => {
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
                navigate("/invitation-reject-page")
              }
            })
            .catch((error) => {
              console.log("error", error);
            });
        }
    })
  };



  return (
    <div className="wraper-inner cpt bg-grey">
      <section className="inner-space">
        <div className="container">
          {invitationValue === "true" ? 
          <>
          {eventDetail?.is_already_registered === false ? (
            <div className="dash-box-shadow d-flex justify-content-between mb-4 joint-event">
              <h3 className="inner-sub-title">{t('web_event_details_join_event')}</h3>
              {eventDetail?.is_rejected ? (
                <div className="btn-group-box">
                  <Link
                    to={`/event-registration?event_id=${id}&invitation_id=${params.get(
                      "invitation_id"
                    )}`}
                    className="btn btn-primary min-w"
                  >
                   {t('web_event_details_register')}
                  </Link>
                </div>
              ) : (
                <div className="btn-group-box">
                  <Link
                    to={`/event-registration?event_id=${id}&invitation_id=${params.get(
                      "invitation_id"
                    )}`}
                    className="btn btn-primary min-w"
                  >
                 {t('web_event_details_accept')}
                  </Link>
                  <button
                    className="btn btn-secondary ms-2"
                    onClick={() => onDecline()}
                  >
                  {t('web_event_details_decline')}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          </>
          :
          <div className="dash-box-shadow d-flex justify-content-between mb-4 joint-event">
              <h3 className="inner-sub-title">{t('web_event_details_join_event')}</h3>
              <div className="btn-group-box" onClick={()=>{setSessionKey('register_event_id',eventDetail?._id);setSessionKey('register_event_name',id)}}>
                  <Link
                    to={`/event-registration?event_id=${id}&invitation_id=${params.get(
                      "invitation_id"
                    )}`}
                    className="btn btn-primary min-w"
                  >
                   {t('web_event_details_register')}
                  </Link>
                </div>
            </div>
          }
          <div className="vanue-details-main event-detail">
            <figure>
              <img src={eventDetail?.image} alt="Event" />
            </figure>
            <div className="dash-box-shadow vanue-details">
              <div className="vanue-details-content">
                <h3>{eventDetail?.title}</h3>
                {eventDetail?.event_quota?.price != 0 ? (
                  <h4 className="mb-2">{t('web_lbl_price')}: <span>{eventDetail?.currency?.sign}{eventDetail?.event_quota?.price}</span></h4>
                ) : (
                  <h4 className="mb-2 text-success">{t('web_lbl_span')}</h4>
                )}
                <ul className="card-info-list">
                  <li>
                    <div className="date-info">
                      <i className="ri-calendar-event-line" />
                      {formateDateWithMonth(eventDetail?.start_date) +
                        " - " +
                        formateDateWithMonth(eventDetail?.end_date)}
                    </div>
                  </li>
                </ul>
                <div className="description-box">
                  <h4 className="title-stand"> {t('web_event_details_description')}</h4>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${
                        eventDetail?.description
                          ? eventDetail?.description
                          : "N/A"
                      }`,
                    }}
                  ></div>
                </div>
                {galleryImages && galleryImages.length > 0 ?<>

                <hr className="divider"/>
               <div className="media-box h-auto">
                  <h4 className="title-stand">{t('web_event_details_media')}</h4>
                  <LightGallery
                    elementClassNames="row media-rows"
                    speed={500}
                    plugins={[lgThumbnail, lgZoom, lgFullscreen, lgRotate]}
                  >
                    {galleryImages.map((image, i) => {
                      return <div className="col-md-3" key={i} href={image}>
                        <div className="img-box cp">
                          <img className="img-box-inner" src={image} alt="Gallery" />
                          <div className="img-box-content">
                            <figure><img src={ZoomImage} /></figure>
                            <h4>{t('web_event_details_view_photo')}</h4>
                          </div>
                        </div>
                      </div>
                    })}
                  </LightGallery>
               </div>
               </>
               :null}
               
                {UserAnnouncements && UserAnnouncements.length > 0 ?<>
                <hr className="divider" />
                <div  className="announcements-box">
                  <h4 className="title-stand">{t('web_header_heading_announcements')} </h4>
                  <ul className="announcements-box-list">
                                {UserAnnouncements.map((data, i) => {
                                    return <li key={i}>
                                        <figure><img src={SpeakerImage} /></figure>
                                        <figcaption>
                                            <h4>{data?.title}</h4>
                                            <p dangerouslySetInnerHTML={{ __html: `${TrimText(data?.description, 100)}` }} />
                                        </figcaption>
                                    </li>
                                })}
                            </ul>
               </div>
                </>
                :
                null
                }
              </div>
            </div>
          </div>
          {invitationValue === "true" ? 
          <>
          {eventDetail?.is_already_registered === false ? (
            <div className="dash-box-shadow d-flex justify-content-between mt-4 joint-event">
              <h3 className="inner-sub-title">{t('web_event_details_join_event')}</h3>
              {eventDetail?.is_rejected ? (
                <div className="btn-group-box">
                  <Link
                    to={`/event-registration?event_id=${id}&invitation_id=${params.get(
                      "invitation_id"
                    )}`}
                    className="btn btn-primary min-w"
                  >
                   {t('web_event_details_register')}
                  </Link>
                </div>
              ) : (
                <div className="btn-group-box">
                  <Link
                    to={`/event-registration?event_id=${id}&invitation_id=${params.get(
                      "invitation_id"
                    )}`}
                    className="btn btn-primary min-w"
                  >
                   {t('web_event_details_accept')}
                  </Link>
                  <button
                    className="btn btn-secondary ms-2"
                    onClick={() => onDecline()}
                  >
                   {t('web_event_details_decline')}
                  </button>
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
          </>
          :
          <div className="dash-box-shadow d-flex justify-content-between mb-4 mt-4 joint-event">
          <h3 className="inner-sub-title">{t('web_event_details_join_event')}</h3>
          <div className="btn-group-box">
              <Link
                to={`/event-registration?event_id=${id}&invitation_id=${params.get(
                  "invitation_id"
                )}`}
                className="btn btn-primary min-w"
              >
               {t('web_event_details_register')}
              </Link>
            </div>
        </div>
          }
        </div>
      </section>
    </div>
  );
};

export default EventDetailsPage;
