import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Formik } from 'formik'
import Swal from 'sweetalert2'
import { getUser, handleServerValidations, setLocalKey, getNameFromList, blockInvalidChar } from '../../../utils/commonfunction';
import { useSelector } from 'react-redux';
import * as userService from '../services/user.service';
import { SWAL_SETTINGS, EMAIL_REGEX } from '../../../utils/Constants';
// import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import * as Yup from "yup";
// import 'react-phone-number-input/style.css'
import  {getCountries, getCountryCallingCode, } from "react-phone-number-input";
import ReactCountryFlag from "react-country-flag"


const EditProfile = () => {
  const navigate = useNavigate()
  const showprofile = useSelector((state) => state.profile);
  const [showdefault, setShowDefault] = useState({})
  const [updateData, setUpdateData] = useState('false')
  const [submitted, setSubmitted] = useState(false)



  useEffect(() => {
    const getuser = getUser()
    setShowDefault(getuser)
  }, [updateData])

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().trim().required('First name is required').matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed in the first name"),
    // middle_name: Yup.string().matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed in the middle name"),
    middle_name: Yup.string(),
    // last_name: Yup.string().trim().required('Last name is required').matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed in the last name"),
    last_name: Yup.string().trim().required('Last name is required'),
    email: Yup.string().trim().required("Email is required").matches(EMAIL_REGEX, "Email should be valid"),
    files: Yup.mixed().required('Image is required').test(
      'fileType',
      'Unsupported file format',
      (value) => {
        if (!value) {
          const supportedFormats = ['image/jpeg', 'image/png', 'image/gif'];
          return supportedFormats.includes(value.type);
        } else {
          return true
        }
      }
    ),
    phone_number: Yup.string()
      .trim()
      .required('Phone number is required')
      .test("min-length", "Phone number cannot be less than 10 digits", (value) => {
      if(value?.trim()?.length < 10){
          return false
        }else{
          return true
        }
      })
      .test("max-length", "Phone number cannot be more than 13 digits", (value) => {
        if(value?.trim()?.length > 13){
          return false
        }else{
          return true
        }
      }),
    phone_number_work: Yup.string()
      .trim()
      .required('Phone number work is required')
      .test("min-length", "Phone number cannot be less than 10 digits", (value) => {
        if(value?.trim()?.length < 10){
          return false
        }else{
          return true
        }
      })
      .test("max-length", "Phone number cannot be more than 13 digits", (value) => {
        if(value?.trim()?.length > 13){
          return false
        }else{
          return true
        }
      })
    })

    const showDropDown = (country) => {
      return(
        <label>{ "   " + getNameFromList(country)} {" +"}{getCountryCallingCode(country)}</label>
      )
    }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        first_name: showdefault && showdefault.first_name ? showdefault.first_name : "",
        middle_name: showdefault && showdefault.middle_name ? showdefault.middle_name : "",
        last_name: showdefault && showdefault.last_name ? showdefault.last_name : "",
        email: showdefault && showdefault.email ? showdefault.email : "",
        files: showdefault && showdefault.profile_image ? showdefault.profile_image : "",
        phone_number:showdefault && showdefault.phone_number && showdefault.phone_number.number ? showdefault.phone_number.number : "",
        dialCodePhone:showdefault && showdefault.phone_number && showdefault.phone_number.code ? showdefault.phone_number.code : "+" + getCountryCallingCode(getCountries()[0]),
        countryCodePhone: showdefault && showdefault.phone_number && showdefault.phone_number.countryCode ? showdefault.phone_number.countryCode : "DE",
        phone_number_work:showdefault && showdefault.phone_number_work && showdefault.phone_number_work.number ? showdefault.phone_number_work.number : "",
        dialCodePhoneWork: showdefault && showdefault.phone_number_work && showdefault.phone_number_work.code ? showdefault.phone_number_work.code : "+" + getCountryCallingCode(getCountries()[0]),
        countryCodePhoneWork: showdefault && showdefault.phone_number_work && showdefault.phone_number_work.countryCode ? showdefault.phone_number_work.countryCode : "",
      }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitted(true)
        let formData = new FormData();
        formData.append('first_name', values.first_name);
        formData.append('middle_name', values.middle_name);
        formData.append('last_name', values.last_name);
        formData.append('email', values.email);
        if(values.phone_number && values.dialCodePhone){
          formData.append('phone_number', JSON.stringify({
            code        : values.dialCodePhone, 
            number      : values.phone_number, 
            countryCode : values.countryCodePhone}));
        }
        if(values.phone_number_work && values.dialCodePhoneWork){
          formData.append('phone_number_work', JSON.stringify({
            code        : values.dialCodePhoneWork, 
            number      : values.phone_number_work,
            countryCode : values.countryCodePhoneWork
          }));
        }
        if (showprofile && showprofile.file) {
          formData.append('image', showprofile.file);
        }
        userService.updateprofile(formData).then(response => {
          setSubmitted(false);
          if (response.success) {
            setLocalKey("user", JSON.stringify(response.data))
            Swal.fire({
              icon: 'success',
              text: response.message,
              ...SWAL_SETTINGS
            })
            resetForm({ values: '' });
            setUpdateData(!updateData)
          } else {
            Swal.fire({
              icon: 'error',
              text: handleServerValidations(response),
              ...SWAL_SETTINGS
            })
          }
          }).catch(error => {
            setSubmitted(false)
            console.log("error ====> ", error);
          })
        }}
      >{({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row row-sm">
              <div className="col-lg-12 col-md-12 animation_fade">
                <div className="card custom-card">
                  <div className="mt-5">
                    <div className="form-group ">
                      <div className="row row-sm">
                        <div className="col-md-4">
                        <label className="text-left d-flex" htmlFor='Email'>First name:<span className="requirestar">*</span></label>
                          <input type="text" className="form-control" id="first_name" name="first_name" onChange={handleChange} onBlur={handleBlur} value={values.first_name} 
                          placeholder="Enter your first name" />
                          <span className='text-danger d-flex text-left'>{errors.first_name && touched.first_name && errors.first_name}</span>
                        </div>
                      </div>
                    </div>
                                    
                    <div className="form-group ">
                      <div className="row row-sm">
                        <div className="col-md-4">
                        <label className="text-left d-flex" htmlFor='Email'>Middle name:</label>
                          <input type="text" className="form-control" id="middle_name" name="middle_name" onChange={handleChange} onBlur={handleBlur} value={values.middle_name} 
                          placeholder="Enter your middle name" />
                          <span className='text-danger d-flex text-left'>{errors.middle_name && touched.middle_name && errors.middle_name}</span>
                        </div>
                      </div>
                    </div>

                    <div className="form-group ">
                      <div className="row row-sm">
                        <div className="col-md-4">
                        <label className="text-left d-flex" htmlFor='Email'>Last name:<span className="requirestar">*</span></label>
                          <input type="text" className="form-control" id="last_name" name="last_name" onChange={handleChange} onBlur={handleBlur} value={values.last_name} 
                          placeholder="Enter your last name" />
                          <span className='text-danger d-flex text-left'>{errors.last_name && touched.last_name && errors.last_name}</span>
                        </div>
                      </div>
                    </div>

                    <div className="form-group ">
                      <div className="row row-sm">
                        <div className="col-md-4">
                        <label className="text-left d-flex" htmlFor='email'>Email:<span className="requirestar">*</span></label>
                          <input type="text" id="email" name='email' className="form-control" placeholder="Enter your email" value={values.email} onChange={handleChange} onBlur={handleBlur} autoComplete="off" />
                          <span className='text-danger d-flex text-left'>{errors.email && touched.email && errors.email}</span>
                        </div>
                      </div>
                    </div>
                    <div className="form-group ">
                      <div className="row row-sm">
                        <div className="col-md-4">
                          <label className="text-left d-flex" htmlFor='phone_number'>Phone number:</label>
                          <div className="form-control d-flex">
                            <select
                              className='selectInput'
                              style={{ height: "100%", paddingLeft: 0, paddingTop: 0, marginTop: 0, border: 0, width: 40, }} 
                              value={values.countryCodePhone} 
                              onChange={(event) => {
                                console.log("event", event);
                                setFieldValue("countryCodePhone", event.target.value)
                                setFieldValue("dialCodePhone", "+" +getCountryCallingCode(event.target.value))
                              }}
                                
                              >
                              
                              {getCountries().map((country) => (<>

                                {/* return( */}
                                  {/* <option></option> */}
                                  <option key={country} value={country} >
                                    <ReactCountryFlag countryCode={country} />
                                      
                                      { showDropDown(country) }
                                      
                                  </option>
                                {/* ) */}
                                </>))}
                                  
                            </select>
                            <label style={{padding: 0, margin: 0, paddingTop: 2}}>{values.dialCodePhone}</label>
                            <input 
                              className={"phoneInput"}
                              placeholder="Enter your phone number"
                              name="phone_number"
                              style={{ border: 0}}
                              onChange={event => {
                                setFieldValue(`phone_number`, event.nativeEvent.target.value)
                              }}
                            // onBlur={handleBlur}
                              value={`${values.phone_number}`} 
                              onKeyDown={blockInvalidChar}
                            />
                          </div>
                          <span className='text-danger d-flex text-left'>{errors.phone_number && errors.phone_number}</span>
                          {/* <PhoneInput
                              id="phone_number"
                              type="text"
                              defaultCountry="de"
                              CountryDat
                              style={{padding: 0, border: 0}}
                              countrySelectorStyleProps={{height: "auto", buttonStyle: { height: "100%",paddingLeft: 5, paddingRight: 5}}}
                              inputStyle={{height: "100%", width: "100%", paddingLeft: 12, fontSize: 14}}
                              name="phone_number"
                              placeholder="Phone number"
                              className="form-control d-flex phone-field-admin"
                              forceDialCode={true}
                              value={values?.dialCodePhone+values?.phone_number}
                              onChange={(value) => {
                                  setFieldValue("dialCodePhone", value?.substring(0, value.indexOf(" ")));
                                  setFieldValue(`phone_number`, value?.substring(value.indexOf(" ") + 1));
                              }}
                          /> */}

                          {/* <PhoneInput 
                            country={"de"}
                            value={values.dialCodePhone + values.phone_number} 
                            placeholder="Phone number" 
                            className="form-control"
                            // forceDialCode={true}
                            buttonStyle={{background: "#fff", paddingLeft: 5, paddingRight: 5}}
                            containerStyle={{padding: 0, border: 0}} 
                            inputStyle={{height: "100%", marginLeft: 10}}
                            onChange={(value, data, event, formattedValue) => {
                              // console.log("formattedValue", formattedValue);
                              setFieldValue("dialCodePhone", formattedValue.substring(0, formattedValue.indexOf(" ")))
                              setFieldValue(`phone_number`, formattedValue.substring(formattedValue.indexOf(" ") + 1))
                            }} 
                          /> */}
                          {/* <PhoneInput
                            // countries={countries} 
                            countryCode={values.dialCodePhone}
                            defaultCountry={"DE"}
                            value={values.dialCodePhone + values.phone_number} 
                            placeholder="Phone number" 
                            className="form-control d-flex"
                            flags={flags}
                            flagUrl={`https://flag.pk/flags/4x3/IN.svg`}
                            style={{padding: 0, margin: 0}}
                            inputComponent={() => (
                              <input 
                                className={"form-control"}
                                placeholder="Enter your phone number"
                                name="phone_number"
                                style={{borderRight: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}}
                                onChange={event => {
                                  console.log("value", event.nativeEvent.target.value)
                                  // setFieldValue("dialCodePhone", value.substring(0, value.indexOf(" ")))
                                  setFieldValue(`phone_number`, event.nativeEvent.target.value)
                                }}
                              // onBlur={handleBlur}
                                value={`+${values.dialCodePhone}  +${values.phone_number}`} 
                              // onKeyDown={blockInvalidChar}
                              />
                            )}
                           
                            countrySelectProps={{flags: flags, }}
                            countrySelectComponent={() => (
                              <>
                                <select
                                  style={{height: "100%", paddingLeft: 10, border: 0, width: 50}} 
                                  value={values.countryCodePhone} 
                                  onChange={(event) => {
                                  console.log("event", event);
                                    setFieldValue("countryCodePhone", event.target.value)
                                    setFieldValue("dialCodePhone", getCountryCallingCode(event.target.value))
                                  }}
                                   
                                  >
                                  
                                  {getCountries().map((country) => {

                                    return(
                                      <option key={country} value={country} >
                                        <ReactCountryFlag countryCode={country} />
                                        {getNameFromList(country)}
                                        
                                        {getCountryCallingCode(country)}
                                      </option>
                                    )}
                                  )}
                                  
                                  </select>
                                  
                                </>
                              )}
                            countrySelectorStyleProps={{height: "auto", buttonStyle: { height: "100%",paddingLeft: 5, paddingRight: 5}}}
                            
                          />  */}
                      </div>
                    </div>
                  </div>

                    <div className="form-group ">
                      <div className="row row-sm">
                        <div className="col-md-4">
                          <label className="text-left d-flex" htmlFor='phone_number_work'>Phone number work:</label>
                          {/* <PhoneInput 
                            country={"de"}
                            value={values?.dialCodePhoneWork + values?.phone_number} 
                            placeholder="Phone number" 
                            className="form-control"
                            buttonStyle={{background: "#fff", paddingLeft: 5, paddingRight: 5, borderTop: 0, borderBottom: 0, borderLeft: 0,}}
                            containerStyle={{padding: 0,}} 
                            inputStyle={{height: "100%",  marginLeft: 10, border: 0,}}
                            onChange={(value, data, event, formattedValue) => {
                              // console.log("formattedValue", formattedValue);
                              setFieldValue("dialCodePhoneWork", formattedValue.substring(0, formattedValue.indexOf(" ")))
                              setFieldValue(`phone_number_work`, formattedValue.substring(formattedValue.indexOf(" ") + 1))
                            }} 
                          /> */}
                          <div className="form-control d-flex">
                            <select
                              className='selectInput'
                              style={{ height: "100%", paddingLeft: 0, paddingTop: 0, marginTop: 0, border: 0, width: 40, }} 
                              value={values.countryCodePhoneWork} 
                              onChange={(event) => {
                                setFieldValue("countryCodePhoneWork", event.target.value)
                                // const addPlus =  
                                setFieldValue("dialCodePhoneWork", "+" + getCountryCallingCode(event.target.value))
                              }}
                                
                              >
                              
                              {getCountries().map((country) => (<>
                                  <option key={country} value={country} >
                                    <ReactCountryFlag countryCode={country} />
                                      { showDropDown(country) }
                                  </option>
                                </>))}
                                  
                            </select>
                            <label style={{padding: 0, margin: 0, paddingTop: 2}}>{values.dialCodePhoneWork}</label>
                            <input 
                              className={"phoneInput"}
                              placeholder="Enter your phone number"
                              name="phone_number_work"
                              style={{ border: 0}}
                              onChange={event => {
                                setFieldValue(`phone_number_work`, event.nativeEvent.target.value)
                              }}
                            // onBlur={handleBlur}
                              value={`${values.phone_number_work}`} 
                              onKeyDown={blockInvalidChar}
                            />
                          </div>
                          <span className='text-danger d-flex text-left' b>{errors.phone_number_work && errors.phone_number_work}</span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-5">
                      <button className="btn btn-info mr-2" type="submit" disabled={submitted ? true : null}>
                        <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                        Submit
                      </button>
                      <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                        <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
      )}
    </Formik>
  )
}

export default EditProfile