import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sorting from "../../common/sorting";
import $ from "jquery";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CustomPagination from "../../common/custompagination";
import Loader from "../../common/loader";
import * as announcementsService from '../../services/announcements.services';
import * as eventServices from '../../services/events.services';
import {
    SWAL_SETTINGS,
    showFilterlist,
    ANNOUNCEMENT_TYPES,
} from "../../../../utils/Constants";
import {
    formateDate,
    handleServerValidations,
    hasPermission,
} from "../../../../utils/commonfunction";
import StatusFilter from "../../common/statusFilter";
import CustomRangepicker from "../../common/rangepicker";


const ViewEventAnnouncements = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState({});
    const [globalsearch, setGlobalSearch] = useState("");
    const [resetdate, setResetDate] = useState(false);
    const params = useParams();
    const [announcementsList, setAnnouncementsList] = useState([]);
    const [statsupdate, setStatusUpdate] = useState("false");
    const [datalength, setDataLength] = useState("");
    const [itemperpage] = useState(10);
    const [sorting, setSorting] = useState({});
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const location = useLocation();
    /********** MULTI SELECT >>>>>>********* */
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [selectedAllData, setSelectedAllData] = useState(false);
    const selectAllCheckbox = useRef();
    /********** MULTI SELECT <<<<<<********* */

    useEffect(() => {
        setLoader(true);
        const formData = new FormData();
        formData.append("page", params.pgno);
        formData.append("per_page", itemperpage);
        formData.append("sort", JSON.stringify(sorting));
        formData.append("search", JSON.stringify(search));
        formData.append("global_search", globalsearch);
        formData.append("event_id", params.id);
        eventServices
            .AnnouncementsList(formData)
            .then((response) => {
                setDataLength(response.data.total_records);
                setAnnouncementsList(response && response?.data && response?.data?.list ? response.data.list : []);
                setSelectedAllData(response && response.data && response.data.data_ids ? response.data.data_ids : []);
                setPage(response && response.data && response.data.page ? response.data.page : 1);
                setLoader(false);
            })
            .catch((error) => {
                console.log("error ====> ", error);
            });

    }, [location, statsupdate, sorting, search, globalsearch,itemperpage,params.pgno,params.id]);



    const viewfunction = (row) => {
        navigate(`/admin/announcements/view/${row._id}`);
    };

    const goToEdit = (row) => {
        navigate(`/admin/announcements/${params.pgno}/edit/${row._id}`);
    };

    function Deletefunction(data) {
        Swal.fire({
            customClass: "swal-wide",
            title: "Are you sure?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#403fad",
            cancelButtonColor: "#f1388b",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.isConfirmed) {
                let ids = Array.isArray(data) ? data : [data];
                const formdata = new FormData();
                formdata.append("o_id", JSON.stringify(ids));
                announcementsService
                    .Delete(formdata)
                    .then((response) => {
                        setStatusUpdate(!statsupdate);
                        if (response.success) {
                            Swal.fire({
                                icon: "success",
                                text: response.message,
                                ...SWAL_SETTINGS,
                            });
                            /** removing deleted ids from selectedRecords */
                            // setSelectedRecords((data) => data.filter((item) => {
                            //     if (ids.indexOf(item) === -1) {
                            //         return item;
                            //     }
                            // }));
                            setSelectedRecords((data) => data.filter((item) => ids.indexOf(item) === -1));

                        } else {
                            Swal.fire({
                                icon: "error",
                                text: handleServerValidations(response),
                                ...SWAL_SETTINGS,
                            });
                        }
                    })
                    .catch((error) => {
                        console.log("deleteError");
                    });
            }
        });
    }

    // sorting function start
    const handleSort = (e, column) => {
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    };
    // sorting end

    // search or filter function start
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    };
    // search or filer function  end

    // reset filter function start
    const resetFilter = (e) => {
        e.preventDefault();
        setGlobalSearch("");
        prepareSearch();
        setSearch({});
        setResetDate(!resetdate);
        $("#defaultstatus")[0].selectedIndex = 0;
    };
    // reset filter function end

    /********** MULTI SELECT START >>>>>>********* */
    useEffect(() => {
        if (selectedRecords.length === datalength) {
            selectAllCheckbox.current.indeterminate = false;
            selectAllCheckbox.current.checked = true;
        } else if (selectedRecords.length) {
            selectAllCheckbox.current.indeterminate = true;
        } else {
            selectAllCheckbox.current.indeterminate = false;
        }
    }, [selectedRecords]);
    const selectRecord = (userData, check) => {
        if (check) {
            setSelectedRecords((previousState) => [...previousState, userData._id]);
        } else {
            setSelectedRecords((previousState) => previousState.filter((item) => item !== userData._id));
        }
    };
    const handleSelectAll = (check) => {
        if (check) {
            setSelectedRecords(selectedAllData);
        } else {
            setSelectedRecords([]);
        }
    };
    const isSelected = (data) => {
        return (selectedRecords.filter((item) => data?._id === item).length > 0);
    };
    /********** MULTI SELECT END<<<<<<<<<********* */



    return (
        <>
            <div className="row">
                <div className="d-flex justify-content-between align-items-center mb-4">
                    <h6 className="main-content-label"><span></span></h6>
                    <div className="d-flex align-items-center">
                        <div className="form-group mb-0 me-3">
                            <div className="form-group mb-0 rangepicker_container filter_design">
                                <i className="fa fa-search calender_icon"></i>
                                <input
                                    type="search"
                                    className="form-control"
                                    value={globalsearch}
                                    placeholder="Search"
                                    onChange={(e) => {
                                        setGlobalSearch(e.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div className="me-3">
                            <CustomRangepicker
                                GetDateRange={(e) => {
                                    prepareSearch("createdAt", e);
                                }}
                                resetdate={resetdate}
                            />
                        </div>
                        <div className="me-3">
                            <StatusFilter
                                data={showFilterlist}
                                prepareSearch={prepareSearch}
                            />
                        </div>

                        <button
                            type="reset"
                            value="Reset"
                            onClick={resetFilter}
                            className="btn btn-warning float-right mr-2"
                        >
                            Reset Filter
                        </button>
                        {hasPermission('/admin/event-management/events/view/event-announcements/button') ?
                        <button
                            className="btn ripple btn-main-primary signbtn"
                            onClick={() => navigate(`/admin/announcements/add`,{state: {event_id: params.id, announcement_type : ANNOUNCEMENT_TYPES.EVENT, prevPage : 'event_announce'}})}
                        >
                            Add New
                        </button>
                        :null}
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                        <thead>
                            <tr>
                                <th className={"position-relative select_head " + (hasPermission('/admin/event-management/events/view/event-announcements/multi-select') ? '': "d-none")}>
                                    <div className="select-check-container">
                                        <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }}
                                            className="ckbox  cp">
                                            <input
                                                ref={selectAllCheckbox}
                                                id="selectAllCheck"
                                                checked={
                                                    datalength && datalength === selectedRecords.length
                                                }
                                                onChange={(e) => handleSelectAll(e.target.checked)} type="checkbox" />
                                            <span></span>
                                        </label>
                                    </div>
                                </th>
                                <th>
                                    <div className="sorting_column">
                                        <span>Title</span>
                                        <Sorting
                                            sort={sorting}
                                            handleSort={handleSort}
                                            column="title"
                                        />
                                    </div>
                                </th>

                                <th>
                                    <div className="sorting_column">
                                        <span>Created Date</span>
                                        <Sorting
                                            sort={sorting}
                                            handleSort={handleSort}
                                            column="createdAt"
                                        />
                                    </div>
                                </th>
                                <th>Created By</th>
                                <th>Updated By</th>
                                <th className="status_head">Status</th>
                                <th className="action_head">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loader ? (
                                <tr>
                                    <td colSpan={9}>
                                        <Loader />
                                    </td>
                                </tr>
                            ) : (
                                <>
                                    {announcementsList.length ? (
                                        announcementsList.map((row, i) => {
                                            return (
                                                <tr key={i} className={isSelected(row) ? "selected" : ""}>
                                                    <td className={"position-relative " + (hasPermission('/admin/event-management/events/view/event-announcements/multi-select') ? '': "d-none")}>
                                                        <div className="select-check-container">
                                                            <label htmlFor={row?._id + "input"} style={{ lineHeight: 'unset' }}
                                                                className="ckbox cp">
                                                                <input
                                                                    id={row?._id + "input"}
                                                                    checked={isSelected(row)}
                                                                    onChange={(e) =>
                                                                        selectRecord(row, e?.target?.checked)
                                                                    }
                                                                    type="checkbox"
                                                                    className="form-check select-check cp" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </td>

                                                    <td>{row?.title ? row.title : "N/A"}</td>


                                                    <td>
                                                        {row?.createdAt
                                                            ? formateDate(row?.createdAt)
                                                            : "N/A"}
                                                    </td>
                                                    <td>{row?.createdBy?.name ? <Link to={`/admin/staff/view/${row.createdBy._id}`}>{row.createdBy.name}</Link> : "N/A"}</td>
                                                    <td>{row?.updatedBy?.name ? <Link to={`/admin/staff/view/${row.updatedBy._id}`}>{row.updatedBy.name}</Link> : "N/A"}</td>
                                                    <td>{row?.status === 1 ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Inactive</span>}
                                                    </td>

                                                    <td>
                                                        <div className="d-flex">
                                                            <button
                                                                className="btn ripple btn-main-primary signbtn"
                                                                onClick={() => {
                                                                    viewfunction(row);
                                                                }}
                                                            >
                                                                View
                                                            </button>
                                                            {hasPermission('/admin/event-management/events/view/event-announcements/button') ?
                                                            <button
                                                                className="btn ripple btn-success mlAction "

                                                                onClick={() => {
                                                                    goToEdit(row);
                                                                }}
                                                            >
                                                                Edit
                                                            </button>
                                                            :null}
                                                            {hasPermission('/admin/event-management/events/view/event-announcements/delete') ?
                                                            <button
                                                                className="btn ripple btn-secondary mlAction "

                                                                onClick={() => {
                                                                    Deletefunction(row?._id);
                                                                }}
                                                            >
                                                                Delete
                                                            </button>
                                                            :null}

                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={9} className="text-center">
                                                No records
                                            </td>
                                        </tr>
                                    )}
                                </>
                            )}
                        </tbody>
                    </table>
                </div>
                <div
                    className=""
                    id="example1_info"
                    role="status"
                    aria-live="polite"
                >
                    <b>Total Records : {datalength ? datalength : "0"}</b>
                </div>
                {datalength && datalength > 0 ? (
                    <CustomPagination
                        datalength={datalength}
                        itemperpage={itemperpage}
                        currentPage={page}
                        setPage={setPage}
                        pageRoute={[
                            {
                                name: "Event Announcements",
                                path: `/admin/event-management/events/view/event-announcements/${params.id}`,
                            },
                        ]}
                    />
                ) : (
                    ""
                )}
            </div>
            {selectedRecords.length ?
                <div className="bulk_actions">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='d-flex align-items-center'>
                            <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "23px", color: "#35b159" }}></i>
                            <h6 className='ms-2 mt-1 mb-0'>Selected {selectedRecords.length} {selectedRecords.length > 1 ? "records" : "record"} of {datalength}</h6>
                        </div>
                        <div>
                            <ul>
                            {hasPermission('/admin/event-management/events/view/event-announcements/delete') ?
                                    <li>
                                        <button className="btn ripple btn-secondary" onClick={() => { Deletefunction(selectedRecords) }}>Delete</button>
                                    </li>
                               :null}
                            </ul>
                        </div>
                    </div>
                </div>
                : ""
            }
        </>
    );
};

export default ViewEventAnnouncements;