import React, { useEffect, useState } from "react";
import * as eventsService from "../../services/events.services";
import Loader from "../../common/loader";
import {
  addSpecialCharacter,
  capitalizeFirstLetter,
  formateDate,
  formateDateWithMonth,
  globalLoader,
  removeCommas,
  removeSpecialCharacter,
  writeMyExcel
} from "../../../../utils/commonfunction";
import Sorting from "../../common/sorting";
import CustomPagination from "../../common/custompagination";
import { Modal } from "rsuite";
import {  SWAL_SETTINGS } from "../../../../utils/Constants";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

const ViewHotels = (props) => {
  const params = useParams();
  const [sorting, setSorting] = useState({});
  const [loader, setLoader] = useState(false);
  const [hotelList, setHotelList] = useState([]);
  const [open, setOpen] = useState(false);
  const [quotaData, setQuotaData] = useState([]);
  const [itemperpage] = useState(10);
  const [page, setPage] = useState(1);
  const [roomType, setRoomType] = useState("");
  const [csvHeaders, setCsvHeaders] = useState(["Creation Date", "Change Date", "Salutation", "Title", "First Name", "Middle Name", "Last Name","Email", "Hotel Name", "Room Type", "Check In", "Check Out", "Number Of Persons", "Guest Salutation", "Guest Title", "Guest First name ", "Guest Last name", "Guest Email"]);
  const [allUsersExportFlag] = useState(false);
  const [datalength, setDataLength] = useState("");


  useEffect(() => {
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", itemperpage);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("event_id", params.id);
    eventsService
      .ListHotels(formData)
      .then((response) => {
        console.log("res", response);
        setLoader(false);
        if (response?.success) {
          setHotelList(response.data.list);
          setDataLength(response?.data?.total_records);
          setPage(response && response.data && response.data.page ? response.data.page : 1);
        }
      })
      .catch((err) => console.log("err", err));
  }, [sorting, page,params.pgno,itemperpage]);

/** This function is used to export all user's data */
  const exportAllUsers = (type="all", hotelId) => {
    globalLoader(true);
    const formData = new FormData();
    formData.append("page", 1);
    formData.append("per_page", datalength);
    formData.append("sort", JSON.stringify(sorting));
    if(type === "single"){
      formData.append("hotel_id", hotelId);
      formData.append("type", type);
    }else{
      formData.append("event_id", params?.id);
    }
    eventsService
      .ReportHotel(formData)
      .then((response) => {
        let data = response &&  response?.data ? response.data : [];
        if(data?.length > 0){
          let row = data.map((item => {
            const tempRow = [
              removeCommas(formateDate(item?.createdAt)),
              removeCommas(formateDate(item?.updatedAt)),
              removeCommas(item?.user_data?.gender) ? removeCommas(item.user_data.gender) : "N/A",
              removeCommas(item?.user_data?.title) ? removeCommas(item.user_data.title) : "N/A",
              removeCommas(item?.user_data?.first_name) ? removeCommas(item.user_data.first_name) : "N/A",
              removeCommas(item?.user_data?.middle_name) ? removeCommas(item.user_data.middle_name) : "N/A",
              removeCommas(item?.user_data?.last_name) ? removeCommas(item.user_data.last_name) : "N/A",
              removeCommas(item?.user_data?.email) ? removeCommas(item.user_data.email) : "N/A",
              removeCommas(item?.hotel_name) ? removeCommas(item?.hotel_name) : "N/A",
              removeCommas(item?.room_type) ? removeCommas(removeSpecialCharacter(capitalizeFirstLetter(item?.room_type))) : "N/A",
              removeCommas(formateDate(item?.check_in)),
              removeCommas(formateDate(item?.check_out)),
              item?.persons ? item?.persons : "N/A",
              removeCommas(item?.guest_data?.gender) ? removeCommas(item.guest_data.gender) : "N/A",
              removeCommas(item?.guest_data?.title) ? removeCommas(item.guest_data.title) : "N/A",
              removeCommas(item?.guest_data?.first_name) ? removeCommas(item.guest_data.first_name) : "N/A",
              removeCommas(item?.guest_data?.last_name) ? removeCommas(item.guest_data.last_name) : "N/A",
              removeCommas(item?.guest_data?.email) ? removeCommas(item.guest_data.email) : "N/A"
            ];
  
            const tempData = [...csvHeaders];
            setCsvHeaders([...tempData]);
            return tempRow;
          }));;
          writeMyExcel([csvHeaders, ...row],`Hotel-${addSpecialCharacter(hotelList[0]?.event_data?.title)}.xlsx`).then(()=>{
            setTimeout(() => {
              globalLoader(false);
            }, 1000);
            }).catch((err)=>{
              console.log(err);
            });
        }else{
          Swal.fire({
            icon: "error",
            text: " No bookings found",
            ...SWAL_SETTINGS,
        });
        globalLoader(false);
        }
      }).catch((error) => {
        console.log("error ====> ", error);
        globalLoader(false);
      });
  };

/** This function is used to export all hotel's data end */
  const showQuotaData = (data, type) => {
    setRoomType(type);
    data?.forEach((item, index) => {
      if (type === "single") {
        setQuotaData((prevState) => [...prevState, { ...item?.single_room, date: item?.date }]);
        setOpen(true);
      } else if (type === "double") {
        setQuotaData((prevState) => [...prevState, { ...item?.double_room, date: item?.date }]);
        setOpen(true);
      }
    });
  };

  const handleSort = (e, column) => {
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };

  return (
    <div className="row">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="main-content-label"><span></span></h6>
        <div className="d-flex align-items-center">
          {
            allUsersExportFlag ?
              <>
                <button className="btn ripple btn-main-primary signbtn mr-2" disabled={datalength ===0 } onClick={exportAllUsers}>Export Report</button>
              </>
              : <button className="btn ripple btn-main-primary signbtn mr-2" disabled={datalength === 0} onClick={exportAllUsers}>Export Report</button>
          }
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
          <thead>
            <tr>
              <th>
                <div className="sorting_column">
                  <span>Name</span>
                  <Sorting
                    sort={sorting}
                    handleSort={handleSort}
                    column="name"
                  />
                </div>
              </th>
              <th>City tax </th>
              <th>Tax %</th>
              <th>Breakfast tax %</th>
              <th className="action_head">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={7}>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {hotelList.length ? (
                  hotelList.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row?.name ? row.name : "N/A"}</td>
                        <td>{row?.city_tax ? row.city_tax : "N/A"}</td>
                        <td>{row?.tax ? row.tax : "N/A"}</td>
                        <td>{row?.breakfast_tax ? row.breakfast_tax : "N/A"}</td>
                        <td>
                          <div className="d-flex">
                            <button
                              className="btn ripple btn-main-primary signbtn"
                              onClick={() => {
                                showQuotaData(row?.quota, "single");
                              }}
                            >
                              Single Rooms
                            </button>
                            <button
                              className="btn ripple btn-main-primary signbtn ms-2"
                              onClick={() => {
                                showQuotaData(row?.quota, "double");
                              }}
                            >
                              Double Rooms
                            </button>
                            <button className="btn ripple btn-main-primary signbtn ms-2" onClick={()=>{exportAllUsers('single',row._id)}}>Export</button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center">
                      No records
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="" id="example1_info" role="status" aria-live="polite">
        <b>Total Records : {datalength ? datalength : "0"}</b>
      </div>
      {datalength && datalength > 0 ? (
        <CustomPagination
          datalength={datalength}
          itemperpage={itemperpage}
          currentPage={page}
          setPage={setPage}
          pageRoute={[
            {
              name: "Events",
              path: `/admin/event-management/events/view/hotels/${params.id}`,
            },
          ]}
        />
      ) : (
        ""
      )}
      <Modal
        size={"lg"}
        open={open}
        backdrop={'static'}
        onClose={() => {
          setOpen(false);
          setQuotaData([]);
        }}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>
            {roomType === "single" ? "Single Room Quota" : "Double Room Quota"}
          </Modal.Title>
        </Modal.Header>
        <div className="table-responsive">
          <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
            <thead>
              <tr>
                <th>
                  <span>Date</span>
                </th>
                <th>
                  <span>Total Quantity</span>
                </th>
                <th>Sold Quantity</th>
                <th>Available Quantity</th>
                <th>Price</th>
                <th>Breakfast Price</th>
              </tr>
            </thead>
            <tbody>
              {loader ? (
                <tr>
                  <td colSpan={6}>
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {quotaData.length ? (
                    quotaData.map((row, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {row?.date
                              ? formateDateWithMonth(row?.date)
                              : "N/A"}
                          </td>
                          <td>
                            {row?.total_quantity ? row?.total_quantity : 0}
                          </td>
                          <td>{row?.sold_quantity ? row?.sold_quantity : 0}</td>
                          <td>
                            {row?.available_quantity
                              ? row?.available_quantity
                              : 0}
                          </td>
                          <td>{row?.price ? row?.price : 0}</td>
                          <td>
                            {row?.breakfast_price ? row?.breakfast_price : 0}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No records
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default ViewHotels;
