import React, { useEffect, useState } from 'react';
import * as otherServices from '../services/common.services';
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { attachDynamicStrings, setTitle } from '../../../utils/commonfunction'
import likeImage from "../../../assets/user/images/cross.png";
import { useTranslation } from 'react-i18next';


const InvitationRejectPage = () => {

    const loc = useLocation();
    const [invitationReject, setInvitationReject] = useState();
    const { t } = useTranslation();


    useEffect(() => {
        otherServices.cmsDetails("invitation_rejection_content").then((response) => {
            console.log("response",response)
            setInvitationReject(attachDynamicStrings(response?.data?.description));
            setTitle(response?.data?.title);
        })
            .catch((error) => {
            })
    }, [loc]);

    return (
        <>
       <div className="wraper-inner cpt bg-grey">
                <section className="inner-space space-b">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 m-auto thanku-card-main">
                                <div className="dash-box-shadow pb-5">
                                    <div className="thanku-card text-center mb-4 ">
                                        <figure className="mb-3">
                                            <img src={likeImage} alt="Like" />
                                        </figure>
                                        <div dangerouslySetInnerHTML={{ __html: `${invitationReject ? invitationReject : "N/A"}` }}></div>
                                    </div>
                                    <div className='d-flex justify-content-center'>
                                        <Link className='btn btn-primary mb-4' to={"/settings/profile"}>{t('web_lbl_go_to_profile')}</Link>
                                    </div>
                             </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default InvitationRejectPage