import React, { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import * as eventDetails from '../services/eventdetailservice';
import { STAFF_ROLE, SWAL_SETTINGS } from "../../../utils/Constants";
import toHtml from 'html-react-parser'
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import * as commonServices from "../services/common.services";
import * as eventServices from "../services/eventdetailservice";
import Loader from "../../admin/common/loader";
import { formateDateWithMonth, capitalizeFirstLetter, getFormatedTime, prepareQuestionsFormData, getUser, removeSpacesAndCharacters, removeSpecialCharacter,handleServerValidations, getSessionKey, DT } from "../../../utils/commonfunction";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Swal from 'sweetalert2';
import * as $ from 'jquery';
import { useTranslation } from 'react-i18next';
import DynamicQuestions from "../shared/DynamicQuestions";



function Registration() {
  const user = getUser();
  const navigate = useNavigate();
  const { state } = useLocation();
  const [detailsData, setDetailsData] = useState([]);
  const [params] = useSearchParams();
  const formikRef = useRef(null);
  const [invitationDetailsData, setInvitationDetailsData] = useState("");
  const [apiDone, setApiDone] = useState(false);
  const [secondApiDone, setSecondApiDone] = useState(false);
  const [editApiDone, setEditApiDone] = useState(true);
  const [preSelectedValues, setPreSelectedValues] = useState([]);
  const [formikHandle, setFormikHandle] = useState(0);
  const [dynamicValidations, setDynamicValidations] = useState({});
  // const [disableBtn, setDisableBtn] = useState(false);
  const [prevStateData, setPrevStateData] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [pricing, setPricing] = useState([]);
  const [agenda, setAgenda] = useState([]);
  const [registrationData, setRegistrationData] = useState([]);  
  const register_event_id = getSessionKey('register_event_id')
  const { t } = useTranslation();
  const dynamicQuestionsRef = useRef();
  const [questionFilesUploadPath] = useState("registrations/questions");
  const url = window.location.href;
  let urlNew = url;
  let urlObj = new URL(urlNew);
  let params2 = new URLSearchParams(urlObj.search);
  var invitationValue = params2.get("invitation") ?params2.get("invitation") : params2.get("invitation_id") ?  params2.get("invitation_id") :null;

  useEffect(() => {
    setPrevStateData(state?.stateData);
    /** For previous data */
    setAgenda(state?.stateData?.agenda);
    if (params.get('invitation_id') && params.get('invitation_id')!="null" && params.get('invitation_id')!="undefined") {
      commonServices.InvitationsDetailsByEmail(params.get('invitation_id')).then((response) => {
        if (response.success) {
          setInvitationDetailsData(response?.data);
          setApiDone(true);
        }
      }).catch((err) => {
        console.log("error", err);
        setApiDone(true);
      })
    }
    else {
      setApiDone(true);
    }
  }, []);

  useEffect(() => {
    eventDetails.EventDetail(params.get('event_id'),invitationValue).then((response) => {
      if (response && response.success) {
        setDetailsData(response?.data);
        setPricing({ event_price: response?.data?.event_quota?.price });
        let obj = {};
        if (response?.data?.choice_event_attendance_date == true) {
          obj.startDate = Yup.string().required(t('web_registration_err_start_date'));
          obj.endDate = Yup.string().required(t('web_registration_err_end_date'));
        }
        setDynamicValidations(obj);
        if (state?.stateData?.agenda) {
          /** For previous data */
          setAgenda(state?.stateData?.agenda)
        } else {
          setAgenda(response?.data?.agenda_data)
        }
        if (params.get('edit') == true || params.get('edit') == "true") {

        }else{
          // setFormikHandle(response?.data?.booth_attendants_registration_data?.questions.length)
          user.role==STAFF_ROLE.BOOTH_ATTENDANTS ? setFormikHandle(response?.data?.booth_attendants_registration_data?.questions.length) : setFormikHandle(response?.data?.primary_question_set_data?.questions.length);
        }
      }
    }).catch((err) => {
      console.log("err", err);
    })
  }, []);

  useEffect(() => {
    if (params.get('edit') == true || params.get('edit') == "true") {
      setEditApiDone(false);
      eventServices.EventRegistrationDetails(params.get('reg_id')).then((response) => {
        if (response.success) {
          setRegistrationData(response?.data);
          setFormikHandle(response?.data?.primary_user?.questions_data.length);
          setEditApiDone(true);
          let selectedAgenda = response?.data && response?.data?.primary_user && response?.data?.primary_user?.agenda ? response?.data?.primary_user?.agenda : [];
          if (detailsData) {
            agenda && agenda.forEach((item, index) => {
              selectedAgenda && selectedAgenda.forEach((agenda, agendaIndex) => {
                if (item?._id === agenda?.agendaId) {
                  item.elements && item.elements.forEach((element, elementIndex) => {
                    agenda.elementIds && agenda.elementIds.forEach((selectedElement, i) => {
                      if (selectedElement == element._id) {
                        element.checked = true;
                      }
                    })
                  })
                }
              })
            })
          }
        }
      }).catch((error) => {
        console.log("error", error);
        setEditApiDone(true);
      })
    }
  }, [detailsData])

  useEffect(() => {
    if (params.get('edit') == true || params.get('edit') == "true") {
      if (detailsData && registrationData) {
        let preSelectedAns = [];
        // TO BE reiewed if questions are not correct
        let questionArr = registrationData?.primary_user?.questions_data.length ? registrationData?.primary_user?.questions_data : [];
        questionArr.forEach(item => {
          let singleAns = {}
          singleAns = {
            question_id: item.question_id,
            question_title: item.question_title,
            answer: item.answer
          }
          preSelectedAns.push(singleAns)
        })
        setPreSelectedValues([...preSelectedAns]);
        setSecondApiDone(true);
      }
    } else {
      if (detailsData && invitationDetailsData) {
        let preSelectedAns = [];
        let questionArr = detailsData.primary_question_set_data?.questions.length ? detailsData.primary_question_set_data.questions : [];

        // setFormikHandle(response?.data?.booth_attendants_registration_data?.questions.length)
        questionArr.forEach(item => {
          let keyName = "";
          let obj = invitationDetailsData.question_sequence.find(val => {
            return val.question == item._id
          })
          if (obj) {
            keyName = obj.field.toString().toLowerCase();
          }
          let singleAns = {}
          singleAns = {
            question_id: item._id,
            question_title: item.title,
            ...(item.question_type == 'likeStatus' ? { answer: 0 } : { answer: invitationDetailsData.fields[keyName] })
          }
          preSelectedAns.push(singleAns)
        })
        setPreSelectedValues([...preSelectedAns]);
        setSecondApiDone(true);
      }
      setSecondApiDone(true);        
    }
  }, [invitationDetailsData, detailsData, registrationData])


  let schema = {
    ...dynamicValidations,
    checkIn: Yup.string().test('check_acommondation', t('web_registration_err_check_in'), (value) => {
      if (formikRef?.current?.values.accommondation) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    checkOut: Yup.string().test('check_acommondation', t('web_registration_err_check_in'), (value) => {
      if (formikRef?.current?.values.accommondation) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    hotel: Yup.string().test('check_acommondation', t('web_registration_err_hotel'), (value) => {
      if (formikRef?.current?.values.accommondation) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    roomType: Yup.string().test('check_acommondation', t('web_registration_err_room_type'), (value) => {
      if (formikRef?.current?.values.accommondation) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    specifyText: Yup.string().test('check_acommondation', t('web_registration_err_required'), (value) => {
      if (formikRef?.current?.values.accommondation) {
        if (formikRef?.current?.values.allergic) {
          return Boolean(value);
        } else {
          return true
        }
      } else {
        return true;
      }
    }),
    passportNumber: Yup.string().test('check_visa', t('web_registration_err_passport_number'), (value) => {
      if (formikRef?.current?.values.visaInvitation && detailsData?.askto_visa_verification) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    surname: Yup.string().test('check_visa', t('web_registration_err_surname'), (value) => {
      if (formikRef?.current?.values.visaInvitation && detailsData?.askto_visa_verification) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    firstName: Yup.string().test('check_visa', t('web_registration_err_firstname'), (value) => {
      if (formikRef?.current?.values.visaInvitation && detailsData?.askto_visa_verification) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    dateOfBirth: Yup.string().test('check_visa', t('web_registration_err_date_of_birth'), (value) => {
      if (formikRef?.current?.values.visaInvitation && detailsData?.askto_visa_verification) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    dateOfExpiry: Yup.string().test('check_visa', t('web_registration_err_date_of_expiry'), (value) => {
      if (formikRef?.current?.values.visaInvitation && detailsData?.askto_visa_verification) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    dateOfIssue: Yup.string().test('check_visa', t('web_registration_err_date_of_issue'), (value) => {
      if (formikRef?.current?.values.visaInvitation && detailsData?.askto_visa_verification) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    nationality: Yup.string().test('check_visa', t('web_registration_err_nationality'), (value) => {
      if (formikRef?.current?.values.visaInvitation && detailsData?.askto_visa_verification) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    authority: Yup.string().test('check_visa', t('web_registration_err_authority'), (value) => {
      if (formikRef?.current?.values.visaInvitation && detailsData?.askto_visa_verification) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
    placeOfBirth: Yup.string().test('check_visa', t('web_registration_err_place_of_birth'), (value) => {
      if (formikRef?.current?.values.visaInvitation && detailsData?.askto_visa_verification) {
        return Boolean(value);
      } else {
        return true;
      }
    }),
  };
  function validateElements(index = null) {
    let errors = [];
    if (isFormSubmitted || index == null) {
      for (let i = 0; i < agenda.length; i++) {
        const max = agenda[i].maximum_bookable_element;
        const min = agenda[i].minimum_bookable_element;
        let selectedElements = (agenda[i].elements.filter(item => item.checked)).length;
        if (min) {
          if (min <= selectedElements) {
            $(`#error-min-${agenda[i]._id}-${i}`).text("");
          } else {
            $(`#error-min-${agenda[i]._id}-${i}`).text(DT(t("err_min_max_agenda_select"), [min, (min === 1 ? t('web_lbl_element') : t('web_lbl_elements'))]));
            errors.push({
              message: DT(t("err_min_max_agenda_select"), [min, (min === 1 ? t('web_lbl_element') : t('web_lbl_elements'))]),
              id: `error-min-${agenda[i]._id}-${i}`
            });
          }
        }
        if (max) {
          if (max >= selectedElements) {
            $(`#error-max-${agenda[i]._id}-${i}`).text("");
          } else {
            $(`#error-max-${agenda[i]._id}-${i}`).text(DT(t("err_max_agenda"),[max]));
            errors.push({
              message: DT(t("err_max_agenda"),[max]),
              id: `error-max-${agenda[i]._id}-${i}`
            });
          }
        }
      }
    } else {
      const max = agenda[index].maximum_bookable_element;
      const min = agenda[index].minimum_bookable_element;
      let selectedElements = (agenda[index].elements.filter(item => item.checked)).length;
      if (min) {
        if (min <= selectedElements) {
          $(`#error-min-${agenda[index]._id}-${index}`).text("");
        } else {
          $(`#error-min-${agenda[index]._id}-${index}`).text(DT(t("err_min_max_agenda_select"), [min, (min === 1 ? t('web_lbl_element') : t('web_lbl_elements'))]));
          errors.push({
            message: DT(t("err_min_max_agenda_select"), [min, (min === 1 ? t('web_lbl_element') : t('web_lbl_elements'))]),
            id: `error-min-${agenda[index]._id}-${index}`
          });
        }
      }
      if (max) {
        if (max >= selectedElements) {
          $(`#error-max-${agenda[index]._id}-${index}`).text("");
        } else {
          $(`#error-max-${agenda[index]._id}-${index}`).text(DT(t("err_max_agenda"),[max]));
          errors.push({
            message: DT(t("err_max_agenda"),[max]),
            id: `error-max-${agenda[index]._id}-${index}`
          });
        }
      }
    }
    return errors;
  };

  const validationSchema = Yup.object().shape(schema);

  const selectAgendaElement = (parentArray, items, value, agendaIndex) => {
    const newArray = parentArray.elements.map(i => {
      if (i._id == items._id) {
        i.checked = value
        return i;
      } else {
        return i;
      }
    });

    const selectedAgenda = { ...parentArray, elements: newArray };
    let anotherAgendas = [...agenda];
    anotherAgendas[agendaIndex] = selectedAgenda;
    setAgenda(anotherAgendas);
  }

  const checkValidationError = () => {
    const questionFormResult = dynamicQuestionsRef?.current?.submit();
    setTimeout(() => {
      if ((formikRef?.current?.errors && Object.keys(formikRef?.current?.errors).length) || questionFormResult?.isValid === false) {
        Swal.fire({
          icon: "error",
          text: t('web_registration_err_fill_required_field'),
          ...SWAL_SETTINGS,
        });
      }
    }, 100);
  }

  const getMinDate = (startDate) => {
    let today = new Date();
    let start_date = new Date(startDate);

    const maxDate = today > start_date ? today : start_date;
    return maxDate;
  }


  return (
    <div className="wraper-inner cpt bg-grey">
      {/* {apiDone && preSelectedValues.length ? ( */}
      {apiDone && secondApiDone && (formikHandle == 0 || preSelectedValues.length || params.get('invitation_id') === "null" || (user.role==STAFF_ROLE.BOOTH_ATTENDANTS && params.get('invitation_id') && params.get('invitation_id')!="null" )) && editApiDone ? (
        <Formik
          validationSchema={validationSchema}
          innerRef={formikRef}
          validateOnBlur={false}
          initialValues={{
            ...(params.get('edit') == true || params.get('edit') == "true") ? ({
              vegan: prevStateData && prevStateData?.vegan ? prevStateData?.vegan : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.vegan ? registrationData?.primary_user?.vegan : false),
              allergic: prevStateData && prevStateData?.allergic ? prevStateData?.allergic : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.allergic ? registrationData?.primary_user?.allergic : false),
              vegeterian: prevStateData && prevStateData?.vegeterian ? prevStateData?.vegeterian : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.vegetarian ? registrationData?.primary_user.vegetarian : false),
              visaInvitation: prevStateData && prevStateData?.visaInvitation ? prevStateData?.visaInvitation : (registrationData && registrationData?.visa_invitation ? registrationData?.visa_invitation : false),
              startDate: prevStateData && prevStateData?.startDate ? prevStateData?.startDate : (registrationData && registrationData?.start_date ? registrationData?.start_date : ""),
              endDate: prevStateData && prevStateData?.endDate ? prevStateData?.endDate : (registrationData && registrationData?.end_date ? registrationData?.end_date : ""),
              specifyText: prevStateData && prevStateData?.specifyText ? prevStateData?.specifyText : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.allergy_reason ? registrationData?.primary_user?.allergy_reason : ""),
              passportNumber: prevStateData && prevStateData?.passportNumber ? prevStateData?.passportNumber : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.visa_info && registrationData?.primary_user?.visa_info?.passport_number ? registrationData?.primary_user?.visa_info?.passport_number : ""),
              surname: prevStateData && prevStateData?.surname ? prevStateData?.surname : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.visa_info && registrationData?.primary_user?.visa_info?.surname ? registrationData?.primary_user?.visa_info?.surname : ""),
              firstName: prevStateData && prevStateData?.firstName ? prevStateData?.firstName : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.visa_info && registrationData?.primary_user?.visa_info?.first_name ? registrationData?.primary_user?.visa_info?.first_name : ""),
              dateOfBirth: prevStateData && prevStateData?.dateOfBirth ? prevStateData?.dateOfBirth : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.visa_info && registrationData?.primary_user?.visa_info?.date_of_birth ? registrationData?.primary_user?.visa_info?.date_of_birth : ""),
              dateOfExpiry: prevStateData && prevStateData?.dateOfExpiry ? prevStateData?.dateOfExpiry : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.visa_info && registrationData?.primary_user?.visa_info?.date_of_expiry ? registrationData?.primary_user?.visa_info?.date_of_expiry : ""),
              dateOfIssue: prevStateData && prevStateData?.dateOfIssue ? prevStateData?.dateOfIssue : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.visa_info && registrationData?.primary_user?.visa_info?.date_of_issue ? registrationData?.primary_user?.visa_info?.date_of_issue : ""),
              nationality: prevStateData && prevStateData?.nationality ? prevStateData?.nationality : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.visa_info && registrationData?.primary_user?.visa_info?.nationality ? registrationData?.primary_user?.visa_info?.nationality : ""),
              placeOfBirth: prevStateData && prevStateData?.placeOfBirth ? prevStateData?.placeOfBirth : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.visa_info && registrationData?.primary_user?.visa_info?.place_of_birth ? registrationData?.primary_user?.visa_info?.place_of_birth : ""),
              authority: prevStateData && prevStateData?.authority ? prevStateData?.authority : (registrationData && registrationData?.primary_user && registrationData?.primary_user?.visa_info && registrationData?.primary_user?.visa_info?.authority ? registrationData?.primary_user?.visa_info?.authority : ""),
              // questionData: registrationData && registrationData?.primary_user && registrationData?.primary_user?.questions_data ? detailsData?.primary_question_set_data?.questions : [],
              // questionData:[...preSelectedValues],
              selectedCheckboxes: [],
            }) : ({
              vegan: prevStateData && prevStateData?.vegan ? prevStateData?.vegan : false,
              allergic: prevStateData && prevStateData?.allergic ? prevStateData?.allergic : false,
              vegeterian: prevStateData && prevStateData?.vegeterian ? prevStateData?.vegeterian : false,
              visaInvitation: prevStateData && prevStateData?.visaInvitation ? prevStateData?.visaInvitation : false,
              startDate: prevStateData && prevStateData?.startDate ? prevStateData?.startDate : "",
              endDate: prevStateData && prevStateData?.endDate ? prevStateData?.endDate : "",
              specifyText: prevStateData && prevStateData?.specifyText ? prevStateData?.specifyText : "",
              passportNumber: prevStateData && prevStateData?.passportNumber ? prevStateData?.passportNumber : "",
              surname: prevStateData && prevStateData?.surname ? prevStateData?.surname : "",
              firstName: prevStateData && prevStateData?.firstName ? prevStateData?.firstName : "",
              dateOfBirth: prevStateData && prevStateData?.dateOfBirth ? prevStateData?.dateOfBirth : "",
              dateOfExpiry: prevStateData && prevStateData?.dateOfExpiry ? prevStateData?.dateOfExpiry : "",
              dateOfIssue: prevStateData && prevStateData?.dateOfIssue ? prevStateData?.dateOfIssue : "",
              nationality: prevStateData && prevStateData?.nationality ? prevStateData?.nationality : "",
              placeOfBirth: prevStateData && prevStateData?.placeOfBirth ? prevStateData?.placeOfBirth : "",
              authority: prevStateData && prevStateData?.authority ? prevStateData?.authority : "",
              // questionData: prevStateData && prevStateData?.questionData ? prevStateData?.questionData : [],
              // questionData:[...preSelectedValues],
              selectedCheckboxes: [],
            })
          }}
          onSubmit={(values) => {
            const questionsFormResult = dynamicQuestionsRef?.current?.submit();
            if ((validateElements()).length || questionsFormResult?.isValid === false) {
              return;
            }
            let allElementPrice = [];
            agenda && agenda.forEach((item, index) => {
              item?.elements?.forEach((element, eIndex) => {
                if (element?.checked) {
                  allElementPrice.push({ elementPrice: element })
                }
              })
            })
            let obj = { ...pricing };
            obj.primaryAgenda = allElementPrice;
            setPricing(obj);
            let result = dynamicQuestionsRef?.current?.getValues();
            let finalForm = prepareQuestionsFormData(result, new FormData(), questionFilesUploadPath);
            window.registrationFormStatus = true;
            window.registrationFinalForm = finalForm;
            if (params.get('edit') == true || params.get('edit') == "true") {
              navigate(`/event-registration-step-2?event_id=${params.get('event_id')}&invitation_id=${params.get('invitation_id')}&reg_id=${params.get('reg_id')}&email=${params.get('email')}&edit=true`, { state: { values: { ...values, agenda: agenda, pricing: obj } } });
            } else {
              let email = params.get('email') ? params.get('email') : user.email ? user.email : null
              navigate(`/event-registration-step-2?event_id=${params.get('event_id')}&invitation_id=${params.get('invitation_id')}&email=${email}`,{ state:{values:{...values, agenda: agenda, pricing : obj}}});
            }
          }}
        >
          {({ values, handleSubmit, setFieldValue, handleChange, handleBlur, setFieldError, errors, touched, setValidationError }) => (
            <Form onSubmit={handleSubmit}>
              <section className="event-page-register  mt-5 pb-5">
                <div className="container">
                  {detailsData && detailsData.guest_can_add_secondary == true ? (
                    <div className="step-process">
                      <ul className="progessbar-list d-flex">
                        <li className="active">
                          <span>01</span>
                          <h6>{t('web_registration_step_one')} </h6>
                        </li>
                        <li>
                          <span>02</span>
                          <h6>{t('web_registration_step_two')}</h6>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}
                  <h3 className="inner-title">{t('web_registration')}</h3>
                  <div className="dash-box-shadow-2 position-relative">
                    <h4 className="reg-title">{detailsData?.title}</h4>
                    {detailsData?.event_quota?.price > 0 ? <span className="mb-2 price-text">{t('web_lbl_price')}: <span>{detailsData?.currency?.sign}{detailsData?.event_quota?.price}</span></span> : <span className="mb-2 price-text text-success">{t('web_registration_free')}</span>}
                    <ul className="card-info-list pb-0">
                      <li>
                        <div className="date-info pb-0">
                          <i className="ri-calendar-event-line black-icon" />
                          {formateDateWithMonth(detailsData?.start_date)}{" - "}{formateDateWithMonth(detailsData?.end_date)}
                        </div>
                      </li>
                    </ul>
                  </div>

                  {detailsData && detailsData.choice_event_attendance_date == true &&
                    <div className="workshop-box">
                      <h5 className="reg-sub-title mb-2">
                        {t('web_event_registration_attend')}
                      </h5>
                      <div className="row">
                        <div className="col-md-6 mb-3">
                          <label className="form-label">{t('web_registration_start_date')} <span className="requirestar">*</span></label>
                          <div className="w-100 date-box date datepicker">
                            <DatePicker
                              showMonthDropdown
                              scrollableYearDropdown
                              showYearDropdown
                              yearDropdownItemNumber={30}
                              onChange={date => {
                                setFieldValue('startDate', date);
                                setFieldValue('endDate', "");
                              }}
                              className="form-control"
                              value={values.startDate == "" ? "" : moment(values.startDate).format('DD/MM/YYYY')}
                              minDate={getMinDate(detailsData?.start_date)}
                              maxDate={new Date(detailsData?.end_date)}
                            />
                            <span className="input-group-append">
                              <span className="input-cal-icon ">
                                <i className="ri-calendar-2-line"></i>
                              </span>
                            </span>
                          </div>
                          <span className="text-danger text-start d-blockerr-spn">
                            {errors.startDate && touched.startDate && errors.startDate}
                          </span>
                        </div>
                        <div className="col-md-6 mb-3">
                          <label className="form-label">{t('web_registration_end_date')}<span className="requirestar">*</span></label>
                          <div className="date-box date datepicker">
                            <DatePicker
                              onChange={date => setFieldValue('endDate', date)}
                              showMonthDropdown
                              scrollableYearDropdown
                              showYearDropdown
                              yearDropdownItemNumber={30}
                              className="form-control"
                              value={values.endDate == "" ? "" : moment(values.endDate).format('DD/MM/YYYY')}
                              minDate={new Date(detailsData?.start_date) && new Date(values?.startDate)}
                              maxDate={new Date(detailsData?.end_date)}
                            />
                            <span className="input-group-append">
                              <span className="input-cal-icon ">
                                <i className="ri-calendar-2-line"></i>
                              </span>
                            </span>
                          </div>
                          <span className="text-danger text-start d-blockerr-spn">
                            {errors.endDate && touched.endDate && errors.endDate}
                          </span>
                        </div>
                      </div>
                    </div>
                  }

                  <div className="about-dietary">
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4 className="reg-title">{t('web_event_registration_dietary')}</h4>
                        <h5 className="reg-sub-title mb-2">
                          {t('web_event_registration_diet')}
                        </h5>
                        <div className="chek-box-card mt-3">
                          <div className="form-check black-check cp">
                            <input
                              type="checkbox"
                              className="form-check-input cp"
                              id="vegan"
                              name="vegan"
                              value={values.vegan}
                              checked={values.vegan}
                              onChange={(e) => setFieldValue('vegan', e.target.checked)}
                            />
                            <label className="cp" htmlFor="vegan">{t('web_event_registration_vegan')}</label>
                          </div>
                          <div className="form-check black-check cp">
                            <input
                              type="checkbox"
                              className="form-check-input cp"
                              name="vegeterian"
                              id="vegeterian"
                              checked={values.vegeterian}
                              value={values.vegeterian}
                              onChange={(e) => setFieldValue('vegeterian', e.target.checked)}
                            />
                            <label className="cp" htmlFor="vegeterian">{t('web_event_registration_vegitarian')}</label>
                          </div>
                          <div className="form-check black-check cp">
                            <input
                              type="checkbox"
                              className="form-check-input cp"
                              name="allergic"
                              id="allergic"
                              value={values.allergic}
                              checked={values.allergic}
                              onChange={(e) => setFieldValue('allergic', e.target.checked)}
                            />
                            <label className="cp" htmlFor="allergic">{t('web_event_registration_allergic')}</label>
                          </div>
                        </div>
                        {values?.allergic && values?.allergic == true && (
                          <div className="form-group mt-3 please-specify">
                            <label className="form-label">{t('web_registration_specify')}</label>
                            <input
                              type="text"
                              name="specifyText"
                              id="specifyText"
                              placeholder={t('web_registration_specify')}
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.specifyText}
                            />
                            <span className="text-danger text-start d-blockerr-spn">
                              {errors.specifyText && touched.specifyText && errors.specifyText}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {agenda && agenda?.length > 0 ? (
                    <div className="agenda-box">
                      <h4 className="reg-title mb-4">{t('web_event_registration_agenda')}</h4>
                      {agenda && agenda.map((item, index) => (
                        <div key={index} >
                          <h4 className="reg-title"> {capitalizeFirstLetter(item.name)}</h4>
                          <ul className="card-info-list">
                            <li>
                              <div className="date-info pb-0">
                                <i className="ri-calendar-event-line black-icon" />
                                {item.start_date && formateDateWithMonth(item.start_date)}{" "}
                              </div>
                            </li>
                            <li>
                              <div className="card-info pb-0">
                                <i className="ri-alarm-line black-icon" />
                                {item.start_time && getFormatedTime(item.start_time)} - {" "}
                                {item.end_time && getFormatedTime(item.end_time)}
                              </div>
                            </li>
                          </ul>
                          {toHtml(item.description)}
                          <div className="radio-group-coustom d-flex row mt-3">
                            {item.elements.map((items, i) => (
                              <div className="form-check radio-coustom mb-2" key={i}>
                                <input
                                  className="form-check-input cp"
                                  type="checkbox"
                                  name="flexRadioDefault"
                                  id={`flexRadioDefault1${i}${index}`}
                                  checked={items?.checked}
                                  disabled={!items?.available_quantity}
                                  onChange={(e) => { selectAgendaElement(item, items, e.target.checked, index); validateElements(index) }}
                                />
                                <label
                                  className="form-check-label cp"
                                  htmlFor={`flexRadioDefault1${i}${index}`}
                                >
                                  <h5>{capitalizeFirstLetter(items.name)}</h5>
                                  {items.price > 0 ? (
                                    <span>
                                      {detailsData?.currency?.sign}{(items.price + (items.price * items.tax_rate / 100)).toFixed(2)} <small>{t('web_lbl_inclusive')} {items.tax_rate}% {t('web_lbl_tax')}</small>
                                    </span>
                                  ) : (
                                    <span className="text-success">{t('web_registration_free')}</span>
                                  )}

                                  {!items?.available_quantity ? (
                                    <span>
                                      <small className="ms-5 ps-5 text-danger">{t('web_registration_stock')}</small>
                                    </span>
                                  ) : (
                                    <></>
                                  )
                                  }
                                </label>
                              </div>
                            ))}
                          </div>
                          <span className="text-danger text-start d-blockerr-spn" id={`error-min-${item._id}-${index}`}></span>
                          <span className="text-danger text-start d-blockerr-spn" id={`error-max-${item._id}-${index}`}></span>
                      <hr className="divider less-space" />
                    </div> ) )}
                  </div>
                ) : (
                  <></>
                )}
                <div className="more-information">
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <h4 className="reg-title">
                      {t('web_event_registration_more')}{" "}
                      </h4>
                      {user.role==STAFF_ROLE.BOOTH_ATTENDANTS ?
                      <>
                       {detailsData?.booth_attendants_registration_data?.questions && detailsData?.booth_attendants_registration_data?.questions.length ?
                            <DynamicQuestions ref={dynamicQuestionsRef} questions={detailsData?.booth_attendants_registration_data?.questions} showCount={true}
                            defaultValues={registrationData?.primary_user?.questions_data}
                              questionTitleClass="" footer="" videoUploadPath={questionFilesUploadPath} />
                            : null
                          }
                      </>
                      :
                      <> 
                        {detailsData?.primary_question_set_data?.questions && detailsData?.primary_question_set_data?.questions.length ?
                            <DynamicQuestions ref={dynamicQuestionsRef} questions={detailsData?.primary_question_set_data?.questions} showCount={true}
                            defaultValues={registrationData?.primary_user?.questions_data}
                              questionTitleClass="" footer="" videoUploadPath={questionFilesUploadPath} />
                            : null
                          }
                        </>
                        
                        }
                       
                    </div>
                  </div>

                  <div className="about-invoice">
                    {detailsData && detailsData.askto_visa_verification == true && (
                      <div>
                        <div className="col-md-6 mb-3">
                          <h4 className="reg-title">
                            {t('web_event_registration_letter')}
                          </h4>
                          <div className="toggle-box d-flex align-items-center justify-content-between mb-3">
                            <h4>{t('web_event_registration_do_letter')}</h4>
                            <label className="switch">
                              <input type="checkbox" checked={values.visaInvitation} onChange={(e) => setFieldValue('visaInvitation', e.target.checked)} />
                              <span className="slider round" />
                            </label>
                          </div>
                        </div>
                        {values.visaInvitation && values.visaInvitation && (
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <label className="form-label">{t('web_event_registration_passport_number')}<span className="requirestar">*</span></label>
                              <input
                                type="text"
                                name="passportNumber"
                                id="passportNumber"
                                placeholder=""
                                className="form-control"
                                value={values.passportNumber}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.passportNumber && touched.passportNumber && errors.passportNumber}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">{t('web_event_registration_surname')} ({t('web_registration_as_passport')})<span className="requirestar">*</span></label>
                              <input
                                type="text"
                                name="surname"
                                id="surname"
                                placeholder=""
                                className="form-control"
                                value={values.surname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.surname && touched.surname && errors.surname}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">{t('web_event_registration_first_name')} ({t('web_registration_as_passport')})<span className="requirestar">*</span></label>
                              <input
                                type="text"
                                name="firstName"
                                id="firstName"
                                placeholder=""
                                className="form-control"
                                value={values.firstName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.firstName && touched.firstName && errors.firstName}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">{t('web_event_registration_dob')}<span className="requirestar">*</span></label>
                              <div className="date-box date datepicker">
                                <DatePicker
                                  showMonthDropdown
                                  scrollableYearDropdown
                                  showYearDropdown
                                  yearDropdownItemNumber={30}
                                  onChange={date => setFieldValue('dateOfBirth', date)}
                                  // placeholderText="Please select birth date"
                                  className="form-control"
                                  value={values.dateOfBirth == "" ? "" : moment(values.dateOfBirth).format('DD/MM/YYYY')}
                                />
                                <span className="input-group-append">
                                  <span className="input-cal-icon ">
                                    <i className="ri-calendar-2-line"></i>
                                  </span>
                                </span>
                              </div>
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.dateOfBirth && touched.dateOfBirth && errors.dateOfBirth}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">{t('web_event_registration_pob')} <span className="requirestar">*</span></label>
                              <input
                                type="text"
                                name="placeOfBirth"
                                id="placeOfBirth"
                                placeholder=""
                                className="form-control"
                                value={values.placeOfBirth}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.placeOfBirth && touched.placeOfBirth && errors.placeOfBirth}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">{t('web_event_registration_nationality')}<span className="requirestar">*</span></label>
                              <input
                                type="text"
                                name="nationality"
                                id="nationality"
                                placeholder=""
                                className="form-control"
                                value={values.nationality}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.nationality && touched.nationality && errors.nationality}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3 ">
                              <label className="form-label">{t('web_event_registration_doi')}<span className="requirestar">*</span></label>
                              <div className="date-box date datepicker">
                                <div className="date-box date datepicker">
                                  <DatePicker
                                    showMonthDropdown
                                    scrollableYearDropdown
                                    showYearDropdown
                                    yearDropdownItemNumber={30}
                                    onChange={date => setFieldValue('dateOfIssue', date)}
                                    // placeholderText="Please select issue date"
                                    className="form-control"
                                    value={values.dateOfIssue == "" ? "" : moment(values.dateOfIssue).format('DD/MM/YYYY')}
                                    maxDate={new Date()}
                                  />
                                  <span className="input-group-append">
                                    <span className="input-cal-icon ">
                                      <i className="ri-calendar-2-line"></i>
                                    </span>
                                  </span>
                                </div>
                              </div>
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.dateOfIssue && touched.dateOfIssue && errors.dateOfIssue}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">{t('web_event_registration_doe')}<span className="requirestar">*</span></label>
                              <div className="date-box date datepicker">
                                <DatePicker
                                  showMonthDropdown
                                  scrollableYearDropdown
                                  showYearDropdown
                                  yearDropdownItemNumber={30}
                                  onChange={date => setFieldValue('dateOfExpiry', date)}
                                  // placeholderText="Please select expiry date"
                                  className="form-control"
                                  value={values.dateOfExpiry == "" ? "" : moment(values.dateOfExpiry).format('DD/MM/YYYY')}
                                  minDate={new Date()}
                                />
                                <span className="input-group-append">
                                  <span className="input-cal-icon ">
                                    <i className="ri-calendar-2-line"></i>
                                  </span>
                                </span>
                              </div>
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.dateOfExpiry && touched.dateOfExpiry && errors.dateOfExpiry}
                              </span>
                            </div>
                            <div className="col-md-6 mb-3">
                              <label className="form-label">{t('web_event_registration_authority')}<span className="requirestar">*</span></label>
                              <input
                                type="text"
                                name="authority"
                                id="authority"
                                placeholder=""
                                className="form-control"
                                value={values.authority}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <span className="text-danger text-start d-blockerr-spn">
                                {errors.authority && touched.authority && errors.authority}
                              </span>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                    <button className="btn btn-secondary me-2" type="submit" onClick={() => {
                      setIsFormSubmitted(true);
                      validateElements();
                      checkValidationError();
                      // setDisableBtn(true);
                    }}>
                      {t('web_registration_next')}
                    </button>
                    <button className="btn btn-danger" type="button" onClick={() => { navigate("/settings/profile") }}>
                      {t('web_lbl_button_cancel')}
                    </button>
                  </div>
                  </div>
                </div>
              </section>
            </Form>
          )}
        </Formik>
      ) : (
        <div className="d-flex justify-content-center align-items-center" style={{ height: "90vh" }}>
          <h1>{t('web_registration_no_data')}</h1>
        </div>
      )}
    </div>
  );
}

export default Registration;
