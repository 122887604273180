import React, { useState, useEffect } from "react";
import { Form, useLocation, useNavigate } from "react-router-dom";
import Sorting from "../../common/sorting";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CustomPagination from "../../common/custompagination";
import Loader from "../../common/loader";
import { ErrorMessage, Field, Formik } from "formik";
import * as eventServices from "../../services/events.services";
import { SWAL_SETTINGS, STAFF_ROLE, LM_BACKOFFICE_INVITATION_STATUS } from "../../../../utils/Constants";
import {
  SpecialCharacter,
  decideRole,
  decideStatus,
  formateDate,
  globalLoader,
  handleServerValidations,
} from "../../../../utils/commonfunction";
import CustomRangepicker from "../../common/rangepicker";
import { Button, Modal } from "rsuite";
import { Steps } from "rsuite";
import { ExcelRead } from "../../services/leadManagement.services";
import * as Yup from "yup";
import $ from "jquery";


function SISUserInvitation() {
  const navigate = useNavigate();
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const [typeSearch, setTypeSearch] = useState("");
  const [resetdate, setResetDate] = useState(false);
  const params = useParams();
  const [list, setList] = useState([]);
  const [statsupdate,setStatusUpdate] = useState("false");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [step, setStep] = React.useState(0);
  const [selectedUser, setSelectedUser] = useState({});
  const [title, setTitle] = useState("");
  const [role, setRole] = useState("");
  const [file, setFile] = useState("");
  const [skippedUsers, setSkippedUsers] = useState(null);
  const [roleOptions] = useState(
    Object.keys(STAFF_ROLE).filter(
      (key) =>
        key !== "USER" &&
        key !== "ADMIN" &&
        key !== "STAFF" &&
        key !== "SUB_ADMIN" &&
        key !== "LM_BACK_OFFICE" && 
        key !== "LEAD_COORDINATOR"
    )
  );
  const [statusOptions] = useState(
    Object.keys(LM_BACKOFFICE_INVITATION_STATUS)
  );
  const [secondModal, setSecondModal] = useState(false);


  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      formData.append("type_search", typeSearch);
      formData.append("event_id", params.id);
      formData.append("type","sis-user");
      eventServices
        .LMUserList(formData)
        .then((data) => {
          setDataLength(data.data.total_records);
          setList(data && data.data && data.data.list ? data.data.list : []);
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [
    location,
    statsupdate,
    sorting,
    search,
    globalsearch,
    secondModal,
    itemperpage,
    params.pgno,
    params.id,
    typeSearch,
  ]);

  useEffect(() => {
    eventServices
      .Options()
      .then((response) => {
        if (response.success) {
          // setEventOptions(response?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);

  // sorting function start
  const handleSort = (e, column) => {
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  // search or filter function start
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  // search or filer function  end

  // reset filter function start
  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    setTypeSearch("");
    prepareSearch();
    setSearch({});
    setResetDate(!resetdate);
    $("#role")[0].selectedIndex = 0;
    $("#status")[0].selectedIndex = 0;
  };

  const handleViewDetails = (SISUserInvitationId, event_id) => {
    const to = `/admin/event-management/events/view/sis-user-invitation-details/${SISUserInvitationId}`;
    // Pass event_id in the state object
    const state = { event_id };
    navigate(to, { state });
  };

  const inviteValidationUsers = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .test("no-spaces", "Title is required", (value) => value.trim()),
    role: Yup.string().required("Role is required"),
  });

  const readExcelFile = (e) => {
    globalLoader(true);
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      ExcelRead(formData)
        .then((response) => {
          setTimeout(() => {
            if (response?.success) {
              setFile(e?.target?.files[0]);
              setSelectedUser(response?.data);
              setStep(step + 1);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
              document.getElementById("file_reader").value = "";
            }
            globalLoader(false);
          }, 1500);
        })
        .catch((error) => {
          console.log("error", error);
          Swal.fire({
            icon: "error",
            text: handleServerValidations(error),
            ...SWAL_SETTINGS,
          });
          globalLoader(false);
        });
    }
  };

  const onOkayClick = (values) => {
    globalLoader(true);
    let formData = new FormData();
    formData.append("title", title);
    formData.append("users", JSON.stringify(selectedUser?.list));
    formData.append("event_id", params.id);
    formData.append("file", file);
    formData.append("role", role);
    formData.append("type","sis-user");
    eventServices
      .InviteLeads(formData)
      .then((response) => {
        globalLoader(false);
        if (response?.success) {
          Swal.fire({
            icon: "success",
            text: response?.message,
            ...SWAL_SETTINGS,
          });
          setStep(step + 1);
          setTitle("");
          setSkippedUsers((response?.data && response?.data?.skippedUsers) ? response?.data?.skippedUsers : null)
          setStatusUpdate(!statsupdate);
        } else {
          Swal.fire({
            icon: "error",
            text: response?.message,
            ...SWAL_SETTINGS,
          });
        }
      })
      .catch((error) => {
        globalLoader(false);
        Swal.fire({
          icon: "error",
          text: error,
          ...SWAL_SETTINGS,
        });
      });
  };

  const modalData = (steps) => {
    if (steps === 0) {
      return (
        <div className="my-3 upload-button ">
          <form
            id="reader_id"
            style={{
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="text-center ">
              <label htmlFor="file_reader">
                <input
                  style={{ display: "none" }}
                  type={"file"}
                  id="file_reader"
                  onChange={readExcelFile}
                  accept=".xls,.xlsx,.csv"
                />
                <i
                  className="fa fa-upload cp"
                  aria-hidden="true"
                  style={{ fontSize: "7em", color: "#3598ff" }}
                ></i>
              </label>
              <p className="text-muted mb-0">Please upload excel file.</p>
              <a
                className="download-file my-0"
                href={`${process.env.REACT_APP_API_URL.replace(
                  "/webservice/api/v1",
                  ""
                )}/configs/default/LM-users-invitation-sample.xlsx`}
                download
              >
                Download sample file.
              </a>
            </div>
          </form>
        </div>
      );
    } else if (steps === 1) {
      return (
        <Formik
          initialValues={{
            title: title,
            role: role,
          }}
          onSubmit={(values) => {
            setStep(step + 1);
          }}
          validationSchema={inviteValidationUsers}
        >
          {({ handleSubmit, values, handleBlur, setFieldValue }) => (
            <div className="mt-4">
              <div className="row">
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">
                        Title:<span className="requirestar">*</span>
                      </label>
                      <input
                        name="title"
                        id="title"
                        type="text"
                        className="form-control"
                        value={values.title}
                        onChange={(e) => {
                          setFieldValue("title", e?.target?.value);
                          setTitle(e?.target?.value);
                        }}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"title"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label htmlFor="slug" className="text-left d-flex">
                        Role:<span className="requirestar">*</span>
                      </label>
                      <div className="select-down-arrow">
                        <Field
                          as="select"
                          id="role"
                          name={"role"}
                          className="form-control select2"
                          onChange={(e) => {
                            setFieldValue("role", e.target.value);
                            setRole(e.target.value);
                          }}
                        >
                          <option value="" label="Select role">
                            Select
                          </option>
                          {roleOptions &&
                            roleOptions.map((option, i) => {
                              return (
                                <option
                                  className="text-capitalize"
                                  key={i}
                                  value={STAFF_ROLE[option]}
                                >
                                  {SpecialCharacter(option)}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"role"} />
                      </span>
                    </div>
                  </div>
                  <Modal.Footer className="mt-4">
                    <Button
                      onClick={() => {
                        setOpen(false);
                        setTitle("");
                        setRole("");
                        setStep(0);
                      }}
                      appearance="subtle"
                      type="button"
                    >
                      Cancel
                    </Button>
                    <Button appearance="primary" type="submit">
                      Next
                    </Button>
                  </Modal.Footer>
                </Form>
              </div>
            </div>
          )}
        </Formik>
      );
    } else if (steps === 2) {
      return (
        <div className="mt-4">
          <div className="table-responsive">
            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Middle Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                {loader ? (
                  <tr>
                    <td colSpan={selectedUser?.headings?.length}>
                      <Loader />
                    </td>
                  </tr>
                ) : (
                  <>
                    {selectedUser.list.length ? (
                      selectedUser.list.map((row, i) => {
                        return (
                          <tr key={i}>
                            {selectedUser?.headings?.map((item, index) => {
                              return (
                                <td key={index}>
                                  {row[item.toString().toLowerCase()]}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td
                          colSpan={selectedUser?.headings?.length}
                          className="text-center"
                        >
                          No records
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <Modal.Footer className="mt-4">
            <div className="chek-box-card mt-3 d-flex justify-content-end"></div>
            <Button
              appearance="primary"
              onClick={() => {
                setStep(step - 1);
                setTitle("");
                setRole("");
              }}
              type="button"
              className="mt-2"
            >
              Previous
            </Button>
            <Button
              appearance="primary"
              onClick={() => {
                onOkayClick();
              }}
              type="submit"
              className="mt-2"
            >
              Submit
            </Button>
          </Modal.Footer>
        </div>
      );
    } else if (steps === 3) {
      return (
        <div style={{ height: "68vh" }}>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <h1>Success</h1>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <p>Total Skipped Users : {skippedUsers ? skippedUsers : 0}</p>
          </div>
        </div>
      );
    }
  };

  const statusChange = (e) => {
    if (e.target.value) {
      prepareSearch("status", e.target.value)
    } else {
      prepareSearch("status", "")
    }
  };

  const inviteValidationSchema = Yup.object().shape({
    invitation_title: Yup.string()
      .required("Invitation title is required")
      .test("no-spaces", "Invitation title is required", (value) => value.trim()),
    firstname: Yup.string()
      .required("First name is required")
      .test("no-spaces", "First name is required", (value) => value.trim()),
    lastname: Yup.string()
      .required("Last name is required")
      .test("no-spaces", "Last name is required", (value) => value.trim()),
    middlename: Yup.string(),
    email: Yup.string().email().required("Email is required"),
    role :Yup.string().required("Role is required"),
  });

  return (
    <div className="row">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="main-content-label">SIS User Invitation</h6>
        <div className="d-flex align-items-center">
          <div className="form-group mb-0 me-3">
            <div className="form-group mb-0 rangepicker_container filter_design">
              <i className="fa fa-search calender_icon"></i>
              <input
                type="search"
                className="form-control"
                value={globalsearch}
                placeholder="Search"
                onChange={(e) => {
                  setGlobalSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="me-3">
            <CustomRangepicker
              GetDateRange={(e) => {
                prepareSearch("createdAt", e);
              }}
              resetdate={resetdate}
            />
          </div>
          <div className="me-3">
              <div className="form-group mb-0 filter_icon_container filter_design">
                <i className="fa fa-filter mr-2 filter_icon"></i>
                <div className="select-down-arrow">
                  <select className="form-control cp" 
                  onChange={(e) => {
                  setTypeSearch(e.target.value);
                }} 
                id="role" 
                style={{ width: "180px" }}>
                    <option value="">
                      Type
                    </option>
                    {roleOptions && roleOptions.length > 0 && roleOptions.map((option, i) => {
                      return (
                        <option key={i} value={STAFF_ROLE[option]}>
                           {SpecialCharacter(option)}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            </div>
          <div className="me-3">
            <div className="form-group mb-0 filter_icon_container filter_design">
              <i className="fa fa-filter mr-2 filter_icon"></i>
              <div className="select-down-arrow">
                <select className="form-control cp" onChange={statusChange} id="status" style={{ width: "150px" }}>
                  <option value="">
                    Status
                  </option>
                  {statusOptions && statusOptions.length > 0 && statusOptions.map((option, i) => {
                    return (
                      <option key={i} value={LM_BACKOFFICE_INVITATION_STATUS[option]}>
                        {SpecialCharacter(option)}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
          <button
            type="reset"
            value="Reset"
            onClick={resetFilter}
            className="btn btn-warning float-right mr-2"
          >
            Reset Filter
          </button>
          <button
            className="btn ripple btn-main-primary signbtn"
            onClick={() => setOpen(true)}
          >
            Invite SIS Users
          </button>
          <button
            className="btn ripple btn-info ms-2"
            onClick={() => setSecondModal(true)}
          >
            Invite
          </button>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Type</th>
              <th>Status</th>
              <th>
                <div className="sorting_column">
                  <span>Created Date</span>
                  <Sorting
                    sort={sorting}
                    handleSort={handleSort}
                    column="createdAt"
                  />
                </div>
              </th>
              <th className="action_head">Actions</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={6}>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {list.length ? (
                  list.map((row, i) => {
                    return (
                      <tr key={i}>
                        <td>{row?.user_data?.name ? row?.user_data?.name : "N/A"}</td>
                        <td>{row?.user_data?.email ? row?.user_data?.email : "N/A"}</td>
                        <td>{decideRole(row?.user_data?.role)}</td>
                        <td>{decideStatus(row?.status)}</td>
                        <td>
                          {formateDate(row?.user_data?.createdAt)}
                        </td>
                        <td>
                          <div className="d-flex">
                            <button
                              className="btn ripple btn-main-primary signbtn"
                              onClick={() =>
                                handleViewDetails(row?._id, params?.id)
                              }
                            >
                              View
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center">
                      No records
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      <div className="" id="example1_info" role="status" aria-live="polite">
        <b>Total Records : {datalength ? datalength : "0"}</b>
      </div>
      {datalength && datalength > 0 ? (
        <CustomPagination
          datalength={datalength}
          itemperpage={itemperpage}
          currentPage={page}
          setPage={setPage}
          pageRoute={[
            {
              name: "Events",
              path: `/admin/event-management/events/view/event-lm-user-invitation/${params.id}`,
            },
          ]}
        />
      ) : (
        ""
      )}
      {/* Invite Back Office Modal Start */}
      <Modal
        size={"full"}
        className="invitation-modal"
        open={open}
        onClose={() => {
          setOpen(false);
          setStep(0);
          setTitle("");
          setRole("");
        }}
        backdrop={"static"}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Invite SIS Users</Modal.Title>
        </Modal.Header>
        <div>
          <Steps current={step}>
            <Steps.Item title="Upload File" />
            <Steps.Item title="Process" />
            <Steps.Item title="Preview" />
            <Steps.Item title="Success" />
          </Steps>
          <div>{modalData(step)}</div>
        </div>
      </Modal>
      {/* Invite Back Office Modal End */}


      <Modal
        size={"lg"}
        className="invitation-modal modal-height-auto"
        open={secondModal}
        onClose={() => setSecondModal(false)}
        backdrop={'static'}
      >
        <Modal.Header className="mb-3">
          <Modal.Title>Invite user</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              validateOnBlur={false}
              initialValues={{
                email: "",
                title: "",
                firstname: "",
                middlename: "",
                lastname: "",
                invitation_title: "",
                role:"",
              }}
              validationSchema={inviteValidationSchema}
              onSubmit={(values) => {
                globalLoader(true);
                let formData = new FormData();
                formData.append("event_id", params.id);
                formData.append("type","sis-user");
                formData.append("invitation_title", values.invitation_title);
                formData.append("role",values.role);
                formData.append("users", JSON.stringify([values]));
                eventServices
                  .InviteLeads(formData)
                  .then((response) => {
                    globalLoader(false);
                    if (response?.success) {
                      Swal.fire({
                        icon: "success",
                        text: response.message,
                        ...SWAL_SETTINGS,
                      });
                      setStatusUpdate(!statsupdate);
                      setSecondModal(false);
                    } else {
                      Swal.fire({
                        icon: "error",
                        text: response.message,
                        ...SWAL_SETTINGS,
                      });
                    }
                  })
                  .catch((error) => {
                    globalLoader(false);
                    Swal.fire({
                      icon: "error",
                      text: error,
                      ...SWAL_SETTINGS,
                    });
                  });

              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                touched,
                handleSubmit,
              }) => (
                <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                  <div className="row">
                    <div className="col-lg-6 text-center form-group mt-4">
                      <label className="text-left d-flex">Invitation Title:<span className="requirestar">*</span></label>
                      <input
                        name="invitation_title"
                        id="invitation_title"
                        type="text"
                        className="form-control"
                        value={values.invitation_title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"invitation_title"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group mt-4">
                      <label className="text-left d-flex">Title:</label>
                      <input
                        name="title"
                        id="title"
                        type="text"
                        className="form-control"
                        value={values.title}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"title"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">First Name:<span className="requirestar">*</span></label>
                      <input
                        name="firstname"
                        id="firstname"
                        type="text"
                        className="form-control"
                        value={values.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"firstname"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">Middle Name:</label>
                      <input
                        name="middlename"
                        id="name"
                        type="text"
                        className="form-control"
                        value={values.middlename}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"middlename"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">Last Name:<span className="requirestar">*</span></label>
                      <input
                        name="lastname"
                        id="lastname"
                        type="text"
                        className="form-control"
                        value={values.lastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"lastname"} />
                      </span>
                    </div>
                    <div className="col-lg-6 text-center form-group">
                      <label className="text-left d-flex">Email:<span className="requirestar">*</span></label>
                      <input
                        name="email"
                        id="email"
                        type="text"
                        className="form-control"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <span className="text-danger d-flex text-left">
                        <ErrorMessage name={"email"} />
                      </span>
                    </div>
                    <div className='col-lg-6 text-center form-group'>
                      <label htmlFor='slug' className='text-left d-flex'>Role:<span className="requirestar">*</span></label>
                      <div className="select-down-arrow ">
                        <Field as='select' id="role" name={"role"} className="form-control select2">
                          <option value="" label="Select role">Select{" "}</option>
                          {roleOptions && roleOptions.map((option, i) => {
                            return (
                              <option className="text-capitalize" key={i} value={STAFF_ROLE[option]}>
                                {SpecialCharacter(option)}
                              </option>
                            );
                          })}
                        </Field>
                      </div>
                      <span className='text-danger d-flex text-left'><ErrorMessage name={"role"} /></span>
                    </div>
                  </div>
                  <Modal.Footer className="mt-4">
                    <Button
                      onClick={() => {
                        setSecondModal(false);
                      }}
                      appearance="subtle"
                    >
                      Close
                    </Button>
                    <Button
                      appearance="primary"
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Send Now
                    </Button>
                  </Modal.Footer>
                </div>
              )}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SISUserInvitation;
