import axiosInstance from "../../../utils/axios";
import { getQuery } from "../../../utils/commonfunction";

const path = "web/leads";

export const InvitationDetails = async (_id, status) => {
  return await axiosInstance().get(
    `${path}/invitation-detail?_id=${_id}`
  );
};

export const InvitationAccept = async (_id, status) => {
  return await axiosInstance().get(
    `${path}/invitation?_id=${_id}&status=${status}`
  );
};

export const CompleteProfileLMUser = async (values) => {
  return await axiosInstance().post(`${path}/complete-profile`, values)
}

export const QuestionList = async (values) => {
  return await axiosInstance().post(`${path}/question-list`, values)
}
export const GetVoiceMemoToken = async (values) => {
  return await axiosInstance().get(`${path}/get-ASC-token${getQuery(values)}`)
}

export const Registration = async (values) => {
  return await axiosInstance().post(`${path}/save-question-data`, values)
}

export const DeleteVoiceMemoToken = async (_id) => {
  return await axiosInstance().get(
    `${path}/delete-ASC-token?_id=${_id}`
  );
};