import React, { useEffect, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import * as eventServices from '../../services/events.services';
import { formateDate, capitalizeFirstLetter, formateDateWithMonth,globalLoader } from '../../../../utils/commonfunction';
import Breadcrums from '../../common/breadcrumbs';
import QRCode from "react-qr-code";
import moment from 'moment';
import Swal from 'sweetalert2';
import LogoImage from '../../../../assets/user/images/logo.png'
import { useSelector } from 'react-redux';

const ViewAttendeesDetails = () => {
    const navigate = useNavigate()
    const params = useParams();
    const [attendeesUsers, setAttendeesUsers] = useState({});
    const location = useLocation();
    const { state } = location;
    const { event_id } = state || {};
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "View Event", url: "/admin/event-management/events/list/1" }, { title: "View Attendees", url: `/admin/event-management/events/view/attendees/${event_id}/1` }, { title: "View Attendees info", url: "" }];

    useEffect(() => {
        const { attendeesId } = params;
        if (attendeesId) {
            eventServices.AttendeesDetails(attendeesId)
                .then((response) => {
                    if (response?.success) {
                        setAttendeesUsers(response.data);
                    }
                })
                .catch(err => console.log("err", err));
        }
    }, [params]);

    let globalData = useSelector((state) => state.globaldetail);

    const myGlobalData = useSelector((state) => state.globalData);
    const [getGlobalData, setGlobalData] = useState({});
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        setGlobalData(JSON.parse(myGlobalData?.data));
      }
    },[myGlobalData]);



    const printTicket = (data, index) => {
        const printableContent = document.querySelector(`#queryTicket${index}`);
        console.log("printableContent",printableContent);
        const contentToPrint = printableContent.cloneNode(true);
        const printWindow = window.open('', '_blank');
        printWindow.document.open();
        printWindow.document.write(`
          <html>
            <head>
              <title>Print Ticket</title>
            </head>
            <body>
              ${contentToPrint.outerHTML}
            </body>
          </html>
        `);
        printWindow.document.close();
        printWindow.print();
        printWindow.close();
    };

    const handleDownloadClick = async (e, fileURL) => {
        let fileName = "";
        if(fileURL){
            fileName = fileURL.replace(`${process.env.REACT_APP_SERVER_BASE_URL}/uploads/registrations/tickets/`, "");
            e.preventDefault();
            globalLoader(true);
            try {
            const response = await fetch(fileURL);
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const blob = await response.blob();
            const blobURL = URL.createObjectURL(blob);
        
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = blobURL;
            a.download = fileName;
        
            document.body.appendChild(a);
            a.click();
        
            document.body.removeChild(a);
            URL.revokeObjectURL(blobURL);
            globalLoader(false);
            } catch (error) {
            globalLoader(false);
            console.error('Error downloading the file:', error);
            Swal.fire({
                title: 'Download Error',
                text: `There was an error while downloading the file "${fileName}".`,
                icon: 'error',
            });
            }
        }else{
            console.log("Error in file downloading");
        }
      };

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm viewregisration">
                <div className="col-lg-12 col-md-12">
                    <div className="card custom-card main-content-body-profile">
                        <div className="tab-content">
                            <div className="main-content-body tab-pane p-4 border-top-0 active" id="about">
                                <h4 className="tx-15 text-uppercase mb-3">Attendees Info</h4>
                                <div className="card-body p-0 border p-0 rounded-10">
                                    <div className="p-4">
                                    {Object.keys(attendeesUsers).length > 0 ? (
                                        <>
                                        {attendeesUsers.user_type === "primary" ?
                                            <div>
                                                <p>
                                                    <label>First Name :</label> {attendeesUsers && attendeesUsers?.user_data?.first_name
                                                        ? capitalizeFirstLetter(attendeesUsers.user_data.first_name)
                                                        : "N/A"}
                                                </p>
                                                <p>
                                                    <label>Middle Name :</label> {attendeesUsers && attendeesUsers?.user_data?.middle_name
                                                        ? capitalizeFirstLetter(attendeesUsers.user_data.middle_name)
                                                        : "N/A"}
                                                </p>
                                                <p>
                                                    <label>Last Name :</label> {attendeesUsers && attendeesUsers?.user_data?.last_name
                                                        ? capitalizeFirstLetter(attendeesUsers.user_data.last_name)
                                                        : "N/A"}
                                                </p>
                                                <p>
                                                    <label>Email :</label> {attendeesUsers && attendeesUsers?.user_data?.email
                                                        ? attendeesUsers.user_data.email
                                                        : "N/A"}
                                                </p>
                                                <p className="pb-0">
                                                    <label>Created Date :</label> {attendeesUsers && attendeesUsers.createdAt ? formateDate(attendeesUsers.createdAt) : "N/A"}
                                                </p>
                                            </div>
                                            :
                                            <div>
                                                <p>
                                                    <label>First Name :</label> {attendeesUsers && attendeesUsers?.guest_user_data?.first_name
                                                        ? capitalizeFirstLetter(attendeesUsers?.guest_user_data?.first_name)
                                                        : "N/A"}
                                                </p>
                                                <p>
                                                    <label>Middle Name :</label> {attendeesUsers && attendeesUsers?.guest_user_data?.middle_name
                                                        ? capitalizeFirstLetter(attendeesUsers?.guest_user_data?.middle_name)
                                                        : "N/A"}
                                                </p>
                                                <p>
                                                    <label>Last Name :</label> {attendeesUsers && attendeesUsers?.guest_user_data?.last_name
                                                        ? capitalizeFirstLetter(attendeesUsers?.guest_user_data?.last_name)
                                                        : "N/A"}
                                                </p>
                                                <p>
                                                    <label>Email :</label> {attendeesUsers && attendeesUsers?.guest_user_data?.email
                                                        ? attendeesUsers?.guest_user_data?.email
                                                        : "N/A"}
                                                </p>
                                                <p className="pb-0">
                                                    <label>Created Date :</label>
                                                    {attendeesUsers && attendeesUsers.createdAt ? formateDate(attendeesUsers.createdAt) : "N/A"}
                                                </p>
                                            </div>
                                        }
                                        </>
                                ) : (
                                    <></>
                                )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
              {attendeesUsers?.tickets_data?.length > 0 ? (
                <div className="row row-sm viewregisration">
                    <div className="col-lg-12 col-md-12">
                        <div className="card custom-card main-content-body-profile">
                            <div className="tab-content">
                                <div className="main-content-body tab-pane p-4 border-top-0 active" id="about">
                                    <h4 className="tx-15 text-uppercase mb-3">Tickets Info</h4>
                                    <div className="row row-sm tickets">
                                        {attendeesUsers.tickets_data.map((data, index) => (
                                            <div className="col-6 mb-4" id={`queryTicket${index}`} key={index}>
                                                <div className="card-body p-0 border p-0 rounded-10 h-100">
                                                    <div className="p-4">
                                                        <div className="row">
                                                            <table width="100%">
                                                                <tbody>
                                                                    <tr>
                                                                        <td width="70%" style={{ paddingRight: 30 }}>
                                                                            <table width="100%" style={{ borderCollapse: "collapse" }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <div className="btn-group button-admin" role="group" aria-label="First group">
                                                                                                <button type="button" className="btn btn-dark" onClick={() => printTicket(data, index)}><i className="fa fa-print" aria-hidden="true"></i>
                                                                                                </button>
                                                                                                <button type="button"
                                                                                                    className="btn btn-dark"
                                                                                                    href="#"
                                                                                                    onClick={(e) => handleDownloadClick(e,data?.ticket_pdf)}
                                                                                                >
                                                                                                <i className="fa fa-download" aria-hidden="true"></i>
                                                                                                </button>
                                                                                                </div>
                                                                                            <img src={LogoImage} alt="Logo" style={{ marginBottom: 20 }}  />
                                                                                            <h4 style={{
                                                                                                fontSize: 22,
                                                                                                margin: 0,
                                                                                                paddingBottom: 5,
                                                                                                textTransform: "uppercase"
                                                                                            }}

                                                                                            >
                                                                                                {attendeesUsers?.event_data?.title ? attendeesUsers?.event_data?.title : null}
                                                                                            </h4>
                                                                                            <p
                                                                                                style={{
                                                                                                    fontSize: 14,
                                                                                                    margin: 0,
                                                                                                    paddingBottom: 20,
                                                                                                    textTransform: "uppercase"
                                                                                                }}
                                                                                            >
                                                                                                {attendeesUsers?.event_data?.start_date
                                                                                                    ? formateDateWithMonth(attendeesUsers?.event_data?.start_date)
                                                                                                    : null} - {attendeesUsers?.event_data?.end_date
                                                                                                        ? formateDateWithMonth(attendeesUsers?.event_data?.end_date)
                                                                                                        : null}
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            width="70%"
                                                                                            style={{
                                                                                                borderTop: "2px solid #6fbe44",
                                                                                                borderBottom: "2px solid #6fbe44",
                                                                                                borderRight: "2px solid #6fbe44"
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    paddingTop: 8,
                                                                                                    margin: 0,
                                                                                                    fontSize: 11,
                                                                                                    paddingBottom: 8,
                                                                                                    textTransform: "uppercase"
                                                                                                }}
                                                                                            >
                                                                                                {data && data?.type === "event" ? "NAME OF THE EVENT" : data?.type === "agenda" ? "NAME OF THE AGENDA ELEMENT" : "NAME OF THE HOTEL"}
                                                                                            </p>
                                                                                            {data && data?.type === "event" ?
                                                                                                <p
                                                                                                    style={{
                                                                                                        margin: 0,
                                                                                                        paddingBottom: 30,
                                                                                                        fontSize: 15,
                                                                                                        fontWeight: 500,
                                                                                                        textTransform: "uppercase"
                                                                                                    }}
                                                                                                >
                                                                                                    {capitalizeFirstLetter(attendeesUsers?.event_data?.title)}
                                                                                                </p> : null
                                                                                            }
                                                                                            {data && data?.type === "hotel" ?
                                                                                                <p
                                                                                                    style={{
                                                                                                        margin: 0,
                                                                                                        paddingBottom: 30,
                                                                                                        fontSize: 15,
                                                                                                        fontWeight: 500,
                                                                                                        textTransform: "uppercase"
                                                                                                    }}
                                                                                                >
                                                                                                    {capitalizeFirstLetter(attendeesUsers?.hotel_data?.name)}
                                                                                                </p> : null
                                                                                            }
                                                                                            {data && data?.type === "agenda" ?
                                                                                                <p
                                                                                                    style={{
                                                                                                        margin: 0,
                                                                                                        paddingBottom: 30,
                                                                                                        fontSize: 15,
                                                                                                        fontWeight: 500,
                                                                                                        textTransform: "uppercase"
                                                                                                    }}
                                                                                                >
                                                                                                    {function () {
                                                                                                        let currentElement = {};
                                                                                                        data && data?.agenda_data.forEach((item, index) => {
                                                                                                            currentElement = item?.elements && item?.elements.filter(element => data?.element_id === element?._id)[0]
                                                                                                        })
                                                                                                        return capitalizeFirstLetter(currentElement?.name);
                                                                                                    }()}
                                                                                                </p> : null
                                                                                            }
                                                                                        </td>
                                                                                        <td
                                                                                            style={{
                                                                                                borderTop: "2px solid #6fbe44",
                                                                                                borderBottom: "2px solid #6fbe44",
                                                                                                paddingLeft: 10
                                                                                            }}
                                                                                        >
                                                                                            <p
                                                                                                style={{
                                                                                                    paddingTop: 8,
                                                                                                    margin: 0,
                                                                                                    fontSize: 11,
                                                                                                    paddingBottom: 8,
                                                                                                    textTransform: "uppercase"
                                                                                                }}
                                                                                            >
                                                                                                VALID :
                                                                                            </p>
                                                                                            <p style={{ margin: 0, paddingBottom: 30, fontSize: 15 }}>
                                                                                                {attendeesUsers?.event_data?.end_date
                                                                                                    ? moment(attendeesUsers?.event_data?.end_date).format('DD.MM.YYYY | hh:mm')
                                                                                                    : null}
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td colSpan={2}>
                                                                                            <h3
                                                                                                style={{
                                                                                                    margin: 0,
                                                                                                    paddingTop: 20,
                                                                                                    fontWeight: 400,
                                                                                                    paddingBottom: 5,
                                                                                                    fontSize: 18,
                                                                                                    textTransform: "uppercase"
                                                                                                }}
                                                                                            >
                                                                                                PARTICIPANT NAME:
                                                                                            </h3>
                                                                                            {data && data?.guest_type === "secondary" ?
                                                                                                <h2
                                                                                                    style={{
                                                                                                        margin: 0,
                                                                                                        paddingTop: 20,
                                                                                                        fontWeight: 400,
                                                                                                        paddingBottom: 5,
                                                                                                        fontSize: 18,
                                                                                                        textTransform: "uppercase"
                                                                                                    }}
                                                                                                >
                                                                                                    {attendeesUsers?.guest_user_data?.name ? attendeesUsers?.guest_user_data?.name : "N/A"}
                                                                                                </h2> : null
                                                                                            }
                                                                                            {data && data?.guest_type === "primary" ?
                                                                                                <h2
                                                                                                    style={{
                                                                                                        margin: 0,
                                                                                                        paddingTop: 20,
                                                                                                        fontWeight: 400,
                                                                                                        paddingBottom: 5,
                                                                                                        fontSize: 18,
                                                                                                        textTransform: "uppercase"
                                                                                                    }}
                                                                                                >
                                                                                                    {attendeesUsers?.user_data?.name ? attendeesUsers?.user_data?.name : "N/A"}
                                                                                                </h2>
                                                                                                : null}
                                                                                            {data && !data?.guest_type ?
                                                                                                <h2
                                                                                                    style={{
                                                                                                        margin: 0,
                                                                                                        paddingTop: 20,
                                                                                                        fontWeight: 400,
                                                                                                        paddingBottom: 5,
                                                                                                        fontSize: 18,
                                                                                                        textTransform: "uppercase"
                                                                                                    }}
                                                                                                >
                                                                                                    {attendeesUsers?.user_data?.name ? attendeesUsers?.user_data?.name : "N/A"}
                                                                                                </h2>
                                                                                                : null}
                                                                                            <div style={{ fontSize: 12, lineHeight: "1.5" }} dangerouslySetInnerHTML={{ __html: attendeesUsers?.event_data?.description ? attendeesUsers?.event_data?.description : "N/A" }}>
                                                                                            </div>
                                                                                            <div className="" id='ticket_footer_text' dangerouslySetInnerHTML={{ __html: getGlobalData?.settings?.ticket_footer_text ? getGlobalData?.settings?.ticket_footer_text : "N/A" }}></div>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <table width="100%" style={{ borderCollapse: "collapse" }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td
                                                                                            style={{
                                                                                                background: "#e7e7e8",
                                                                                                textAlign: "center",
                                                                                                padding: "30px 10px"
                                                                                            }}
                                                                                        >
                                                                                            {window.location.host}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td
                                                                                            style={{
                                                                                                background: "#6fbe44",
                                                                                                textAlign: "center",
                                                                                                padding: "30px 30px"
                                                                                            }}
                                                                                        >
                                                                                            <table width="100%" style={{ background: "#fff" }}>
                                                                                                <tbody>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <p style={{ marginTop: "5px" }}>
                                                                                                                {data && data?.ticket_number ? data.ticket_number : "N/A"}
                                                                                                            </p>
                                                                                                            <p className="qr-code">
                                                                                                                <QRCode
                                                                                                                    size={256}
                                                                                                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                                                                                                    value={data?.ticket_number}
                                                                                                                    viewBox={`0 0 256 256`}
                                                                                                                />
                                                                                                            </p>
                                                                                                            <p>
                                                                                                                {data && data?.type ? capitalizeFirstLetter(data.type) : "N/A"}
                                                                                                            </p>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </tbody>
                                                                                            </table>
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="mt-5">
                                        <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                                            <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                            Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};

export default ViewAttendeesDetails;

