import React, { useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { getLocalKey, logOutUser } from "../../../utils/commonfunction";
import { STAFF_ROLE } from "../../../utils/Constants";

function InfoCounterSettingMenu(props) {
  let sidebarContainer = useRef();
  const loc = useLocation();
  const { t } = useTranslation();
  const [isPhone, setIsPhone] = useState(false);
  const [getuserkey, setGetUserkey] = useState();
  const navigate = useNavigate();


  const leadData = useSelector((state) => state.designportal);

  useEffect(() => {
    if(window.innerWidth > 500){
      setIsPhone(false);
    }else{
      setIsPhone(true);
    }
  },[])

  useEffect(() => {
    try {
        const getuserkey = JSON.parse(getLocalKey("user"));
        setGetUserkey(getuserkey);
    } catch (err) {
        setGetUserkey(null)
    }
}, [loc])

  const logout = () => {
    Swal.fire({
      customClass: "swal-wide",
      title: t('web_swal_are_you_sure'),
      text:  t('lbl_logout_text'),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor:
        leadData?.data?.background_colors?.primary_active_color,
      cancelButtonColor:
        leadData?.data?.background_colors?.footer_background_color,
      confirmButtonText: t('web_swal_yes'),
      cancelButtonText: t('web_swal_no')
    }).then((result) => {
      if (result.isConfirmed) {
        logOutUser(navigate);
      }
    });
  };

  const handleResponsiveMenu = () => {
    if(isPhone){
      props.onClose(false)
    }
  }


  return (
    <div id="setting_open" className={`side-bar-L ${props.showMenu === true ? "open" : ""}`} ref={sidebarContainer}>
      <div className="sidebar-body">
        <a
          href="/"
          className="close-side-btn"
          onClick={(e) => {
            e.preventDefault();
            if(props.onClose){
              props.onClose(false);
            }
          }}
        >
          <i className="ri-close-line"></i>
        </a>
        <ul className="sidebar-link-list">
          <li
            className={
              loc.pathname.includes("/info-counter/settings/profile") ? " active" : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/info-counter/settings/profile"}>
              <figure>
                <i className="ti ti-user"></i>
              </figure>
              {t("web_sidebar_profile")}
            </Link>
          </li>
          <li
            className={
              loc.pathname.includes("/info-counter/settings/change-password")
                ? " active"
                : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/info-counter/settings/change-password"}>
              <figure>
                <i className="ti ti-lock"></i>
              </figure>
              {t("web_sidebar_change_password")}
            </Link>
          </li>
          
            {/* <li
            className={
              loc.pathname.includes("/info-counter/settings/event-registrations")
                ? " active"
                : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/info-counter/settings/event-registrations/list/1"}>
              <figure>
                <i className="ti ti-calendar-event"></i>
              </figure>
              {t("web_sidebar_event_registration")}
            </Link>
          </li> */}
          
            {/* <li
            className={
              loc.pathname.includes("/info-counter/settings/shared-files") ? " active" : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/info-counter/settings/shared-files/1"}>
              <figure>
                <i className="ti ti-files"></i>
              </figure>
              {t("web_sidebar_shared_files")}
            </Link>
          </li> */}
          
            {/* <li
            className={
              loc.pathname.includes("/info-counter/settings/invitation-list")
                ? " active"
                : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/info-counter/settings/invitation-list/1"}>
              <figure>
                <i className="ti ti-link"></i>
              </figure>
              {t("web_sidebar_invitation_list")}
            </Link>
          </li> */}
          
            {/* <li
            className={
              loc.pathname.includes("/info-counter/settings/transaction-list")
                ? " active"
                : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/info-counter/settings/transaction-list/1"}>
              <figure>
                <i className="ri-bank-line"></i>
              </figure>
              {t("web_sidebar_transaction_list")}
            </Link>
          </li> */}
          {/* <li
            className={
              loc.pathname.includes("/info-counter/settings/booking-list")
                ? " active"
                : ""
            }
          >
            <Link onClick={() => handleResponsiveMenu()} to={"/info-counter/settings/booking-list/1"}>
              <figure>
                <i className="ti ti-calendar-event"></i>
              </figure>
              {t("web_sidebar_my_bookings")}
            </Link>
          </li> */}
          <li>
            <a href="/" onClick={(e) => {e.preventDefault();logout()}}>
              <figure>
                <i className="ti ti-logout"></i>
              </figure>
              {t("web_sidebar_logout")}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default InfoCounterSettingMenu;