import React, { useEffect, useState } from 'react';
import * as surveyServices from '../../services/survey.service';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TrimText, formatDateFromTime, getSessionKey, globalLoader, removeSessionKey, setSessionKey } from '../../../../utils/commonfunction';
import bannerImage from '../../../../assets/user/images/banner-card.png'
import CustomPagination from '../../../admin/common/custompagination';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';


const SurveyTable = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [forum, setForum] = useState([]);
    const [itemperpage] = useState(12);
    const [page, setPage] = useState(1);
    const [datalength, setDataLength] = useState('');
    const {t} = useTranslation();
    const [loader,setLoader] = useState(true);
    const myGlobalData = useSelector((state) => state.globalData);
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        const settingsData = JSON.parse(myGlobalData?.data);
        if(!settingsData?.settings?.surveys){
            navigate("/404");
        }
      }
    },[myGlobalData]);

    useEffect(() => {
        document.addEventListener('updateSurveyHeight',(e)=>{
            let id = e.detail.id;
            let height = e.detail.height;
            let index = e.detail.index;
            if (!getSessionKey(id) || getSessionKey(id) !== height) {
                setSessionKey(id,height);
                let element = document.getElementById("showMoreSurveyContainerbutton"+index);
                if(element){
                    removeSessionKey(id);
                    if(height>100){
                        element.classList.remove('d-none');
                    }
                }
            }
        })
        return () => {
            // console.log("Component will unmount");
            for(let i = 0; i <= 20; i++){ 
              removeSessionKey("showMoreSurveyContainer"+i);
            }
          };
    }, []);

    useEffect(() => {
        // globalLoader(true);
        setLoader(true);
        const formData = new FormData();
        formData.append("page", params.pgno);
        formData.append("per_page", itemperpage);
        formData.append("type", 'survey');
        surveyServices.List(formData)
            .then((response) => {
                setForum(response?.data?.list || []);
                setDataLength(response?.data?.total_records)
                setPage(response && response?.data && response?.data?.page ? response?.data?.page : 1);
                // globalLoader(false);
                setLoader(false);
            })
            .catch((err) => {
                console.log(err);
                // globalLoader(false);
                setLoader(false);
            });
    }, [itemperpage, params.pgno]);

    const handleSurvey = (_id, data = {}) => {
        navigate(`/survey/question/${_id}`, { state: data })
    }

    const handleShowMore = (e, id)=>{
        if(e.target.classList.contains('active')){
            e.target.classList.remove('active');
            document.getElementById(id).classList.add('max-100');
            e.target.innerText="Show More";
            
        }else{
            e.target.classList.add('active');
            document.getElementById(id).classList.remove('max-100');
            e.target.innerText="Show Less";
        }
    }

    return (
        <>
            <div className="wraper-inner cpt bg-grey">
                <section className="banner-card mt-4">
                    <div className="container">
                        <div className="banner-card-content text-center" style={{ backgroundImage: `url(${bannerImage})` }}>
                            <h1>{t('web_header_heading_survey')}</h1>
                            <nav className="breadcrumb text-center">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item">
                                        <Link to={`/`}>{t('web_header_heading_home')}</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                       {t('web_header_heading_survey')}
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </section>
                <section className="survey grid-box-main mt-5 pb-5">
                    <div className="container">
                        <div className="row">
                            {loader ? 
                            <div class="d-flex justify-content-center">
                            <div class="spinner-border" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                          :
                          <>
                            {forum && forum?.length > 0 ? forum.map((data, i) => {
                                return(
                                <div className="col-md-4" key={i}>
                                    <div className="grid-box">
                                        <h3>{TrimText(data?.title, 75)}</h3>
                                        <div className="date-info">
                                            <i className="ri-calendar-event-line"></i>{' '}
                                            {formatDateFromTime(data?.createdAt)}
                                        </div>
                                        <div className='surveyPage max-100' id={'showMoreSurveyContainer' + i} ref={(el) => {
                                            if (el) {
                                                const evt = new CustomEvent('updateSurveyHeight', {
                                                    detail: {
                                                        id: 'showMoreSurveyContainer' + i,
                                                        index: i,
                                                        height: el.scrollHeight
                                                    },
                                                });
                                                document.dispatchEvent(evt);
                                            }
                                        }} dangerouslySetInnerHTML={{ __html: data?.description }}>
                                            {/* Description */}
                                        </div>
                                         {/* Show more integration */}
                                        <div className='d-flex justify-content-end w-100 d-none mt-2' id={'showMoreSurveyContainerbutton' + i}>
                                            <button type='button' className='show-more' onClick={(e) => { handleShowMore(e, 'showMoreSurveyContainer' + i) }}>Show More</button>
                                        </div>     
                                        <span className="question-info">{data?.questions_count} {t('web_lbl_question')}</span>
                                        <button className="btn btn-primary" type='button' disabled={data.questions_count === 0 ? true : null} onClick={() => handleSurvey(data?._id, { title: data?.title, description: data?.description, createdAt: data?.createdAt })}>
                                        {t('web_lbl_take_survey')}
                                        </button>
                                    </div>
                                </div>
                                )
                                    })
                            :
                            <h2 style={{ textAlign: "center", fontSize: "25px" }}>
                                   {t('web_lbl_no_records')}
                                </h2>
                            } 
                          </>
                            }
                        </div>
                    </div>
                    <div id='div_class'>
                        {datalength && datalength > 0 ? (
                            <div className="mt-4 text-right pagination-main d-flex justify-content-end  pe-4">
                            <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "Survey", path: "/survey" }]} />
                            </div> ) : (
                                ""
                        )}
                    </div>
                </section>
            </div>
        </>
    );
};

export default SurveyTable;
