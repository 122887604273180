import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Sorting from "../../common/sorting";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import CustomPagination from "../../common/custompagination";
import Loader from "../../common/loader";
import {
  capitalizeFirstLetter,
  formateDate,globalLoader,handleServerValidations,removeCommas, removeSpecialCharacter, writeMyExcel
} from "../../../../utils/commonfunction";
import CustomRangepicker from "../../common/rangepicker";
import {LeadManagementDelete, List, Options } from "../../services/leadManagement.services";
import { LEADTYPES, SWAL_SETTINGS } from "../../../../utils/Constants";
import $ from "jquery";



function EventLeads() {
  const navigate = useNavigate();
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const [resetdate, setResetDate] = useState(false);
  const params = useParams();
  const [list, setList] = useState([]);
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const location = useLocation();
  const [allUsersExportFlag] = useState(false);
  const [defaultSorting, setDefaultSorting] = useState(true);
  const [statsupdate, setStatusUpdate] = useState("false");
  const [type] = useState(Object.keys(LEADTYPES).map((item => {
    return {
      label: item, value: LEADTYPES[item]
    }
  })));
  const [options,setOptions] = useState([]);







  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      formData.append("event_id", params.id);
        List(formData)
        .then((data) => {
          setDataLength(data.data.total_records);
          setList(data && data.data && data.data.list ? data.data.list : []);
          setPage(data && data.data && data.data.page ? data.data.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [location, statsupdate, sorting, search, globalsearch,itemperpage,params.pgno,params.id]);

  useEffect(() => {
    Options(params.id)
      .then((response) => {
        if (response.success) {
          setOptions(response?.data);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, []);


  const exportAllLeads = (event_id, done) => {
    globalLoader(true);
    const formData = new FormData();
    formData.append("page", 1);
    formData.append("per_page", datalength);
    formData.append("sort", JSON.stringify(sorting));
    formData.append("search", JSON.stringify(search));
    formData.append("global_search", globalsearch);
    formData.append("event_id", params?.id);
    List(formData)
      .then((response) => {
        let data =
          response && response?.data && response?.data?.list
            ? response.data.list
            : [];
        let lengthArr = [];
        data.forEach((item, index) => {
          if (lengthArr?.length < item?.questions_data?.length) {
            lengthArr = Array(item?.questions_data?.length).fill([]);
          }
        });
        let rows = data.map((item) => {
          const tempRow = [
            removeCommas(item?.name),
            removeCommas(item?.email),
            removeCommas(item?.event_data?.title),
            removeCommas(
              item?.is_draft === true || item?.is_draft === true
                ? "Draft"
                : "Completed"
            ),
            removeCommas(item?.lm_back_office_data?.name)
              ? removeCommas(item?.lm_back_office_data?.name)
              : "N/A",
            removeCommas(formateDate(item?.createdAt)),
          ];
          // for (let i = 0; i < lengthArr?.length; i++) {
          //     tempRow.push(`${capitalizeFirstLetter(removeSpecialCharacter(item?.questions_data[i]?.question_title ?? "N/A"))}: ${item?.questions_data[i]?.answer ?? "N/A"}`);
          // }
          for (let i = 0; i < lengthArr?.length; i++) {
            // let questionTitle = item?.questions_data[i]?.question_title ?? "N/A";
            let questionTitle;
            if (item && item?.questions_data && item?.questions_data[i]) {
              questionTitle = item?.questions_data[i]?.question_title ?? "N/A";
            } else {
              questionTitle = "N/A";
            }
            // let answer = item?.questions_data[i]?.answer ?? "N/A";
            // if (questionTitle === "N/A" && answer === "N/A") {
            //   tempRow.push("N/A");
            // } else {
            //   tempRow.push(
            //     `${capitalizeFirstLetter(
            //       removeSpecialCharacter(questionTitle)
            //     )}: ${answer}`
            //   );
            // }
            let answer;
            if (item && item.questions_data && item.questions_data[i]) {
              answer = item.questions_data[i].answer ?? "N/A";
            } else {
              answer = "N/A";
            }

            if (questionTitle === "N/A" && answer === "N/A") {
              tempRow.push("N/A");
            } else {
              tempRow.push(
                `${capitalizeFirstLetter(
                  removeSpecialCharacter(questionTitle)
                )}: ${answer}`
              );
            }
          }
          return tempRow;
        });

        const headers = [
          "Name",
          "Email",
          "Event",
          "Type",
          "Created By",
          "Created Date",
          ...lengthArr.map((_, index) => `Extra Info`),
        ];

        writeMyExcel([headers, ...rows], `lead-report.xlsx`)
          .then(() => {
            setTimeout(() => {
              globalLoader(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log("error ====> ", error);
        globalLoader(false);
      });
  };

  // search or filter function start
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  // search or filer function  end

  // reset filter function start
  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    setResetDate(!resetdate);
    $("#resetFilter")[0].selectedIndex = 0;
    $("#created_by")[0].selectedIndex = 0;
  };
  // reset filter function end

  const viewfunction = (row) => {
    navigate(`/admin/lead-management/view/${row._id}`);
  };
    
  const goToEdit = (row) =>{
    navigate(`/admin/lead-management/${params.pgno}/edit/${row._id}`)
  }
    // sorting function start
    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
          sort.order = -1;
        } else {
          sort.order = 1;
        }
        setSorting(sort);
      };
      // sorting end

  
    function Deletefunction(data) {
        Swal.fire({
          customClass: "swal-wide",
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#403fad",
          cancelButtonColor: "#f1388b",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (result.isConfirmed) {
            const formdata = new FormData();
            let ids = Array.isArray(data) ? data : [data];
            formdata.append("o_id", JSON.stringify(ids));
            LeadManagementDelete(formdata)
              .then((response) => {
                setStatusUpdate(!statsupdate);
                if (response.success) {
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                }
              })
              .catch((error) => {
                console.log("deleteError");
              });
          }
        });
      }

      const handleFilterChange = (e) => {
        if (e.target.value) {
          prepareSearch("type", e.target.value)
        } else {
          prepareSearch("type", "")
        }
      };

      const handleCreatedByChange = (e) => {
        if (e.target.value) {
          prepareSearch("created_by", e.target.value);
        } else {
          prepareSearch("created_by", "");
        }
      }


  return (
    <div className="row">
        {/* <div className="card custom-card"> */}
          {/* <div className="card-body"> */}
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="main-content-label">Leads</h6>
              <div className="d-flex align-items-center">
                <div className="form-group mb-0 me-3">
                  <div className="form-group mb-0 rangepicker_container filter_design">
                    <i className="fa fa-search calender_icon"></i>
                    <input
                      type="search"
                      className="form-control"
                      value={globalsearch}
                      placeholder="Search"
                      onChange={(e) => {
                        setGlobalSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="me-3">
                  <CustomRangepicker
                    GetDateRange={(e) => {
                      prepareSearch("createdAt", e);
                    }}
                    resetdate={resetdate}
                  />
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select className="form-control cp" onChange={handleFilterChange} id="resetFilter" style={{ width: "130px" }}>
                        <option value="">
                         Type
                        </option>
                        {type && type.length > 0 && type.map((option, i) => {
                          return (
                            <option key={i} value={option.value}>
                              {option.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select
                        className="form-control cp"
                        onChange={handleCreatedByChange}
                        id="created_by"
                      >
                        <option value="" label="Select created by">
                          Select{" "}
                        </option>
                        {options &&
                          options.map((option, i) => {
                            return (
                              <option key={i} value={option._id}>
                                {option.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </div>
                <button
                  type="reset"
                  value="Reset"
                  onClick={resetFilter}
                  className="btn btn-warning float-right mr-2"
                >
                  Reset Filter
                </button>
                {
                  allUsersExportFlag ?
                    <>
                      <button className="btn ripple btn-main-primary signbtn mr-2" disabled={datalength === 0} onClick={exportAllLeads}>Export Leads</button>
                    </>
                    : <button className="btn ripple btn-main-primary signbtn mr-2" disabled={datalength === 0} onClick={exportAllLeads}>Export Leads</button>
                }
              </div>
            </div>
            <div className="table-responsive">
              <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                <thead>
                  <tr>
                    <th>
                      <div className="sorting_column">
                        <span>Name</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="name"
                        />
                      </div>
                    </th>
                    <th>
                      <div className="sorting_column">
                        <span>Email</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          column="email"
                        />
                      </div>
                    </th>
                    <th>Type</th>
                    <th>Created By</th>
                    <th className="created_head">
                      <div className="sorting_column">
                        <span>Created Date</span>
                        <Sorting
                          sort={sorting}
                          handleSort={handleSort}
                          defaultSorting={defaultSorting}
                          column="createdAt"
                        />
                      </div>
                    </th>
                    {/* <th className="status_head">Status</th> */}
                    <th className="action_head">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loader ? (
                    <tr>
                      <td colSpan={6}>
                        <Loader />
                      </td>
                    </tr>
                  ) : (
                    <>
                      {list.length ? (
                        list.map((row, i) => {
                          return (
                            <tr key={i} >
                               <td className="text-capitalize">{row?.name ? row.name : "N/A"}</td>
                              <td>{row?.email ? row.email : "N/A"}</td>
                              <td>{row?.is_draft === true || row?.is_draft === "true"  ? <span className="badge badge-danger">Draft</span> : <span className="badge badge-success">Completed</span>}</td>
                              <td className="text-capitalize">{row?.lm_back_office_data?.name ? <Link to={`/admin/staff/view/${row.lm_back_office_data._id}`}>{row.lm_back_office_data.name}</Link> : "N/A"}</td>
                              <td>
                                {row.createdAt
                                  ? formateDate(row.createdAt)
                                  : "N/A"}
                              </td>
                              {/* <td>
                                {row.status === 1 ? (
                                  <button
                                    className="btn ripple btn-main-primary signbtn"
                                    onClick={() => {
                                      ChangeStatus(row?._id, 0);
                                    }}
                                  >
                                    Active
                                  </button>
                                ) : (
                                  <button
                                    className="btn ripple btn-secondary"
                                    onClick={() => {
                                      ChangeStatus(row?._id, 1);
                                    }}
                                  >
                                    Inactive
                                  </button>
                                )}
                              </td> */}
                              <td>
                                <div className="d-flex">
                                  <button
                                    className="btn ripple btn-main-primary signbtn"
                                    onClick={() => {
                                      viewfunction(row);
                                    }}
                                  >
                                    View
                                  </button>
                                        <button className="btn ripple btn-success mlAction" 
                                        onClick={() => { goToEdit(row) }}
                                        >
                                            Edit
                                        </button>
                                          <button
                                            className="btn ripple btn-secondary mlAction"
                                            onClick={() => {
                                              Deletefunction(row?._id);
                                            }}
                                          >
                                            Delete
                                          </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={6} className="text-center">
                            No records
                          </td>
                        </tr>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div
              className=""
              id="example1_info"
              role="status"
              aria-live="polite"
            >
              <b>Total Records : {datalength ? datalength : "0"}</b>
            </div>
            {datalength && datalength > 0 ? (
              <CustomPagination
                datalength={datalength}
                itemperpage={itemperpage}
                currentPage={page}
                setPage={setPage}
                pageRoute={[
                  {
                    name: "Events",
                    path: `/admin/event-management/events/view/event-leads/${params.id}`,
                  },
                ]}
              />
            ) : (
              ""
            )}
          {/* </div> */}
        {/* </div> */}
      </div>
  )
}

export default EventLeads;