import React, { useState } from "react";
import { login } from "../../../utils/bosch.service";

function NewTestPages() {
   const [loginURL, setLoginUrl] = useState();
   const runAPI = () => {
      const formData = new FormData();
      /*formData.append("email", 'test@mailinator.com');*/
      login(formData).then((response) => {
         console.log("*****Success*****", response.data);
         setLoginUrl(response.data);
      }).catch(err => {
         console.log("*****Error*****", err);
      });
   }
   return (
      <>
         <div className="container py-5">
            <h1>Hello</h1>
            <button type="button" className="btn btn-success mt-4" onClick={runAPI}>Get URL</button>
            {loginURL?<a target="_blank" href={loginURL} className="btn btn-success mt-4">Login</a>:null}

         </div>
      </>
   );
}
export default NewTestPages;