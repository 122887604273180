import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import * as eventServices from '../../services/events.services';
import { formateDate, capitalizeFirstLetter, decideRole, decideStatus } from '../../../../utils/commonfunction';
import Breadcrums from '../../common/breadcrumbs';

const ViewSISUserInvitationDetails = () => {
    const params = useParams();
    const [data, setData] = useState({});
    const location = useLocation();
    const { state } = location;
    const { event_id } = state || {};
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "View Event", url: "/admin/event-management/events/list/1" }, { title: "View SIS User Invitation", url: `/admin/event-management/events/view/event-sis-user-invitation/${event_id}/1` }, { title: "View SIS User Invitation Info", url: "" }];

    useEffect(() => {
        const { SISUserInvitationId } = params;
        if (SISUserInvitationId) {
            eventServices.LMUserInvitationDetails(SISUserInvitationId)
                .then((response) => {
                    if (response?.success) {
                        setData(response.data);
                    }
                })
                .catch(err => console.log("err", err));
        }
    }, [params]);

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm viewregisration">
                <div className="col-lg-12 col-md-12">
                    <div className="card custom-card main-content-body-profile">
                        <div className="tab-content">
                            <div className="main-content-body tab-pane p-4 border-top-0 active" id="about">
                                <h4 className="tx-15 text-uppercase mb-3">SIS User Invitation Info</h4>
                                <div className="card-body p-0 border p-0 rounded-10">
                                    <div className="p-4">
                                    {Object.keys(data).length > 0 ? (
                                        <>
                                            <div>
                                                <p>
                                                    <label>First Name :</label> {data && data?.user_data?.first_name
                                                        ? capitalizeFirstLetter(data.user_data?.first_name)
                                                        : "N/A"}
                                                </p>
                                                <p>
                                                    <label>Middle Name :</label> {data && data?.user_data?.middle_name
                                                        ? capitalizeFirstLetter(data.user_data?.middle_name)
                                                        : "N/A"}
                                                </p>
                                                <p>
                                                    <label>Last Name :</label> {data && data?.user_data?.last_name
                                                        ? capitalizeFirstLetter(data.user_data?.last_name)
                                                        : "N/A"}
                                                </p>
                                                <p>
                                                    <label>Email :</label> {data && data?.user_data?.email
                                                        ? data.user_data?.email
                                                        : "N/A"}
                                                </p>
                                                <p>
                                                    <label>Type :</label> {decideRole(data?.user_data?.role)}
                                                </p>
                                                <p>
                                                    <label>Status :</label> {decideStatus(data?.status)}
                                                </p>
                                                <p className="pb-0">
                                                    <label>Created Date :</label>
                                                    {data && data.user_data?.createdAt ? formateDate(data.user_data?.createdAt) : "N/A"}
                                                </p>
                                            </div>
                                        </>
                                ) : (
                                    <></>
                                )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewSISUserInvitationDetails;

