import React, { useState, useEffect, useRef } from 'react';
import { Formik } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from "sweetalert2";
import Breadcrums from '../../../common/breadcrumbs';
import CustomCkeditor from '../../../common/customeditor';
import * as pageServices from "../../../services/pages.services";
import {  SWAL_SETTINGS } from '../../../../../utils/Constants';
import {  globalLoader, handleServerValidations } from '../../../../../utils/commonfunction';
import { Tooltip, Whisper } from 'rsuite';
import { AddContentBlock } from '../../../services/contentBlocks.services';
const ContentBlockAdd = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const [submitted, setSubmitted] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [initialValues, setInitialValues] = useState({
        title: '',
        richtext: "",
    });
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Content Blocks", url: "/admin/cms/content-blocks/list/1" }, { title: "Add", url: "" },]
  
    useEffect(() => {
        setInitialValues({
            title: state && state?.title ? state?.title : "",
            richtext: state && state?.description ? state?.description : "",
        });
        setDataLoaded(true);
    }, [])

    return (
        <>
            <Breadcrums data={breadcrumbs} />
            {dataLoaded ? (
                <Formik
                    initialValues={initialValues}
                    validateOnBlur={false}
                    validate={values => {
                        const error = {};
                        if (!values.title || !values.title.trim()) error.title = "Title is required";
                        if (!values.richtext || !values.richtext.trim()) error.richtext = "Description is required";

                        return error;
                    }}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        setSubmitted(true);
                        globalLoader(true);
                        let formData = new FormData();
                        formData.append('title', values.title);
                        formData.append('description', values.richtext);
                        AddContentBlock(formData).then(response => {
                            setSubmitting(false);
                            if (response.success) {
                                Swal.fire({
                                    icon: 'success',
                                    text: response.message,
                                    ...SWAL_SETTINGS
                                })
                                setTimeout(() => {
                                    resetForm({ values: '' });
                                    globalLoader(false);
                                    navigate(`/admin/cms/content-blocks/list/1`)
                                }, 1000);
                            } else {
                                Swal.fire({
                                    icon: 'error',
                                    text: handleServerValidations(response),
                                    ...SWAL_SETTINGS
                                })
                                setSubmitted(false);
                                globalLoader(false);
                            }
                        }).catch(error => {
                            console.log("error ====> ", error);
                        })
                    }}
                >{({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                    setFieldTouched,
                    isSubmitting,

                }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row row-sm">
                            <div className="col-lg-12 col-md-12 animation_fade">
                                <div className="card custom-card">
                                    <div className="card-body">
                                        <div>
                                            <h6 className="main-content-label mb-3">Add Content Block</h6>
                                        </div>
                                        <div className="row row-sm">
                                            <div className='col-lg-6 text-center form-group'>
                                                <label htmlFor='title' className='text-left d-flex'>Title:<span className="requirestar">*</span>
                                                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={<Tooltip>Page Title - Shown in Front-End (Menus and Tab Bar)</Tooltip>}>
                                                        <span className='field-more-info mt-1 ms-1 cp'>?</span>
                                                    </Whisper>
                                                </label>
                                                <input name='title' id="title" type='text' onChange={handleChange} onBlur={handleBlur} value={values.title} className='form-control' />
                                                <span className='text-danger d-flex text-left'>{errors.title && touched.title && errors.title}</span>
                                            </div>
                                            <div className='col-lg-12 text-center form-group'>
                                                <label htmlFor='subject' className='text-left d-flex'>Description:<span className="requirestar">*</span></label>
                                                <CustomCkeditor
                                                    fieldname={"richtext"}
                                                    setFieldValue={setFieldValue}
                                                    value={values.richtext}
                                                    setFieldTouched={setFieldTouched}
                                                />
                                                <span className='text-danger d-flex text-left'>{errors.richtext && touched.richtext && errors.richtext}</span>
                                            </div>
                                            <div className="">
                                                <button className="btn btn-main-primary signbtn mr-2" type="submit" disabled={submitted ? true : null}>
                                                    <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                                                    Submit
                                                </button>
                                                <button className="btn ripple btn-secondary" type='button' disabled={submitted ? true : null} onClick={() => navigate(-1)}>
                                                    <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
                </Formik>
            ) : (
                <></>
            )}
        </>
    )
}

export default ContentBlockAdd