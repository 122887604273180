import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import * as transactionServices from "../../services/transactions.services";
import { formateDate } from '../../../../utils/commonfunction';
import Breadcrums from '../../common/breadcrumbs';
import Loader from '../../common/loader';
import { getType, TRANSACTION_STATUS } from '../../../../utils/Constants';

const TransactionsView = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const breadcrumbs = [{title: "Dashboard",url: "/admin/dashboard"},{title: "Transactions",url: "/admin/transactions/list/1"},{title: "View",url: ""}]

    console.log("showdefault",showdefault);

    useEffect(() => {
        transactionServices.Details(params.id).then(data => {
            setShowDefault(data && data.data ? data.data : [])
            console.log("anii",data.data);
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [])

    const getStatus = (status) => {
        if (status === TRANSACTION_STATUS.PENDING) {
          return <span className="badge badge-warning">Pending</span>;
        } else if (status === TRANSACTION_STATUS.SUCCESS) {
          return <span className="badge badge-success">Success</span>;
        } else if (status === TRANSACTION_STATUS.FAILED) {
          return <span className="badge badge-danger">Failed</span>;
        }
      };

    return (
        <>
            <Breadcrums data={breadcrumbs} />
                <div className="row row-sm">
                    <div className="col-lg-12 col-md-12 animation_fade">
                        <div className="card custom-card">
                            <div className="card-body">
                                <div>
                                    <h6 className="main-content-label mb-3">View Transaction</h6>
                                </div>
                                {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                                    <div className="col-lg-12 form-group">
                                        <table id="simple-table" className="table  table-bordered table-hover">
                                            <tbody>
                                                {/* <tr>
                                                    <th>User Name</th>
                                                    <td>{showdefault && showdefault?.user_data?.name ? <Link to={`/admin/staff/view/${showdefault?.user_data?._id}`}>{showdefault?.user_data?.name}</Link> : "N/A"}</td>
                                                </tr> */}
                                                {/* <tr>
                                                    <th>User Email</th>
                                                    <td>{showdefault && showdefault?.user_data?.email ? <Link to={`/admin/staff/view/${showdefault?.user_data?._id}`}>{showdefault?.user_data?.email}</Link> : "N/A"}</td>
                                                </tr> */}
                                                {/* <tr>
                                                    <th>Event Name</th>
                                                    <td>{showdefault && showdefault?.registration_data?.event_data?.title ? <Link to={`/admin/event-management/events/view/event/${showdefault?.registration_data?.event_data?._id}`}>{showdefault?.registration_data?.event_data?.title}</Link> : "N/A"}</td>
                                                </tr> */}
                                                {/* <tr>
                                                    <th>Registration Id</th>
                                                    <td>{showdefault && showdefault?.registration_id ? <Link to={`/admin/event-management/events/view/${showdefault?.registration_id}`}>{showdefault?.registration_id}</Link> : "N/A"}</td>
                                                </tr> */}
                                                <tr>
                                                    <th>User Name</th>
                                                    <td>{showdefault && showdefault?.user_data?.name ? <Link to={`/admin/user-management/users/view/${showdefault?.user_id}`}>{showdefault?.user_data?.name}</Link>: "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>User Email</th>
                                                    <td>{showdefault && showdefault?.user_data?.email ? <Link to={`/admin/user-management/users/view/${showdefault?.user_id}`}>{showdefault?.user_data?.email}</Link>: "N/A"}</td>
                                                </tr>
                                                 <tr>
                                                    <th>Event Title</th>
                                                    <td>{showdefault && showdefault?.registration_data && showdefault?.registration_data?.event_data ? <Link to={`/admin/event-management/events/view/event/${showdefault?.registration_data?.event_data?._id}`}>{showdefault?.registration_data?.event_data?.title}</Link>: "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>PayPal Payment Id</th>
                                                    <td>{showdefault && showdefault?.paypal_pay_id ? showdefault?.paypal_pay_id : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Type</th>
                                                    <td className="text-capitalize">{getType(showdefault && showdefault?.type ? showdefault?.type : "N/A")}</td>
                                                </tr>
                                                <tr>
                                                    <th>Amount</th>
                                                    <td>{showdefault && showdefault?.amount ? (showdefault?.currency.sign ? showdefault?.currency.sign : '') + showdefault?.amount : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>PayPal Sale Id</th>
                                                    <td>{showdefault && showdefault?.paypal_sale_id ? showdefault?.paypal_sale_id : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Payer Id</th>
                                                    <td>{showdefault && showdefault?.payer_id ? showdefault?.payer_id : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Refund By</th>
                                                    <td>{showdefault && showdefault?.refund_by?.name? <Link to={`/admin/user-management/users/view/${showdefault?.user_id}`}>{showdefault.refund_by?.name}</Link>: "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Email</th>
                                                    <td>{showdefault && showdefault?.refund_by?.email? showdefault?.refund_by?.email: "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Refund Type</th>
                                                    <td className="text-capitalize">{showdefault && showdefault?.refund_type ? showdefault?.refund_type : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Refund Reason</th>
                                                    <td>{showdefault && showdefault?.refund_reason ? showdefault?.refund_reason : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Status</th>
                                                    <td>{getStatus(showdefault && showdefault?.status ? showdefault?.status : "N/A")}</td>
                                                </tr>
                                                <tr>
                                                    <th>Created Date</th>
                                                    <td>{showdefault && showdefault.createdAt ? formateDate(showdefault.createdAt) : "N/A"}</td>
                                                </tr>
                                                <tr>
                                                    <th>Refund Amount</th>
                                                    <td>{showdefault && showdefault.total_refunded_amount ? (showdefault?.currency.sign ? showdefault?.currency.sign : '') +  showdefault.total_refunded_amount : "0"}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div className="mt-5">
                                            <button className="btn ripple btn-dark" type='button' onClick={() => navigate(-1)}>
                                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                                Back
                                            </button>
                                        </div>
                                    </div>
                                </div> : <Loader />}
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default TransactionsView;