import * as React from "react";
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";
import { Provider, useDispatch } from 'react-redux';
import store from './redux/store';
import { I18nextProvider } from "react-i18next";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import router from './App';
import './App.css';
import 'animate.css/animate.css';
import 'react-image-crop/dist/ReactCrop.css';
import "bootstrap/dist/js/bootstrap.bundle.js";
import { GetLanguageJson, globalSetting } from "./modules/user/services/common.services";
import { getLocalKey } from "./utils/commonfunction";


ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
      <RouterProvider router={router} />
      <div id="main-loader" className="main-loader" style={{ display: "none" }}>
        <div className="spinner-border text-light" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
  </Provider>
);