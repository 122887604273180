import React, { useEffect, useState } from 'react';
import { DateRangePicker } from 'rsuite';
import moment from 'moment';

const CustomRangepicker = (props) => {
    const [value, setValue] = useState([]);

    useEffect(() => {
        if (props.resetdate) {
            setValue([]);
        } if(props.setdate) {
            setValue([moment().startOf('day').toDate(), moment().endOf('day').toDate()]);
        }
    }, [props.resetdate,props.setdate]);

    function handleChange(daterange) {
        if (daterange && daterange.length === 2) {
            props.GetDateRange({ start: daterange[0], end: daterange[1] });
            setValue(daterange);
        } else {
            props.GetDateRange({});
            setValue([]);
        }
    }

    return (
        <>
            <div className="form-group mb-0 rangepicker_container filter_design">
                <i className="fa fa-calendar glyphicon glyphicon-calendar calender_icon"></i>
                <DateRangePicker
                    value={value}
                    placeholder={"Date"}
                    placement='bottomEnd'
                    cleanable={false}
                    onChange={handleChange}
                />
            </div>
        </>
    );
}

export default CustomRangepicker;



