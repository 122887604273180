import React, { useEffect, useState } from "react";
import {  useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { formateDate } from "../../../../utils/commonfunction";
import CustomPagination from "../../../admin/common/custompagination";
import { RegistrationList } from "../../../user/services/lead.services";

const BackOfficeRegistrationList = () => {
  const params = useParams();
  const [list, setList] = useState([]);
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(9);
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const navigate = useNavigate();


  useEffect(() => {
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", itemperpage);
    RegistrationList(formData)
      .then((response) => {
        if (response && response?.success) {
          setList(response?.data?.list);
          setDataLength(response?.data?.total_records);
          setPage(response && response?.data && response?.data?.page ? response?.data?.page : 1);
        } else {
          console.log("error");
        }
      })
      .catch((err) => console.log("err", err));
  }, [page,itemperpage,params.pgno]);



  return (
    <div className="row">
    <div className="col-md-9 m-auto">
    <h3 className="dash-title">{t('web_lbl_registration_list')}</h3>
      <div className="dash-box-shadow">
      <div className="row">
          {list?.length ? (list.map((item, index) => (
              <div className="col-md-4 my-2" key={index}>
                <div className="grid-box">
                  <h3 className="text-capitalize">{item?.user_data?.name}</h3>
                  <strong>{item?.event_data?.title}</strong>
                  <div className="date-info">
                    <i className="ri-calendar-event-line"></i> 
                    {formateDate(item?.event_data?.start_date)}
                  </div>
                  <div className="date-info">
                    <i className="ri-calendar-event-line"></i> 
                    {formateDate(item?.event_data?.end_date)}
                  </div>
                  <div className="action-tag mt-4">
                  <button className="btn btn-primary mt-4" onClick={() => navigate(`/back-office/settings/registration-details/${item._id}`, { state: { item } })}>{t('web_lbl_button_view')}</button>
                      </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-md-12 my-2">
              <div className="grid-box text-center">
              {t('web_lbl_no_records')}
              </div>
            </div>
          )}
          </div>
          {datalength && datalength > 0 ? (
          <div className="mt-4 text-right pagination-main d-flex justify-content-end  pe-4">
           <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "Sharemedia", path: "/back-office/settings/registration-list" }]} />
         </div> ) : (
            ""
          )}
      </div>
    </div>
  </div>
  );
};

export default BackOfficeRegistrationList;