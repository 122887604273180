import React, { useEffect, useState } from 'react'
import {useNavigate, useParams, useSearchParams } from 'react-router-dom'
import toHtml from 'html-react-parser'
import * as topicsService from '../../services/topic.services';
import Breadcrums from '../../common/breadcrumbs';
import Loader from '../../common/loader'
import { formateDate, handleServerValidations, showStatus, isImage, getFileIcons } from '../../../../utils/commonfunction';
import Swal from 'sweetalert2';
import { SWAL_SETTINGS, COMMENT_TYPES } from '../../../../utils/Constants';
import { Button, Modal } from 'rsuite';
import CustomPagination from '../../common/custompagination';


const TopicView = (props) => {
    const navigate = useNavigate()
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const [list, setList] = useState([])
    const [loader, setLoader] = useState(true)
    const [statsupdate, setStatusUpdate] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedComment, setSelectedComment] = useState(null);
    const [datalength, setDataLength] = useState("")
    const [itemperpage] = useState(10)
    const [page, setPage] = useState(1);
    const [query] = useSearchParams();
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Activity Forums", url: "/admin/activity-forum/activities/list/1" }, { title: "View", url: `/admin/activity-forum/activities/view/${showdefault?.activity_data?._id}/?page=1` }, { title: "Topics", url: `/admin/activity-forum/activities/view/${showdefault?.activity_data?._id}?page=1` }, { title: "View", url: "" }]

 
    useEffect(() => {
        topicsService.Details(params.id).then(response => {
            setShowDefault(response && response.data ? response.data : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [params.id])


    useEffect(() => {
        setLoader(true)
        const formData = new FormData()
        formData.append("page", query.get('page'))
        formData.append("per_page", itemperpage)
        formData.append("topic_id", params.id);
        topicsService.ListComments(formData).then(data => {
            setList(data && data?.data && data?.data?.list ? data?.data?.list : []);
            setDataLength(data.data.total_records)
            setPage(data && data.data && data.data.page ? data.data.page : 1);
            setLoader(false)
        }).catch(error => {
            console.log("Error:", error);
        });
    }, [page, statsupdate,itemperpage,params.id]);


    function Deletefunction(data) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formData = new FormData();
                formData.append("o_id", data)
                topicsService.CommentDelete(formData).then(response => {
                    setStatusUpdate(!statsupdate)
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch(error => {
                    console.log("deleteError", error)
                });
            }
        })
    }


    const handleViewClick = (data) => {
        setSelectedComment(data);
        setIsModalVisible(true);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };


    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h6 className="main-content-label mb-3">View Topic</h6>
                            </div>
                            {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table  table-bordered table-hover">
                                        <tbody>
                                            <tr>
                                                <th>Title</th>
                                                <td>{showdefault && showdefault.title ? showdefault.title : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Title In German</th>
                                                <td>{showdefault && showdefault.title_de ? showdefault.title_de : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Activity</th>
                                                <td>{showdefault && showdefault.activity_data && showdefault.activity_data.title ? showdefault.activity_data.title : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Created Date</th>
                                                <td>{showdefault && showdefault.createdAt ? (formateDate(showdefault.createdAt)) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{showdefault && showdefault.status === 0 ? toHtml(showStatus(showdefault.status)) : toHtml(showStatus(showdefault.status))}</td>
                                            </tr>
                                            <tr>
                                                <th>Modified Date</th>
                                                <td>{showdefault && showdefault.updatedAt ? formateDate(showdefault.updatedAt) : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault.description ? showdefault.description : "N/A" }}></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}>
                                                    <div dangerouslySetInnerHTML={{ __html: showdefault && showdefault.description_de ? showdefault.description_de : "N/A" }}></div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> : <Loader />}
                        </div>
                        <div className="">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className='d-flex justify-content-between align-items-center mb-4'>
                                        <h6 className="main-content-label">Comments Details</h6>
                                        <div className='d-flex align-items-center'>
                                        </div>
                                    </div>
                                    <div className="table-responsive position-relative">
                                        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                            <thead>
                                                <tr>
                                                    <th>Comments</th>
                                                    <th className='action_head'>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loader ?
                                                    <tr>
                                                        <td colSpan={3}><Loader /></td>
                                                    </tr> : <>
                                                        {list.length ? list.map((item, i) => {
                                                            return <tr key={i}>
                                                                {/* {item?.comment_type === "image" || item?.comment_type === "file" ? <td>{<Link to={item?.comment_file_url} target="_blank">{item?.comment_file_name}</Link>}</td> : <td>{item?.comment ? item?.comment : "N/A"}</td>} */}
                                                                {isImage(item?.comment_file_url) ? (
                                                                    <td>
                                                                        <a target="_blank" rel="noopener noreferrer" href={item?.comment_file_url}>
                                                                            <img src={item?.comment_file_url} alt="Comment" style={{ width: "50px", height: "50px", objectFit: 'cover' }} />
                                                                        </a>
                                                                        <div>{item?.user_data?.name}</div>
                                                                    </td>
                                                                ) : (
                                                                    <td>
                                                                        <div>
                                                                            <a target="_blank" href={item?.comment_file_url} rel="noopener noreferrer">
                                                                                {getFileIcons(item?.comment_file_name)}
                                                                            </a>
                                                                        </div>
                                                                        <div>{item?.user_data?.name}</div>
                                                                        {item?.comment_type === COMMENT_TYPES.TEXT ? (
                                                                            <p>{item?.comment}</p>
                                                                        ) : null}
                                                                    </td>
                                                                )}
                                                                {/* <td className="text-capitalize">{item.comment ? item.comment : "N/A"}</td> */}
                                                                <td>
                                                                    <div className="d-flex">
                                                                        <button
                                                                            className="btn ripple btn-main-primary signbtn"
                                                                            onClick={() => handleViewClick(item)}
                                                                        >
                                                                            View
                                                                        </button>
                                                                        <button className="btn ripple btn-secondary mlAction" onClick={() => { Deletefunction(item._id) }}>Delete</button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        }) : <tr><td colSpan={3} className="text-center">No records</td></tr>}
                                                    </>}
                                            </tbody>
                                        </table>
                                        <Modal
                                            size="lg"
                                            className="invitation-modal modal-height-auto"
                                            open={isModalVisible}
                                            onClose={closeModal}
                                        >
                                            <Modal.Header>
                                                <Modal.Title>Comment</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <table id="questions-table" className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th>Comment</th>
                                                        </tr>
                                                    </thead>
                                                    {/* <tbody>
                                                        {selectedComment?.comment_type === "image" || selectedComment?.comment_type === "file" ? <td>{<Link to={selectedComment?.comment_file_url} target="_blank">{selectedComment?.comment_file_name}</Link>}</td> : <td>{selectedComment?.comment ? selectedComment?.comment : "N/A"}</td>}
                                                    </tbody> */}
                                                    <tbody>
                                                        {isImage(selectedComment?.comment_file_url) ? (
                                                            <td>
                                                                <a target="_blank" rel="noopener noreferrer" href={selectedComment?.comment_file_url}>
                                                                    <img src={selectedComment?.comment_file_url} alt="Comment" style={{ width: "50px", height: "50px", objectFit: 'cover' }} />
                                                                </a>
                                                            </td>
                                                        ) : (
                                                            <td>
                                                                <a target="_blank" href={selectedComment?.comment_file_url} rel="noopener noreferrer">
                                                                    {getFileIcons(selectedComment?.comment_file_name)}
                                                                </a>
                                                                {selectedComment?.comment_type === COMMENT_TYPES.TEXT ? (
                                                                    <p>{selectedComment?.comment}</p>
                                                                ) : null}
                                                            </td>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button onClick={closeModal} appearance="subtle">
                                                    Close
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                    <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                                    {datalength && datalength > 0 ?
                                        <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} query={"true"} pageRoute={[{ name: "Topics", path: `/admin/activity-forum/topics/view/${params.id}` }]} /> : ""}
                                </div>
                            </div>
                            <div className="mt-5 back-button">
                                <button className="btn ripple btn-dark" type='button' onClick={() => navigate(`/admin/activity-forum/activities/view/${showdefault?.activity_data?._id}?page=1`)}>
                                    <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                    Back
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TopicView
