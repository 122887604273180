import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { EventDetail, EventDetailBySlug } from "../../services/eventdetailservice";
import SpeakerImage from '../../../../assets/user/images/speker-icon.png'
import ZoomImage from '../../../../assets/user/images/zoom-icon.svg'
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-transitions.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-fullscreen.css';
import 'lightgallery/css/lg-zoom.css';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lg-autoplay.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgRotate from 'lightgallery/plugins/rotate';
import 'lightgallery/css/lg-rotate.css';
import lgFullscreen from 'lightgallery/plugins/fullscreen';
import 'lightgallery/css/lg-fullscreen.css';
import {
  useParams,
} from "react-router-dom";
import {
  formateDateWithMonth,
  globalLoader,
  TrimText,
} from "../../../../utils/commonfunction";
import * as webother from "../../../user/services/webother.service"
import Swal from "sweetalert2";
import { ANNOUNCEMENT_TYPES, SWAL_SETTINGS } from "../../../../utils/Constants";
import { useTranslation } from 'react-i18next';
import * as $ from 'jquery';

const EventDetailsPreviewAdmin = () => {
  const { slug } = useParams();
  const  [id, setEventId ] = useState(null);
  const [eventInfo, setEventInfo] = useState(null);
  const [eventDetail, setEventDetail] = useState([]);
  const [UserAnnouncements, setUserAnnouncements] = useState([]);
  const [galleryImages, setGalleryImages] = useState([]);
  const [eventNotFound, setEventNotFound] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
      globalLoader(true);
      EventDetailBySlug(slug).then((response) => {
          if (response?.success) {
            if(response?.data?._id){
              setEventInfo(response?.data);
              setEventId(response?.data?._id);
              setEventNotFound(false);
            }else{
              Swal.fire({
                icon: "error",
                text: "Event not found",
                ...SWAL_SETTINGS,
              });
              setEventNotFound(true);
            }
          }else{
            Swal.fire({
              icon: "error",
              text: response.message,
              ...SWAL_SETTINGS,
            });
          }
          globalLoader(false);
        }).catch((err) => {
          Swal.fire({
            icon: "error",
            text: err.message,
            ...SWAL_SETTINGS,
          });
        });
  }, [id]);

  useEffect(() => {
    if(id){
      EventDetail(id)
        .then((response) => {
          if (response?.success) {
            setEventDetail(response?.data);
            setGalleryImages(response?.data?.gallery_images);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [eventInfo, id]);
  useEffect(() => {
    if(id){
      const formData = {
        type:ANNOUNCEMENT_TYPES.EVENT,
        event_id:id,
      };
      webother.UserAnnouncements(formData).then((response) => {
          setUserAnnouncements(response && response.data && response.data.list ? response.data.list : []);
      }).catch((err) => {
          console.log(err)
      });
    }
  }, [id]);

  /** Disable all other links for admin */
  useEffect(() => {
   $(document).ready(function(){
    $('.topHeader .nav-item, .footer-link a, .social-link a, .disable-for-admin').addClass('disable-click').click(function(){
      actionNotAuthorized();
      return false;
    });
   });
  }, [id]);
  function actionNotAuthorized(){
    Swal.fire({
      icon: "error",
      text: "Action not authorized.",
      ...SWAL_SETTINGS,
    });
  }

  return (
    <div className="wraper-inner cpt bg-grey">
      <section className="inner-space">
        <div className="container">
          {!eventNotFound?
          <>
            {eventDetail?.is_already_registered === false ? (
              <div className="dash-box-shadow d-flex justify-content-between mb-4 joint-event">
                <h3 className="inner-sub-title">{t('web_event_details_join_event')}</h3>
                {eventDetail?.is_rejected ? (
                  <div className="btn-group-box">
                    <button
                      className="btn btn-primary min-w"
                      onClick={actionNotAuthorized}
                    >
                    {t('web_event_details_register')}
                    </button>
                  </div>
                ) : (
                  <div className="btn-group-box">
                    <button
                      className="btn btn-primary min-w"
                      onClick={actionNotAuthorized}
                    >
                  {t('web_event_details_accept')}
                    </button>
                    <button
                      className="btn btn-secondary ms-2"
                      onClick={actionNotAuthorized}
                    >
                    {t('web_event_details_decline')}
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
            <div className="vanue-details-main event-detail">
              <figure>
                <img src={eventDetail?.image} alt="Event" />
              </figure>
              <div className="dash-box-shadow vanue-details">
                <div className="vanue-details-content">
                  <h3>{eventDetail?.title}</h3>
                  {eventDetail?.event_quota?.price !== 0 ? (
                    <h4 className="mb-2">{t('web_lbl_price')}: <span>{eventDetail?.currency?.sign}{eventDetail?.event_quota?.price}</span></h4>
                  ) : (
                    <h4 className="mb-2 text-success">{t('web_lbl_span')}</h4>
                  )}
                  <ul className="card-info-list">
                    <li>
                      <div className="date-info">
                        <i className="ri-calendar-event-line" />
                        {formateDateWithMonth(eventDetail?.start_date) +
                          " - " +
                          formateDateWithMonth(eventDetail?.end_date)}
                      </div>
                    </li>
                  </ul>
                  <div className="description-box">
                    <h4 className="title-stand"> {t('web_event_details_description')}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `${
                          eventDetail?.description
                            ? eventDetail?.description
                            : "N/A"
                        }`,
                      }}
                    ></div>
                  </div>
                  {galleryImages && galleryImages.length > 0 ?<>

                  <hr className="divider"/>
                <div className="media-box h-auto">
                    <h4 className="title-stand">{t('web_event_details_media')}</h4>
                    <LightGallery
                      elementClassNames="row media-rows"
                      speed={500}
                      plugins={[lgThumbnail, lgZoom, lgFullscreen, lgRotate]}
                    >
                      {galleryImages.map((image, i) => {
                        return <div className="col-md-3" key={i} href={image}>
                          <div className="img-box cp">
                            <img className="img-box-inner" src={image} alt="Gallery" />
                            <div className="img-box-content">
                              <figure><img src={ZoomImage} alt="Zoom" /></figure>
                              <h4>{t('web_event_details_view_photo')}</h4>
                            </div>
                          </div>
                        </div>
                      })}
                    </LightGallery>
                </div>
                </>
                :null}
                
                  {UserAnnouncements && UserAnnouncements.length > 0 ?<>
                  <hr className="divider" />
                  <div  className="announcements-box">
                    <h4 className="title-stand">{t('web_header_heading_announcements')} </h4>
                    <ul className="announcements-box-list">
                                  {UserAnnouncements.map((data, i) => {
                                      return <li key={i}>
                                          <figure><img src={SpeakerImage} alt="Announcement" /></figure>
                                          <figcaption>
                                              <h4>{data?.title}</h4>
                                              <p dangerouslySetInnerHTML={{ __html: `${TrimText(data?.description, 100)}` }} />
                                          </figcaption>
                                      </li>
                                  })}
                              </ul>
                </div>
                  </>
                  :
                  null
                  }
                </div>
              </div>
            </div>
            {eventDetail?.is_already_registered === false ? (
              <div className="dash-box-shadow d-flex justify-content-between mt-4 joint-event">
                <h3 className="inner-sub-title">{t('web_event_details_join_event')}</h3>
                {eventDetail?.is_rejected ? (
                  <div className="btn-group-box">
                    <button
                      className="btn btn-primary min-w"
                      onClick={actionNotAuthorized}
                    >
                    {t('web_event_details_register')}
                    </button>
                  </div>
                ) : (
                  <div className="btn-group-box">
                    <button
                      className="btn btn-primary min-w"
                      onClick={actionNotAuthorized}
                    >
                    {t('web_event_details_accept')}
                    </button>
                    <button
                      className="btn btn-secondary ms-2"
                      onClick={actionNotAuthorized}
                    >
                    {t('web_event_details_decline')}
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}
          </>
          :
          <>
            <div className="w-100 d-flex justify-content-center align-items-center flex-column" style={{minHeight:'70vh'}}>
                <h1>{t('web_lbl_slug')} "{slug}"</h1>
                <p className="mt-2">{t('web_lbl_slug_try_again')}.</p>
            </div>
          </>}
        </div>
      </section>
    </div>
  );
};

export default EventDetailsPreviewAdmin;
