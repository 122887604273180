import axiosInstance from "../../../utils/axios";
const path = "admin/user"

export const updateprofile = async (values) => {
    return await axiosInstance().post(`${path}/edit-profile`, values)
}

export const changepassword = async (values) => {
    return await axiosInstance().post(`${path}/change-password`, values);
}

