import React, { useEffect, useState } from 'react'
import {  useNavigate} from 'react-router-dom'
import Breadcrums from "../../../common/breadcrumbs";
import CodeMirror from "@uiw/react-codemirror";
import * as styleFilesService from '../../../services/stylefiles.services';
import Loader from '../../../common/loader';
import { less } from '@codemirror/lang-less';
import { copilot } from '@uiw/codemirror-themes-all';
import { StreamLanguage } from '@codemirror/language';
import { css } from '@codemirror/legacy-modes/mode/css';
import Swal from 'sweetalert2';
import { SWAL_SETTINGS } from '../../../../../utils/Constants';
import { handleServerValidations } from '../../../../../utils/commonfunction';
import { Tooltip, Whisper } from 'rsuite';

const StyleCssFile = () => {
  const navigate = useNavigate();
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Root Style", url: "" },
  ];
  const [dataLoaded, setDataLoaded] = useState(false);
  const [fileData, setFileData] = useState('');
  const [updatedFileData, setUpdatedFileData] = useState('');
  const [refreshData, setRefreshData] = useState(false);
  useEffect(() => {
    styleFilesService.Details('style.css').then((response) => {
      setFileData(response && response.data ? response.data : '');
      setUpdatedFileData(response && response.data ? response.data : '');
      setTimeout(() => {
        setDataLoaded(true);
      }, 100)
    }).catch((error) => {
      console.log("error=====>", error);
      setDataLoaded(true);
    });
  }, [refreshData]);

  const handleSubmit = () => {
    styleFilesService.Edit({ file_name:'style.css', data: updatedFileData }).then(response => {
      if (response.success) {
        Swal.fire({
          icon: 'success',
          text: response.message,
          ...SWAL_SETTINGS
        });
      } else {
        Swal.fire({
          icon: 'error',
          text: handleServerValidations(response),
          ...SWAL_SETTINGS
        })
      }
    }).catch(error => {
      console.log("error ====> ", error);
    })
  };

  const handleResetLatest = () => {
    Swal.fire({
			customClass: 'swal-wide',
			title: 'Are you sure?',
			text: "You will lose your latest changes and this action will restore the last stable version of style. This action can't be undone.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#403fad',
			cancelButtonColor: '#f1388b',
			confirmButtonText: 'Yes! Reset Changes'
		}).then((result) => {
			if (result.isConfirmed) {
        styleFilesService.ResetLatest({ file_name:'style.css' }).then(response => {
          if (response.success) {
            Swal.fire({
              icon: 'success',
              text: response.message,
              ...SWAL_SETTINGS
            });
            setRefreshData(!refreshData);
          } else {
            Swal.fire({
              icon: 'error',
              text: handleServerValidations(response),
              ...SWAL_SETTINGS
            })
          }
        }).catch(error => {
          console.log("error ====> ", error);
        })
			}
		})
  };

  const handleReset = () => {
    Swal.fire({
			customClass: 'swal-wide',
			title: 'Are you sure?',
			text: "You will lose all your changes that you have done yet. This action will restore the initial style of the project. This action can't be undone.",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#403fad',
			cancelButtonColor: '#f1388b',
			confirmButtonText: 'Yes! Reset All'
		}).then((result) => {
			if (result.isConfirmed) {
        styleFilesService.Reset({ file_name:'style.css' }).then(response => {
          if (response.success) {
            Swal.fire({
              icon: 'success',
              text: response.message,
              ...SWAL_SETTINGS
            });
            setRefreshData(!refreshData);
          } else {
            Swal.fire({
              icon: 'error',
              text: handleServerValidations(response),
              ...SWAL_SETTINGS
            })
          }
        }).catch(error => {
          console.log("error ====> ", error);
        })
			}
		})
  };

  return (
    <>
     <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-4">
                <h6 className="main-content-label">Root Style</h6>
                <div>
                  <Whisper delayOpen={1000} placement="top" controlId="handle-reset-latest" trigger="hover" speaker={<Tooltip>This action will restore the last stable version of style.</Tooltip>}>
                    <button className="btn btn-success me-2" type='button' onClick={handleResetLatest}>
                      Restore Last Saved version
                    </button>
                  </Whisper>
                  <Whisper delayOpen={1000} placement="left" controlId="handle-reset" trigger="hover" speaker={<Tooltip>This action will restore the initial style of the project.</Tooltip>}>
                    <button className="btn btn-warning" type='button' onClick={handleReset}>
                      Reset Default
                    </button>
                  </Whisper>
                </div>
              </div>
              <div className="w-100">
                {
                  dataLoaded?
                    <>
                        <CodeMirror
                          value={fileData}
                          height="800px"
                          theme={copilot}
                          extensions={[StreamLanguage.define(css), less()]}
                          onChange={(e) => { setUpdatedFileData(e); }}
                        />
                        <div className="">
                          <button className="btn btn-main-primary signbtn mr-2 mt-4" type="button" onClick={handleSubmit}>
                            <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                            Submit
                          </button>
                          <button className="btn ripple btn-secondary mt-4" type='button' onClick={() => navigate(-1)}>
                            <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                            Cancel
                          </button>
                        </div>
                    </>
                    :<>
                    <Loader/>
                    </>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StyleCssFile