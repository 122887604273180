import React, { useEffect, useRef, useState } from 'react'
import $ from 'jquery'
import Swal from 'sweetalert2'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { getLocalKey, getUrlPrefix, getUser, handleServerValidations, isUser, logOutUser, setLocalKey } from '../../../utils/commonfunction';
import { useDispatch, useSelector } from 'react-redux';
import { CMS_PAGES_POSITIONS, SWAL_SETTINGS } from '../../../utils/Constants';
import * as commonService from "../../user/services/common.services";
import { useTranslation } from 'react-i18next';
import { SOCKET_CLIENT } from '../../../utils/socket';
import SisUserSettingMenu from './settingMenu';
import { EventOptions } from '../../user/services/lead.services';
import { eventData } from '../../../redux/slices/EventDetailSlice';
import { eventList } from '../services/common.services';
import { UserDetails } from '../../user/services/webuser.service';

const SISUserHeader = () => {
    const loc = useLocation();
    let dispatch = useDispatch();
    const notifTrigger = useRef();
    const [getuserkey, setGetUserkey] = useState();
    let { i18n, t } = useTranslation();
    const navigate = useNavigate();
    const [menuData, setMenuData] = useState([]);
    const [activityForumData, setActivityForumData] = useState(null);
    const [surveysData, setSurveysData] = useState(null);
    const leadData = useSelector((state) => state.designportal);
    const [notifications, setNotifications] = useState([]);
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
    const [refreshNotifications, setRefreshNotifications] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [showMobileNav, setShowMobileNav] = useState(false);
    const [assignedEvents, setAssignedEvents] = useState([]);

    let reducerData = useSelector(state => state.manageLmBackOffice);
    const [getGlobalData, setGlobalData] = useState({});
    const myGlobalData = useSelector((state) => state.globalData);
 
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        setGlobalData(JSON.parse(myGlobalData?.data));
      }
    },[myGlobalData]);

    useEffect(() => {
        /** Listening for notifications */
        SOCKET_CLIENT.on('notificationReceive', (data) => {
            console.log("notificationReceive", data);
            Swal.fire({
                icon: "info",
                text: data.title || data.message,
                ...SWAL_SETTINGS,
                timer: 4000
            });
            setNotifications([data, ...notifications]);
            setUnreadNotificationsCount(unreadNotificationsCount + 1);
        });
    }, [notifications]);

    useEffect(() => {
        if (window.innerWidth > 500) {
            setShowMobileNav(false);
        } else {
            setShowMobileNav(true);
        }
    }, [])

    useEffect(() => {
        /** Getting unread notifications */
        let userData = getUser();
        if (userData && userData.token) {
            let data = {
                per_page: 5
            };
            commonService.NotificationsList(data).then((response) => {
                if (response.success) {
                    setNotifications(response?.data?.list || []);
                    setUnreadNotificationsCount(response?.data?.unread_records || 0);
                } else {
                    Swal.fire({
                        icon: "error",
                        text: handleServerValidations(response),
                        ...SWAL_SETTINGS
                    });
                }
            }).catch((err) => {
                console.log("****Error while getting notifications****", err);
            });
        }

    }, [refreshNotifications, loc]);

    useEffect(() => {
        $(document).ready(function () {
            $('.showpop').click(function () {
                $('.main-profile-menu').addClass("show");
            })
            $('.fixed_bg').click(function () {
                $('.main-profile-menu').removeClass("show");
            })
        })
    }, [loc])

    useEffect(() => {
        const userLocalData = getUser();
        if(userLocalData && userLocalData.token){
            UserDetails().then(response => {
                if(response.success){
                    setGetUserkey(response.data || {});
                }
            }).catch(error => {
                console.log("Error:", error);
            });
        }
    }, []);

    useEffect(() => {
        commonService.menuOptions({ position: CMS_PAGES_POSITIONS.BOOTH_ATTENDANTS }).then((response) => {
            setMenuData(response?.data)
        }).catch((error) => {
            console.log("error", error);
        })
    }, [loc])

    useEffect(() => {
        commonService.globalSetting()
            .then((response) => {
                setActivityForumData(response?.data.activity_forum);
                setSurveysData(response?.data.surveys);
            })
            .catch((error) => {
                console.log("error", error);
            });
    }, []);
    useEffect(() => {
        var notificationDropdown = document.getElementById('notificationDropdown');
        if (notificationDropdown) {
            notificationDropdown.addEventListener('hide.bs.dropdown', function (e) {
                let unreadNotif = notifications.filter((item) => {
                    return item.is_read == false;
                });
                if (unreadNotif.length) {
                    let ids = unreadNotif.map(item => item._id);
                    commonService.NotificationsMarkAsRead({ ids: JSON.stringify(ids) }).then(() => {
                        setRefreshNotifications(!refreshNotifications);
                    })
                }
            });
            window.addEventListener("refreshNotifications", function () {
                setRefreshNotifications(!refreshNotifications);
            })
        }
    }, [notifications]);

    useEffect(() => {
        eventList().then((response) => {
            if (response && response?.success) {
                setAssignedEvents(response && response.data ? response?.data : []);
                if(!getLocalKey("selectedEventId")){
                    dispatch(eventData(response?.data?.length > 0 ? response?.data[0]._id : ''));
                    setLocalKey("selectedEventId", response?.data[0]._id);
                  }else{
                    dispatch(eventData(getLocalKey("selectedEventId")));
                  }
            } else {
                console.log("error");
            }
        }).catch((err) => {
            console.log(err);
        });
    }, []);

    const logout = () => {
        Swal.fire({
            customClass: 'swal-wide',
            title:  t('web_swal_are_you_sure'),
            text: t('lbl_logout_text'),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: getGlobalData?.portal_design?.background_colors?.primary_active_color,
            cancelButtonColor: getGlobalData?.portal_design?.colors?.danger,
            confirmButtonText: t('web_swal_yes'),
            cancelButtonText: t('web_swal_no')
        }).then((result) => {
            if (result.isConfirmed) {
                window.ReactNativeWebView?.postMessage("LoggedOut");
                logOutUser(navigate);
            }
        })
    }
    function goToNotification(notification) {
        if (notification.redirection == true) {
            navigate(getUrlPrefix() + notification.redirect_to);
        } else {
            navigate(getUrlPrefix() + '/notifications');
        }
        notifTrigger.current.click();
    }

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng); /* Change the language using i18next */
        setLocalKey("system_language", lng);
        window.location.reload();
    };

    const handleMenuButton = () => {
        document.getElementById('toggle_button').click()
    }


    function handleEventChange(e) {
        if (e.target.value) {
            dispatch(eventData(e.target.value));
            setLocalKey("selectedEventId", e.target.value);
        }
    }


    return (
        <>
            {
                showMobileNav ?
                    <SisUserSettingMenu showMenu={toggle} onClose={setToggle} />
                    : null
            }
            <header className="topHeader " id="fixed-header">
                <div className="container-fluid">
                    <nav className="navbar navbar-expand-lg " id="navbar-scrollspy">
                        <div className="navbar-inner-box d-flex align-items-center justify-content-between">
                            <div className="nav-L d-flex">
                                <Link className="navbar-brand desktop-view-logo" to={"/sis-user/home"}>
                                    <img style={{ height: "51px" }} src={getGlobalData?.portal_design?.logo_transparent} alt="logo" />
                                </Link>
                                <button
                                    className="navbar-toggler"
                                    id='toggle_button'
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent"
                                    aria-controls="navbarSupportedContent"
                                    aria-expanded="false"
                                    aria-label="Toggle navigation"
                                >
                                    <span className="navbar-toggler-icon">
                                        <i className="ri-menu-3-line" />
                                        <i style={{ display: "none" }} className="ri-close-line"></i>
                                    </span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                        <li className="nav-item" >
                                            <Link onClick={() => handleMenuButton()} className={`nav-link` + (loc.pathname == "/sis-user/home" ? " active" : "")} to={"/sis-user/home"}>{t('web_header_heading_home')}</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link onClick={() => handleMenuButton()} className={`nav-link` + (loc.pathname.includes("/sis-user/announcements/list") ? " active" : "")} to={"/sis-user/announcements/list/1"}> {t('web_header_heading_announcements')} </Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link onClick={() => handleMenuButton()} className={`nav-link` + (loc.pathname.includes("/sis-user/rooms/list") ? " active" : "")} to={"/sis-user/rooms/list/1"}> {t('web_sis_user_rooms')} </Link>
                                        </li>
                                        {/* <li className="nav-item">
                                                <Link onClick={() => handleMenuButton()} className={`nav-link` + (loc.pathname.includes("/open-registration/events/list") ? " active" : "")} to={"/open-registration/events/list/1"}> Events </Link>
                                        </li> */}
                                        {menuData && menuData.map((item, index) => {
                                            return (
                                                <li key={index} className="nav-item">
                                                    <Link
                                                        onClick={() => handleMenuButton()}
                                                        className={`nav-link` + (loc.pathname == `/sis-user/org-info/${item?.slug}` ? " active" : "")}
                                                        aria-current="page"
                                                        to={`/sis-user/org-info/${item?.slug}`}
                                                    >
                                                        {" "}{item?.name}
                                                    </Link>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                    <div className="d-flex p-2">
                                        <select className="form-select" aria-label="Select Event" onChange={handleEventChange} value={reducerData ? reducerData : ""}>
                                            <option value="">{t('web_lbl_select_event')}</option>
                                            {
                                                assignedEvents.map((event, i) => {
                                                    return <option key={i} value={event?._id}>{event?.title}</option>;
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>


                            </div>
                            {isUser(3) &&
                                (
                                    <div className="nav-R">
                                        <div className="d-flex align-items-center">
                                            <div className="btn-group mobile-btn-group lang-box">
                                                <a className="nav-icon-button" data-bs-toggle="dropdown" id="dropdownLangButton" aria-expanded="false" href='/' onClick={(e) => e.preventDefault()}>
                                                    <i className="ri-global-fill"></i>
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownLangButton">
                                                    <li>
                                                        <button onClick={() => changeLanguage("en")} className="dropdown-item">English</button>
                                                    </li>
                                                    <li>
                                                        <button onClick={() => changeLanguage("de")} className="dropdown-item">Deutsch</button>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="btn-group">
                                                <a className={'nav-icon-button me-3' + (loc.pathname === "sis-user/notifications" ? " active" : "")} id='notificationDropdown' ref={notifTrigger} data-bs-toggle="dropdown" aria-expanded="false" href='/' onClick={(e) => e.preventDefault()}>
                                                    {unreadNotificationsCount ? <span className="notification-badge">{unreadNotificationsCount}</span> : null}
                                                    <i className="ri-notification-3-fill"></i>
                                                </a>
                                                <ul className="dropdown-menu announcements-box-list py-2 notification-list height-fixed" style={{ minWidth: '350px', left: '-17vw' }} >
                                                    {notifications.length ? <>
                                                        {
                                                            notifications.map((notification, i) => {
                                                                return (
                                                                    <li className={'cp' + (i != notifications.length - 1 ? ' border-bottom' : '') + (notification.is_read ? '' : ' unread')} key={i} onClick={(e) => { goToNotification(notification) }}>
                                                                        <figure>
                                                                            <i className="ri-notification-3-fill bell" ></i>
                                                                        </figure>
                                                                        <figcaption className='border-0 p-0'>
                                                                            <span className='notif-title'>{notification?.title}</span>
                                                                            <span className='notif-message'>{notification?.message}</span>
                                                                        </figcaption>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                        <li className='no-hover p-0'>
                                                            <button className='d-block w-100 text-center btn' onClick={(e) => { navigate('/sis-user/notifications/1'); notifTrigger.current.click(); }}>{t('web_lbl_view_all')}</button>
                                                        </li>
                                                    </> :
                                                        <li className='no-hover'>
                                                            {t('web_lbl_no_notification')}
                                                        </li>}
                                                </ul>
                                            </div>
                                            <div className="btn-group mobile-btn-group">
                                                <a className="nav-icon-button" href='/' onClick={(e) => { e.preventDefault(); setToggle(!toggle) }}>
                                                    <i className="ri-user-fill"></i>
                                                </a>
                                            </div>
                                            <div className="dropdown profile-list-drop">
                                                <button
                                                    className="dropdown-toggle text-capitalize"
                                                    type="button"
                                                    data-bs-toggle="dropdown"
                                                    aria-expanded="false"
                                                >
                                                    <span className='user-name' id='user-name'>{getuserkey?.name} </span><i className="ri-arrow-down-s-fill" />
                                                    <figure>
                                                        <img src={getuserkey?.profile_image} id='user-profile' className='web-user-profile rounded-circle' alt='web-user-profile' />
                                                    </figure>
                                                </button>
                                                <ul className="dropdown-menu" style={{ minWidth: "7rem" }}>
                                                    <li
                                                    >
                                                        <Link className="dropdown-item" to={"/sis-user/settings/profile"}>
                                                            {t('web_header_user_profile')}
                                                        </Link>
                                                    </li>
                                                    <li
                                                    >
                                                        <a className="dropdown-item cp" href='/' onClick={(e) => { e.preventDefault(); logout() }}>
                                                            {t('web_header_user_logout')}

                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                )}
                        </div>
                    </nav>
                </div>
            </header>
        </>
    )
}

export default SISUserHeader