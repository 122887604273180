import axiosInstance from "../../../utils/axios";

const path = "web/other/survey"

export const List = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const GetQuestions = async (values) => {
    return await axiosInstance().post(`${path}/getquestions`, values)
}

export const SaveSurveyQuestions = async (values) => {
    return await axiosInstance().post(`${path}/getquestions/save`, values)
}
// export const Addcomments = async (values) => {
//     return await axiosInstance().post(`${path}/comment`, values)
// }

// export const Add = async (values) => {
//     return await axiosInstance().post(`${path}/add`, values);
// }

// export const Edit = async (values) => {
//     return await axiosInstance().post(`${path}/edit`, values);
// }

// export const Delete = async (values) => {
//     return await axiosInstance().post(`${path}/delete`, values)
// }

// export const Details = async (id) => {
//     return await axiosInstance().get(`${path}/details?o_id=${id}`);
// }

// export const Status = async (values) => {
//     return await axiosInstance().post(`${path}/change-status`, values)
// }

export const Options = async (values) => {
    return await axiosInstance().post(`${path}/options`, values)
}