import React, { useState } from 'react'
import { Formik } from 'formik'
import { useLocation, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../../../src/assets/admin/img/brand/icon.png'
import * as authService from '../services/auth.services';
import { 
	SWAL_SETTINGS, 
	ATLEAST_ONE_CAPITAL_REGEX, 
	ATLEAST_ONE_NUMBER_REGEX, 
	ATLEAST_ONE_SPECIAL_CHARACTER_REGEX,
	PASSWORD_MIN_LENGTH,
	PASSWORD_MAX_LENGTH,
	ATLEAST_ONE_SMALL_REGEX
  } from '../../../utils/Constants';
import Swal from 'sweetalert2';
import { handleServerValidations } from '../../../utils/commonfunction';
import * as Yup from "yup";

const ResetPwdPage = () => {
	const navigate = useNavigate()
	const location = useLocation()
	const [newshow, setNewShow] = useState('false')
	const [conformshow, setConformShow] = useState('false')

	const validationSchema = Yup.object().shape({
		newPassword: Yup.string()
    .required("Password is required")
	.matches(ATLEAST_ONE_SMALL_REGEX, "Password should have at least 1 lowercase letter")
    .matches(ATLEAST_ONE_CAPITAL_REGEX, "Password should have at least 1 capital letter")
    .matches(ATLEAST_ONE_NUMBER_REGEX, "Password should have at least 1 number")
    .matches(ATLEAST_ONE_SPECIAL_CHARACTER_REGEX, "Password should have at least 1 special character")
    .min(PASSWORD_MIN_LENGTH, "Password should be at least 8 characters long")
    .max(PASSWORD_MAX_LENGTH, "Password cannot be more than 20 characters long"),
		confirmPassword: Yup.string()
		.required("Confirm password is required")
		.oneOf([Yup.ref("newPassword"), null], "Passwords don't match"),
	})

	const handleshow = (event) => {
		if (event === "newpwd") {
			setNewShow(!newshow)
		}
		if (event === "confirepwd") {
			setConformShow(!conformshow)
		}
	}

	return (
		<>
			<Formik
				initialValues={{
					newPassword: '',
					confirmPassword: ''
				}}
				// validate={values => {
				// 	const errors = {};
				// 	if (!values.newpassword) {
				// 		errors.newpassword = 'Password is required';
				// 	}
				// 	if (!values.confirmpassword) {
				// 		errors.confirmpassword = 'Confirm password is required';
				// 	}
				// 	if (values.newpassword != values.confirmpassword) {
				// 		errors.confirmpassword = "Password does not match"
				// 	}
				// 	return errors;
				// }}
				validationSchema={validationSchema}
				onSubmit={(values, { setSubmitting, resetForm }) => {
					let formData = new FormData();
					formData.append("new_password", values.newPassword);
					formData.append("c_password", values.confirmPassword)
					formData.append("validate_string", location.state)
					authService.resetPassword(formData).then(response => {
						if (response.success) {
							Swal.fire({
								icon: 'success',
								text: response.message,
								...SWAL_SETTINGS
							})
							setTimeout(() => {
								resetForm()
								navigate("/admin/login");
							}, 2000);
						} else {
							Swal.fire({
								icon: 'error',
								text: handleServerValidations(response),
								...SWAL_SETTINGS
							})
						}
					}).catch(error => {
						console.log("error: ", error);
					})
				}}
			>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					// and other goodies
				}) => (

					<div className='page main-signin-wrapper'>
						<div className='innerbody'>
							<div className="row signpages text-center">
								<div className="col-md-12">
									<div className="card">
										<div className="row row-sm">
											<div className="col-lg-6 col-xl-5 d-none d-lg-block text-center bg-primary details">
												<div className="mt-5 pt-4 p-2 pos-absolute">
													<img src={Logo} className="ht-120 mb-4" alt="logo" />
													<div className="clearfix"></div>
													<h5 className="mt-4 text-white">Reset Your Password</h5>
													<span className="tx-white-6 tx-13 mb-5 mt-xl-0">Signup to create, discover and connect with the global community</span>
												</div>
											</div>
											<div className="col-lg-6 col-xl-7 col-xs-12 col-sm-12 login_form ">
												<div className="container-fluid">
													<div className="row row-sm">
														<div className="card-body mt-2 mb-2">
															<img src="../assets/img/brand/logo.png" className=" d-lg-none header-brand-img text-left float-left mb-4" alt="logo" />
															<div className="clearfix"></div>
															<h5 className="text-left mb-2">Reset Your Password</h5>
															<p className="mb-4 text-muted tx-13 ml-0 text-left">It's free to signup and only takes a minute.</p>
															<form onSubmit={handleSubmit}>
																<div className="form-group text-left">
																	<label>New Password</label>
																	<div className="input-group">
																		<input className={"form-control" + (errors.newpassword && touched.newpassword ? " is-invalid state-invalid" : "")}
																			placeholder="Enter your new password"
																			type={!newshow === true ? "text" : "password"}
																			name="newPassword"
																			onChange={handleChange}
																			onBlur={handleBlur}
																			value={values.newPassword}
																			autoComplete="off" />
																		<span className='input-group-text' onClick={() => { handleshow("newpwd") }}>{(!newshow === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span>
																	</div>
																	<span className='text-danger text-left d-blockerr-spn'>{errors.newPassword && touched.newPassword && errors.newPassword}</span>
																</div>
																<div className="form-group text-left">
																	<label>Confirm Password</label>
																	<div className="input-group">
																		<input className={"form-control" + (errors.confirmPassword && touched.confirmPassword ? " is-invalid state-invalid" : "")}
																			placeholder="Confirm password"
																			type={!conformshow === true ? "text" : "password"}
																			name="confirmPassword"
																			onChange={handleChange}
																			onBlur={handleBlur}
																			value={values.confirmPassword}
																			autoComplete="off" />
																		<span className='input-group-text' onClick={() => { handleshow("confirepwd") }}>{(!conformshow === true ? <i className="far fa-eye"></i> : <i className="far fa-eye-slash"></i>)}</span>
																	</div>
																	<span className='text-danger text-left d-blockerr-spn'>{errors.confirmPassword && touched.confirmPassword && errors.confirmPassword}</span>
																</div>
																<button className="btn ripple btn-main-primary btn-block signbtn" type='submit'>Reset Password</button>
															</form>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</Formik>
		</>
	)
}

export default ResetPwdPage