import React, { useState, useEffect } from 'react';
import * as commonServices from '../services/common.services';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { Link,useLocation,useNavigate,useParams } from "react-router-dom";
import {createMeta, getHomeUrl, getLocalKey, setTitle} from '../../../utils/commonfunction';
import { useTranslation } from 'react-i18next';
import { DYNAMIC_FORM_PREFIX, DYNAMIC_FORM_PREFIX_REGEX, DYNAMIC_FORM_SUFFIX, STAFF_ROLE } from '../../../utils/Constants';


const DynamicPages = () => {

  const params = useParams();
  const loc = useLocation();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState({});
  const { t } = useTranslation();
  const [HTML, setHTML] = useState('');
  const [getuserkey, setGetUserkey] = useState();
  const navigate = useNavigate();

  function attachDynamicForms(response) {
    let description = response?.data?.description;
    const regex = DYNAMIC_FORM_PREFIX_REGEX;
    const matches = description.match(regex);
    if (matches && matches.length) {
      matches.forEach((form, index) => {
        const formId = form.replace(DYNAMIC_FORM_PREFIX, "").replace(DYNAMIC_FORM_SUFFIX, "");
        description = description.replace(form, `<iframe maxHeight="no" src='/dynamic-form-wrapper/${formId}/${index}' id="${formId + index}" 
        onload="window.frameLoaded('${formId + index}')"
        style="width: 100%; height:100px;"></iframe>`);
      });
    }
    return description;
  }


    useEffect(() => {
      try {
          const getuserkey = JSON.parse(getLocalKey("user"));
          setGetUserkey(getuserkey);
      } catch (err) {
          setGetUserkey(null)
      }
  }, [loc])

    useEffect(() => {
      const userRole= getuserkey?.role
      setLoading(true);
      commonServices.cmsDetails(params?.slug).then((response) => {
        let data1 = Object.keys(STAFF_ROLE).find(key => STAFF_ROLE[key] == userRole);
        data1= data1 ? data1.toLowerCase() : null
        if(data1 && response?.data && response?.data?.position == data1 ){
          setData(response?.data);
          setTitle(response?.data?.title);
          createMeta(response?.data);
          let description = response?.data?.description;
          /** Attaching dynamic elements to the page >>>> */
          description = attachDynamicForms(response);
          setHTML(description);
          setLoading(false);
        }
        else{
          const route = getHomeUrl(userRole);
          navigate(route);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("error of terms&conditions",error);
      })
    },[loc,params?.slug,getuserkey])

  return (
    <div className="wraper-inner cpt">
      <section className="inner-banner">
        <div className="container">
          <div className="row ">
            <div className="banner-inner-card">
              <h1>{data?.title}</h1>
              <nav className="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                  <Link to={getHomeUrl(getuserkey?.role)}>{t('web_header_heading_home')}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">{data?.name}</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      {loading ? (
        <section className="content-box-main section-padding">
          <div className="container" style={{ minHeight: '48vh' }}>
            <Skeleton count={10} style={{ height: '50px' }} />
          </div>
        </section>
      ) : (
        <div style={{ minHeight: '70vh' }} dangerouslySetInnerHTML={{ __html: `${HTML || "N/A"}` }}></div>
      )}
    </div>
  )
}

export default DynamicPages;