import React, { useState, useEffect } from "react";
import Messages from '../Messages/Messages';
import InfoBar from '../InfoBar/InfoBar';
import Input from '../Input/Input';
import "./Chat.css";
import { getUser } from "../../../../../utils/commonfunction";
import { useLocation, useSearchParams } from "react-router-dom";
import { GetMessages, ReceiverInfo } from "../../../services/common.services";
import { DEFAULT_MESSAGE_LIMIT, MESSAGE_TYPES, VIDEO_UPLOAD } from "../../../../../utils/Constants";
import { SOCKET_CLIENT } from "../../../../../utils/socket";
import { useTranslation } from 'react-i18next';
import * as $ from 'jquery';

const Chat = () => {
	const location = useLocation();
	const [room, setRoom] = useState("");
	const [message, setMessage] = useState('');
	const [messageData, setMessageData] = useState('');
	const [messages, setMessages] = useState([]);
	const [dataLoaded, setDataLoaded] = useState(false);
	const [edit, setEdit] = useState(false);
	const [receiverData, setReceiverData] = useState({});
	const [senderData, setSenderData] = useState({});
	const [lastLimit, setLastLimit] = useState(DEFAULT_MESSAGE_LIMIT);
	const [newLimit, setNewLimit] = useState(DEFAULT_MESSAGE_LIMIT);
	const [hasMore, setHasMore] = useState(false);
	const [scrollProperties, setScrollProperties] = useState({scrollTo:'bottom',smoothly:false});
	const [searchParams] = useSearchParams();
	const [validUrl, setValidUrl] = useState(null);
    const { t } = useTranslation();

	function fetchMessages(){
		GetMessages(searchParams.get("room"),newLimit).then((response)=>{
			setMessages(response && response.data && response.data.list ? response.data.list : []);
			setLastLimit(response && response.data && response.data.limit ? response.data.limit : DEFAULT_MESSAGE_LIMIT);
			setHasMore(response && response.data && response.data.hasMore ? response.data.hasMore : false);
		}).catch((err)=>{
			console.log(err);
		});
	}
	useEffect(() => {
		/**Listening for new messages */
		if(searchParams.get("to") === senderData._id){
			setValidUrl(false);
		}else{
			setValidUrl(true);
		}
	}, [location,senderData]);
	
	useEffect(() => {
		/** getting old messages */
		fetchMessages();
	}, [newLimit]);
	useEffect(() => {
		/** getting receiver's info */
		ReceiverInfo(searchParams.get("to")).then((response)=>{
			setReceiverData(response && response.data ? response.data : {});
			/** getting old messages */
			fetchMessages();
			setDataLoaded(true);
		}).catch((err)=>{
			console.log(err);
		});
	}, []);
	useEffect(() => {
		if(dataLoaded){
			const { _id } = getUser() || {};
			setSenderData(getUser() || {});
			setRoom(searchParams.get("room"));
			let joinRequest = {
				sender_id: _id,
				receiver_id:searchParams.get("to"),
				room:searchParams.get("room")
			};
			SOCKET_CLIENT.emit('chatJoin', joinRequest, (error) => {
				if (error) {
					alert(error);
				}
			})
			return () => {
				SOCKET_CLIENT.emit('chatDisconnected');
				SOCKET_CLIENT.off();
			}
		}
	}, [dataLoaded]);

	useEffect(() => {
		/**Listening for new messages */
		if(dataLoaded){
			SOCKET_CLIENT.on('chatMessage', (message) => {
				setScrollProperties({scrollTo:'bottom',smoothly:true, delay:100});
				let allMessages = [...messages];
				allMessages.push(message);
				setMessages([...messages, message]);
			});
		}
	}, [dataLoaded, messages]);

	useEffect(() => {
		/**Listening for updating messages */
			SOCKET_CLIENT.on('chatClientEditMessage', (data) => {
				setMessages((prevState) => {
					let currentMessage = prevState && prevState.filter((item) => item?._id === data?._id);
					if(currentMessage.length){
						currentMessage[0].message = data.message
					}
					return prevState;
				})
			});
	}, [messages]);

	/** Function for Sending Message */
	const sendMessage = (e, extraData={}) => {
		if(e){
			e.preventDefault();
		}
		let data = {
			message:message,
			sender_id:senderData._id,
			receiver_id:receiverData._id,
			room:room,
			createdAt:new Date(),
			type:MESSAGE_TYPES.TEXT,
			...extraData
		};
		if(data.type === MESSAGE_TYPES.FILE || data.type === MESSAGE_TYPES.IMAGE){
			data.message = "";
		}else{
			if ((!message && data.type === MESSAGE_TYPES.TEXT)) {
				return;
			}
			if(data.message && isOnlyEmojis(data.message)){
				data.type = MESSAGE_TYPES.EMOJI;
			}
		}
		
		SOCKET_CLIENT.emit('chatSendMessage', data, () => setMessage(''));
	}

	const editMessage = (e, extraData={}) => {
		if(e){
			e.preventDefault();
		}
		let data = {
			message:message,
			sender_id:senderData._id,
			receiver_id:receiverData._id,
			room:room,
			createdAt:new Date(),
			type:MESSAGE_TYPES.TEXT,
			_id: messageData?._id,
			...extraData
		};
		setEdit(false);
		SOCKET_CLIENT.emit('chatEditMessage', data, () => setMessage(''));
	}

	function handleScroll(e) {
		if(e.target.scrollTop === 0 && hasMore){
			setScrollProperties({scrollTo:'lastTopMessage',smoothly:false});
			setNewLimit(lastLimit + DEFAULT_MESSAGE_LIMIT);
		}
	}
	function isOnlyEmojis(inputString) {
		const emojiOnlyRegex = /^[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27BF]*$/;
		return emojiOnlyRegex.test(inputString) && !/\p{L}/u.test(inputString);
	}
	 /**>>>>>>>>> UPLOAD CHUNKS >>>>>>>>>>>> */
	 const getChunks = (file) => {
        let chunks = [];
        const size = file.size;
        const totalChunks = Math.ceil(size / VIDEO_UPLOAD.CHUNK_SIZE);
        return new Promise((resove) => {
            let i = 0;
            for (i; i < totalChunks; i++) {
                chunks.push(file.slice(i * VIDEO_UPLOAD.CHUNK_SIZE, VIDEO_UPLOAD.CHUNK_SIZE * (i + 1)));
            };
            resove(chunks);
        });
    }
    const startLoading = (progress) => {
        return new Promise((resove) => {
            $('#upload_progress').css({ 'width': progress + '%' });
            $('#percent').html(progress + '%');
            resove(true);
        })
    }
    const uploadVideo = (myVid) => {
        let lastUploadedChunk = {};
        return new Promise(async (resove) => {
            let currentChunk = 0;
            const file = myVid[0];
            const myChunks = await getChunks(file);
            const totalChunks = myChunks.length;
            const timeStamp = (new Date()).getTime();
            function uploadChunk() {
                let formData = new FormData();
                formData.append("name", timeStamp + file.name);
                formData.append("size", file.size);
                formData.append("mimetype", file.mimetype);
                formData.append("tempFilePath", file.tempFilePath);
                formData.append("allChunks", totalChunks);
                formData.append("video", myChunks[currentChunk]);
                formData.append("currentChunk", currentChunk);
                if (currentChunk < totalChunks) {
                    $.ajax({
                        // url: 'upload',
                        url: `${process.env.REACT_APP_API_URL}/web/other/chat/upload-file`,
                        headers: {
                            "Authorization": `Bearer ${getUser()?.token}`
                        },
                        contentType: false,
                        processData: false,
                        type: 'POST',
                        data: formData,
                        success: function (data) {
                            if (data.success) {
                                lastUploadedChunk = data;
                                startLoading(data?.data?.uploadProgress).then((resultPromise) => {
                                    // startLoading(data.uploadProgress).then((resultPromise)=>{
                                    currentChunk++;
                                    uploadChunk();
                                });
                            }
                        }
                    });
                } else {
                    resove(lastUploadedChunk);
                }
            };
            uploadChunk();
        });
    }
    /**<<<<<<<<< UPLOAD CHUNKS <<<<<<<<<<<< */
	function handleSelectedImage(e) {
		setMessage('');

		let previewData = {
			file_name:'preview',
			type:'preview',
			sender_id: senderData._id,
		};
		setMessages((prevState) => [...prevState,previewData]);
		if(e.target.files.length > 0){
			const selectedFiles  = e.target.files[0];

			const fileType = selectedFiles.type;

			uploadVideo(e.target.files).then((response) => {
				let messageData = {
					file_name:response.data.fileName
				};
				if(fileType.includes('image')){
					messageData.type = MESSAGE_TYPES.IMAGE;
				}else{
					messageData.type = MESSAGE_TYPES.FILE;
				}
				setMessages((prevState) => prevState && prevState.filter((item) => item?.type !== 'preview'));
				sendMessage(null, messageData);
			}).catch((error) => {
				console.log("Error in image uploading =>", error);
			})
		}

		// sendMessage()
		// const data = new FormData();
		// Object.values(e.target.files).forEach((file)=>{
		// 	data.append('images',file);
		// });
		// UploadChatImage(data).then((response)=>{
		// 	if(response.success){
		// 		let messageData = {
		// 			type:MESSAGE_TYPES.IMAGE,
		// 			file_name:response.data.imageName
		// 		};
		// 		sendMessage(null, messageData);
		// 	}else{
		// 		console.log("Error in image uploading =>", response);
		// 	}
		// }).catch((err)=>{
		// 	console.log("Error in image uploading", err);
		// })
	}
	return (
		<div className="wraper-inner cpt bg-grey">
			<section className="user-list mt-5 pb-5">
				<div className="container" style={{minHeight:'60vh'}}>
					{/* <h3 className="inner-title mb-0">Chat</h3> */}
					{
						validUrl ?
							(<div className="my-chat-box-main">
								<div className="messaging">
									<div className="inbox_msg">
										<div className="mesgs">
											<InfoBar receiverData={receiverData} />
											<Messages setEdit={setEdit} setMessageData={setMessageData} setMessage={setMessage} setMessages={setMessages} messages={messages} senderData={senderData} receiverData={receiverData} onScrollEvt={handleScroll}
												scrollProperties={scrollProperties} />
											<Input edit={edit} message={message} setMessage={setMessage} editMessage={editMessage} sendMessage={sendMessage} onSelectImage={handleSelectedImage} />
										</div>
									</div>
								</div>
							</div>)
							: <>
								<h3 className="inner-title mb-0 text-center mt-5 pt-5">{t('web_lbl_url')}</h3>
							</>
					}
				</div>
			</section>
		</div>
	)
};

export default Chat;
