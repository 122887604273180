import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import toHtml from "html-react-parser";
import * as eventsService from "../../services/events.services";
import Loader from "../../common/loader";
import QRCode from "react-qr-code";
import { formateDate, showStatus, getStringfromPTag, SpecialCharacter, capitalizeFirstLetterOfEachWord, globalLoader, getPdfByHtml } from '../../../../utils/commonfunction';
import Swal from "sweetalert2";

const ViewEvent = (props) => {
  const navigate = useNavigate();
  const [eventList, setEventList] = useState();
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});

  useEffect(() => {
    eventsService
      .Details(params.id)
      .then((response) => {
        console.log("res", response);
        if (response && response.success) {
          setEventList(response.data.event_quota_data);
          setShowDefault(response.data ? response.data : []);
        }
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, [params.id]);

  const QRUrl = window.location.origin;
  const InfoCounterQRUrl = window.location.origin;

  const handleDownloadClick = async (e, fileURL) => {
    let fileName = "";
    if(fileURL){
        fileName = fileURL.replace(`${process.env.REACT_APP_SERVER_BASE_URL}/uploads/cms/`, "");
        e.preventDefault();
        globalLoader(true);
        try {
        const response = await fetch(fileURL);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const blob = await response.blob();
        const blobURL = URL.createObjectURL(blob);
    
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobURL;
        a.download = fileName;
    
        document.body.appendChild(a);
        a.click();
    
        document.body.removeChild(a);
        URL.revokeObjectURL(blobURL);
        globalLoader(false);
        } catch (error) {
        globalLoader(false);
        console.error('Error downloading the file:', error);
        Swal.fire({
            title: 'Download Error',
            text: `There was an error while downloading the file "${fileName}".`,
            icon: 'error',
        });
        }
    }else{
        console.log("Error in file downloading");
    }
  };

  return (
    <>
      {showdefault && Object.keys(showdefault).length > 0 ? (
        <div className="row">
          <div className="col-lg-12 form-group">
            <table
              id="simple-table"
              className="table  table-bordered table-hover"
            >
              <tbody>
                <tr>
                  <th>Title</th>
                  <td>
                    {showdefault && showdefault.title
                      ? showdefault.title
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Title In German</th>
                  <td>
                    {showdefault && showdefault.title_de
                      ? showdefault.title_de
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Image</th>
                  <td>
                    <img
                      src={
                        showdefault && showdefault.image
                          ? showdefault.image
                          : ""
                      }
                      alt="default"
                      style={{ height: "100px" }}
                    />
                  </td>
                </tr>
                <tr>
                  <th>Image German</th>
                  <td><img src={showdefault && showdefault.image_de ? showdefault.image_de : ""} alt="default" style={{ height: "100px" }} /></td>
                </tr>
                <tr>
                  <th>Hosting Company</th>
                  <td>
                    {showdefault && showdefault.hosting_company
                      ? showdefault.hosting_company
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Start Date</th>
                  <td>
                    {showdefault && showdefault.start_date
                      ? showdefault.start_date.split("T")[0]
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Last Date</th>
                  <td>
                    {showdefault && showdefault.end_date
                      ? showdefault.end_date.split("T")[0]
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Is the guest can be added </th>
                  <td>
                    {showdefault &&
                    showdefault.guest_can_add_secondary &&
                    showdefault.guest_can_add_secondary === true
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th>Lead Management </th>
                  <td>
                    {showdefault &&
                    showdefault.lead_management &&
                    showdefault.lead_management === true
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th>Stand Information Standard(SIS) </th>
                  <td>
                    {showdefault &&
                    showdefault.sis_management &&
                    showdefault.sis_management === true
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th>
                    Is secondary registrants allowed to register agenda option
                  </th>
                  <td>
                    {showdefault &&
                    showdefault.secondary_registrants_allowed_to_agenda_options &&
                    showdefault.secondary_registrants_allowed_to_agenda_options ===
                      true
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th>Wheather or not QR code is required</th>
                  <td>
                    {showdefault &&
                    showdefault.is_qr_required &&
                    showdefault.is_qr_required === true
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                {showdefault?.lead_management === true ? (
                  <tr>
                    <th>Enable QR-Code for Lead Coordinators</th>
                    <td>
                      {showdefault &&
                      showdefault.enable_qr_for_leads &&
                      showdefault.enable_qr_for_leads === true
                        ? "Yes"
                        : "No"}
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <th>Is accomodation offered</th>
                  <td>
                    {showdefault &&
                    showdefault.accommodation_offered &&
                    showdefault.accommodation_offered === true
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th>User can choose arrival and departure date</th>
                  <td>
                    {showdefault &&
                    showdefault.choice_arrival_departure_date &&
                    showdefault.choice_arrival_departure_date === true
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th>User can choose room category</th>
                  <td>
                    {showdefault &&
                    showdefault.choice_room_category &&
                    showdefault.choice_room_category === true
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th>Can the user choose event attendance dates</th>
                  <td>
                    {showdefault &&
                    showdefault.choice_event_attendance_date &&
                    showdefault.choice_event_attendance_date === true
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th>Is the visa invitation letter asked</th>
                  <td>
                    {showdefault &&
                    showdefault.askto_visa_verification &&
                    showdefault.askto_visa_verification === true
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th>Primary registrant(Question set)</th>
                  <td>
                    {showdefault &&
                    showdefault.question_group_primary_data &&
                    showdefault.question_group_primary_data.title ? (
                      <Link
                        to={`/admin/question-settings/sets/view/${showdefault?.question_group_primary_data?._id}?page=1&survey_page=1`}
                      >
                        {showdefault.question_group_primary_data.title}
                      </Link>
                    ) : (
                      "N/A"
                    )}
                  </td>
                </tr>
                {showdefault?.lead_management === true ? (
                  <tr>
                    <th>Lead coordinator registration(Question set)</th>
                    <td>
                      {showdefault &&
                      showdefault.lead_coordinator_registration_data &&
                      showdefault.lead_coordinator_registration_data?.title ? (
                        <Link
                          to={`/admin/question-settings/sets/view/${showdefault?.lead_coordinator_registration_data?._id}?page=1&survey_page=1`}
                        >
                          {showdefault.lead_coordinator_registration_data.title}
                        </Link>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ) : null}
                {showdefault?.lead_management === true ? (
                  <tr>
                    <th>Trade fair questionnaire(Question set)</th>
                    <td>
                      {showdefault &&
                      showdefault.trade_fair_questionnaire_data &&
                      showdefault.trade_fair_questionnaire_data?.title ? (
                        <Link
                          to={`/admin/question-settings/sets/view/${showdefault?.trade_fair_questionnaire_data?._id}?page=1&survey_page=1`}
                        >
                          {showdefault.trade_fair_questionnaire_data.title}
                        </Link>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ) : null}
                {showdefault?.lead_management === true ? (
                  <tr>
                    <th>Back Office registration(Question set)</th>
                    <td>
                      {showdefault &&
                      showdefault.back_office_registration_data &&
                      showdefault.back_office_registration_data?.title ? (
                        <Link
                          to={`/admin/question-settings/sets/view/${showdefault?.back_office_registration_data?._id}?page=1&survey_page=1`}
                        >
                          {showdefault.back_office_registration_data.title}
                        </Link>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ) : null}
                  {showdefault?.sis_management === true ? (
                  <tr>
                    <th>Booth attendants registration(Question set)</th>
                    <td>
                      {showdefault &&
                      showdefault.booth_attendants_registration_data &&
                      showdefault.booth_attendants_registration_data?.title ? (
                        <Link
                          to={`/admin/question-settings/sets/view/${showdefault?.booth_attendants_registration_data?._id}?page=1&survey_page=1`}
                        >
                          {showdefault.booth_attendants_registration_data.title}
                        </Link>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ) : null}
                  {showdefault?.sis_management === true ? (
                  <tr>
                    <th>Info counter registration(Question set)</th>
                    <td>
                      {showdefault &&
                      showdefault.info_counter_registration_data &&
                      showdefault.info_counter_registration_data?.title ? (
                        <Link
                          to={`/admin/question-settings/sets/view/${showdefault?.info_counter_registration_data?._id}?page=1&survey_page=1`}
                        >
                          {showdefault.info_counter_registration_data.title}
                        </Link>
                      ) : (
                        "N/A"
                      )}
                    </td>
                  </tr>
                ) : null}
                <tr>
                  <th>Is the second email address as CC asked</th>
                  <td>
                    {showdefault &&
                    showdefault.askto_second_email &&
                    showdefault.askto_second_email === true
                      ? "Yes"
                      : "No"}
                  </td>
                </tr>
                <tr>
                  <th>Secondary registrant(Question set)</th>
                  <td>
                    {showdefault &&
                    showdefault.guest_can_add_secondary &&
                    showdefault.question_group_secondary_data ? (
                      <Link
                        to={`/admin/question-settings/sets/view/${showdefault?.question_group_secondary_data?._id}?page=1&survey_page=1`}
                      >
                        {showdefault.question_group_secondary_data.title}
                      </Link>
                    ) : (
                      "N/A"
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Managers</th>
                  <td>
                    {showdefault &&
                    showdefault.event_manager_data &&
                    showdefault.event_manager_data.length > 0
                      ? showdefault.event_manager_data.map((item, index) => {
                          return (
                            <span
                              key={index}
                              className={
                                index === 0
                                  ? "badge badge-pill badge-primary-light text-capitalize"
                                  : "badge badge-pill badge-primary-light text-capitalize ms-2"
                              }
                            >
                              {item?.name}
                            </span>
                          );
                        })
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Event Type</th>
                  <td>{showdefault && showdefault.event_type ? capitalizeFirstLetterOfEachWord(SpecialCharacter(showdefault?.event_type)) : "N/A"}</td>
                </tr>
                <tr>
                  <th>Status For Booth Attendants</th>
                  <td>
                    {showdefault &&
                    showdefault.status_booth_attendants &&
                    showdefault.status_booth_attendants.length > 0
                      ? showdefault.status_booth_attendants.map((item, index) => {
                          return (
                            <span
                              key={index}
                              className={
                                index === 0
                                  ? "badge badge-pill badge-primary-light text-capitalize"
                                  : "badge badge-pill badge-primary-light text-capitalize ms-2"
                              }
                            >
                              {item}
                            </span>
                          );
                        })
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>
                    {showdefault && showdefault.status === 0
                      ? toHtml(showStatus(showdefault.status))
                      : toHtml(showStatus(showdefault.status))}
                  </td>
                </tr>
                <tr>
                  <th>Created Date</th>
                  <td>
                    {showdefault && showdefault.createdAt
                      ? formateDate(showdefault.createdAt)
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Modified Date</th>
                  <td>
                    {showdefault && showdefault.updatedAt
                      ? formateDate(showdefault.updatedAt)
                      : "N/A"}
                  </td>
                </tr>
                {showdefault?.lead_management === true &&
                showdefault?.enable_qr_for_leads === true &&
                QRUrl ? (
                  <tr>
                    <th>Qr Code For Lead Coordinator</th>
                    <td>
                      <div className="qr-code-admin btn-group" id="lead-coordinator-qr-code">
                        <QRCode
                          size={256}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={QRUrl}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                      {showdefault?.lead_management === true && showdefault?.enable_qr_for_leads === true ?    
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="First group"
                      style={{ marginLeft:"20px"}}
                    >
                      <button
                        type="button"
                        className="btn btn-dark"
                        href="#"
                        onClick={(e) =>
                          getPdfByHtml(document.getElementById('lead-coordinator-qr-code'), "lead-coordinator-qr-code.pdf")
                        }
                      >
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                    :
                    <></>
                    }
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                  {showdefault?.sis_management === true &&
                showdefault?.enable_qr_for_info_counter === true &&
                InfoCounterQRUrl ? (
                  <tr>
                    <th>Qr Code For Info Counter</th>
                    <td>
                      <div className="qr-code-admin btn-group" id="info-counter-qr-code">
                        <QRCode
                          size={256}
                          style={{
                            height: "auto",
                            maxWidth: "100%",
                            width: "100%",
                          }}
                          value={InfoCounterQRUrl}
                          viewBox={`0 0 256 256`}
                        />
                      </div>
                      {showdefault?.sis_management === true && showdefault?.enable_qr_for_info_counter === true ?    
                    <div
                      className="btn-group"
                      role="group"
                      aria-label="First group"
                      style={{ marginLeft:"20px"}}
                    >
                      <button
                        type="button"
                        className="btn btn-dark"
                        href="#"
                        onClick={(e) =>
                          getPdfByHtml(document.getElementById('info-counter-qr-code'), "info-counter-qr-code.pdf")
                        }
                      >
                        <i className="fa fa-download" aria-hidden="true"></i>
                      </button>
                    </div>
                    :
                    <></>
                    }
                    </td>
                  </tr>
                ) : (
                  <></>
                )}
                <tr>
                  <td colSpan={2}>
                    <div
                      className="mb-3"
                      dangerouslySetInnerHTML={{
                        __html: getStringfromPTag(showdefault.description),
                      }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h6 className="main-content-label">Quota</h6>
        <div className="d-flex align-items-center">
          <div className="form-group mb-0 me-3">
            <div className="form-group mb-0 rangepicker_container">
              <input
                type="search"
                className="form-control"
                placeholder="Search"
                style={{ opacity: "0" }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
          <thead>
            <tr>
              <th>
                <div className="sorting_column">
                  <span>Total Quantity</span>
                </div>
              </th>
              <th>Sold Quantity</th>
              <th className="sr_head">Available Quantity</th>
              <th>Price</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>tax</th>
            </tr>
          </thead>
          <tbody>
            <>
              {eventList ? (
                <tr>
                  <td>{eventList.total_quantity}</td>
                  <td>{eventList.sold_quantity}</td>
                  <td>{eventList.available_quantity}</td>
                  <td>{eventList.price}</td>
                  <td>
                    {eventList.start_date
                      ? eventList.start_date.split("T")[0]
                      : "N/A"}
                  </td>
                  <td>
                    {eventList.end_date
                      ? eventList.end_date.split("T")[0]
                      : "N/A"}
                  </td>
                  <td>{eventList.tax}</td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={7} className="text-center">
                    No records
                  </td>
                </tr>
              )}
            </>
          </tbody>
        </table>
        <div className="mt-5">
          <button
            className="btn ripple btn-dark"
            type="button"
            onClick={() => navigate(-1)}
          >
            <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default ViewEvent;
