import React, { useEffect} from 'react'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import SISUserHeader from './header';
import SISUserFooter from './footer';

const SISUserLayout = () => {
    let { i18n, t } = useTranslation();
    let reducerData = useSelector(state => state.manageLmBackOffice);
    

    
   /** Deep links */
   useEffect(() => {
        try{
            let url = "";
            if(window && window.location && window.location.href){
                let origin = window.location.origin ? window.location.origin : '';
                url = window.location.href.replace(origin, '');
                if(url[0] === "/" || url[0] === "\\"){
                    url = url.slice(1, url.length);
                }
            }
        }catch(err){
            console.log("Deep link error", err);
        }
    }, []);
    return (
        <>
            <SISUserHeader />
            {/* style={{paddingTop:'75px',minHeight: "60vh"}} */}
            <div >
                {reducerData !== "" ?
                <>
                    <Outlet/>
                </>
                :
                    <div className="wraper-inner cpt bg-grey">
                        <section className="inner-space pt-0">
                            <div className="container" style={{ minHeight: "60vh" }}>
                                <div className="dash-box-shadow">
                                    <h3 className='text-center'>{t('web_lbl_no_events_found')}</h3>
                                </div>
                            </div>
                        </section>
                    </div>
                }
                
            </div>
            <SISUserFooter />
        </>
    )
}

export default SISUserLayout;