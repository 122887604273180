import axiosInstance from "../../../utils/axios";

const path = "admin/transactions"

export const List = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const Details = async (id) => {
    return await axiosInstance().get(`${path}/details?o_id=${id}`);
}

export const Refund = async (values) =>{
    return await axiosInstance().post(`${path}/refund`, values);
}


