import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../common/loader";
import Breadcrums from "../../common/breadcrumbs";
import * as Yup from "yup";
import $ from "jquery";
import { TrimText, formateDate, globalLoader, handleServerValidations, hasPermission, removeSpecialCharacter, removeCommas, writeMyExcel, prepareQuestionsFormData, getPdfByHtml } from "../../../../utils/commonfunction";
import StatusFilter from "../../common/statusFilter";
import CustomRangepicker from "../../common/rangepicker";
import CustomPagination from "../../common/custompagination";
import { GENDER, SWAL_SETTINGS, showFilterlist } from "../../../../utils/Constants";
import Sorting from "../../common/sorting";
import { AddBooking, GetRoomQuestions, GetUserOptions, RoomsDelete, RoomsList, RoomsStatus, UpdateBooking, RoomsDetails, ReadUsersExcel } from "../../services/roommanagement.services";
import * as eventServices from "../../services/events.services";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import resourceDayGridPlugin from '@fullcalendar/resource-daygrid';
import moment from "moment";
import { Button, Calendar, DatePicker, Modal, SelectPicker, Steps } from "rsuite";
import { Formik } from "formik";
import DynamicQuestions from "../../../user/shared/DynamicQuestions";
import jsPDF from 'jspdf';
import { List } from "../../services/usermgmt.services";

const RoomManagementTable = () => {
  const formikRef = useRef();
  const VIEWS = { LIST: 'list', APPOINTMENT_MANAGEMENT: "appointment_management", CALENDAR: "calendar" };
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const [statsupdate, setStatusUpdate] = useState("false");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const [sorting, setSorting] = useState({});
  const [defaultSorting, setDefaultSorting] = useState(true);
  const [page, setPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState({});
  const [globalsearch, setGlobalSearch] = useState("");
  const [eventFilter, setEventFilter] = useState(null);
  const [view, setView] = useState(VIEWS.LIST);
  const [addBookingData, setAddBookingData] = useState(null);
  const [calendarBookings, setCalendarBookings] = useState([]);
  const [calenderDate, setCalenderDate] = useState(moment().format("YYYY-MM-DD"));
  const [questionList, setQuestionList] = useState([]);
  const [userList, setUserList] = useState([]);
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Room Management", url: "" },
  ];
  const [eventOptions, setEventOptions] = useState([]);
  const [resetdate, setResetDate] = useState(false);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const calendarRef = useRef();
  const childRef = useRef();
  const [questionFilesUploadPath] = useState("room-bookings");
  /********** MULTI SELECT >>>>>>********* */
  const [selectedRecords, setSelectedRecords] = useState([]);
  const [selectedAllData, setSelectedAllData] = useState(false);
  const selectAllCheckbox = useRef();
  /********** MULTI SELECT <<<<<<********* */
  const [allUsersExportFlag] = useState(false);
  const [csvHeaders] = useState(["Room Name", "Question Set", "Capacity", "Bookable Interval", "Event Name", "Start Date", "End Date", "Created Date"]);
  const [InviteMemberModal, setInviteMemberModal] = useState(false);
  const [globalsearchNews, setGlobalSearchNews] = useState("");
  const [itemPerPageModal] = useState(10);
  const [dataPageNo, setDataPageNo] = useState(1);
  const [searchNew, setSearchNew] = useState({});
  const [userdatalength, setUserDataLength] = useState(0);
  const [userData, setUserData] = useState([]);
  const [selectedRoomDetail, setSelectedRoomDetail] = useState(false);
  const [fileType] = useState(Object.keys(GENDER).map((item => {
    return {
      label: item, value: GENDER[item]
    }
  })));
  const [selectedUser, setSelectedUser] = useState([]);
  const [showSelectedUsers, setShowSelectedUsers] = useState(false);
  const [selectedAllDataNew, setSelectedAllDataNew] = useState(false);


  const [excelModal, setExcelModal] = useState(false);


  const [step, setStep] = React.useState(0);
  const [file, setFile] = useState("");
  const [skippedUsers, setSkippedUsers] = useState(null);
  const [selectedManualUser, setSelectedManualUser] = useState([]);
  const [selectedNewManualUser, setSelectedNewManualUser] = useState([]);
  const [selectedManualUserData, setSelectedManualUserData] = useState(false);


  // console.log("calendarBookings",calendarBookings);
  useEffect(() => {
    if (search) {
      setLoader(true);
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      if (eventFilter) {
        formData.append("event_id", eventFilter);
      }
      RoomsList(formData)
        .then((data) => {
          setDataLength(data?.data?.total_records);
          setList(data && data?.data && data?.data?.list ? data.data.list : []);
          setSelectedAllData(
            data && data?.data && data?.data?.data_ids ? data.data.data_ids : []
          );
          setPage(data && data?.data && data?.data?.page ? data.data.page : 1);
          setCalendarBookings(getBookings(data?.data?.bookings_list));
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [location, statsupdate, sorting, search, globalsearch, eventFilter]);

  useEffect(() => {
    let formData = new FormData();
    formData.append('global_search', globalsearchNews);
    formData.append("per_page", itemPerPageModal);
    formData.append("page", dataPageNo);
    formData.append("search", JSON.stringify({ subscribed_for_newsletter: true, ...searchNew }));
    List(formData)
      .then((response) => {
        setUserDataLength(response?.data?.total_records);
        setUserData(response?.data?.list);
        setSelectedAllDataNew(response && response.data && response.data.data_ids ? response.data.data_ids : []);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }, [globalsearchNews, searchNew, statsupdate, dataPageNo]);

  const viewfunction = (row) => {
    navigate(`/admin/room-management/view/rooms/${row._id}`);
  };

  const goToEdit = (row) => {
    navigate(
      `/admin/room-management/${params.pgno}/edit/${row._id}`
    );
  };

  useEffect(() => {
    GetUserOptions().then((response) => {
      if (response.success) {
        setUserList(response?.data.map((user) => {
          return { label: user?.name, value: user?._id };
        }));
      }
    }).catch((error) => {
      console.log("error", error)
    })
    eventServices.Options().then((response) => {
      if (response.success) {
        setEventOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])

  const ChangeStatus = (data, Num) => {
    let ids = Array.isArray(data) ? data : [data];
    const formData = new FormData();
    formData.append("o_id", JSON.stringify(ids));
    formData.append("status", Num);
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        RoomsStatus(formData)
          .then((response) => {
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              setStatusUpdate(!statsupdate);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("error===>");
          });
      }
    });
  };

  function Deletefunction(data) {
    Swal.fire({
      customClass: "swal-wide",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#403fad",
      cancelButtonColor: "#f1388b",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let ids = Array.isArray(data) ? data : [data];
        const formdata = new FormData();
        formdata.append("o_id", JSON.stringify(ids));
        RoomsDelete(formdata)
          .then((response) => {
            setStatusUpdate(!statsupdate);
            if (response.success) {
              Swal.fire({
                icon: "success",
                text: response.message,
                ...SWAL_SETTINGS,
              });
              /** removing deleted ids from selectedRecords */
              setSelectedRecords((data) =>
                data.filter((item) => {
                  return ids.indexOf(item) === -1;
                })
              );
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
            }
          })
          .catch((error) => {
            console.log("deleteError", error);
          });
      }
    });
  }

  // sorting function start
  const handleSort = (e, column) => {
    setDefaultSorting(false);
    let sort = { order: 0, column: column };
    if (e.target.classList.contains("assc")) {
      sort.order = -1;
    } else {
      sort.order = 1;
    }
    setSorting(sort);
  };
  // sorting end

  // search or filter function
  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };
  // search or filer end

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    setResetDate(!resetdate);
    setEventFilter(null);
    $("#event")[0].selectedIndex = 0;
  };

  /********** MULTI SELECT >>>>>>********* */
  useEffect(() => {
    if (selectAllCheckbox && selectAllCheckbox.current) {
      if (selectedRecords.length === datalength) {
        selectAllCheckbox.current.indeterminate = false;
        selectAllCheckbox.current.checked = true;
      } else if (selectedRecords.length) {
        selectAllCheckbox.current.indeterminate = true;
      } else {
        selectAllCheckbox.current.indeterminate = false;
      }
    }
  }, [selectedRecords]);
  const selectRecord = (userData, check) => {
    if (check) {
      setSelectedRecords((previousState) => [...previousState, userData._id]);
    } else {
      setSelectedRecords((previousState) =>
        previousState.filter((item) => item !== userData._id)
      );
    }
  };
  const handleSelectAll = (check) => {
    if (check) {
      setSelectedRecords(selectedAllData);
    } else {
      setSelectedRecords([]);
    }
  };
  const isSelected = (data) => {
    return selectedRecords.filter((item) => data?._id === item).length > 0;
  };
  /********** MULTI SELECT <<<<<<<<<********* */;

  const handleEventChange = (e) => {
    if (e.target.value) {
      setEventFilter(e.target.value);
    } else {
      setEventFilter(null);
    }
  };

  const getBookings = (data) => {
    return data.map((item) => {
      return {
        id: item?._id,
        title: item?.title,
        start: item?.start_date,
        end: item?.end_date,
        resourceId: item?.room_id,
      }
    });
  };
  const getResources = (data) => {
    return data.map((item) => {
      return {
        id: item?._id,
        title: item?.name
      }
    });
  };
  const handleDateClick = (e) => {
    const date = e?.dateStr;
    globalLoader(true);
    setTimeout(() => {
      GetRoomQuestions(e.resource.id).then((response) => {
        globalLoader(false);
        if (response.success) {
          setQuestionList(response.data || []);
          setAddBookingData({
            date: date,
            resourceId: e.resource.id,
            resourceTitle: e.resource.title,
          });
        }
      }).catch(err => {
        globalLoader(false);
        console.error(err);
      });
    }, 200);
  };
  const handleBookingResize = (e) => {
    const formData = new FormData();
    formData.append('_id', e?.event?.id);
    formData.append('title', e?.event?.title);
    formData.append('start_date', e?.event?.startStr);
    formData.append('end_date', e?.event?.endStr);
    updateCalendarBooking(e, formData);
  };
  const handleBookingDrop = (e) => {
    const formData = new FormData();
    if (e.newResource) {
      formData.append('room_id', e?.newResource?.id);
    }
    formData.append('_id', e?.event?.id);
    formData.append('title', e?.event?.title);
    formData.append('start_date', e?.event?.startStr);
    formData.append('end_date', e?.event?.endStr);
    updateCalendarBooking(e, formData);
  };
  const updateCalendarBooking = (e, formData) => {
    globalLoader(true);
    setTimeout(() => {
      UpdateBooking(formData).then((response) => {
        globalLoader(false);
        if (response?.success) {
          setCalendarBookings(prev => {
            return prev.map((booking) => {
              if (booking?.id === e?.event?.id) {
                let newData = { ...booking };
                newData.start = e?.event?.startStr;
                newData.end = e?.event?.endStr;
                if (e.newResource) {
                  newData.resourceId = e?.newResource?.id;
                }
                return newData;
              }
              return booking;
            })
          });
          Swal.fire({
            icon: 'success',
            text: response?.message,
            ...SWAL_SETTINGS
          });
        } else {
          e?.event?.setStart(e?.oldEvent?.startStr);
          e?.event?.setEnd(e?.oldEvent?.endStr);
          if (e.oldResource && e.oldResource.id) {
            setCalendarBookings(prev => {
              return prev.map((booking) => {
                if (booking?.id === e?.event?.id) {
                  let newData = { ...booking };
                  newData.resourceId = e.oldResource.id;
                  return newData;
                }
                return booking;
              })
            });
          }
          Swal.fire({
            icon: 'error',
            text: response?.message,
            ...SWAL_SETTINGS
          });
        }
      }).catch(err => {
        globalLoader(false);
        console.error(err);
      });
    }, 200);
  };
  const CALENDER_TOP_TOOLBAR = {
    left: 'title',
    center: '',
    right: 'datePickerButton today prev,next'
  };
  const CALENDER_CUSTOM_BUTTONS = {
    today: {
      text: 'Today',
      click: function () {
        calendarRef.current.getApi().gotoDate(moment().format());
      }
    },
    datePickerButton: {
      text: 'Select Date',
      click: function () {
        setOpenDatePicker(true);
      }
    }
  };
  const handleSelectDate = (e) => {
    const date = moment(e).format("YYYY-MM-DD");
    calendarRef?.current?.getApi()?.gotoDate(date);
    setCalenderDate(date);
  };
  const handleDateChange = (e) => {
    const date = moment(e.startStr).format("YYYY-MM-DD");
    if (date !== calenderDate) {
      setCalenderDate(date);
    }
  };
  const goToDuplicate = (row) => {
    navigate(`/admin/room-management/add`, { state: row });
  };

  const exportAllRooms = async () => {
    globalLoader(true);
    if (view === VIEWS.APPOINTMENT_MANAGEMENT || view === VIEWS.CALENDAR) {
      const calendarApi = calendarRef.current.getApi();
      const calendarEl = calendarApi.el;
      getPdfByHtml(calendarEl, "bookings.pdf").then(() => {
        globalLoader(false);
      });
    } else {
      const formData = new FormData();
      formData.append("page", params.pgno);
      formData.append("per_page", itemperpage);
      formData.append("sort", JSON.stringify(sorting));
      formData.append("search", JSON.stringify(search));
      formData.append("global_search", globalsearch);
      RoomsList(formData)
        .then((response) => {
          let data = response && response?.data && response?.data?.list ? response.data.list : [];
          let rows = data.map((item => {
            return [
              removeCommas(item?.name ? item.name : "N/A"),
              removeCommas(item?.questions_data?.title ? item?.questions_data?.title : "N/A"),
              removeCommas(item?.capacity ? item.capacity : "N/A"),
              removeCommas(item?.bookable_interval ? item.bookable_interval : "N/A"),
              removeCommas(item?.event_data?.title ? item.event_data.title : "N/A"),
              removeCommas(formateDate(item?.start_date)),
              removeCommas(formateDate(item?.end_date)),
              removeCommas(formateDate(item?.createdAt))
            ];
          }));
          writeMyExcel([csvHeaders, ...rows], `room-report.xlsx`).then(() => {
            setTimeout(() => {
              globalLoader(false);
            }, 1000)
          }).catch((err) => {
            console.log(err);
          });
        }).catch((error) => {
          console.log("error ====> ", error);
          globalLoader(false);
        });
    }
  };

  const handleInvitation = () => {
    // setSelectedUser([]);
    setInviteMemberModal(true);
  };

  const resetFilterNew = (e) => {
    e.preventDefault();
    setGlobalSearchNews("");
    setSearchNew({});
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
    $("#resetFilterNew")[0].selectedIndex = 0;
  };

  const prepareSearchNew = (key, value) => {
    let sr = { ...searchNew };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearchNew(sr);
  };

  const handleFilterChange = (e) => {
    if (e.target.value) {
      prepareSearchNew("gender", e.target.value)
    } else {
      prepareSearchNew("gender", "")
    }
  };

  const selectRow = (item) => {
    let id = item?._id;
    $("#" + id + "label").trigger("click");
  };

  /**********Handle members selection when invite members >>>>>>********* */

  useEffect(() => {
    if (InviteMemberModal) {
      if (selectedUser.length === userdatalength) {
        selectAllCheckbox.current.indeterminate = false;
        selectAllCheckbox.current.checked = true;
      } else if (selectedUser.length) {
        selectAllCheckbox.current.indeterminate = true;
      } else {
        selectAllCheckbox.current.indeterminate = false;
      }

    }
  }, [selectedUser, InviteMemberModal]);
  const selectUser = (userData, check) => {
    if (check) {
      setSelectedUser((previousState) => [...previousState, userData._id]);
    } else {
      setSelectedUser((previousState) =>
        previousState.filter((item) => item !== userData._id)
      );
    }
  };
  const handleSelect = (check) => {
    if (check) {
      setSelectedUser(selectedAllDataNew);
    } else {
      setSelectedUser([]);
    }
  };
  const isSelectedNew = (item) => {
    return selectedUser.filter((data) => item._id === data).length > 0;
  };

  /**********Handle members selection when invite members >>>>>>********* */

  const handleNextButtonClick = () => {
    setShowSelectedUsers(true);
    setInviteMemberModal(false);
  };


  useEffect(() => {
    RoomsDetails(addBookingData?.resourceId)
      .then((response) => {
        setSelectedRoomDetail(response && response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, [addBookingData?.resourceId]);

  const setEndDate = (e) => {
    let room_booking_interval = selectedRoomDetail && selectedRoomDetail.bookable_interval ? selectedRoomDetail.bookable_interval : null
    if (room_booking_interval) {
      const start_date = new Date(e.target.value);
      const end_date = new Date(start_date.getTime() + room_booking_interval * 60000);
      const formattedEndDate = moment(end_date).format("YYYY-MM-DD HH:mm:ss");
      console.log(formattedEndDate, "formattedEndDate");
      formikRef?.current?.setFieldValue('end_date', formattedEndDate)
    }
  }



  const readExcelFile = (e) => {
    globalLoader(true);
    if (e.target.files[0]) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      ReadUsersExcel(formData)
        .then((response) => {
          setTimeout(() => {
            if (response?.success) {
              setFile(e?.target?.files[0]);
              setSelectedNewManualUser(response?.data?.list);
              setSelectedManualUser(response.data.list);
              setStep(step + 1);
            } else {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(response),
                ...SWAL_SETTINGS,
              });
              document.getElementById("file_reader").value = "";
            }
            globalLoader(false);
          }, 1500);
        })
        .catch((error) => {
          console.log("error", error);
          Swal.fire({
            icon: "error",
            text: handleServerValidations(error),
            ...SWAL_SETTINGS,
          });
          globalLoader(false);
        });
    }
  };

  const onOkayClick = () => {
    setSelectedManualUserData(JSON.stringify(selectedManualUser));
    setStep(step + 1);
  };

  const handleRemoveManualUser = (email) => {
    setSelectedManualUser(selectedManualUser.filter(user => user.email !== email));
  };
  const modalData = (steps) => {
    if (steps === 0) {
      return (
        <div className="my-3 upload-button "  style={ {height: "560px"}}>
          <form
            id="reader_id"
            style={{
              height: "300px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="text-center ">
              <label htmlFor="file_reader">
                <input
                  style={{ display: "none" }}
                  type={"file"}
                  id="file_reader"
                  onChange={readExcelFile}
                  accept=".xls,.xlsx,.csv"
                />
                <i
                  className="fa fa-upload cp"
                  aria-hidden="true"
                  style={{ fontSize: "7em", color: "#3598ff" }}
                ></i>
              </label>
              <p className="text-muted mb-0">Please upload excel file.</p>
              <a
                className="download-file my-0"
                href={`${process.env.REACT_APP_API_URL.replace(
                  "/webservice/api/v1",
                  ""
                )}/configs/default/LM-users-invitation-sample.xlsx`}
                download
              >
                Download sample file.
              </a>
            </div>
          </form>
        </div>
      );
    } else if (steps === 1) {
      return (
        <div className="mt-4" style={ {height: "560px"}}>
          <div className="table-responsive">
            <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
              <thead>
                <tr>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <>
                  {selectedNewManualUser.length ? (
                    selectedNewManualUser.map((row, i) => {
                      return (
                        <tr key={i}>
                          <td>
                            {row.email.toLowerCase()}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={selectedManualUser?.headings?.length}
                        className="text-center"
                      >
                        No records
                      </td>
                    </tr>
                  )}
                </>
              </tbody>
            </table>
          </div>
          <Modal.Footer className="mt-4">
            <div className="chek-box-card mt-3 d-flex justify-content-end"></div>
            <Button
              appearance="primary"
              onClick={() => {
                setStep(step - 1);
              }}
              type="button"
              className="mt-2"
            >
              Previous
            </Button>

            <Button
              appearance="primary"
              onClick={() => {
                onOkayClick();
              }}
              type="submit"
              className="mt-2"
            >
              Submit
            </Button>
          </Modal.Footer>
        </div>
      );
    } else if (steps === 2) {
      return (
        <div  style={ {height: "560px"}}>
          <div className="d-flex justify-content-center align-items-center mt-4">
            <h1>Success</h1>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <p>Total Skipped Users : {skippedUsers ? skippedUsers : 0}</p>
          </div>

          <Modal.Footer className="mt-4">
            <div className="d-flex justify-content-center align-items-center">
              <Button
                appearance="primary"
                onClick={() => {
                  setExcelModal(false);
                  setStep(0);
                }}
                type="submit"
                className="mt-2"
              >
                Submit
              </Button>
            </div>
          </Modal.Footer>
        </div>
      );
    }
  };
  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="animation_fade">
        <div className="card custom-card position-relative">
          <div className="table-top-button-bar">
            <div aria-label="Basic example" className="btn btn-group pe-0" role="group">
              {Object.keys(VIEWS).map((key, i) => {
                return <button key={i} className={`btn ripple btn-info text-capitalize ${view === VIEWS[key] ? 'active' : ''}`} type="button" onClick={() => setView(VIEWS[key])}>{removeSpecialCharacter(VIEWS[key])}</button>
              })}
            </div>
          </div>
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center mb-4">
              <h6 className="main-content-label">Room Management </h6>
              <div className="d-flex align-items-center">
                <div className="form-group mb-0 me-3">
                  <div className="form-group mb-0 rangepicker_container filter_design">
                    <i className="fa fa-search calender_icon"></i>
                    <input
                      type="search"
                      className="form-control"
                      value={globalsearch}
                      placeholder="Search"
                      onChange={(e) => {
                        setGlobalSearch(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="me-3">
                  <CustomRangepicker
                    GetDateRange={(e) => {
                      prepareSearch("createdAt", e);
                    }}
                    resetdate={resetdate}
                  />
                </div>
                {/* <div className="me-3">
                  <StatusFilter
                    data={showFilterlist}
                    prepareSearch={prepareSearch}
                  />
                </div> */}
                <div className="me-3">
                  <div className="form-group mb-0 filter_icon_container filter_design">
                    <i className="fa fa-filter mr-2 filter_icon"></i>
                    <div className="select-down-arrow">
                      <select className="form-control cp" onChange={handleEventChange} id="event">
                        <option value="" label="Select event">Select{" "}</option>
                        {eventOptions && eventOptions.map((option, i) => {
                          return (
                            <option key={i} value={option._id}>
                              {option.title}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
                <button type="reset" value="Reset" onClick={resetFilter} className="btn btn-warning float-right mr-2" >
                  Reset Filter
                </button>
                <button className="btn ripple btn-main-primary signbtn mr-2" onClick={() => navigate(`/admin/room-management/add`)}>Add New</button>
                <button className="btn ripple btn-main-primary signbtn mr-2" disabled={datalength === 0} onClick={exportAllRooms}>Export Report</button>
              </div>
            </div>
            {view === VIEWS.LIST ?
              <>
                <div className="table-responsive" id="ramveer">
                  <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                    <thead>
                      <tr>
                        <th
                          className={
                            "position-relative select_head " +
                            (hasPermission(
                              "/admin/cms/default-email-template/multi-select"
                            )
                              ? ""
                              : "d-none")
                          }
                        >
                          <div className="select-check-container">
                            <label
                              htmlFor="selectAllCheck"
                              style={{ lineHeight: "unset" }}
                              className="ckbox  cp"
                            >
                              <input
                                ref={selectAllCheckbox}
                                id="selectAllCheck"
                                checked={
                                  datalength &&
                                  datalength === selectedRecords.length
                                }
                                onChange={(e) => handleSelectAll(e.target.checked)}
                                type="checkbox"
                              />
                              <span></span>
                            </label>
                          </div>
                        </th>
                        <th>Name</th>
                        <th>Question Set</th>
                        <th>Capacity</th>
                        <th>Bookable Interval(Min)</th>
                        <th>Event Name</th>
                        <th>
                          <div className="sorting_column">
                            <span>Created Date</span>
                            <Sorting
                              sort={sorting}
                              handleSort={handleSort}
                              defaultSorting={defaultSorting}
                              column="createdAt"
                            />
                          </div>
                        </th>
                        {/* <th className="status_head">Status</th> */}
                        <th className="action_head">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {loader ? (
                        <tr>
                          <td colSpan={8}>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {list.length ? (
                            list.map((row, i) => {
                              return (
                                <tr
                                  key={i}
                                  className={isSelected(row) ? "selected" : ""}
                                >
                                  <td
                                    className={
                                      "position-relative " +
                                      (hasPermission(
                                        "/admin/cms/default-email-template/multi-select"
                                      )
                                        ? ""
                                        : "d-none")
                                    }
                                  >
                                    <div className="select-check-container">
                                      <label
                                        htmlFor={row?._id + "input"}
                                        style={{ lineHeight: "unset" }}
                                        className="ckbox cp"
                                      >
                                        <input
                                          id={row?._id + "input"}
                                          checked={isSelected(row)}
                                          onChange={(e) =>
                                            selectRecord(row, e?.target?.checked)
                                          }
                                          type="checkbox"
                                          className="form-check select-check cp"
                                        />
                                        <span></span>
                                      </label>
                                    </div>
                                  </td>
                                  <td>
                                    {row.name ? row.name : "N/A"}
                                  </td>
                                  <td>{row?.questions_data?.title ? row.questions_data.title : "N/A"}</td>
                                  <td>{row?.capacity ? row.capacity : "N/A"}</td>
                                  <td>{row?.bookable_interval ? row?.bookable_interval : "N/A"}</td>
                                  <td>{row?.event_data?.title ? row?.event_data.title : "N/A"}</td>
                                  <td>
                                    {row.createdAt
                                      ? formateDate(row.createdAt)
                                      : "N/A"}
                                  </td>
                                  <td>
                                    <div className="d-flex">
                                      <button
                                        className="btn ripple btn-main-primary signbtn"
                                        onClick={() => {
                                          viewfunction(row);
                                        }}
                                      >
                                        View
                                      </button>
                                      {hasPermission(
                                        "/admin/cms/promotional-email-templates/*/edit/*"
                                      ) ? (
                                        <button
                                          className="btn ripple btn-success mlAction"
                                          onClick={() => {
                                            goToEdit(row);
                                          }}
                                        >
                                          Edit
                                        </button>
                                      ) : null}
                                      {hasPermission(
                                        "/admin/cms/default-email-template/delete"
                                      ) ? (
                                        <>
                                          <button
                                            className="btn ripple btn-secondary mlAction"
                                            onClick={() => {
                                              Deletefunction(row?._id);
                                            }}
                                          >
                                            Delete
                                          </button>
                                        </>
                                      ) : null}
                                      <div className="btn-group mlAction">
                                        <button
                                          type="button"
                                          className="btn btn-dark dropdown-toggle"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          More
                                        </button>
                                        <ul className="dropdown-menu">
                                          {hasPermission('/admin/staff/duplicate') ?
                                            <li>
                                              <button
                                                type="button"
                                                className="dropdown-item"
                                                onClick={() => goToDuplicate(row)}
                                              >
                                                Duplicate
                                              </button>
                                            </li>
                                            : null}
                                        </ul>
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={8} className="text-center">
                                No records
                              </td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
                <div
                  className=""
                  id="example1_info"
                  role="status"
                  aria-live="polite"
                >
                  <b>Total Records : {datalength ? datalength : "0"}</b>
                </div>
                {datalength && datalength > 0 ? (
                  <CustomPagination
                    datalength={datalength}
                    itemperpage={itemperpage}
                    currentPage={page}
                    setPage={setPage}
                    pageRoute={[
                      {
                        name: "name-badge-templates",
                        path: "/admin/room-management/list",
                      },
                    ]}
                  />
                ) : (
                  ""
                )}
              </>
              : null
            }
            {view === VIEWS.APPOINTMENT_MANAGEMENT ?
              <>
                <FullCalendar
                  ref={calendarRef}
                  plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, resourceTimelinePlugin]}
                  initialView="resourceTimelineDay"
                  dateClick={handleDateClick}
                  contentHeight={700}
                  editable={true}
                  events={calendarBookings}
                  dayMaxEventRows={true}
                  eventResizableFromStart={true}
                  eventDrop={handleBookingDrop}
                  eventResize={handleBookingResize}
                  resources={getResources(list)}
                  initialDate={calenderDate}
                  resourceAreaWidth={100}
                  resourceAreaHeaderContent="Rooms"
                  headerToolbar={CALENDER_TOP_TOOLBAR}
                  customButtons={CALENDER_CUSTOM_BUTTONS}
                  datesSet={handleDateChange}
                />
              </>
              : null
            }
            {view === VIEWS.CALENDAR ?
              <>
                <FullCalendar
                  ref={calendarRef}
                  plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin, resourceTimeGridPlugin]}
                  initialView="resourceTimeGridDay"
                  dateClick={handleDateClick}
                  contentHeight={1000}
                  content
                  editable={true}
                  // eventContent = {(e)=> <h3>{e.event.title}</h3>}
                  events={calendarBookings}
                  dayMaxEventRows={true}
                  eventResizableFromStart={true}
                  eventDrop={handleBookingDrop}
                  eventResize={handleBookingResize}
                  resources={getResources(list)}
                  initialDate={calenderDate}
                  resourceAreaWidth={100}
                  resourceAreaHeaderContent="Rooms"
                  headerToolbar={CALENDER_TOP_TOOLBAR}
                  customButtons={CALENDER_CUSTOM_BUTTONS}
                  datesSet={handleDateChange}
                />
              </>
              : null
            }
          </div>
        </div>
      </div>
      {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
      {selectedRecords.length ? (
        <div className="bulk_actions">
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <i
                className="fa fa-check-circle"
                aria-hidden="true"
                style={{ fontSize: "23px", color: "#35b159" }}
              ></i>
              <h6 className="ms-2 mt-1 mb-0">
                Selected {selectedRecords.length}{" "}
                {selectedRecords.length > 1 ? "records" : "record"} of{" "}
                {datalength}
              </h6>
            </div>
            <div>
              <ul>
                {hasPermission("/admin/cms/default-email-template/status") ? (
                  <li>
                    <button
                      className="btn ripple btn-main-primary signbtn"
                      onClick={() => {
                        ChangeStatus(selectedRecords, 1);
                      }}
                    >
                      Active
                    </button>
                  </li>
                ) : null}
                {hasPermission("/admin/cms/default-email-template/status") ? (
                  <li>
                    <button
                      className="btn ripple btn-secondary"
                      onClick={() => {
                        ChangeStatus(selectedRecords, 0);
                      }}
                    >
                      Inactive
                    </button>
                  </li>
                ) : null}
                <li>
                  {hasPermission("/admin/cms/default-email-template/delete") ? (
                    <>
                      <button
                        className="btn ripple btn-secondary"
                        onClick={() => {
                          Deletefunction(selectedRecords);
                        }}
                      >
                        Delete
                      </button>
                    </>
                  ) : null}
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/***********MULTI SELECTION OPTIONS <<<<<<*************/}
      {/* Change date modal >>>>>> */}
      <Modal open={Boolean(openDatePicker)} onClose={() => setOpenDatePicker(false)}>
        <Modal.Header>
          <Modal.Title>Select Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Calendar defaultValue={new Date(calenderDate)} compact bordered onChange={handleSelectDate} />
        </Modal.Body>
      </Modal>
      {/* Change date modal <<<<<< */}

      {/* Add booking modal >>>>>> */}
      <Modal backdrop="static" open={Boolean(addBookingData)} onClose={() => { setAddBookingData(null); setSelectedUser([]); }} size="full">
        <Modal.Header>
          <Modal.Title>Add New Booking for <b>{addBookingData?.resourceTitle}</b> on <b>{moment(addBookingData?.date).format("DD/MM/YYYY")}</b></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Formik
            enableReinitialize
            innerRef={formikRef}
            initialValues={{
              booking_title: "",
              start_date: "",
              end_date: "",
              user_id: ""
            }}
            validationSchema={Yup.object().shape({
              booking_title: Yup.string().required("Booking title is required").trim(),
              start_date: Yup.string().required("Start date is required").trim(),
              end_date: Yup.string().required("End date is required").trim(),
              user_id: Yup.string().required("Please select a user").trim(),
            })}
            onSubmit={(values) => {
              let formResult = childRef?.current?.submit();
              console.log("formResult",formResult);
              if (formResult && formResult?.isValid) {
                globalLoader(true);
                setTimeout(() => {
                  const formData = new FormData();
                  formData.append('start_date', values?.start_date);
                  formData.append('end_date', values?.end_date);
                  formData.append('title', values?.booking_title);
                  formData.append('user_id', values.user_id);
                  formData.append('room_id', addBookingData?.resourceId);
                  if (selectedUser && selectedUser.length > 0 && selectedUser != null) {
                    formData.append("invitees", JSON.stringify(selectedUser));
                  }

                  if (selectedManualUser && selectedManualUser.length > 0) {
                    let emails = selectedManualUser.map(user=>user.email)
                    formData.append("manualInvitees", JSON.stringify(emails));
                  }
                  let result = childRef?.current?.getValues();
                  let finalForm = prepareQuestionsFormData(result, formData, questionFilesUploadPath);
                  AddBooking(finalForm).then((response) => {
                    if (response?.success) {
                      Swal.fire({
                        icon: 'success',
                        text: response?.message,
                        ...SWAL_SETTINGS
                      });
                      const bookingData = {
                        _id: response?.data?.bookingId,
                        start_date: values?.start_date,
                        end_date: values?.end_date,
                        title: values?.booking_title,
                        room_id: addBookingData?.resourceId,
                      };
                      setCalendarBookings(prev => [...prev, ...getBookings([bookingData])]);
                      setAddBookingData(null);
                    } else {
                      Swal.fire({
                        icon: 'error',
                        text: response?.message,
                        ...SWAL_SETTINGS
                      });
                    }
                    globalLoader(false);
                  }).catch((error) => {
                    globalLoader(false);
                    Swal.fire({
                      icon: 'error',
                      text: error?.message,
                      ...SWAL_SETTINGS
                    });
                  });
                }, 200);
              } else {
                Swal.fire({
                  icon: 'error',
                  text: "Please fill all required information",
                  ...SWAL_SETTINGS
                })
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => (
              <div className="w-100 px-3">
                <form onSubmit={handleSubmit} >
                  <div className="row">
                    <div className="col-12">
                      <div className="dash-box-shadow space-b">
                        <div className="row add-coustomer">
                          <div className="form-group  mb-4 col-md-6">
                            <label htmlFor='booking_title' className='text-left d-flex'>Booking Title:<span className="requirestar">*</span> </label>
                            <div>
                              <input
                                name='booking_title'
                                id='booking_title'
                                type='text'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.booking_title}
                                className='form-control' />
                            </div>
                            <span className="text-danger d-flex text-left">
                              {errors.booking_title && touched.booking_title && errors.booking_title}
                            </span>
                          </div>
                          <div className="form-group  mb-4 col-md-6">
                            <label htmlFor='start_date' className='text-left d-flex'>Start Date:<span className="requirestar">*</span> </label>
                            <div>
                              <input
                                name='start_date'
                                id='start_date'
                                type='datetime-local'
                                onChange={(e) => { handleChange(e); setEndDate(e) }}
                                onBlur={handleBlur}
                                onClick={(e) => e.target.showPicker()}
                                value={values.start_date}
                                className='form-control' />
                            </div>
                            <span className="text-danger d-flex text-left">
                              {errors.start_date && touched.start_date && errors.start_date}
                            </span>
                          </div>
                          {/* <div className="form-group  mb-4 col-md-6">
                            <label htmlFor='end_date' className='text-left d-flex'>End Date:<span className="requirestar">*</span> </label>
                            <div>
                              <input
                                name='end_date'
                                id='end_date'
                                type='datetime-local'
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onClick={(e) => e.target.showPicker()}
                                value={values.end_date}
                                className='form-control' />
                              <span className="text-danger d-flex text-left">
                                {errors.end_date && touched.end_date && errors.end_date}
                              </span>
                            </div>
                          </div> */}
                          <div className="form-group  mb-4 col-md-6">
                            <label htmlFor='user_id' className='text-left d-flex'>Select user:<span className="requirestar">*</span> </label>
                            <div>
                              <SelectPicker data={userList} appearance="default" onChange={(e) => setFieldValue('user_id', e)} placeholder="Default" />
                              <span className="text-danger d-flex text-left">
                                {errors.user_id && touched.user_id && errors.user_id}
                              </span>
                            </div>
                          </div>
                          <div class="form-group mb-4 col-md-6">
                            <div class="invite-member-container">
                              <button class="btn ripple btn-main-primary signbtn cp" type="button" onClick={() => handleInvitation()}>Invite Member</button>
                              {showSelectedUsers && selectedUser?.length > 0 && (
                                <div class="selected-members">
                                  Total Selected Members: <span>({selectedUser?.length})</span>
                                </div>
                              )}

                              {selectedManualUser && selectedManualUser?.length > 0 && (
                                <div class="selected-members">
                                  Total Selected Manual Members: <span>({selectedManualUser?.length})</span>
                                </div>
                              )}
                            </div>
                          </div>

                          {selectedManualUser && selectedManualUser.length > 0 && (
                            <>
                              <h4>Manual Users</h4>
                              <div className="manual-invitees-chips mt-2 mb-2">
                                {selectedManualUser.map((user, index) => (
                                  <div key={index} className="chip">
                                    {user.email}
                                    <button className="close-btn" type="button" onClick={() => handleRemoveManualUser(user.email)}>
                                      &times;
                                    </button>
                                  </div>
                                ))}
                              </div>
                            </>
                          )}
                          {questionList.length > 0 ? (
                            <h4 className="mb-3">More info</h4>
                          ) : null}
                          <div className="form-group mb-4 col-md-12">
                            {/* {questionList.length > 0
                              ? */}
                              <div>
                                <DynamicQuestions ref={childRef} questions={questionList} showCount={false}
                                  questionTitleClass="" footer="" videoUploadPath={questionFilesUploadPath} />
                              </div>
                              {/* : null} */}
                          </div>
                          <div className="d-flex justify-content-start">
                            <button className="btn btn-info mr-2" onClick={() => childRef?.current?.submit()} type="submit"><i className="ace-icon fa fa-check bigger-110 mx-1"></i>Submit</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
      {/* Add booking modal <<<<<< */}
      <Modal size={"550"} open={InviteMemberModal} backdrop={'static'} onClose={() => { setInviteMemberModal(false) }}>
        <Modal.Header className="mb-3">
          <Modal.Title>Select members</Modal.Title>
          <div className="mt-4">
            <div className="d-flex align-items-center">
              <div className="form-group mb-0 me-3 rangepicker_container filter_design">
                <i className="fa fa-search calender_icon"></i>
                <input type="search"
                  className="form-control"
                  placeholder="Search"
                  value={globalsearchNews}
                  onChange={(e) => setGlobalSearchNews(e.target.value)}
                />
              </div>
              <div className="form-group mb-0 me-3 rangepicker_container filter_design">
                <CustomRangepicker
                  GetDateRange={(e) => {
                    prepareSearchNew("createdAt", e);
                  }}
                  resetdate={resetdate}
                />
              </div>
              <div className="form-group mb-0 me-3 filter_icon_container filter_design">
                <i className="fa fa-filter mr-2 filter_icon"></i>
                <div className="select-down-arrow">
                  <select className="form-control cp" onChange={handleFilterChange} id="resetFilterNew" style={{ width: "130px" }}>
                    <option value="">
                      Salutation
                    </option>
                    {fileType && fileType.length > 0 && fileType.map((option, i) => {
                      return (
                        <option key={i} value={option.value}>
                          {option.label}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="form-group mb-0 me-3 filter_icon_container filter_design" style={{ width: "110px" }}>
                <StatusFilter
                  data={showFilterlist}
                  prepareSearch={prepareSearchNew}
                />
              </div>
              <button
                type="reset"
                value="Reset"
                onClick={resetFilterNew}
                className="btn btn-warning float-right mr-2"
              >
                Reset Filter
              </button>
              <button
                type="button"
                value="upload_excel"
                className="btn btn-success float-right mr-2"
                onClick={() => {
                  setExcelModal(true);
                  // setInviteMemberModal(false)
                }}
              >
                Invite Manually
              </button>
            </div>
          </div>
        </Modal.Header>
        <div className="clearfix"></div>
        <div className="w-100">
          <table className="table table-hover table-bordered border-t0 key-buttons text-nowrap w-100">
            <thead>
              <tr>
                <th className="position-relative select_head">
                  <div className="select-check-container">
                    <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }}
                      className="ckbox  cp">
                      <input
                        ref={selectAllCheckbox}
                        id="selectAllCheck"
                        checked={
                          userdatalength && userdatalength === selectedUser.length
                        }
                        onChange={(e) => handleSelect(e.target.checked)} type="checkbox" />
                      <span></span>
                    </label>
                  </div>
                </th>
                <th>Name</th>
                <th>Email</th>
                <th>Salutation</th>
                <th>Status</th>
                <th>Created Date</th>
              </tr>
            </thead>
            <tbody>
              {loader ?
                <tr>
                  <td colSpan={8}><Loader /></td>
                </tr> : <>
                  {userData.length ? userData.map((item, i) => {
                    return <tr
                      key={i}
                      onClick={() => selectRow(item)}
                      className={isSelectedNew(item) ? "selected" : ""}
                    >
                      <td>
                        <label htmlFor={item?._id + "input"} style={{ lineHeight: 'unset' }}
                          className="ckbox cp">
                          <input
                            id={item?._id + "input"}
                            checked={isSelectedNew(item)}
                            onChange={(e) =>
                              selectUser(item, e?.target?.checked)
                            }
                            type="checkbox"
                            className="form-check select-check cp" />
                          <span></span>
                        </label>
                      </td>
                      <td className="text-capitalize">{item?.name ? TrimText(item.name, 20) : "N/A"}</td>
                      <td>{item?.email ? TrimText(item.email, 20) : "N/A"}</td>
                      <td className="text-capitalize">{item?.gender}</td>
                      <td>
                        {item.status === 1 ? (
                          <button
                            className="badge badge-success"
                          >
                            Active
                          </button>
                        ) : (
                          <button
                            className="badge badge-danger"

                          >
                            Inactive
                          </button>
                        )}
                      </td>

                      <td>
                        {item.createdAt
                          ? formateDate(item.createdAt)
                          : "N/A"}
                      </td>
                    </tr>
                  }) : <tr><td colSpan={8} className="text-center">No records</td></tr>}</>}
            </tbody>
          </table>
        </div>
        <div
          className=""
          id="example1_info"
          role="status"
          aria-live="polite"
        >
          <b>Total Records : {userdatalength ? userdatalength : "0"}</b>
        </div>
        {userdatalength && userdatalength > 0 ? (
          <CustomPagination
            datalength={userdatalength}
            itemperpage={itemPerPageModal}
            currentPage={dataPageNo}
            setPage={setDataPageNo}
            pageRoute={[
              { name: "Room Management", path: "/admin/room-management/list" },
            ]}
            modalPagination={'modalPagination'}
          />
        ) : (
          ""
        )}
        <div className="d-flex justify-content-between align-items-center mt-5">
          <h6 className="ms-2 mt-1 mb-0">{selectedUser.length ? "Selected " + selectedUser.length + " of records " + userdatalength : ""}</h6>
          <div>
            {/* <Button onClose={() => { setInviteMemberModal(false)}} appearance="subtle">
                  Cancel
                </Button> */}
            <Button
              disabled={selectedUser.length === 0}
              onClick={handleNextButtonClick}
              appearance="primary"
            >
              Next
            </Button>
          </div>
        </div>
      </Modal>


      <Modal size={"lg"} open={excelModal} backdrop={'static'} onClose={() => { setExcelModal(false) }}>
        <Modal.Header className="mb-3">
          <Modal.Title>Select Manual members</Modal.Title>
        </Modal.Header>
        <div>
          <Steps current={step}>
            <Steps.Item title="Upload File" />
            <Steps.Item title="Preview" />
            <Steps.Item title="Success" />
          </Steps>
          <div>{modalData(step)}</div>
        </div>
      </Modal>
    </>
  );
};

export default RoomManagementTable;
