import React from 'react'

export default function GlobalLoader() {
    return (
        <>
            <div id="global-loader">
                <div className="text-center">
                    <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </>
    )
}
