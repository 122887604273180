import React, { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import * as surveyServices from '../../services/survey.service';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {formatDateFromTime, globalLoader, handleServerValidations, prepareQuestionsFormData } from '../../../../utils/commonfunction';
import 'react-loading-skeleton/dist/skeleton.css';
import { Rating } from 'react-simple-star-rating'
import DynamicQuestions from '../../shared/DynamicQuestions';
import {SWAL_SETTINGS} from '../../../../utils/Constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const SurveyQuestions = (props) => {
    const childRef = useRef();
    const params = useParams();
    const navigate = useNavigate();
    const [surveyQuestions, setSurveyQuestions] = useState([]);
    const location = useLocation();
    const title = location?.state?.title ? location.state.title : "N/A";
    const description = location?.state?.description || "N/A";
    const createdAt = location?.state?.createdAt ? location.state.createdAt : "N/A";
    const [questionFilesUploadPath] = useState("survey");
    const {t} =  useTranslation();
    const myGlobalData = useSelector((state) => state.globalData);
    useEffect(() => {
      if(myGlobalData && myGlobalData?.data){
        const settingsData = JSON.parse(myGlobalData?.data);
        if(!settingsData?.settings?.surveys){
            navigate("/404");
        }
      }
    },[myGlobalData]);
    useEffect(() => {
        const formData = new FormData();
        formData.append('question_set_id', params.id);
        surveyServices.GetQuestions(formData)
            .then((response) => {
                setSurveyQuestions(response?.data?.list || []);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [params.id]);

    const handleSubmit = async () => {
        try {
            const formResult = childRef?.current?.submit();
            if(formResult && formResult?.isValid){
                globalLoader(true);
                const formData = new FormData();
                formData.append('survey_id', params.id);
                let result = childRef?.current?.getValues();
                let finalForm = prepareQuestionsFormData(result, formData, questionFilesUploadPath);
                surveyServices.SaveSurveyQuestions(finalForm).then((response)=>{
                    globalLoader(false);
                    if (response.success) {
                        navigate('/survey/thanks',{state:{title, createdAt:new Date()}});
                    }else{
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                });
            }else{
                Swal.fire({
                    icon: 'error',
                    text: "Please fill all required information",
                    ...SWAL_SETTINGS
                })
            }
        } catch (error) {
            console.error('Error saving survey questions:', error);
            // Handle the error as needed
        }
    };

    return (
        <div className="wraper-inner cpt bg-grey">
            <section className="inner-space">
                <div className="container">
                    <nav className="breadcrumb text-center mb-4">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">{t('web_header_heading_home')}</Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/survey/1">{t('web_header_heading_survey')}</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                                {title}
                            </li>
                        </ol>
                    </nav>
                    <div className="dash-box-shadow">
                        <div className="card-questions-box">
                            <h3 className="inner-title">{title}</h3>
                            <div className="date-info">
                                <i className="ri-calendar-event-line"></i>{' '}
                                {formatDateFromTime(createdAt)}
                            </div>
                            <p dangerouslySetInnerHTML={{ __html: decodeURIComponent(description) }} />
                        </div>
                        <hr className="divider" />
                        {surveyQuestions.length > 0 ? <small className="s-tag">{t('web_lbl_question')} </small> : (
                            null
                        )}
                        {surveyQuestions.length > 0 ? (
                            <div>
                                <DynamicQuestions ref={childRef} questions={surveyQuestions} showCount={true}
                                questionTitleClass="survey-questions-titles" footer="<hr class='divider'>" videoUploadPath={questionFilesUploadPath}/>
                            </div>
                        ) : (
                            <p className="text-center">{t('web_lbl_no_question')}</p>
                        )}
                        {surveyQuestions.length > 0 && (
                            <div>
                                <button className="btn btn-primary mt-4" onClick={handleSubmit} >
                                {t('web_lbl_submit_survey')}
                                </button>
                                {/* {validationError && (
                                    <div className="error-message requirestar">{validationError}</div>
                                )} */}
                            </div>

                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SurveyQuestions;