import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { globalLoader, handleServerValidations } from "../../../utils/commonfunction";
import {
  SWAL_SETTINGS,
} from "../../../utils/Constants";
import * as otherService from "../services/webother.service";
import * as commonServices from "../services/common.services";
import { formateDate } from '../../../utils/commonfunction';
import CustomPagination from "../../admin/common/custompagination";
import { useTranslation } from 'react-i18next';
import FrontLoader from "../../globalComponents/FrontLoader";

const EventRegistrationList = () => {
  const params = useParams();
  const [events, setEvents] = useState([]);
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(9);
  const [page, setPage] = useState(1);
  const [cancellationSuccess, setCancellationSuccess] = useState(false);
  const { t } = useTranslation();
  const [loader,setLoader] = useState(true);


  useEffect(() => {
    // globalLoader(true);
    setLoader(true);
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", itemperpage);
    otherService.eventRegistrationList(formData)
      .then((res) => {
        if (res && res?.success) {
          setEvents(res?.data?.list);
          setDataLength(res?.data?.total_records);
          setPage(res && res?.data && res?.data?.page ? res?.data?.page : 1);
          // globalLoader(false);
          setLoader(false);
        } else {
          console.log("error");
          // globalLoader(false);
          setLoader(false);
        }
      })
      .catch((err) => console.log("err", err));
  }, [page, cancellationSuccess,itemperpage,params.pgno]);

  const handleCancelClick = async (event) => {
    const values = {
      _id: event._id,
    };
    // const confirmed = window.confirm('Do you want to cancel this registration?');
    Swal.fire({
        customClass: 'swal-wide',
        title: t('web_swal_are_you_sure'),
        text: t('web_swal_cancel_registration'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: "#66cc33",
        cancelButtonColor: "#6e7881",
        confirmButtonText: t('web_swal_yes'),
        cancelButtonText: t('web_swal_no')
    }).then((result) => {
          if (result.isConfirmed) {
            try {
              otherService.eventRegistrationCancel(values).then((response)=>{
      
                setCancellationSuccess(() => !cancellationSuccess);
                if (response.success) {
                  const updatedEvents = events.map(e => {
                    if (e._id === event._id) {
                      e.status = 2;
                    }
                    return e;
                  });
                  setEvents(updatedEvents);
                  Swal.fire({
                    icon: "success",
                    text: response.message,
                    ...SWAL_SETTINGS,
                  });
                }else {
                  Swal.fire({
                    icon: "error",
                    text: handleServerValidations(response),
                    ...SWAL_SETTINGS,
                  });
                }
              })
            } catch (error) {
              console.error('An error occurred while cancelling the registration', error);
              window.alert('An error occurred while cancelling the registration');
            }
          }
    })
  };

  const payAmountFunction = (amount, registration_id, event_id) => {
    globalLoader(true);
    commonServices.createPayment({
      amount: amount,
      registration_id: registration_id,
      event_id: event_id
    }).then((response) => {
      if(response && response?.success){
        window.location.href=response?.data?.paymentUrl;
        globalLoader(false);
      }else{
        globalLoader(false);
      }
    }).catch((err) => {
      console.log("err",err);
      globalLoader(false);
    })
  }

  const statusLabels = {
    0: (t('web_lbl_de_active')),
    1: (t('web_lbl_active')),
  };
  return (
    <div className="dash-main-R">
      <div className="row">
        <div className="col-md-9 m-auto">
        <h3 className="dash-title">{t('web_heading_event_registration')}</h3>
          <div className="dash-box-shadow" style={{ minHeight:"100px" }}>
              <div className="row">
                {loader ? 
                <FrontLoader/>
              :<>
              {events?.length ? (
                events.map((event, index) => (
                  <div className="col-md-4 my-2" >
                    <div className="grid-box">
                        <h3>{event?.event_data?.title}</h3>
                      <div className="date-info">
                        <i className="ri-calendar-event-line"></i> 
                        {formateDate(event?.start_date,false)}{' - '}{formateDate(event?.end_date,false)}
                      </div>
                      <p>{statusLabels[event?.status]}</p>
                      {event?.payable && event?.payable_amount > 0 ? <p className="text-danger">{t('web_lbl_payment_pending')}</p> : ""}
                      <div className="action-tag mt-4 flex-wrap">
                        {event && event?.status !== 2 && (
                          <>
                            <a className="btn-s danger-btn cp mb-2" href="/" onClick={(e) => {e.preventDefault(); handleCancelClick(event)}}>
                            {t('web_lbl_button_cancel')}
                            </a>
                            <Link to={`/event-registration?event_id=${event?.event_data?.slug}&invitation_id=${event?.invitation_data?._id}&reg_id=${event?._id}&edit=true`} className="btn-s org-btn cp mb-2">{t('web_lbl_button_update')}</Link>
                          </>
                        )}
                        <Link to={`/settings/event-registrations/view/${event?._id}`} className="btn-s green-btn cp mb-2">{t('web_lbl_button_view')}</Link>
                        {event?.payable && event?.payable_amount > 0 ? (
                          <a style={{ whiteSpace: 'nowrap' }} href="/" className="btn-s danger-btn cp" onClick={(e) => {
                              e.preventDefault();
                              payAmountFunction(event?.payable_amount, event?._id, event?.event_data?._id);
                          }}>{t('web_lbl_button_pay_now')}</a>
                        ) : null}

                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-md-12 my-2">
                  <div className="text-center">
                  {t('web_lbl_no_records')}
                  </div>
                </div>
              )}
              </>
                }
              </div>
            {datalength && datalength > 0 ?
            <div className="mt-4 text-right pagination-main d-flex justify-content-end  pe-4">
            <CustomPagination datalength={datalength} itemperpage={itemperpage} currentPage={page} setPage={setPage} pageRoute={[{ name: "EventsRegistration", path: "/settings/event-registrations/list" }]} /> 
            </div>
            : ""}
          </div>
        </div>
      </div>
    </div >
  );
};

export default EventRegistrationList;