import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrums from "../../common/breadcrumbs";
import Loader from "../../common/loader";
import { formateDate, formateDateWithMonth, showStatus } from "../../../../utils/commonfunction";
import toHtml from "html-react-parser";
import { RoomsDetails } from "../../services/roommanagement.services";

const RoomManagementView = (props) => {
  const navigate = useNavigate();
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});

  useEffect(() => {
    RoomsDetails(params.id)
      .then((response) => {
        setShowDefault(response && response.data ? response.data : []);
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, []);

  return (
    <>
      {showdefault && Object.keys(showdefault).length > 0 ? (
        <div className="row">
          <div className="col-lg-12 form-group">
            <table
              id="simple-table"
              className="table  table-bordered table-hover"
            >
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>
                    {showdefault && showdefault.name ? showdefault.name : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Question Set</th>
                  <td>
                    {showdefault && showdefault?.questions_data?.title
                      ? showdefault?.questions_data?.title
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Capacity</th>
                  <td>
                    {showdefault && showdefault?.capacity
                      ? showdefault?.capacity
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Bookable Interval</th>
                  <td>
                    {showdefault && showdefault?.bookable_interval
                      ? showdefault?.bookable_interval
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Event Name</th>
                  <td>
                    {showdefault && showdefault?.event_data?.title
                      ? showdefault?.event_data?.title
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Start Date</th>
                  <td>
                    {showdefault && showdefault.start_date
                      ? formateDateWithMonth(showdefault.start_date)
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>End Date</th>
                  <td>
                    {showdefault && showdefault.end_date
                      ? formateDateWithMonth(showdefault.end_date)
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Created Date</th>
                  <td>
                    {showdefault && showdefault.createdAt
                      ? formateDate(showdefault.createdAt)
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <th>Modified Date</th>
                  <td>
                    {showdefault && showdefault.updatedAt
                      ? formateDate(showdefault.updatedAt)
                      : "N/A"}
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          showdefault && showdefault.description
                            ? showdefault.description
                            : "N/A",
                      }}
                    ></div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default RoomManagementView;
