import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {Loader } from 'rsuite';
import { formateDate,  showStatus } from '../../../../../utils/commonfunction';
import Breadcrums from '../../../common/breadcrumbs';
import { DistributionDetails,  DistributionUsersList } from '../../../services/distributionlist.services';
import toHtml from 'html-react-parser'
import CustomPagination from '../../../common/custompagination';



const DistributionListView = (props) => {
    const navigate = useNavigate();
    const params = useParams()
    const [showdefault, setShowDefault] = useState({})
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Distribution List", url: "/admin/distribution-list/list/1" }, { title: "View", url: "" },]
    const [loader, setLoader] = useState(true)
    const [datalength, setDataLength] = useState("")
    const [itemperpage] = useState(10)
    const [page, setPage] = useState(1);
    const [usersData, setUsersData] = useState([]);
    const [query] = useSearchParams();
    const [statsupdate] = useState(false);


    useEffect(() => {
        DistributionDetails(params.id).then(response => {
            setShowDefault(response && response.data ? response.data : [])
        }).catch(error => {
            console.log("error=====>", error)
        })
    }, [params.id])

    useEffect(() => {
        setLoader(true)
        const formData = new FormData()
        formData.append("page", query.get('page'))
        formData.append("per_page", itemperpage)
        formData.append("distribution_id", params.id);
        DistributionUsersList(formData).then(data => {
            setUsersData(data && data?.data && data?.data.list ? data.data.list : []);
            setDataLength(data.data.total_records)
            setPage(data && data.data && data.data.page ? data.data.page : 1);
            setLoader(false)
        }).catch(error => {
            console.log("Error:", error);
        });
    }, [page, itemperpage, statsupdate, params.id]);



    return (
        <>
            <Breadcrums data={breadcrumbs} />
            <div className="row row-sm">
                <div className="col-lg-12 col-md-12 animation_fade">
                    <div className="card custom-card">
                        <div className="card-body">
                            <div>
                                <h6 className="main-content-label mb-3">View Distribution List</h6>
                            </div>
                            {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                                <div className="col-lg-12 form-group">
                                    <table id="simple-table" className="table  table-bordered table-hover">
                                        <tbody>

                                            <tr>
                                                <th> Title</th>
                                                <td>{showdefault && showdefault?.title ? showdefault.title : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Created By</th>

                                                <td>{showdefault && showdefault?.createdBy?.name ? <Link to={`/admin/staff/view/${showdefault.createdBy._id}`}>{showdefault.createdBy.name}</Link> : "N/A"}</td>
                                            </tr>
                                            <tr>
                                                <th>Status</th>
                                                <td>{showdefault && showdefault?.status === 0 ? toHtml(showStatus(showdefault.status)) : toHtml(showStatus(showdefault.status))}</td>
                                            </tr>
                                            <tr>
                                                <th>Created Date</th>
                                                <td>{showdefault && showdefault?.createdAt ? formateDate(showdefault.createdAt) : "N/A"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div> : <Loader />}
                        </div>
                        <div className="">
                            <div className="card custom-card">
                                <div className="card-body">
                                    <div className='d-flex justify-content-between align-items-center mb-4'>
                                        <h6 className="main-content-label">Selected Users Details</h6>
                                    </div>
                                    <div className="table-responsive position-relative">
                                        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Created By</th>
                                                    <th>Created Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {loader ?
                                                    <tr>
                                                        <td colSpan={8}><Loader /></td>
                                                    </tr> : <>
                                                        {usersData.length ? usersData.map((data, i) => {
                                                            return <tr key={i}>
                                                                <td>{data?.user_data?.name ? <Link to={`/admin/user-management/users/view/${data?.user_data?._id}?page=1&chat_page=1`}>{data?.user_data.name}</Link> : "N/A"}</td>
                                                                <td>{data?.user_data?.email ? <Link to={`/admin/user-management/users/view/${data?.user_data?._id}?page=1&chat_page=1`}>{data?.user_data.email}</Link> : "N/A"}</td>
                                                                <td>{data?.createdBy?.name ? <Link to={`/admin/staff/view/${data.createdBy._id}`}>{data.createdBy.name}</Link> : "N/A"}</td>
                                                                <td>{data?.createdAt ? formateDate(data.createdAt) : "N/A"}</td>
                                                            </tr>
                                                        }) : <tr><td colSpan={8} className="text-center">No records</td></tr>}
                                                    </>}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                                    {datalength && datalength > 0 ?
                                        <CustomPagination datalength={datalength} paramName={'page'} itemperpage={itemperpage} currentPage={page} setPage={setPage} query={"true"} pageRoute={[{ name: "Distribution List", path: `/admin/distribution-list/view/${params.id}` }]} /> : ""}
                                </div>
                            </div>
                        </div>
                        <div className="mt-5 back-button">
                            <button className="btn ripple btn-dark" type='button' onClick={() => navigate(`/admin/distribution-list/list/1`)}>
                                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                                Back
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DistributionListView;