import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import bannerImage from "../../../assets/user/images/banner-card.png";
import { useTranslation } from "react-i18next";
import {
  SpecialCharacter,
  TrimText,
  capitalizeFirstLetterOfEachWord,
  formateDateWithMonth,
  getSessionKey,
  removeSessionKey,
  setSessionKey,
} from "../../../utils/commonfunction";
import $ from "jquery";
import CustomPagination from "../../admin/common/custompagination";
import { EventList } from "../services/events.services";
import { EVENT_TYPES } from "../../../utils/Constants";

const UserEvents = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [events, setEvents] = useState([]);
  const [itemperpage] = useState(6);
  const [page, setPage] = useState(1);
  const [datalength, setDataLength] = useState("");
  const { t } = useTranslation();
  const [globalsearch, setGlobalSearch] = useState("");
  const [dateFilterValue, setDateFilterValue] = useState();
  const [search, setSearch] = useState({});
  const ChooseDateTrigger = useRef();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();


  useEffect(() => {
    document.addEventListener("updateActivityHeight", (e) => {
      let id = e.detail.id;
      let height = e.detail.height;
      let index = e.detail.index;
      if (!getSessionKey(id) || getSessionKey(id) !== height) {
        setSessionKey(id, height);
        let element = document.getElementById(
          "showMoreContainerbutton" + index
        );
        if (element) {
          removeSessionKey(id);
          if (height > 100) {
            element.classList.remove("d-none");
          }
        }
      }
    });
    return () => {
      for (let i = 0; i <= 20; i++) {
        removeSessionKey("showMoreContainer" + i);
      }
    };
  }, []);

  useEffect(() => {
    const formData = new FormData();
    formData.append("page", params.pgno);
    formData.append("per_page", itemperpage);
    formData.append("global_search", globalsearch);
    formData.append("search", JSON.stringify(search));
    EventList(formData)
      .then((response) => {
        setEvents(
          response && response.data && response.data.list
            ? response.data.list
            : []
        );
        setDataLength(response?.data?.total_records);
        setPage(
          response && response?.data && response?.data?.page
            ? response?.data?.page
            : 1
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, [itemperpage, params.pgno, globalsearch, search]);

  const handleShowMore = (e, id) => {
    if (e.target.classList.contains("active")) {
      e.target.classList.remove("active");
      document.getElementById(id).classList.add("max-100");
      e.target.innerText = "Show More";
    } else {
      e.target.classList.add("active");
      document.getElementById(id).classList.remove("max-100");
      e.target.innerText = "Show Less";
    }
  };

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    setDateFilterValue("");
    setSearch({});
    $("#eventType")[0].selectedIndex = 0;
  };

  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };

  const handleClick = () => {
    ChooseDateTrigger.current.click();
  };

  const handleCloseModal = () => {
      const closeButton = document.getElementById('close_button');
      closeButton.click();
      setFromDate('');
      setToDate('');
  };
  
  const handleDateChange = (e, type) => {
    const value = e.target.value;
    if (type === 'from') {
        setFromDate(value);
        if(toDate){
            handleCloseModal();
            prepareSearch('event_date', `${value}-${toDate}`);
        }
    } else if (type === 'to') {
      setToDate(value);
      if(fromDate){
        handleCloseModal();
        prepareSearch('event_date', `${fromDate}-${value}`);
    }
    }
  };

  return (
    <>
      <div className="wraper-inner cpt bg-grey">
        <section className="banner-card mt-4">
          <div className="container">
            <div
              className="banner-card-content text-center"
              style={{ backgroundImage: `url(${bannerImage})` }}
            >
              <h1>{t('web_lbl_text_event')}</h1>
              <nav className="breadcrumb text-center">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to={`/`}>{t("web_header_heading_home")}</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                  {t('web_lbl_text_event')}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </section>
        <section className="forums-listing grid-box-main mt-5 pb-5">
          <div className="container">
            <div className="user-box-head d-flex justify-content-between mb-4">
              <h3 className="inner-title mb-0"></h3>
              <div className="user-right filter-responsive d-flex align-items-center">
                <div className="search-box ms-1">
                  <input
                    type="text"
                    name=""
                    className="form-control"
                    placeholder={t('web_lbl_search')}
                    value={globalsearch}
                    onChange={(e) => {
                      setGlobalSearch(e.target.value);
                    }}
                  />
                  <button className="btn-search">
                    <i className="ti ti-search"></i>
                  </button>
                </div>
                 <div className="form-group ms-1 cp filters-free-events">
                  <input
                    className="form-control cp"
                    type="text"
                    onClick={() => handleClick()}
                    value={search.event_date ? search.event_date : ''}
                    placeholder={t('web_lbl_from_to')}
                    style={{ width: "200px" }}
                  ></input>
                </div>
                <div className="search-box ms-1">
                  <select
                    className="form-select cp"
                    onChange={(e) => {
                      prepareSearch("event_type", e.target.value);
                    }}
                    id="eventType"
                  >
                    <option value="">{t('web_lbl_event_type')}</option>
                    { Object.values(EVENT_TYPES).filter(option => option !== EVENT_TYPES.INVITATION_ONLY).map((option, i) => {
                        return (
                          <option key={i} value={option}>
                            {SpecialCharacter(
                              capitalizeFirstLetterOfEachWord(option)
                            )}
                          </option>
                        );
                      })}
                  </select>
                  <button className="btn-search"></button>
                </div>
                <button
                  className="btn btn-warning ms-1 filters-free-events"
                  onClick={(e) => {
                    resetFilter(e);
                  }}
                >
                  {t("web_lbl_reset_filter")}
                </button>
              </div>
            </div>
            <div className="row mt-2">
              {events && events?.length > 0
                ? events.map((data, i) => {
                    return (
                      <div className="col-md-4" key={i}>
                        <div className="grid_back h-100">
                          <div className="grid-box d-flex flex-column justify-content-between align-items-start">
                            <div className="w-100">
                              <a
                                href={`/open-registration/event/details/${data?.slug}`}
                              >
                                <h3 className="text-capitalize">
                                  {data?.title ? TrimText(data?.title) : null}
                                </h3>
                              </a>
                              {data?.event_quota?.price > 0 ? (
                                <span className="mb-2 price-text">
                                  <span>
                                    {data?.currency?.sign}
                                    {data?.event_quota?.price}
                                  </span>
                                </span>
                              ) : (
                                <span className="mb-2 price-text text-success">
                                  {t('web_lbl_span')}
                                </span>
                              )}
                              <img
                                src={data?.image}
                                className="rounded mb-3 event-img"
                              ></img>
                              <div className="date-info">
                                <i className="ri-calendar-event-line"></i>
                                {formateDateWithMonth(data?.start_date) +
                                  " - " +
                                  formateDateWithMonth(data?.end_date)}
                              </div>
                              <div className="flag_event_type">
                                {data?.event_type
                                  ? SpecialCharacter(
                                      capitalizeFirstLetterOfEachWord(
                                        data?.event_type
                                      )
                                    )
                                  : null}
                              </div>
                              <div
                                className="surveyPage max-100"
                                id={"showMoreContainer" + i}
                                ref={(el) => {
                                  if (el) {
                                    const evt = new CustomEvent(
                                      "updateActivityHeight",
                                      {
                                        detail: {
                                          id: "showMoreContainer" + i,
                                          index: i,
                                          height: el.scrollHeight,
                                        },
                                      }
                                    );
                                    document.dispatchEvent(evt);
                                  }
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: data?.description,
                                }}
                              ></div>
                              {/* Show more integration */}
                              <div
                                className="d-flex justify-content-end w-100 d-none mt-2"
                                id={"showMoreContainerbutton" + i}
                              >
                                <button
                                  type="button"
                                  className="show-more"
                                  onClick={(e) => {
                                    handleShowMore(e, "showMoreContainer" + i);
                                  }}
                                >
                                  Show More
                                </button>
                              </div>
                            </div>
                            <button
                              className="btn btn-primary mt-2"
                              onClick={() =>
                                navigate(
                                  `/open-registration/event/details/${data?.slug}`
                                )
                              }
                            >
                              {t('web_lbl_button_view')}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : 
                <h2 style={{ textAlign: "center", fontSize: "25px" }}>
							{t('web_lbl_no_records')}
						</h2>
                }
            </div>
          </div>
          <div id="div_class">
            {datalength && datalength > 0 ? (
              <div className="mt-4 text-right pagination-main d-flex justify-content-end  pe-4">
                <CustomPagination
                  datalength={datalength}
                  itemperpage={itemperpage}
                  currentPage={page}
                  setPage={setPage}
                  pageRoute={[
                    { name: "Events", path: "/open-registration/events/list" },
                  ]}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </section>
      </div>
    <a className="d-none" ref={ChooseDateTrigger} data-bs-toggle="modal" href="#ChoosePayment" role="button"><span></span></a>
      <div className="modal fade" id="ChoosePayment" aria-hidden="true" data-bs-backdrop="static" aria-labelledby="ChoosePaymentLabel" tabIndex="-1">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <span className="announcements-title" id="ChoosePaymentLabel">Select Date</span>
              <button type="button" className="btn-close" id="close_button" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{handleCloseModal()}}></button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <div className="row">
                  <div className="col">
                    <label htmlFor="fromDate" className="form-label">From:</label>
                    <input
                      id="fromDate"
                      className="form-control"
                      type="date"
                      value={fromDate}
                      onChange={(e) => handleDateChange(e, 'from')}
                      style={{ width: "100%" }}
                    />
                  </div>
                  <div className="col">
                    <label htmlFor="toDate" className="form-label">To:</label>
                    <input
                      id="toDate"
                      className="form-control"
                      type="date"
                      value={toDate}
                      onChange={(e) => handleDateChange(e, 'to')}
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEvents;
