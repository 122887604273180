import React, { useEffect, useState, useRef } from "react";
import { ErrorMessage, Field, Formik } from "formik";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";
import Breadcrums from "../../common/breadcrumbs";
import { GENDER, SWAL_SETTINGS } from "../../../../utils/Constants";
import {
  blockInvalidChar,
  capitalizeFirstLetter,
  getNameFromList,
  globalLoader,
  handleServerValidations,
} from "../../../../utils/commonfunction";
import * as Yup from "yup";
import { getCountryCallingCode } from "react-phone-number-input";
import ReactCountryFlag from "react-country-flag";
import { getCountries } from "react-phone-number-input";
import { Details, Edit, Options } from "../../services/leadManagement.services";
import * as eventsService from "../../services/events.services"
import * as questionService from "../../services/questions"
import { Rating } from "react-simple-star-rating";
import Drawing from "../../../../utils/Drawing";

const LeadManagementEdit = (props) => {
  const canvasRef = useRef(null);
  const canvasRef1 = useRef(null);
  const navigate = useNavigate();
  const params = useParams();
  const [showdefault, setShowDefault] = useState({});
  const [saveType, setSaveType] = useState("");
  const [leadOptions, setLeadOptions] = useState([]);
  const [validationError, setValidationError] = useState("");
  const [eventId, setEventId] = useState(null);
  const [questionSetId, setQuestionSetId] = useState(null);
  const [showDrawingCanvas, setShowDrawingCanvas] = useState(false);
  const [showDrawingCanvasForFreeHand, setShowDrawingCanvasForFreeHand] = useState(false);
  const [previewImages, setPreviewImages] = useState([])
  const [questionList, setQuestionList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [questionAnswerList, setQuestionAnswerList] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "Lead Management", url: "/admin/lead-management/list/1" },
    { title: "Edit", url: "" },
  ];


  const findAnswerIndex = (answers, type) => {
    return answers.findIndex(item => item.question_type === type);
  }
  useEffect(() => {
    Details(params.id)
      .then((response) => {
        setShowDefault(response && response.data ? response.data : []);
        setEventId(response && response.data && response.data?.event_id ? response.data?.event_id : null)
        setAnswerList(response && response.data && response.data.questions_data);
        let uploadedPhotoQuestionIndex = findAnswerIndex(response.data.questions_data, 'photo_upload')
        if (uploadedPhotoQuestionIndex !== -1) {
          // setPreviewImages(response.data.questions_data[uploadedPhotoQuestionIndex]?.file_url ? response.data.questions_data[uploadedPhotoQuestionIndex]?.file_url : [])
          setPreviewImages(response.data.questions_data[uploadedPhotoQuestionIndex]?.answer ? response.data.questions_data[uploadedPhotoQuestionIndex]?.answer : [])
        }
      })
      .catch((error) => {
        console.log("error=====>", error);
      });
  }, [params.id]);

  useEffect(() => {
    Options().then((response) => {
      if (response.success) {
        setLeadOptions(response?.data);
      }
    }).catch((error) => {
      console.log("error", error)
    })
  }, [])


  useEffect(() => {
    if (eventId) {
      eventsService.Details(eventId)
        .then((response) => {
          setQuestionSetId(response?.data?.trade_fair_questionnaire);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
    }
  }, [eventId])

  useEffect(() => {
    if (questionSetId) {
      questionService.allQuestions({ question_set_id: questionSetId }).then(response => {
        setQuestionList(response && response.data ? response.data : []);
      })
        .catch(error => {
          console.log("Error:", error);
        });
    }
  }, [questionSetId]);

  useEffect(() => {
    if (answerList && answerList?.length > 0 && questionList && questionList?.length > 0) {
      const updatedQuestionList = questionList.map(question => {
        const correspondingQuestion = answerList.find(q => q.question_id === question._id);
        if (correspondingQuestion) {
          question.answer = correspondingQuestion.answer;
          if (correspondingQuestion.file_url) {
            if (Array.isArray(question.answer)) {
              question.answer = question.answer.map((_, index) => correspondingQuestion.answer[index]);
            } else {
              question.file_url = correspondingQuestion.file_url;
            }
          }
        }
        return question;
      });

      setQuestionAnswerList(updatedQuestionList)
    }
  }, [questionList, answerList]);

  const showDropDown = (country) => {
    return (
      <label>
        {"   " + getNameFromList(country)} {" +"}
        {getCountryCallingCode(country)}
      </label>
    );
  };

  const validationSchema = Yup.object().shape({
    gender: Yup.string()
      .oneOf(Object.values(GENDER), "Invalid salutation selection")
      .required("Salutation is required"),
    first_name: Yup.string()
      .required("First name is required")
      .test("no-spaces", "First name is required", (value) => value.trim()),
    middle_name: Yup.string(),
    last_name: Yup.string()
      .required("Last name is required")
      .test("no-spaces", "Last name is required", (value) => value.trim()),
    email: Yup.string().email().required("Email is required"),
    phone_number: Yup.string()
      .trim()
      .required("Phone number is required")
      .test(
        "min-length",
        "Phone number minimum length should be 4 digits",
        (value) => {
          if (value?.trim()?.length < 5) {
            return false;
          } else {
            return true;
          }
        }
      )
      .test(
        "max-length",
        "Phone number cannot be more than 13 digits",
        (value) => {
          if (value?.trim()?.length > 13) {
            return false;
          } else {
            return true;
          }
        }
      ),
    manager_id: Yup.string()
      .required("Manager id is required"),
  });

  /** Voice Recording */

  const [recordings, setRecordings] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const [recordingTimer, setRecordingTimer] = useState(0);
  const timerIntervalRef = useRef(null);

  useEffect(() => {
    if (isRecording) {
      startTimer();
    } else {
      stopTimer();
    }
  }, [isRecording]);

  const stopRecording = () => {
    setIsRecording(false);
    mediaRecorderRef.current.stop();
  };

  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      chunksRef.current.push(event.data);
    }
  };

  const handleStop = (questionId, index) => {
    const blob = new Blob(chunksRef.current, { type: 'audio/webm' });
    const reader = new FileReader();
    reader.onload = () => {
      const base64Data = reader.result;
      console.log('Recorded audio base64:', base64Data);
      setRecordings(base64Data);
      handleAnswerChange(questionId, base64Data, "voice_memo", index);
    };
    reader.readAsDataURL(blob);
  };

  const startTimer = () => {
    setRecordingTimer(0);
    timerIntervalRef.current = setInterval(() => {
      setRecordingTimer(prevTimer => prevTimer + 1);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(timerIntervalRef.current);
  };

  const startRecording = (questionId, index) => {
    setRecordings(null);
    if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
      navigator.mediaDevices.enumerateDevices()
        .then(devices => {
          const audioInputDevices = devices.filter(device => device.kind === 'audioinput');
          if (audioInputDevices.length === 0) {
            Swal.fire({
              icon: 'error',
              title: 'Requested device not found',
            });
          } else {
            navigator.mediaDevices.getUserMedia({ audio: true })
              .then(stream => {
                const mediaRecorder = new MediaRecorder(stream);
                mediaRecorder.ondataavailable = handleDataAvailable;
                mediaRecorder.onstop = () => handleStop(questionId, index); // Pass a function reference
                mediaRecorderRef.current = mediaRecorder;
                chunksRef.current = [];
                mediaRecorder.start();
                setIsRecording(true);
              })
              .catch(err => console.error('Error accessing microphone: ', err));
          }
        })
        .catch(err => console.error('Error enumerating devices: ', err));
    } else {
      Swal.fire({
        icon: 'error',
        title: 'enumerateDevices() is not supported',
      });
    }
  };

  const handleAudioUploading = (questionId, event, questionIndex) => {
    let selectedAudioFiles = [];
    const promises = Array.from(event.currentTarget.files).map(file => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                selectedAudioFiles.push(reader.result);
                resolve();
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    });
    Promise.all(promises).then(() => {
        setQuestionAnswerList(prevQuestions =>
            prevQuestions.map(question => {
                if (question._id === questionId) {
                    let newAns = {
                        ...question,
                        answer: selectedAudioFiles[0],
                        validationError: ""
                    };
                    return newAns;
                }
                return question;
            })
        );
    });
    handelQuestionError(questionIndex);
};

  const renderQuestion = (question, index, values = null) => {
    switch (question.question_type) {
      case "single_choice":
        return (
          <div key={index} className="card-questions-box">
            <label
              className="form-label"
              htmlFor={"dynamicQuestion" + index}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* {index + 1}. */}
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>
            {Array.isArray(question.options) &&
              question.options.map((option, i) => (
                <div key={i} className="form-radio">
                  <input
                    type="radio"
                    id={option}
                    name={"flexRadioDefault"}
                    value={option}
                    checked={question.answer === option}
                    onChange={(e) => {
                      handleAnswerChange(question._id, e, "single_choice", index);
                    }}
                    className={`form-radio ${question.is_required ? "question-required me-2" : ""
                      }`}
                  />
                  <label className="no-before no-after" htmlFor={option}>
                    {capitalizeFirstLetter(option)}
                  </label>
                </div>
              ))}
            {question?.is_required && !question.answer && (
              <div className="error-message requirestar">{validationError[index]}</div>
            )}
          </div>
        );
      case "emoji":
        return (
          <div key={question._id} className="card-questions-box">
            <label
              className="form-label"
              htmlFor={"dynamicQuestion" + index}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* {index + 1}.  */}
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>
            <ul className="select-face-list">
              {Array.isArray(question.emoji_options)
                ? question.emoji_options.map((option, i) => (
                  <li key={i}>
                    <div className="form-radio">
                      <input
                        type="radio"
                        id={`emoji_click${i}${question._id}`}
                        name={question._id}
                        value={option}
                        checked={question.answer === option}
                        onChange={(e) => {
                          handleAnswerChange(question._id, e, "emoji", index);
                        }}
                        className="form-check-input cp"
                      />
                      <label
                        className="form-check-label cp"
                        htmlFor={`emoji_click${i}${question._id}`}
                      >
                        <span
                          className="active-emoji"
                          style={{ fontSize: "40px" }}
                        >
                          {option}
                        </span>
                      </label>
                    </div>
                  </li>
                ))
                : null}
            </ul>
            {/* {validationError && (
                    <div className="error-message requirestar">{validationError[index]}</div>
                )} */}
            {question?.is_required && !question.answer && (
              <div className="error-message requirestar">{validationError[index]}</div>
            )}
          </div>
        );
      case "multiple_choice":
        return (
          <div key={index} className="card-questions-box">
            <label
              className="form-label"
              htmlFor={"dynamicQuestion" + index}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* {index + 1}.  */}
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>
            {Array.isArray(question.options) ? (
              <div className="check-list">
                {question.options.map((option, i) => (
                  <div key={i}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        id={option}
                        name={"flexRadioDefault"}
                        value={option}
                        checked={question?.answer && question?.answer?.includes(option)}
                        onChange={(e) => {
                          handleAnswerChange(
                            question._id,
                            e,
                            "multiple_choice",
                            index
                          );
                        }}
                        className={`form-check-input cp ${question.is_required && !question.answer
                          ? "question-required"
                          : ""
                          }`}
                      />
                      <label className="form-check-label cp" htmlFor={option}>
                        {capitalizeFirstLetter(option)}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}
            {/* {validationError && (
                    <div className="error-message requirestar">{validationError[index]}</div>
                )} */}
            {question?.is_required && question?.answer?.length === 0 && (
              <div className="error-message requirestar">{validationError[index]}</div>
            )}
          </div>
        );
      case "dropdown":
        return (
          <div key={index} className="card-questions-box">
            <label
              className="form-label"
              htmlFor={"dynamicQuestion" + index}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* {index + 1}.  */}
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>
            {Array.isArray(question.options) ? (
              <div className="dropdown" key={Math.random()}>
                <select
                  id={"dynamicQuestion" + index}
                  defaultValue={question?.answer}
                  onChange={(e) => {
                    handleAnswerChange(question._id, e, "dropdown", index);
                  }}
                  className={
                    question.is_required ? "question-required form-select" : ""
                  }
                >
                  <option value={""}>Select</option>
                  {question.options.map((option, i) => (
                    <option key={i} value={option}>
                      {capitalizeFirstLetter(option)}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}
            {/* {validationError && (
                    <div className="error-message requirestar">{validationError[index]}</div>
                )} */}
            {question?.is_required && !question.answer && (
              <div className="error-message requirestar">{validationError[index]}</div>
            )}
          </div>
        );
      case "rating":
        return (
          <>
            <div key={question._id} className="card-questions-box">
              <label
                className="form-label"
                htmlFor={"dynamicQuestion" + index}
                style={{ display: "flex", alignItems: "center" }}
              >
                {/* {index + 1}.  */}
                {question?.title}
                {question.is_required && <span className="requirestar">*</span>}
              </label>
              <div className="rating">
                <Rating
                  id={"dynamicQuestion" + index}
                  onClick={(e) => handleRating(e, question._id, index)}
                  initialValue={question?.answer}
                />
                {/* {validationError && (
                            <div className="error-message requirestar">{validationError[index]}</div>
                        )} */}
                {question?.is_required && !question.answer && (
                  <div className="error-message requirestar">
                    {validationError[index]}
                  </div>
                )}
              </div>
            </div>
          </>
        );
      case "likeStatus":
        return (
          <>
            <div key={question._id} className="card-questions-box">
              <label
                className="form-label"
                htmlFor={"dynamicQuestion" + index}
                style={{ display: "flex", alignItems: "center" }}
              >
                {/* {index + 1}.  */}
                {question?.title}
                {question.is_required && <span className="requirestar">*</span>}
              </label>
              <div>
                <div className="d-flex align-items-center like-question-container">
                  <input
                    checked={question.answer === 1}
                    type="radio"
                    id={`likeRadio_${question._id}`}
                    name={`likeRadioGroup_${question._id}`}
                    onChange={(e) => handleLikeDislike(1, question._id, index)}
                  />
                  <label htmlFor={`likeRadio_${question._id}`}>
                    <i
                      className="ri-thumb-up-fill cp ms-2"
                      style={{ fontSize: "24px" }}
                    ></i>
                  </label>
                </div>
                <div>
                  <div className="d-flex align-items-center like-question-container">
                    <input
                      checked={question.answer === 0}
                      type="radio"
                      id={`dislikeRadio_${question._id}`}
                      name={`likeRadioGroup_${question._id}`}
                      onChange={(e) => handleLikeDislike(0, question._id, index)}
                    />
                    <label htmlFor={`dislikeRadio_${question._id}`}>
                      <i
                        className="ri-thumb-down-fill cp ms-2"
                        style={{ fontSize: "24px" }}
                      ></i>
                    </label>
                  </div>
                  {question?.is_required && !question.answer && (
                    <div className="error-message requirestar">
                      {validationError[index]}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        );
      case "short_ans":
        return (
          <div key={question._id} className="card-questions-box">
            <label
              className="form-label"
              htmlFor={"dynamicQuestion" + index}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* {index + 1}.  */}
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>
            <input
              id={"dynamicQuestion" + index}
              type="text"
              data-type={question.question_type}
              name={question._id}
              //required={question.is_required}
              value={question?.answer}
              onChange={(e) => {
                handleAnswerChange(question._id, e, "short_ans", index);
              }}
              placeholder="Please give your answer..."
              className={`form-control ${question.is_required ? "question-required" : ""
                }`}
            />
            {/* {validationError && (
                    <div className="error-message requirestar">{validationError[index]}</div>
                )} */}
            {question?.is_required && !question.answer && (
              <div className="error-message requirestar">{validationError[index]}</div>
            )}
          </div>
        );
      case "slider":
        return (
          <div key={question._id} className="card-questions-box">
            <label
              className="form-label"
              htmlFor={"dynamicQuestion" + index}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* {index + 1}.  */}
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>
            <div className="range-slider">
              <div id="tooltip" />
              <input
                id={"range"}
                type="range"
                //required={question.is_required}
                className={`${question.is_required ? "question-required" : ""}`}
                onChange={(e) => {
                  handleAnswerChange(question._id, e, "slider", index);
                }}
                defaultValue={question?.answer}
                min={question.min_slider_value}
                max={question.max_slider_value}
              />
              {question?.is_required && !question.answer && (
                <div className="error-message requirestar">
                  {validationError[index]}
                </div>
              )}
            </div>
          </div>
        );
      case "long_ans":
        return (
          <div key={question._id} className="card-questions-box">
            <label
              className="form-label"
              htmlFor={"dynamicQuestion" + index}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* {index + 1}.  */}
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>
            <textarea
              id={"dynamicQuestion" + index}
              data-type={question.question_type}
              rows={4}
              name={question._id}
              value={question?.answer}
              //required={question.is_required}
              onChange={(e) => {
                handleAnswerChange(question._id, e, "long_ans", index);
              }}
              placeholder="Please give your answer..."
              className={`form-control note-min ${question.is_required ? "question-required" : ""}`
              }
            ></textarea>
            {/* {validationError && (
                    <div className="error-message requirestar">{validationError[index]}</div>
                )} */}
            {question?.is_required && !question.answer && (
              <div className="error-message requirestar">{validationError[index]}</div>
            )}
          </div>
        );
      case "calendar":
        return (
          <div key={index} className="card-questions-box">
            <label
              className="form-label"
              htmlFor={"dynamicQuestion" + index}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* {index + 1}. */}
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>
            <div className="form-group">
              <input
                className="form-control"
                type="date"
                value={question.answer ? question.answer : ''}
                onChange={async (e) => {
                  handleAnswerChange(question._id, e, "calendar", index, values);
                }}
              />
            </div>
            {question?.is_required && !question.answer && (
              <div className="error-message requirestar">{validationError[index]}</div>
            )}
          </div>
        );
      case "time":
        return (
          <div key={index} className="card-questions-box">
            <label
              className="form-label"
              htmlFor={"dynamicQuestion" + index}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* {index + 1}. */}
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>
            <div className="form-group">
            <input
              className="form-control"
              type="time"
              placeholder="Time"
              value={question.answer ? question.answer : null}
              onChange={async (e) => {
                handleAnswerChange(question._id, e, "time", index, values);
              }}
            />
            </div>
            {question?.is_required && !question.answer && (
              <div className="error-message requirestar">{validationError[index]}</div>
            )}
          </div>
        );
      case "signature":
        return (
          <div  key={index} className="card-questions-box">
            <label className="form-label" htmlFor={"dynamicQuestion" + index} style={{ display: "flex", alignItems: "center" }} >
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>
            <div className="position-relative d-inline-block w-100">
              {((question.answer && question.file_url)) ?
                <>
                  {showDrawingCanvas ?
                    <div id="question-parent-div" className="position-relative d-inline-block w-100">
                      <Drawing 
                        parentId={`question-parent-div`}
                        ref={canvasRef}
                        onChange={(e) => {
                          handleAnswerChange(question._id, e, "signature", index, values);
                        }}
                      />
                      <ul className="draw-actions-list">
                        {question?.newAns ? (
                          <>
                            <li>
                              <a href="/" onClick={(e) => { e.preventDefault(); clearSignature(question._id, index, 1); }}
                                className="cp text-danger" id="sign-clear-btn" title="Clear" ><i className="ri-delete-bin-6-fill"></i></a>
                            </li>
                            <li>
                              <a href="/" onClick={(e) => { e.preventDefault(); toggleSignature(1); }}
                                className="cp text-danger" id="sign-clear-btn" title="Clear" ><i className="ri-close-fill"></i></a>
                            </li>
                          </>
                        ) : <li>
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleSignature(1);
                            }}
                            className="cp text-danger"
                            title="Cancel"
                          >
                            <i className="ri-close-fill"></i>
                          </a>
                        </li>
                        }
                      </ul>
                    </div>
                    :
                    <div className="position-relative d-inline-block w-100" style={{ border: "1px solid black", height: "200px", width: "600px" }}>
                      <img src={question.file_url} style={{ height: "100%" }} alt="" />
                      <ul className="draw-actions-list">
                        <li>
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleSignature(1);
                            }}
                            className="cp text-dark"
                            title="Edit"
                          >
                            <i className="ri-pencil-fill"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  }
                </> :
                <div className="position-relative d-inline-block w-100">
                  <Drawing
                    parentId={`question-parent-div`}
                    ref={canvasRef}
                    onChange={(e) => {
                      handleAnswerChange(question._id, e, "signature", index, values);
                    }}
                  />
                  <ul className="draw-actions-list">
                    {question?.answer || question?.newAns ? (
                      <li>
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            clearSignature(question._id, index, 1);
                          }}
                          className="cp text-danger"
                          id="sign-clear-btn"
                          title="Clear"
                        >
                          <i className="ri-delete-bin-6-fill"></i>
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              }
            </div>
            {question?.is_required && !question.answer && (
              <div className="error-message requirestar">{validationError[index]}</div>
            )}
          </div>
        );
      case "free_hand_drawing":
        return (
          <div key={index} className="card-questions-box">
            <label className="form-label" htmlFor={"dynamicQuestion" + index} style={{ display: "flex", alignItems: "center" }} >
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>
            <div className="position-relative d-inline-block w-100">
              {question.answer && question.file_url ?
                <>
                  {showDrawingCanvasForFreeHand ?
                    <div id="question-parent-div" className="position-relative d-inline-block w-100">
                      <Drawing 
                      parentId={`question-parent-div`}
                      ref={canvasRef1}
                        onChange={(e) => {
                          handleAnswerChange(question._id, e, "free_hand_drawing", index, values);
                        }}
                      />
                      <ul className="draw-actions-list">
                        {question?.newAns ? (
                          <>
                            <li>
                              <a href="/" onClick={(e) => { e.preventDefault(); clearSignature(question._id, index, 2); }}
                                className="cp text-danger" id="sign-clear-btn" title="Clear" ><i className="ri-delete-bin-6-fill"></i></a>
                            </li>
                            <li>
                              <a href="/" onClick={(e) => { e.preventDefault(); toggleSignature(2); }}
                                className="cp text-danger" id="sign-clear-btn" title="Clear" ><i className="ri-close-fill"></i></a>
                            </li>
                          </>
                        ) : <li>
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleSignature(2);
                            }}
                            className="cp text-danger"
                            title="Cancel"
                          >
                            <i className="ri-close-fill"></i>
                          </a>
                        </li>
                        }
                      </ul>
                    </div>
                    :
                    <div className="position-relative d-inline-block w-100" style={{ border: "1px solid black", height: "200px", width: "600px" }}>
                      <img src={question.file_url} style={{ height: "100%" }} alt="" />
                      <ul className="draw-actions-list">
                        <li>
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              toggleSignature(2);
                            }}
                            className="cp text-dark"
                            title="Edit"
                          >
                            <i className="ri-pencil-fill"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  }
                </> :
                <div className="position-relative d-inline-block w-100">
                  <Drawing
                    parentId={`question-parent-div`}
                    ref={canvasRef}
                    onChange={(e) => {
                      handleAnswerChange(question._id, e, "free_hand_drawing", index, values);
                    }}
                  />
                  <ul className="draw-actions-list">
                    {question?.answer ? (
                      <li>
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            clearSignature(question._id, index, 1);
                          }}
                          className="cp text-danger"
                          id="sign-clear-btn"
                          title="Clear"
                        >
                          <i className="ri-delete-bin-6-fill"></i>
                        </a>
                      </li>
                    ) : null}
                  </ul>
                </div>
              }
            </div>
            {question?.is_required && !question.answer && (
              <div className="error-message requirestar">{validationError[index]}</div>
            )}
          </div>

        );
      case "photo_upload":
        return (
          <div key={index} className="card-questions-box">
            <label
              className="form-label"
              htmlFor={"dynamicQuestion" + index}
              style={{ display: "flex", alignItems: "center" }}
            >
              {/* {index + 1}. */}
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>
            <input
              className='form-control imgInput'
              id="imageUpload"
              name="files"
              accept="image/*"
              type="file"
              multiple
              onChange={(event) => {
                if (event.currentTarget.files.length > 0) {
                  handleImageUploading(question._id, event, index);
                }
              }}
            />
            {(question?.is_required && (question.answer?.length === 0 || !question?.answer) && (!question?.newFile || question?.newFile?.length === 0)) ? (
              <div className="error-message requirestar">{validationError[index]}</div>
            ) : null}
            {/* {previewImages && previewImages.length > 0 && (
              <div className="d-flex flex-wrap">
                {previewImages.map((imageUrl, idx) => (
                  <div key={idx} className="m-1 position-relative">
                    <img src={imageUrl} alt={`Preview ${idx}`} width="300"/>
                    <a href="/" style={{ position: "absolute", top: "0", right: "0" }}
                      onClick={(e) => { e.preventDefault(); handleImageUploading(question._id, null, index, true, idx, imageUrl); }}
                      className="cp text-danger" id="sign-clear-btn" title="Clear" ><i className="ri-delete-bin-6-fill"></i></a>
                  </div>
                ))}
              </div>

            )} */}
            {previewImages &&
              Array.isArray(previewImages) &&
              previewImages.length > 0 && (
                <div className="d-flex flex-wrap">
                  {previewImages.map((media, idx) => (
                    <div key={idx} className="m-1 position-relative">
                      <div className="custom-checks">
                        <label className="" htmlFor="idddd">
                          {media}
                        </label>
                      </div>
                      <a
                        href="/"
                        style={{
                          position: "absolute",
                          top: "1px",
                          right: "5px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          handleImageUploading(
                            question._id,
                            null,
                            index,
                            true,
                            idx,
                            media
                          );
                        }}
                        className="cp text-danger"
                        id="sign-clear-btn"
                        title="Clear"
                      >
                        <i className="ri-delete-bin-6-fill"></i>
                      </a>
                    </div>
                  ))}
                </div>
              )}
          </div>
        );
        case "voice_memo":
          return (
              <div key={index} className="card-questions-box">
                  <label
                      className="form-label"
                      htmlFor={"dynamicQuestion" + index}
                      style={{ display: "flex", alignItems: "center" }}
                  >
                      {question?.title}
                      {question.is_required && <span className="requirestar">*</span>}
                  </label>

                  <div className="">
                  <div className="recording-box">
                      <div className="d-flex align-item-center">
                        <div className="mic mt-1">
              <i className={`fas fa-microphone`} style={{ fontSize: '3em', color: isRecording ? 'var(--primary_active_color)' : 'black' }}></i>
            </div>
            <div className="record-text">
              <h4>Audio Recorder</h4>
              {isRecording ? (
            <>
             <p>Recording: {recordingTimer} seconds</p>
            </>
          ) : null}
              
            </div>
                      </div>
                      {(question.answer && question?.file_url && !question?.newAns) ?
                          <>
                              <div className="recording-controls">
                                  <div className="recordings  d-flex">
                                      <audio style={{ maxHeight: "41px" }} key={index} src={question?.file_url} controls />
                                  </div>
                              </div>

                              <div className="buttons">
                                  <button onClick={() => { handleAnswerChange(question._id, null, "voice_memo", index); }} type="button" className="btn btn-secondary" >Delete Recording
                                  </button>
                              </div>
                          </>
                          :
                          <>
                              <div className="recording-controls">
                                  <div className="recordings  d-flex">
                                      {recordings ? <><audio style={{ maxHeight: "41px" }} key={index} src={recordings} controls /></> : null}
                                  </div>
                              </div>
                              <div className="buttons">
                                  {isRecording ? (
                                      <>
                                          <button onClick={stopRecording} type="button" className="btn btn-secondary mt-1" >Stop Recording</button>
                                      </>
                                  ) : (
                                      !recordings ? <button onClick={() => startRecording(question._id, index)} type="button" className="btn btn-success mt-1" >Start Recording</button> :

                                          <button onClick={() => { setRecordings(null); handleAnswerChange(question._id, null, "voice_memo", index); }} type="button" className="btn btn-danger" >Delete Recording</button>
                                  )}
                              </div>
                          </>
                      }
                  </div>
              </div>
              <div className="upload-audio">
                            <label
                                className="mt-2 mb-2 d-flex justify-content-center"
                                htmlFor="audioUpload"
                            >
                                <span style={{ fontWeight: 500 }}>Or Upload File</span>
                            </label>
                            <input
                  className="form-control audioInput"
                  id="audioUpload"
                  name="files"
                  type="file"
                  accept="audio/*"
                  onChange={(event) => {
                    if (event.currentTarget.files.length > 0) {
                      const file = event.currentTarget.files[0];
                      if (isValidAudioFile(file)) {
                        handleAudioUploading(question._id, event, index);
                      } else {
                        Swal.fire({
                          icon: "error",
                          text: "Invalid file format",
                          ...SWAL_SETTINGS,
                        });
                      }
                    }
                  }}
                />
                        </div>
              {question?.is_required && !question?.answer && (
        <div className="error-message requirestar">{validationError[index]}</div>
        )}
              </div>
          );
      
      case "extra_information":
        return (
          <div key={index} className="card-questions-box">
            <label
              className="form-label"
              htmlFor={"dynamicQuestion" + index}
              style={{ display: "flex", alignItems: "center" }}
            >
              {question?.title}
              {question.is_required && <span className="requirestar">*</span>}
            </label>

            <div className="">
              <div dangerouslySetInnerHTML={{ __html: question?.description ? question?.description : "N/A" }}></div>
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const isValidAudioFile = (file) => {
    const allowedTypes = ["audio/mpeg", "audio/wav", "audio/mp3"];
    return allowedTypes.includes(file.type);
  };





  const toggleSignature = (type) => {
    type == 1 ? setShowDrawingCanvas(!showDrawingCanvas) : setShowDrawingCanvasForFreeHand(!showDrawingCanvasForFreeHand)
    // setShowSignatureField(!showSignatureField);
  };

  // const handleImageUploading = (questionId, e, questionIndex, deleting = false, index = null, imgUrl = null) => {
  //   if (deleting && index != null) {
  //     let imgName = imgUrl.split('/').pop();
  //     const newImages = previewImages ? previewImages.filter((_, i) => i !== index) : [];
  //     setPreviewImages(newImages);
  //     setQuestionAnswerList((prevQuestions) =>
  //       prevQuestions.map((question) => {
  //         if (question._id === questionId) {
  //           console.log(question)
  //           if (question.answer && question.answer.includes(imgName)) {
  //             let imagesToRemoveIndex = question.answer.findIndex(img => img === imgName);
  //             if (imagesToRemoveIndex !== -1) {
  //               let newAnswer = [...question.answer];
  //               newAnswer.splice(imagesToRemoveIndex, 1);
  //               let newFileUrl = question.file_url ? [...question.file_url] : null;
  //               if (newFileUrl) {
  //                 newFileUrl.splice(imagesToRemoveIndex, 1);
  //               }
  //               let newAns = {
  //                 ...question,
  //                 answer: newAnswer,
  //                 file_url: newFileUrl,
  //                 validationError: "",
  //               };
  //               return newAns;
  //             }
  //           }
  //           if (question.newFile && question.newFile.includes(imgUrl)) {
  //             let imagesToRemoveIndex = question.newFile.findIndex(img => img === imgUrl);
  //             if (imagesToRemoveIndex !== -1) {
  //               let newAnswer = [...question.newFile];
  //               newAnswer.splice(imagesToRemoveIndex, 1);
  //               let newAns = {
  //                 ...question,
  //                 newFile: newAnswer,
  //                 validationError: "",
  //               };
  //               return newAns;
  //             }
  //           }
  //         }
  //         return question;
  //       })
  //     );
  //   }
  //   else {
  //     let selectedValue = [];
  //     const promises = Array.from(e.currentTarget.files).map(file => {
  //       return new Promise((resolve, reject) => {
  //         const reader = new FileReader();
  //         reader.onload = () => {
  //           selectedValue.push(reader.result);
  //           resolve();
  //         };
  //         reader.onerror = reject;
  //         reader.readAsDataURL(file);
  //       });
  //     });

  //     Promise.all(promises).then(() => {
  //       setPreviewImages((prevImages) => [...prevImages, ...selectedValue]);
  //       setQuestionAnswerList((prevQuestions) =>
  //         prevQuestions.map((question) => {
  //           if (question._id === questionId) {
  //             let newAns = {
  //               ...question,
  //               newFile: selectedValue,
  //               validationError: "",
  //             }
  //             return newAns
  //           }
  //           return question;
  //         })
  //       );

  //     });
  //   }
  //   handelQuestionError(questionIndex)
  // }

  const handleImageUploading = (
    questionId,
    e,
    questionIndex,
    deleting = false,
    index = null,
    imgUrl = null
  ) => {
    if (deleting && index != null) {
      let imgName = imgUrl.split("/").pop();
      const newImages = previewImages
        ? previewImages.filter((_, i) => i !== index)
        : [];
      setPreviewImages(newImages);
      setQuestionList((prevQuestions) =>
        prevQuestions.map((question) => {
          if (question._id === questionId) {
            if (question.answer && question.answer.includes(imgName)) {
              let imagesToRemoveIndex = question.answer.findIndex(
                (img) => img === imgName
              );
              if (imagesToRemoveIndex !== -1) {
                let newAnswer = [...question.answer];
                newAnswer.splice(imagesToRemoveIndex, 1);
                let newFileUrl = question.file_url
                  ? [...question.file_url]
                  : null;
                if (newFileUrl) {
                  newFileUrl.splice(imagesToRemoveIndex, 1);
                }
                let newAns = {
                  ...question,
                  answer: newAnswer,
                  file_url: newFileUrl,
                  validationError: "",
                };
                return newAns;
              }
            }
            if (question.newAns && question.newAns.includes(imgName)) {
              let imagesToRemoveIndex = question.newAns.findIndex((url) =>
                url.includes(".img")
              );
              let answer = question.newAns.filter(
                (_, i) => i !== imagesToRemoveIndex
              );
              let newAns = {
                ...question,
                newFile: answer,
                validationError: "",
              };
              return newAns;
            }
          }
          return question;
        })
      );
    } else {
      let selectedValue = [];
      let selectedFileName = [];
      const promises = Array.from(e.currentTarget.files).map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            selectedFileName.push(file.name);
            selectedValue.push(reader.result);
            resolve();
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });

      Promise.all(promises).then(() => {
        setPreviewImages((prevImages) => [...prevImages, ...selectedFileName]);
        setQuestionList((prevQuestions) =>
          prevQuestions.map((question) => {
            if (question._id === questionId) {
              let prevAns =
                question && question.newFile ? question.newFile : [];
              let newFiles =
                prevAns && prevAns.length > 0
                  ? [...prevAns, ...selectedValue]
                  : selectedValue;
              let newAns = {
                ...question,
                newFile: newFiles,
                validationError: "",
              };
              return newAns;
            }
            return question;
          })
        );
      });
    }
    handelQuestionError(questionIndex);
  };

  const handleAnswerChange = (questionId, e, type, index = null, values = null) => {
    let selectedValue = "";
    if (type === "rating") {
      selectedValue = e;
    } else if (type === "likeStatus") {
      selectedValue = e === 1 ? 1 : 0;
    }
    else if (type === "signature" || type === 'free_hand_drawing') {
      selectedValue = e.getDataURL();
      setQuestionAnswerList((prevQuestions) =>
        prevQuestions.map((question) => {
          if (question._id === questionId) {
            return {
              ...question,
              newAns: selectedValue,
              validationError: "",
            };
          }
          return question;
        })
      );
    }
    else if (type === 'voice_memo') {
      selectedValue = e
      setQuestionAnswerList((prevQuestions) =>
        prevQuestions.map((question) => {
          if (question._id === questionId) {
            let newAns = {
              ...question,
              answer: selectedValue,
              validationError: "",
            }
            return newAns
          }
          return question;
        })
      );
    }

    else if (type === "multiple_choice") {
      selectedValue = e.target.value
      let currentQuestion = questionList.filter(
        (item) => item._id === questionId
      )[0];
      let prevAns =
        currentQuestion && currentQuestion.answer
          ? currentQuestion.answer
          : [];
      if (e.target.checked) {
        prevAns.push(selectedValue);
      } else {
        let ind = prevAns.indexOf(selectedValue);
        if (ind !== -1) {
          prevAns.splice(ind, 1);
        }
      }
      setQuestionAnswerList((prevQuestions) =>
        prevQuestions.map((question) => {
          if (question._id === questionId) {
            return {
              ...question,
              answer: prevAns,
              validationError: "",
            };
          }
          return question;
        })
      );
    } else {
      selectedValue = e.target.value;
      setQuestionAnswerList((prevQuestions) =>
        prevQuestions.map((question) => {
          if (question._id === questionId) {
            return {
              ...question,
              answer: selectedValue,
              validationError: "",
            };
          }
          return question;
        })
      );
    }
    if (index) {
      handelQuestionError(index)
    }
  };

  const handleRating = (rate, _id, index) => {
    handleAnswerChange(_id, rate, "rating", index);
  };

  const handleLikeDislike = (res, _id, index) => {
    setQuestionAnswerList((prevQuestions) =>
      prevQuestions.map((question) => {
        if (question._id === _id) {
          let answer = {
            ...question,
            answer: res,
            validationError: "",
          }
          return answer
        }
        return question;
      })
    );
  };


  function dataURLtoFile(base64, filename) {
    var arr = base64.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  function clearSignature(questionId, index, ref) {
    setQuestionAnswerList((prevQuestions) =>
      prevQuestions.map((question) => {
        if (question._id === questionId) {
          let newAns = {
            ...question,
            newAns: "",
            validationError: "",
          }
          return newAns
        }
        return question;
      })
    );
    if (ref == 1) {
      canvasRef.current.clear();
    }
    else {
      canvasRef1.current.clear()
    }
    handelQuestionError(index)
  }

  const handelQuestionError = (index) => {
    const errorMessages = { ...validationError };

    delete errorMessages[index];
    if (Object.keys(errorMessages).length > 0) {
      setValidationError(errorMessages);
    } else {
      setValidationError({});
    }
  }
  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <Formik
        enableReinitialize
        initialValues={{
          email: showdefault && showdefault.email ? showdefault.email : "",
          title: showdefault && showdefault.title ? showdefault.title : "",
          gender: showdefault && showdefault.gender ? showdefault.gender : "",
          first_name:
            showdefault && showdefault.first_name ? showdefault.first_name : "",
          middle_name:
            showdefault && showdefault.middle_name
              ? showdefault.middle_name
              : "",
          last_name:
            showdefault && showdefault.last_name ? showdefault.last_name : "",
          phone_number:
            showdefault &&
              showdefault?.phone_number &&
              showdefault?.phone_number.number
              ? showdefault?.phone_number.number
              : "",
          countryCodePhone:
            showdefault &&
              showdefault?.phone_number &&
              showdefault?.phone_number.countryCode
              ? showdefault?.phone_number.countryCode
              : "",
          dialCodePhone:
            showdefault &&
              showdefault?.phone_number &&
              showdefault?.phone_number.code
              ? showdefault?.phone_number.code
              : "+" + getCountryCallingCode(getCountries()[0]),

          manager_id: showdefault && showdefault.createdBy ? showdefault.createdBy : "",
        }}
        validationSchema={validationSchema}
        validate={() => {
          if (submitted) {
            const errorMessages = [];
            questionAnswerList.forEach((question, index) => {
              if (question.is_required) {
                // if (question.question_type !== 'photo_upload') {
                //   if (!question.answer && question?.answer !== 0) {
                //     errorMessages[index] = `Please provide an answer`;
                //   }
                // }
                if(question.question_type !== 'photo_upload') {
                  if(question.question_type === 'multiple_choice') {
                      if(question?.answer?.length === 0 ) {
                          errorMessages[index] = `Please provide an answer`;
                      }
                  }
                  else{
                      if(!question.answer && question?.answer !== 0) {
                          errorMessages[index] = `Please provide an answer`;
                      } 
                  }
              }
                else {
                  if((question.answer?.length === 0 || !question?.answer) && (!question?.newFile || question?.newFile?.length === 0)) {
                    errorMessages[index] = `Please provide an answer`;
                  }
                }
              }
            });
            if (Object.keys(errorMessages).length > 0) {
              setValidationError(errorMessages);
              return;
            } else {
              setValidationError([]);
            }
          }
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          const errorMessages = [];
          questionAnswerList.forEach((question, index) => {

            if (question.is_required) {
              // if (question.question_type !== 'photo_upload') {
              //   if (!question.answer && question?.answer !== 0) {
              //     errorMessages[index] = `Please provide an answer`;
              //   }
              // }
              if (question.question_type !== 'photo_upload') {
                if (question.question_type === 'multiple_choice') {
                    if (question?.answer?.length === 0 ) {
                        errorMessages[index] = `Please provide an answer`;
                    }
                }
                else{
                    if (!question.answer && question?.answer !== 0) {
                        errorMessages[index] = `Please provide an answer`;
                    } 
                }
            }
              else {
                if ((question.answer?.length === 0 || !question?.answer) && (!question?.newFile || question?.newFile?.length === 0)) {
                  errorMessages[index] = `Please provide an answer`;
                }
              }
            }
          });

          if (Object.keys(errorMessages).length > 0) {
            setValidationError(errorMessages);
            return;
          } else {
            setValidationError([]);
          }
          let formData = new FormData();
          const answers = questionAnswerList
            .filter(question => question.answer || question.answer === 0 || question.newAns)
            .map((question) => {
              let answerData = {
                question_id: question?._id,
                question_title: question?.title,
                answer: question?.answer ? question?.answer : '',
                question_type: question.question_type,
              };
              if (question.question_type === 'signature' && question?.newAns) {
                const timestamp = Date.now();
                const convertedFile = dataURLtoFile(question?.newAns, `${question?._id}_signature_${timestamp}.png`);
                answerData.answer = `${question?._id}_signature_${timestamp}.png`;
                answerData['newFile'] = `${question?._id}_signature_${timestamp}.png`;
                formData.append(`${question._id}_signature_${timestamp}`, convertedFile);
              }
              if (question.question_type === 'free_hand_drawing' && question?.newAns) {
                const timestamp = Date.now();
                const convertedFile = dataURLtoFile(question?.newAns, `${question?._id}_free_hand_drawing_${timestamp}.png`);
                answerData.answer = `${question?._id}_free_hand_drawing_${timestamp}.png`;
                answerData['newFile'] = `${question?._id}_free_hand_drawing_${timestamp}.png`;
                formData.append(`${question._id}_free_hand_drawing_${timestamp}`, convertedFile);
              }
              // if (question.question_type === 'photo_upload') {
              //   if (question?.newFile && question?.newFile.length > 0) {
              //     let answersLen = question.answer && question.answer.length ? question.answer.length : -1;
              //     let newFiles = []
              //     question.newFile.forEach((file, index) => {
              //       const timestamp = Date.now();
              //       const convertedFile = dataURLtoFile(file, `${question._id}_index_${answersLen + index + 1}_photo_${timestamp}.png`);
              //       newFiles.push(`${question._id}_index_${answersLen + index + 1}_photo_${timestamp}.png`);
              //       formData.append(`${question._id}_index_${answersLen + index + 1}_photo_${timestamp}`, convertedFile);
              //     });
              //     answerData.answer = [...question.answer, ...newFiles]
              //     answerData.newFile = newFiles
              //   }
              // }
              if (question.question_type === "photo_upload") {
                if (question?.newFile && question?.newFile?.length > 0) {
                  let answersLen =
                    question?.answer && question?.answer?.length
                      ? question?.answer?.length
                      : -1;
                  let newFiles = [];
                  question.newFile.forEach((file, index) => {
                    if (typeof file === "string" && file.startsWith("data:")) {
                      var arr = file.split(",");
                      let mime = arr[0].match(/:(.*?);/)[1];
                      let mimeArray = mime.split("/");
                      let mimetype = mimeArray[0] === "video" ? "mp4" : "png";
                      const timestamp = Date.now();
                      const convertedFile = dataURLtoFile(
                        file,
                        `${question._id}_index_${
                          answersLen + index + 1
                        }_photo_${timestamp}.${mimetype}`
                      );
                      newFiles.push(
                        `${question._id}_index_${
                          answersLen + index + 1
                        }_photo_${timestamp}.${mimetype}`
                      );
                      formData.append(
                        `${question._id}_index_${
                          answersLen + index + 1
                        }_photo_${timestamp}`,
                        convertedFile
                      );
                    }
                  });
                  answerData.answer = [...question.answer, ...newFiles];
                  answerData.newFile = newFiles;
                }
              }
              if (question.question_type === 'voice_memo' && question?.answer) {
                if (typeof question.answer === 'string' && question.answer.startsWith('data:')) {
                  const timestamp = Date.now();
                  const convertedFile = dataURLtoFile(question.answer, `${question._id}_voice_${timestamp}.mp3`);
                  answerData.answer = `${question._id}_voice_${timestamp}.mp3`;
                  formData.append(`${question._id}_voice_${timestamp}`, convertedFile);
              }
            }
              return answerData;
            });

          formData.append("event_id", eventId);
          formData.append("o_id", params.id);
          formData.append("title", values.title);
          formData.append("first_name", values.first_name);
          formData.append("middle_name", values.middle_name);
          formData.append("last_name", values.last_name);
          formData.append("gender", values.gender);
          formData.append("email", values.email);
          formData.append("manager_id", values.manager_id);
          formData.append("questionData", JSON.stringify(answers));
          if (values.phone_number && values.dialCodePhone) {
            formData.append(
              "phone_number",
              JSON.stringify({
                code: values.dialCodePhone,
                number: values.phone_number,
                countryCode: values.countryCodePhone,
              })
            );
          }
          Edit(formData)
            .then((response) => {
              setSubmitting(false);
              globalLoader(true);
              if (response.success) {
                Swal.fire({
                  icon: "success",
                  text: response.message,
                  ...SWAL_SETTINGS,
                });
                setTimeout(() => {
                  globalLoader(false);
                  if (saveType !== "Save") {
                    navigate(`/admin/lead-management/list/1`);
                  }
                }, 1000);
              } else {
                Swal.fire({
                  icon: "error",
                  text: handleServerValidations(response),
                  ...SWAL_SETTINGS,
                });
                globalLoader(false);
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                text: handleServerValidations(error),
                ...SWAL_SETTINGS,
              });
              console.log("error ====> ", error);
              globalLoader(false);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row row-sm">
              <div className="col-lg-12 col-md-12 animation_fade">
                <div className="card custom-card">
                  <div className="card-body">
                    <div>
                      <h6 className="main-content-label mb-3">
                        Edit Lead Management
                      </h6>
                    </div>
                    <div className="row row-sm">
                      <div className="col-md-6 text-center form-group">
                        <label htmlFor="title" className="text-left d-flex">
                          Title:
                        </label>
                        <input
                          name="title"
                          id="title"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.title}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-6 text-center form-group">
                        <label htmlFor="gender" className="text-left d-flex">
                          Salutation:<span className="requirestar">*</span>{" "}
                        </label>
                        <div className="select-down-arrow ">
                          <Field
                            as="select"
                            id="gender"
                            name={"gender"}
                            className="form-control select2 text-capitalize"
                          >
                            <option value="" label="Select Salutation">
                              Select{" "}
                            </option>
                            {GENDER &&
                              Object.values(GENDER).map((option, i) => {
                                return (
                                  <option key={i} value={option}>
                                    {option}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"gender"} />
                        </span>
                      </div>
                      <div className="col-md-6 text-center form-group">
                        <label
                          htmlFor="first_name"
                          className="text-left d-flex"
                        >
                          First Name:<span className="requirestar">*</span>{" "}
                        </label>
                        <input
                          name="first_name"
                          id="first_name"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.first_name}
                          className="form-control"
                        />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"first_name"} />
                        </span>
                      </div>
                      <div className="col-md-6 text-center form-group">
                        <label
                          htmlFor="middle_name"
                          className="text-left d-flex"
                        >
                          Middle Name:{" "}
                        </label>
                        <input
                          name="middle_name"
                          id="middle_name"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.middle_name}
                          className="form-control"
                        />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"middle_name"} />
                        </span>
                      </div>
                      <div className="col-md-6 text-center form-group">
                        <label htmlFor="last_name" className="text-left d-flex">
                          Last Name:<span className="requirestar">*</span>{" "}
                        </label>
                        <input
                          name="last_name"
                          id="last_name"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.last_name}
                          className="form-control"
                        />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"last_name"} />
                        </span>
                      </div>

                      <div className="col-lg-6 text-center form-group">
                        <label htmlFor="email" className="text-left d-flex">
                          Email:<span className="requirestar">*</span>{" "}
                        </label>
                        <input
                          name="email"
                          id="email"
                          type="text"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.email}
                          className="form-control"
                        />
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"email"} />
                        </span>
                      </div>
                      <div className="col-lg-6 text-center form-group">
                        <label
                          className="text-left d-flex"
                          htmlFor="phone_number"
                        >
                          Phone number:<span className="requirestar">*</span>
                        </label>
                        <div className="form-control d-flex">
                          <select
                            className="selectInput"
                            style={{
                              height: "100%",
                              paddingLeft: 0,
                              paddingTop: 0,
                              marginTop: 0,
                              border: 0,
                              width: 40,
                            }}
                            value={values.countryCodePhone}
                            onChange={(event) => {
                              setFieldValue(
                                "countryCodePhone",
                                event.target.value
                              );
                              setFieldValue(
                                "dialCodePhone",
                                "+" + getCountryCallingCode(event.target.value)
                              );
                            }}
                          >
                            {getCountries().map((country) => (
                              <>
                                <option key={country} value={country}>
                                  <ReactCountryFlag countryCode={country} />
                                  {showDropDown(country)}
                                </option>
                              </>
                            ))}
                          </select>
                          <label
                            style={{ padding: 0, margin: 0, paddingTop: 2 }}
                          >
                            {values.dialCodePhone}
                          </label>
                          <input
                            className={"phoneInput"}
                            name="phone_number"
                            style={{ border: 0 }}
                            onChange={(event) => {
                              setFieldValue(`phone_number`, event.target.value);
                            }}
                            value={`${values.phone_number}`}
                            onKeyDown={blockInvalidChar}
                          />
                        </div>
                        <span className="text-danger d-flex text-left">
                          {errors.phone_number && errors.phone_number}
                        </span>
                      </div>
                      <div className="col-md-6 text-center form-group">
                        <label
                          htmlFor="manager_id"
                          className="text-left d-flex"
                        >
                          Created By:<span className="requirestar">*</span>{" "}
                        </label>
                        <div className="select-down-arrow ">
                          <Field
                            as="select"
                            id="manager_id"
                            name={"manager_id"}
                            className="form-control select2 text-capitalize"
                          >
                            <option value="" label="Created By">
                              Select{" "}
                            </option>
                            {leadOptions &&
                              leadOptions.length > 0 &&
                              leadOptions.map((option, i) => {
                                return (
                                  <option key={i} value={option._id}>
                                    {option.name}
                                  </option>
                                );
                              })}
                          </Field>
                        </div>
                        <span className="text-danger d-flex text-left">
                          <ErrorMessage name={"manager_id"} />
                        </span>
                      </div>

                      {questionAnswerList.length > 0 ?
                        <h4 className="mb-3">Question info</h4>
                        : null}
                      <div className="form-group mb-4 col-md-12">
                        {questionAnswerList.length > 0
                          ? questionAnswerList.map((question, index) => (
                            <div
                              key={index}
                              className={
                                index < questionAnswerList.length - 1
                                  ? "mb-4"
                                  : ""
                              }
                            >
                              {renderQuestion(question, index, values)}
                            </div>
                          ))
                          : null}
                      </div>
                      <div className="mt-5">
                        <button
                          onClick={() => {
                            setSubmitted(true);
                            setSaveType("Save");
                          }}
                          className="btn btn-info mr-2"
                          type="submit"
                        >
                          <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                          Save
                        </button>
                        <button
                          onClick={() => {
                            setSubmitted(true);
                            setSaveType("");
                          }}
                          className="btn btn-success mr-2"
                          type="submit"
                        >
                          <i className="ace-icon fa fa-check bigger-110 mx-1"></i>
                          Save & Exit
                        </button>
                        <button
                          className="btn ripple btn-secondary"
                          type="button"
                          onClick={() => navigate(-1)}
                        >
                          <i className="ace-icon fa fa-times bigger-110 mx-1"></i>
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default LeadManagementEdit;
