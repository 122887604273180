import axiosInstance from "../../../utils/axios";

const path = "admin/question-sets"
const path1 = "admin/questions"

export const List = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const Add = async (values) => {
    return await axiosInstance().post(`${path}/add`, values);
}

export const Edit = async (values) => {
    return await axiosInstance().post(`${path}/edit`, values);
}

export const Delete = async (values) => {
    return await axiosInstance().post(`${path}/delete`, values)
}

export const Details = async (id) => {
    return await axiosInstance().get(`${path}/details?o_id=${id}`);
}

export const Status = async (values) => {
    return await axiosInstance().post(`${path}/change-status`, values)
}

export const Options = async (values) => {
    return await axiosInstance().post(`${path}/options`, values)
}

export const Order = async (values) => {
    return await axiosInstance().post(`${path1}/order`, values)
}

export const QuestionsList = async (values) => {
    return await axiosInstance().post(`${path1}/question-list`, values)
}
export const SubmittedQuestionsList = async (values) => {
    return await axiosInstance().post(`${path}/survey-questions-list`, values)
}
export const SubmittedFormList = async (values) => {
    return await axiosInstance().post(`${path}/dynamic-form-submissions`, values)
}
