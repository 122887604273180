import React, { useEffect, useState } from 'react'
import likeImage from "../../assets/user/images/thump-icon.png";
import { useNavigate, useSearchParams } from 'react-router-dom';

export default function Success() {
  const [message, setMessage] = useState('Success');
  const [backButton, setBackButton] = useState(false);
  const [backButtonURL, setBackButtonURL] = useState(null);
  const [params] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    try {
      const myMessage = atob(params.get('m'));
      setMessage(myMessage);
      if(params.get('back') === "true"){
        setBackButton(true);
      }
      if(params.get('bURL') && params.get('bURL').length){
        setBackButtonURL(params.get('bURL'));
      }
    } catch (err) {
      console.log("Invalid message");
    }
  }, []);
  function handleBackClick(){
    if(backButtonURL){
      navigate(backButtonURL);
    }else{
      window.history.back();
    }
  }
  
  return (
    <div>
      <div className="wraper-inner cpt bg-grey" style={{ height: "100vh" }}>
        <section className="inner-space space-b">
          <div className="container">
            <div className="row">
              <div className="col-md-6 m-auto thanku-card-main">
                <div className="dash-box-shadow pb-5">
                  <div className="thanku-card text-center mb-4 ">
                    <figure className="mb-3">
                      <img src={likeImage} alt="like" />
                    </figure>
                    <h3 className='pt-3'>{message || "Invitation accepted successfully."}</h3>
                    {/* <h3 className='pt-3'>Invitation accepted successfully.</h3> */}
                    {backButton ?
                      <button className='btn btn-primary mt-4' onClick={handleBackClick}><i className="ri-arrow-left-fill" style={{ fontSize: "25px" }}></i></button>
                      : null
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
