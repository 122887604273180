import React, { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import $ from "jquery";
import Breadcrums from '../../../common/breadcrumbs';
import Loader from '../../../common/loader';
import CustomRangepicker from '../../../common/rangepicker';
import StatusFilter from '../../../common/statusFilter';
import { SWAL_SETTINGS, showFilterlist } from '../../../../../utils/Constants';
import { TrimText, formateDate, handleServerValidations, capitalizeFirstLetter, hasPermission, SpecialCharacter } from '../../../../../utils/commonfunction';
import CustomPagination from '../../../common/custompagination';
import Sorting from '../../../common/sorting';
import * as pageServices from "../../../services/pages.services";

const PageTable = () => {
    const params = useParams()
    const location = useLocation();
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [statsupdate, setStatusUpdate] = useState("false");
    const [datalength, setDataLength] = useState();
    const [itemperpage] = useState(10);
    const [sorting, setSorting] = useState({});
    const [defaultSorting, setDefaultSorting] = useState(true);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [search, setSearch] = useState({});
    const [globalsearch, setGlobalSearch] = useState("")
    const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Pages", url: "" },]
    const [resetdate, setResetDate] = useState(false);
    /********** MULTI SELECT >>>>>>********* */
    const [selectedRecords, setSelectedRecords] = useState([]);
    const [otherState, setOtherState] = useState([]);
    const [selectedAllData, setSelectedAllData] = useState(false);
    const selectAllCheckbox = useRef();
    /********** MULTI SELECT <<<<<<********* */

    useEffect(() => {
        if (search) {
            setLoader(true)
            const formData = new FormData()
            formData.append("page", params.pgno)
            formData.append("per_page", itemperpage)
            formData.append("sort", JSON.stringify(sorting))
            formData.append("search", JSON.stringify(search))
            formData.append("global_search", globalsearch)
            pageServices.List(formData).then(data => {
                setDataLength(data.data.total_records);
                setList(data && data.data && data.data.list ? data.data.list : []);
                /********** MULTI SELECT >>>>>>********* */
                setSelectedAllData(data && data.data && data.data.data_ids ? data.data.data_ids : []);
                /********** MULTI SELECT <<<<<<********* */
                setLoader(false);
            }).catch(error => {
                console.log("error ====> ", error);
            })
        }
    }, [location, statsupdate, sorting, search, globalsearch])

    const viewfunction = (row) => {
        navigate(`/admin/cms/pages/view/${row._id}`)
    }
    const Editfunction = (row) => {
        navigate(`/admin/cms/pages/${params.pgno}/edit/${row._id}`)
    }

    const ChangeStatus = (data, Num) => {
        const formData = new FormData();
        let ids = Array.isArray(data) ? data : [data];
        formData.append("o_id", JSON.stringify(ids));
        formData.append("status", Num);
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                pageServices.Status(formData).then(response => {
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                        setStatusUpdate(!statsupdate)
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch(error => {
                    console.log("error===>")
                })
            }
        })
    }

    function Deletefunction(data) {
        Swal.fire({
            customClass: 'swal-wide',
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#403fad',
            cancelButtonColor: '#f1388b',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                const formdata = new FormData();
                let ids = Array.isArray(data) ? data : [data];
                formdata.append("o_id", JSON.stringify(ids));
                pageServices.Delete(formdata).then(response => {
                    if (response.success) {
                        Swal.fire({
                            icon: 'success',
                            text: response.message,
                            ...SWAL_SETTINGS
                        })
                        setStatusUpdate(!statsupdate)
                        /** removing deleted ids from selectedRecords */
                        setSelectedRecords((data) => data.filter((item) => {
                            return ids.indexOf(item) === -1;
                        }));
                    } else {
                        Swal.fire({
                            icon: 'error',
                            text: handleServerValidations(response),
                            ...SWAL_SETTINGS
                        })
                    }
                }).catch(error => {
                    console.log("deleteError")
                })
            }
        })
    }

    const handleSort = (e, column) => {
        setDefaultSorting(false);
        let sort = { order: 0, column: column };
        if (e.target.classList.contains("assc")) {
            sort.order = -1;
        } else {
            sort.order = 1;
        }
        setSorting(sort);
    }

    // search or filter function
    const prepareSearch = (key, value) => {
        let sr = { ...search };
        if (String(value).length > 0) {
            sr[key] = value;
        } else {
            delete sr[key];
        }
        setSearch(sr);
    }
    // search or filer end

    const resetFilter = (e) => {
        e.preventDefault()
        setGlobalSearch("")
        prepareSearch()
        setSearch({})
        // reset customrangepicker & customstatusfilter state using jquery//
        setResetDate(!resetdate)
        $("#defaultstatus")[0].selectedIndex = 0
    }

    /********** MULTI SELECT >>>>>>********* */
    useEffect(() => {
        if (selectedRecords.length === datalength) {
            selectAllCheckbox.current.indeterminate = false;
            selectAllCheckbox.current.checked = true;
        } else if (selectedRecords.length) {
            selectAllCheckbox.current.indeterminate = true;
        } else {
            selectAllCheckbox.current.indeterminate = false;
        }
    }, [selectedRecords]);
    const selectRecord = (userData, check) => {
        if (check) {
            setSelectedRecords((previousState) => [...previousState, userData._id]);
        } else {
            setSelectedRecords((previousState) => previousState.filter((item) => item !== userData._id));
        }
    };
    const handleSelectAll = (check) => {
        if (check) {
            setSelectedRecords(selectedAllData);
        } else {
            setSelectedRecords([]);
        }
    };
    const isSelected = (data) => {
        return (selectedRecords.filter((item) => data?._id === item).length > 0);
    };
    /********** MULTI SELECT <<<<<<<<<********* */

    const goToDuplicate = (row) => {
        navigate(`/admin/cms/pages/add`, { state: row });
    };

    const nonDeletedData = (id, checked) => {
        if(checked){
            let abc = [...otherState];
            abc.push(id);
            setOtherState(abc);
        }else{
            setOtherState(otherState.filter(item=> item !== id ));
        }
    }
console.log("Ramveer",otherState);
    return (
        <>
            <Breadcrums data={breadcrumbs} />
            {/* table section */}
            <div className="animation_fade">
                <div className="card custom-card overflow-hidden">
                    <div className="card-body">
                        <div className='d-flex justify-content-between align-items-center mb-4'>
                            <h6 className="main-content-label">CMS PAGES</h6>
                            <div className='d-flex align-items-center'>
                                <div className='form-group mb-0 me-3'>
                                    <div className="form-group mb-0 rangepicker_container filter_design">
                                        <i className="fa fa-search calender_icon"></i>
                                        <input type="search" className="form-control" value={globalsearch} placeholder="Search" onChange={(e) => { setGlobalSearch(e.target.value) }} />
                                    </div>
                                </div>
                                <div className="me-3">
                                    <CustomRangepicker GetDateRange={(e) => { prepareSearch("createdAt", e) }} resetdate={resetdate} />
                                </div>
                                <div className="me-3">
                                    <StatusFilter data={showFilterlist} prepareSearch={prepareSearch} />
                                </div>
                                <button type="reset" value='Reset' onClick={resetFilter} className="btn btn-warning float-right mr-2">Reset Filter</button>
                                {hasPermission('/admin/cms/pages/add') ?
                                <button className="btn ripple btn-main-primary signbtn" onClick={() => navigate(`/admin/cms/pages/add`)}>Add New</button>
                                :null}
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table id="example-input" className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                                <thead>
                                    <tr>
                                        <th className={"position-relative select_head " +  (hasPermission('/admin/cms/pages/multi-select') ? '': "d-none")}>
                                            <div className="select-check-container">
                                                <label htmlFor="selectAllCheck" style={{ lineHeight: 'unset' }}
                                                    className="ckbox  cp">
                                                    <input
                                                        ref={selectAllCheckbox}
                                                        id="selectAllCheck"
                                                        checked={
                                                            datalength && datalength === selectedRecords.length
                                                        }
                                                        onChange={(e) => {handleSelectAll(e.target.checked);
                                                            setOtherState(e.target.checked?[""]:[]);}} type="checkbox" />
                                                    <span></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Name</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="name" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Title</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="title" />
                                            </div>
                                        </th>
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Slug</span>
                                                <Sorting sort={sorting} handleSort={handleSort} column="slug" />
                                            </div>
                                        </th>
                                     
                                        <th>
                                            <div className='sorting_column'>
                                                <span>Menu Location</span>
                                            </div>
                                        </th>
                                      
                                        <th className="created_head">
                                            <div className='sorting_column'>
                                                <span>Created Date</span>
                                                <Sorting sort={sorting} defaultSorting={defaultSorting} handleSort={handleSort} column="createdAt" />
                                            </div>
                                        </th>
                                        <th>Created By</th>
                                        <th>Updated By</th>
                                        <th className='status_head'>Status</th>
                                        <th className='action_head'>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loader ?
                                        <tr>
                                            <td colSpan={13}><Loader /></td>
                                        </tr> : <>
                                            {list.length ? list.map((row, i) => {
                                                return <tr key={i} className={isSelected(row) ? "selected" : ""}>
                                                    <td className={"position-relative " + (hasPermission('/admin/cms/pages/multi-select') ? '': "d-none")}>
                                                        <div className="select-check-container">
                                                            <label htmlFor={row?._id + "input"} style={{ lineHeight: 'unset' }}
                                                                className="ckbox cp">
                                                                <input
                                                                    id={row?._id + "input"}
                                                                    checked={isSelected(row)}
                                                                    onChange={(e) =>{
                                                                        selectRecord(row, e?.target?.checked);
                                                                        if(row?.position === 'other'){
                                                                            nonDeletedData(row?._id, e?.target?.checked)
                                                                        }
                                                                    }}
                                                                    type="checkbox"
                                                                    className="form-check select-check cp" />
                                                                <span></span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>{row?.name ? capitalizeFirstLetter(row.name) : "N/A"}</td>
                                                    <td>{row?.title ? TrimText(row.title,20) : "N/A"}</td>
                                                    <td>{row?.slug ? row.slug : "N/A"}</td>
                                                    <td>{row?.position ? <span className="badge badge-success text-capitalize">{SpecialCharacter(row.position)}</span> : "N/A"}</td>
                                                    <td>{row?.createdAt ? formateDate(row.createdAt) : "N/A"}</td>
                                                    <td>{row?.createdBy?.name ? <Link to={`/admin/staff/view/${row.createdBy._id}`}>{row.createdBy.name}</Link> : "N/A"}</td>
                                                    <td>{row?.updatedBy?.name ? <Link to={`/admin/staff/view/${row.updatedBy._id}`}>{row.updatedBy.name}</Link> : "N/A"}</td>
                                                    {hasPermission('/admin/cms/pages/status') ?
                                                    <td>
                                                    {row.status === 1 ? 
                                                    <button className="btn ripple btn-main-primary signbtn" 
                                                    onClick={() => { ChangeStatus(row?._id, 0) }}>Active
                                                    </button>
                                                        : 
                                                        <button className="btn ripple btn-secondary" 
                                                        onClick={() => { ChangeStatus(row?._id, 1) }}>Inactive
                                                        </button>}
                                                        </td>
                                                        :
                                                        <td>{row?.status === 1  ? <span className="badge badge-success">Active</span> : <span className="badge badge-danger">Inactive</span>}</td>
                                                        }
                                                    <td>
                                                        <div className="d-flex">
                                                            <button className="btn ripple btn-main-primary signbtn " onClick={() => { viewfunction(row) }}>View</button>
                                                            {hasPermission('/admin/cms/pages/*/edit/*') ?
                                                            <button className="btn ripple btn-success mlAction" onClick={() => { Editfunction(row) }}>Edit</button>
                                                            :null}
                                                            {hasPermission('/admin/cms/pages/more') ?
                                                            <div className="btn-group mlAction">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-dark dropdown-toggle"
                                                                    data-bs-toggle="dropdown"
                                                                    aria-expanded="false"
                                                                >
                                                                    More
                                                                </button>
                                                                <ul className="dropdown-menu">
                                                                {hasPermission('/admin/cms/pages/duplicate') ?
                                                                    <li>
                                                                        <button
                                                                            type="button"
                                                                            className="dropdown-item"
                                                                            onClick={() => goToDuplicate(row)}
                                                                        >
                                                                            Duplicate
                                                                        </button>
                                                                    </li>
                                                                    :null}
                                                                     {hasPermission('/admin/cms/pages/delete') ?
                                                                     <>
                                                                     
                                                                    {row?.position === "other" ? (
                                                                        <></>
                                                                    ) :(
                                                                    <li>
                                                                        <button
                                                                            type="button"
                                                                            className="dropdown-item"
                                                                            onClick={() => { Deletefunction(row?._id) }}
                                                                        >
                                                                            Delete
                                                                        </button>
                                                                    </li>
                                                                    )}</>
                                                                     :null}
                                                                </ul>
                                                            </div>
                                                            :null}
                                                        </div>

                                                    </td>
                                                </tr>
                                            }) : <tr><td colSpan={13} className="text-center">No records</td></tr>}</>}

                                </tbody>
                            </table>
                        </div>
                        <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                        {datalength && datalength > 0 ?
                            <CustomPagination datalength={datalength} itemperpage={itemperpage} setPage={setPage} currentPage={page} pageRoute={[{ name: "PageTable", path: "/admin/cms/pages/list" }]} /> : ""}
                    </div>
                    <div>
                    </div>
                </div>
            </div>
            {/* tablesection end */}
            {/***********MULTI SELECTION OPTIONS >>>>>>*************/}
            {selectedRecords.length ?
                <div className="bulk_actions">
                    <div className="d-flex justify-content-between align-items-center">
                        <div className='d-flex align-items-center'>
                            <i className="fa fa-check-circle" aria-hidden="true" style={{ fontSize: "23px", color: "#35b159" }}></i>
                            <h6 className='ms-2 mt-1 mb-0'>Selected {selectedRecords.length} {selectedRecords.length > 1 ? "records" : "record"} of {datalength}</h6>
                        </div>
                        <div>
                            <ul>
                            {hasPermission('/admin/cms/pages/status') ?
                                <li>
                                    <button className="btn ripple btn-main-primary signbtn" onClick={() => { ChangeStatus(selectedRecords, 1) }}>Active</button>
                                </li>
                                :null}
                                {hasPermission('/admin/cms/pages/status') ?
                                <li>
                                    <button className="btn ripple btn-secondary" onClick={() => { ChangeStatus(selectedRecords, 0) }}>Inactive</button>
                                </li>
                                :null}
                                 {hasPermission('/admin/cms/pages/delete') ?
                                 <>

                                {!otherState.length ? (
                                <li>
                                    <button className="btn ripple btn-secondary" onClick={() => { Deletefunction(selectedRecords) }}>Delete</button>
                                </li>
                                ) : (
                                    <></>
                                )}
                                 </>
                                 :null}
                            </ul>
                        </div>
                    </div>
                </div>
                : ""
            }
            {/***********MULTI SELECTION OPTIONS <<<<<<*************/}
        </>
    )
}

export default PageTable