import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import toHtml from 'html-react-parser';
import * as eventsService from '../../../services/events.services';
import Breadcrums from '../../../common/breadcrumbs';
import Loader from '../../../common/loader';
import { formatetime, showStatus, formateDate } from '../../../../../utils/commonfunction';


const InvitationView = (props) => {
  const navigate = useNavigate()
  const params = useParams()
  const [showdefault, setShowDefault] = useState({})
  const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Invitations", url: "/admin/event-management/invitations/list/1" }, { title: "View", url: "" },]


  useEffect(() => {
    eventsService.InvitationsDetails(params?.id).then(response => {
      setShowDefault(response && response.data ? response.data : [])
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [])


  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="main-content-label mb-3">View Edit</h6>
              </div>
              {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                <div className="col-lg-12 form-group">
                  <table id="simple-table" className="table  table-bordered table-hover">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <td>{showdefault && showdefault.name ? showdefault.name : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Email</th>
                        <td>{showdefault && showdefault?.email ? showdefault?.email : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Event Name </th>
                        <td>{showdefault && showdefault?.event_data?.title ? showdefault?.event_data?.title : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Status</th>
                        <td>{showdefault && showdefault.status === 0 ? toHtml(showStatus(showdefault.status)) : toHtml(showStatus(showdefault?.status))}</td>
                      </tr>
                      <tr>
                        <th>CreatedAt</th>
                        <td>{showdefault && showdefault?.createdAt && showdefault?.createdAt ? showdefault?.createdAt : "N/A"}</td>
                      </tr>
                    </tbody>
                  </table>

                  <h6 className="main-content-label mb-3">Description</h6>
                  {/* <div className="mb-3">{toHtml(showdefault && showdefault.description ? showdefault.description : "N/A")}</div> */}
                  <div dangerouslySetInnerHTML={{__html: showdefault && showdefault.description ? showdefault.description : "N/A"}}></div>

                  <h6 className="main-content-label mb-3 pt-3">Events Dates</h6>
                  {showdefault && Object.keys(showdefault).length > 0 && showdefault.timetable && showdefault.timetable.map((data, index) => (
                    <div className="accordion mb-3" id={"accordionExample" + index} key={index}>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne" + index} aria-expanded="true" aria-controls={"collapseOne" + index}>
                            {data && data.title ? data.title : "N/A"}
                          </button>
                        </h2>
                        <div id={"collapseOne" + index} className="accordion-collapse collapse" data-bs-parent={"#accordionExample" + index}>
                          <div className="accordion-body">
                            <table id="simple-table" className="table  table-bordered table-hover">
                              <tbody>
                                <tr>
                                  <th>Title</th>
                                  <td>{data && data.title ? data.title : "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>Venue</th>
                                  <td>{data && data.venue_data && data.venue_data.title ? data.venue_data.title : "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>Workshop</th>
                                  <td>{data && data.workshop_data && data.workshop_data.title ? data.workshop_data.title : "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>Ticket</th>
                                  <td>{data && data.tickets_data && data.tickets_data.title ? data.tickets_data.title : "N/A"}</td>
                                </tr>
                                <tr>
                                  <th>StartDate</th>
                                  <td>{data && data.start_date ? formateDate(data.start_date) : ""}</td>
                                </tr>
                                <tr>
                                  <th>Start Time</th>
                                  <td>{data && data.start_time ? formatetime(data.start_time) : ""}</td>
                                </tr>
                                <tr>
                                  <th>End Time</th>
                                  <td>{data && data.end_time ? formatetime(data.end_time) : ""}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="mt-5">
                    <button className="btn ripple btn-secondary" type='button' onClick={() => navigate(-1)}>
                      <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                      Back
                    </button>
                  </div>
                </div>
              </div> : <Loader />}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InvitationView;