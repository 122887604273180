import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import * as eventServices from "../../services/events.services";
import {
  formateDate,
} from "../../../../utils/commonfunction";
import Breadcrums from "../../common/breadcrumbs";
import Loader from "../../common/loader";
import CustomPagination from "../../common/custompagination";

const ViewLinkStatics = () => {
  const params = useParams();
  const [showdefault, setShowDefault] = useState([]);
  const [globalsearch, setGlobalSearch] = useState("");
  const [datalength, setDataLength] = useState("");
  const [itemperpage] = useState(10);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const { state } = location;
  const { event_id, id } = state || {};
  const breadcrumbs = [
    { title: "Dashboard", url: "/admin/dashboard" },
    { title: "View Event", url: "/admin/event-management/events/list/1" },
    {
      title: "Drip Content",
      url: `/admin/event-management/events/view/event-drip-content/${event_id}?page=1&count_page=1`,
    },
    { title: "Link Statics Details", url: "" },
  ];
  const [loader, setLoader] = useState(true);

  useEffect(() => {
      setLoader(true);
      const formData = new FormData();
         formData.append("url", id);
         formData.append("page", params.pgno)
         formData.append("per_page", itemperpage);
         formData.append("global_search", globalsearch);
      eventServices
        .LinkStaticsDetails(formData)
        .then((data) => {
          setDataLength(data?.data?.total_records);
          setShowDefault(data.data.list);
          setPage(data && data?.data && data?.data.page ? data?.data?.page : 1);
          setLoader(false);
        })
        .catch((error) => {
          console.log("error ====> ", error);
        });
  }, [globalsearch,params.pgno,id]);

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
  };

  return (
    <>
      <Breadcrums data={breadcrumbs} />
        <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center mb-4">
         <h6 className="main-content-label"><span></span></h6>
         <div className="d-flex align-items-center">
           <div className="form-group mb-0 me-3">
             <div className="form-group mb-0 rangepicker_container filter_design">
               <i className="fa fa-search calender_icon"></i>
               <input
                 type="search"
                 className="form-control"
                 value={globalsearch}
                 placeholder="Search"
                 onChange={(e) => {
                  setGlobalSearch(e.target.value);
                 }}
               />
             </div>
           </div>
           <button
             type="reset"
             value="Reset"
             onClick={resetFilter}
             className="btn btn-warning float-right mr-2"
           >
            Reset Filter
          </button>
         </div>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
          <thead>
            <tr>
              <th>Category</th>
              <th>Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th className="create_head">Created Date</th>
            </tr>
          </thead>
          <tbody>
            {loader ? (
              <tr>
                <td colSpan={5}>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {showdefault?.length ? (
                  showdefault.map((row, i) => (
                    <tr key={i}>
                      <td className="text-capitalize">{row?.category ? row.category : "N/A"}</td>
                      <td>{row?.user_data?.name ? row?.user_data?.name : "N/A"}</td>
                      <td>{row?.user_data?.email ? row?.user_data?.email : "N/A"}</td>
                      <td>{row?.user_data?.phone_number?.number ? row?.user_data?.phone_number?.number : "N/A"}</td>
                      <td>
                        {row?.createdAt ? formateDate(row?.createdAt) : "N/A"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="text-center">
                      No records
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
       <div
        className=""
        id="example1_info"
        role="status"
        aria-live="polite"
       >
        <b>Total Records : {datalength ? datalength : "0"}</b>
      </div>
      {datalength && datalength > 0 ? (
        <CustomPagination
         datalength={datalength}
         itemperpage={itemperpage}
         currentPage={page}
         setPage={setPage}
           pageRoute={[
             {
              name: "Invitees",
              path: `/admin/event-management/events/view/link-statics-details`,
             },
           ]}
         />
      ) : (
        ""
       )}
     </div>
    </>
  );
};

export default ViewLinkStatics;
