import axiosInstance from "../../../utils/axios";

const path = "admin/email-logs";

export const List = async (values) => {
    return await axiosInstance().post(`${path}/list`, values);
}

export const ResendEmail = async (values) => {
    return await axiosInstance().post(`${path}/resend-email`, values);
}

export const Details = async (id) => {
    return await axiosInstance().get(`${path}/details?o_id=${id}`);
}
